import { DeleteOutlined } from '@ant-design/icons';
import { contractApi } from '@api/contractApi';
import { AppTable } from '@components/molecules/AppTable';
import { LinkToContract as linkToContractComponent } from '@components/organisms/ContractsTable/libs/components/LinkToContract';
import type { Contract } from '@components/types/models/Contract';
import { defineContractType, defineScopeOfWorks } from '@components/types/models/Contract';
import { PERMISSIONS } from '@components/types/models/Permissions';
import { UserContext } from '@contexts/userContext';
import { isUserHasPermission } from '@utils/permissionHelper';
import { Button, notification, Popconfirm, Row, Table, Typography } from 'antd';
import type { AxiosError } from 'axios';
import type { FC } from 'react';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ContractStatus as contractStatus } from './libs/components/ContractStatus';
import { ContractFilters } from './libs/components/Filters';
import type { ContractsTableProps } from './props';

export const ContractsTable: FC<ContractsTableProps> = ({
  contractsList,
  isLoading,
  linkToContract,
  refetch,
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [, i18n] = useTranslation();
  const [activeFilter, setActiveFilter] = useState(0);
  const { userRole, user } = useContext(UserContext);
  const { Column } = Table;

  const handleFilterClick = useCallback(
    (filter) => () => {
      setActiveFilter(filter);
    },
    [setActiveFilter]
  );

  const filteredList = (): readonly Contract[] => {
    if (activeFilter === 0) {
      return contractsList;
    }
    return contractsList.filter((item: Contract) => item.contractStatus === activeFilter);
  };

  const deleteContractMutation = useMutation(
    async (contractId: number) => contractApi.deleteContract(contractId),
    {
      onSuccess() {
        notification.success({ message: t('contracts.contractDeleted') });
        refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );
  const handleDeleteContract = useCallback(
    (contractId) => () => {
      deleteContractMutation.mutate(contractId);
    },
    [deleteContractMutation]
  );

  const hasContractEditPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACT_EDIT
  );
  const hasContractViewPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACT_VIEW
  );

  return (
    <>
      <ContractFilters activeFilter={activeFilter} onFilterClick={handleFilterClick} />
      <AppTable
        locale={{ emptyText: t('noData') }}
        loading={isLoading}
        dataSource={filteredList()}
        subtitle={`${t('contracts.totalContracts')}: ${filteredList().length}`}
      >
        <Column
          key="contractNumber"
          title={t('contractors.contractNumber')}
          dataIndex="contractNumber"
          render={(contractNumber: number, record: { readonly id: number }) =>
            hasContractViewPermission ? (
              linkToContractComponent({ contractNumber, id: record.id, linkToContract })
            ) : (
              <Text>{contractNumber}</Text>
            )
          }
        />
        <Column
          key="version"
          title={t('contractors.version')}
          dataIndex="version"
          // eslint-disable-next-line react/jsx-no-literals
          render={(version: number) => <Text>{`v${version}`}</Text>}
        />
        <Column
          key="contractType"
          title={t('contractors.type')}
          dataIndex="contractType"
          render={(contractType: number) => (
            <Text>
              {i18n.language === 'en'
                ? defineContractType(contractType).enName
                : defineContractType(contractType).ruName}
            </Text>
          )}
        />
        <Column
          key="masterAgreement"
          title={t('contractors.masterAgreement')}
          render={(item: Contract) => <Text>{item.masterAgreement?.contractNumber}</Text>}
        />
        {userRole === 'contractor' && (
          <Column
            key="scopes"
            title={t('contracts.scopeOfWorks')}
            dataIndex="scopes"
            render={(scopes: readonly number[]) => (
              <Row>
                {scopes.map((item: number, index) => (
                  <Text key={index}>
                    {(index ? ', ' : '') +
                      (i18n.language === 'en'
                        ? defineScopeOfWorks(item).enName
                        : defineScopeOfWorks(item).ruName)}
                  </Text>
                ))}
              </Row>
            )}
          />
        )}
        <Column
          key="contractStatus"
          title={t('contractors.status')}
          dataIndex="contractStatus"
          render={(statusNumber: number) =>
            contractStatus({
              statusNumber,
            })
          }
        />
        {userRole === 'admin' ? (
          <Column
            key="actions"
            title={t('actions')}
            render={(item: Contract) => (
              <Popconfirm
                title={
                  <Text>
                    {t('contracts.confirmDelete', {
                      name: item.contractNumber,
                    })}
                    <br />
                    {t('contracts.confirmDeleteSecondLine')}
                  </Text>
                }
                cancelText={t('cancel')}
                okText={t('yes')}
                placement="topLeft"
                disabled={!hasContractEditPermission}
                style={{ maxWidth: '300px' }}
                onConfirm={handleDeleteContract(item.id)}
              >
                <Button
                  danger
                  disabled={!hasContractEditPermission}
                  title={t('delete')}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            )}
          />
        ) : null}
      </AppTable>
    </>
  );
};
