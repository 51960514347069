import { PlusOutlined } from '@ant-design/icons';
import { parseAmendmentFormFields } from '@components/organisms/UpdateContractForm/libs/parseAmendmentFormFields';
import type { AmendmentFormFields } from '@components/types/models/Amendment';
import { CONTRACT_TYPE, defineContractType } from '@components/types/models/Contract';
import { STATUS } from '@components/types/models/Statuses';
import { css } from '@linaria/core';
import { isFloat, isPercentage } from '@utils/validate';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from 'antd';
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import type { AxiosError } from 'axios';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC, FormEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import tw from 'twin.macro';

import { createAmendment, resubmitAmendment } from './libs/services';
import type { UpdateContractFormProps } from './props';

const { Text } = Typography;

const scopeOfWorks = [
  { enName: 'Goods', ruName: 'Товары', id: 1 },
  { enName: 'Services', ruName: 'Услуги', id: 2 },
  { enName: 'Works', ruName: 'Работы', id: 3 },
];

export const UpdateContractForm: FC<UpdateContractFormProps> = ({
  contract,
  currencyList,
  refetch,
  amendment,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState<UploadFile>();

  const { TextArea } = Input;
  const { Option } = Select;
  const contractTypeTitle =
    i18n.language === 'en'
      ? defineContractType(contract?.contractType).enName
      : defineContractType(contract?.contractType).ruName;

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
    watch,
  } = useForm();

  const buttonTitle = useMemo(
    () =>
      (amendment &&
        ((amendment.contractStatus === STATUS.REJECTED && t('contracts.updateAndResubmit')) ||
          (amendment.contractStatus === STATUS.DRAFT && t('contracts.updateContract')) ||
          (amendment.contractStatus === STATUS.APPROVED && t('contracts.addAmendment')))) ||
      (contract?.contractStatus === STATUS.REJECTED && t('contracts.updateAndResubmit')) ||
      (contract?.contractStatus === STATUS.DRAFT && t('contracts.updateContract')) ||
      (contract?.contractStatus === STATUS.APPROVED && t('contracts.addAmendment')),
    [contract, amendment, t]
  );

  const modalTitle = useMemo(
    () =>
      (amendment &&
        ((amendment.contractStatus === STATUS.REJECTED && t('contracts.updateAndResubmit')) ||
          (amendment.contractStatus === STATUS.DRAFT && t('contracts.updateContract')) ||
          (amendment.contractStatus === STATUS.APPROVED && t('contracts.newAmendment')))) ||
      (contract?.contractStatus === STATUS.REJECTED && t('contracts.updateAndResubmit')) ||
      (contract?.contractStatus === STATUS.DRAFT && t('contracts.updateContract')) ||
      (contract?.contractStatus === STATUS.APPROVED && t('contracts.newAmendment')),
    [contract, amendment, t]
  );

  useEffect(() => {
    if (amendment) {
      setValue('startDate', moment(amendment.startDate));
      setValue('endDate', moment(amendment.endDate));
      setValue('kcTarget', amendment.kcTarget);
      setValue('amount', amendment.amount);
      setValue('comment', amendment.comment);
      setValue('detailsOnScopeWork', amendment.detailsOnScopeOfWork);
      setValue('currencyId', amendment.currencyId);
      setValue('scopeOfWorks', contract?.contractType === CONTRACT_TYPE.MASTER_AGREEMENT ? global.undefined : amendment.scopes);
    } else if (contract?.contractType === CONTRACT_TYPE.SUB_AGREEMENT) {
      setValue('startDate', moment(contract.masterAgreement?.contractStartDate));
      setValue('endDate', moment(contract.masterAgreement?.contractEndDate));
      setValue('kcTarget', global.undefined);
      setValue('amount', global.undefined);
      setValue('comment', global.undefined);
      setValue('detailsOnScopeWork', global.undefined);
      setValue('currencyId', global.undefined);
      setValue('scopeOfWorks', global.undefined);
    } else {
      reset();
    }
  }, [
    amendment,
    setValue,
    isModalVisible,
    reset,
    contract?.contractType,
    contract?.masterAgreement?.contractStartDate,
    contract?.masterAgreement?.contractEndDate,
  ]);

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };
  const handleCloseModal = (): void => {
    reset();
    setIsModalVisible(false);
  };

  const isSubmitButtonDisable =
    contract?.contractType !== CONTRACT_TYPE.MASTER_AGREEMENT &&
    (watch('scopeOfWorks')?.length < 1 || !watch('scopeOfWorks'));

  const mutation = useMutation(
    async (values: AmendmentFormFields) => {
      const formData = new FormData();
      if (Object.keys(values).length !== 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const n: any = {
          ...parseAmendmentFormFields(values, file),
          amendmentId: amendment?.id ?? global.undefined,
          contractId: contract?.id,
        };
        n.ScopeOfWorks?.forEach((value: number) => {
          formData.append('ScopeOfWorks', `${value}`);
        });
        Object.keys(n).forEach((key) => {
          if (n[key] && !['contractId', 'amendmentId', 'ScopeOfWorks'].includes(key)) {
            formData.append(key, n[key]);
          }
        });
      }
      if (contract?.contractStatus === STATUS.REJECTED) {
        const id = amendment?.id ?? 0;
        return resubmitAmendment(contract.id, id, formData);
      }
      return contract && createAmendment(contract.id, formData);
    },
    {
      onSuccess: () => {
        handleCloseModal();
        const contractStatus = contract?.contractStatus;
        refetch();
        switch (contractStatus) {
          case STATUS.APPROVED: {
            notification.success({
              message: t('contracts.newAmendmentSent'),
            });
            break;
          }
          case STATUS.REJECTED: {
            notification.success({
              message: t('contracts.amendmentResubmitted'),
            });
            break;
          }
          default: {
            notification.success({
              message: t('contracts.successUpdate'),
            });
            break;
          }
        }
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: { readonly name: string; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('error', { message: errData.message });
        }
      },
    }
  );
  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: AmendmentFormFields): void => {
      mutation.mutate({ ...values });
    })();
  }, [clearErrors, mutation, handleSubmit]);

  const handleDateChange = useCallback(
    (name) => (date: Moment | null) => {
      setValue(name, date);
    },
    [setValue]
  );

  const handleAmountChange = (e: FormEvent<HTMLInputElement>): void => {
    if (isFloat(e.currentTarget.value)) {
      setValue('amount', e.currentTarget.value);
    }
  };

  const handleCurrencyChange = (id: number): void => {
    setValue('currencyId', id);
  };

  const handleCheckboxChange = useCallback(
    (name) => (value: unknown) => {
      setValue(name, value);
    },
    [setValue]
  );
  const handleFileChange = (fileList: UploadChangeParam): void => {
    setFile(fileList.fileList[0]?.originFileObj);
  };

  const handleKcTargetChange = (e: FormEvent<HTMLInputElement>): void => {
    if (isPercentage(e.currentTarget.value) && isFloat(e.currentTarget.value)) {
      setValue('kcTarget', e.currentTarget.value);
    }
  };

  const handleTextareaChange = useCallback(
    (name: string) => (e: FormEvent<HTMLTextAreaElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const disabledDate = (current: Moment): boolean => {
    if (current < moment(contract?.masterAgreement?.contractStartDate)) {
      return true;
    } else if (current > moment(contract?.masterAgreement?.contractEndDate)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Button type="primary" onClick={handleOpenModal}>
        {buttonTitle}
      </Button>
      <Modal
        destroyOnClose
        visible={isModalVisible}
        title={modalTitle}
        width={1000}
        footer={null}
        confirmLoading={mutation.isLoading && !mutation.isError && !mutation.isSuccess}
        onCancel={handleCloseModal}
      >
        {contract?.contractStatus === STATUS.APPROVED && (
          <Alert
            showIcon
            message={<Text>{t('contracts.instructionMessage')}</Text>}
            description={<Text>{t('contracts.instructionExample')}</Text>}
            type="info"
            className={css`
              ${tw`mb-4`}
            `}
          />
        )}
        <Form layout="vertical" onFinish={handleFormSubmit}>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label={t('contracts.contractNumber')}>
                <Input readOnly disabled value={contract?.contractNumber} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t('contracts.kcCategoryAndArea')}>
                <Input readOnly disabled value={contract?.kcCategory.name} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t('contracts.contractType')}>
                <Input readOnly disabled value={contractTypeTitle} />
              </Form.Item>
            </Col>
          </Row>
          {contract?.masterAgreement && (
            <Form.Item label={t('contracts.masterAgreement')}>
              <Input readOnly disabled value={contract.masterAgreement.contractNumber} />
            </Form.Item>
          )}
          <Text type="danger">{errors.error?.message}</Text>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                required
                label={t('contracts.startDate')}
                validateStatus={errors.startDate?.message && 'error'}
                help={errors.startDate?.message}
              >
                <DatePicker
                  disabledDate={
                    contract?.contractType === CONTRACT_TYPE.SUB_AGREEMENT
                      ? disabledDate
                      : global.undefined
                  }
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  value={watch('startDate')}
                  onChange={handleDateChange('startDate')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                label={t('contracts.amount')}
                validateStatus={errors.amount?.message && 'error'}
                help={errors.amount?.message}
              >
                <Input type="number" value={watch('amount')} onChange={handleAmountChange} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                label={t('contracts.currency')}
                validateStatus={errors.currencyId?.message && 'error'}
                help={errors.currencyId?.message}
              >
                <Select
                  showSearch
                  value={watch('currencyId')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleCurrencyChange}
                >
                  {currencyList
                    ?.filter((currency) => currency.enabled)
                    ?.map((availableCurrency) => (
                      <Option key={availableCurrency.id} value={availableCurrency.id}>
                        <span>{availableCurrency.code}</span>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                required
                label={t('contracts.endDate')}
                validateStatus={errors.endDate?.message && 'error'}
                help={errors.endDate?.message}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD"
                  disabledDate={
                    contract?.contractType === CONTRACT_TYPE.SUB_AGREEMENT
                      ? disabledDate
                      : global.undefined
                  }
                  value={watch('endDate')}
                  onChange={handleDateChange('endDate')}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                required
                label={t('contracts.kcTarget')}
                validateStatus={errors.kcTarget?.message && 'error'}
                help={errors.kcTarget?.message}
              >
                <Input
                  step=".01"
                  type="number"
                  value={watch('kcTarget')}
                  onChange={handleKcTargetChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {contract?.contractType !== CONTRACT_TYPE.MASTER_AGREEMENT && (
            <Form.Item
              required
              label={t('contracts.scopeOfWorks')}
              validateStatus={errors.scopeOfWorks?.message && 'error'}
              help={errors.scopeOfWorks?.message}
            >
              <Checkbox.Group
                value={watch('scopeOfWorks')}
                style={{ display: 'flex', flexDirection: 'row' }}
                onChange={handleCheckboxChange('scopeOfWorks')}
              >
                {scopeOfWorks.map(
                  (
                    item: { readonly id: number; readonly enName: string; readonly ruName: string },
                    index
                  ) => (
                    <Checkbox key={index} value={item.id}>
                      {i18n.language === 'en' ? item.enName : item.ruName}
                    </Checkbox>
                  )
                )}
              </Checkbox.Group>
            </Form.Item>
          )}
          <Form.Item
            label={t('contracts.detailsOnScopeWork')}
            validateStatus={errors.detailsOnScopeOfWork?.message && 'error'}
            help={errors.detailsOnScopeOfWork?.message}
          >
            <TextArea
              value={watch('detailsOnScopeWork')}
              onChange={handleTextareaChange('detailsOnScopeWork')}
            />
          </Form.Item>
          <Form.Item
            label={t('contracts.comment')}
            validateStatus={errors.comment?.message && 'error'}
            help={errors.comment?.message}
          >
            <TextArea value={watch('comment')} onChange={handleTextareaChange('comment')} />
          </Form.Item>
          <Row>
            <Form.Item
              validateStatus={errors.kcPlanFile?.message && 'error'}
              help={errors.kcPlanFile?.message}
            >
              <Upload
                style={{ alignItems: 'flex-end' }}
                maxCount={1}
                accept=".pdf,.doc, .docx"
                beforeUpload={() => false}
                onChange={handleFileChange}
              >
                <Button type="primary" htmlType="button" icon={<PlusOutlined color="primary" />}>
                  {t('contracts.attach')}
                </Button>
              </Upload>
            </Form.Item>
          </Row>
          <Row gutter={8}>
            <Col md={24}>
              <br />
              <Form.Item noStyle>
                <Row justify="end">
                  <Col>
                    <Space align="end">
                      <Button htmlType="button" onClick={handleCloseModal}>
                        {t('cancel')}
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={mutation.isLoading}
                        disabled={isSubmitButtonDisable}
                      >
                        {t('save')}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
//
