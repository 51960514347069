import { PaginatedResponse } from "@api";
import axios, { AxiosResponse } from "axios";
import { MigrationRecord, MigrationType } from "../types/MigrationTypes";

const getMigrationList = async (page: number, pageSize?: number): Promise<PaginatedResponse<MigrationRecord>> => axios.get(`/api/migrations`, {
  params: { page, pageSize }
}).then(response => response.data);

const postMigration = async (type: MigrationType, data: FormData): Promise<AxiosResponse> =>
  axios.post(`/api/migrations/${type}`, data);

export {
  getMigrationList,
  postMigration
}