import {
  AlignLeftOutlined,
  AreaChartOutlined,
  CheckOutlined,
  CopyOutlined,
  DollarOutlined,
  FileTextOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { ActualView } from '@components/pages/admin/Actual/ActualView';
import { Actuals } from '@components/pages/admin/Actuals';
import { ContractView } from '@components/pages/admin/Contract/ContractView';
import { Contractor } from '@components/pages/admin/Contractor/Contractor';
import { ContractorsPage } from '@components/pages/admin/Contractor/ContractorsPage';
import { CountriesCitiesList } from '@components/pages/admin/CountriesCities';
import { ExchangeRateListPage } from '@components/pages/admin/ExchangeRates';
import { ForecastView } from '@components/pages/admin/Forecast/ForecastView';
import { Forecasts } from '@components/pages/admin/Forecasts';
import { InvoicesList } from '@components/pages/admin/Invoice';
import { InvoiceView } from '@components/pages/admin/Invoice/InvoiceView';
import { Member } from '@components/pages/admin/Member';
import { EditMember } from '@components/pages/admin/Member/EditMember';
import { InviteMember } from '@components/pages/admin/Member/InviteMember';
import { AdminDashboardPage } from '@components/pages/admin/Reports/AdminDashboardPage';
import { DatadumpListPage } from '@components/pages/admin/Reports/DatadumpListPage';
import { UserGuide } from '@components/pages/admin/UserGuide/component';
import { GlossaryPage } from '@components/pages/GlossaryPage';
import { Profile } from '@components/pages/Profile';
import { DefaultLayout } from '@components/templates/DefaultLayout';
import { PERMISSIONS } from '@components/types/models/Permissions';
import type { RouteConfig, RouteItem } from '@components/types/RouteConfig';
import { UserContext } from '@contexts/userContext';
import { MigrationListPage } from '@modules/Migrations/pages/MigrationListPage';
import { MigrationUploadPage } from '@modules/Migrations/pages/MigrationUploadPage';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

const adminRoutesInit: RouteConfig = [
  {
    id: 'profile',
    path: '/profile',
    component: Profile,
  },
  {
    id: 'reports',
    text: 'reports.reports',
    icon: <AreaChartOutlined />,
    items: [
      {
        id: 'reports.dashboards',
        text: 'reports.dashboards',
        path: '/admin/reports',
        component: AdminDashboardPage,
      },
      {
        id: 'reports.datadumps',
        text: 'reports.datadump',
        path: '/admin/datadump',
        component: DatadumpListPage,
      },
    ],
    isNavItem: true,
  },
  {
    id: 'members',
    text: 'members.members',
    icon: <TeamOutlined />,
    path: '/admin/member',
    component: Member,
    isNavItem: true,
    permissions: [PERMISSIONS.MEMBER_VIEW],
  },
  {
    id: 'newMember',
    path: '/admin/member/new',
    component: InviteMember,
  },
  {
    id: 'editMember',
    path: '/admin/member/:id/edit',
    component: EditMember,
  },
  {
    id: 'contractors',
    text: 'contractors.contractors',
    icon: <CopyOutlined />,
    path: '/admin/contractor',
    component: ContractorsPage,
    isNavItem: true,
    permissions: [PERMISSIONS.CONTRACTOR_VIEW],
  },
  {
    id: 'contractor',
    path: '/admin/contractor/:id',
    component: Contractor,
  },
  {
    id: 'contract',
    path: '/admin/contract/:id',
    component: ContractView,
  },
  {
    id: 'userGuide',
    path: '/admin/user-guide',
    component: UserGuide,
  },
  {
    id: 'forecasts',
    text: 'forecasts.forecasts',
    icon: <FileTextOutlined />,
    path: '/admin/forecasts',
    component: Forecasts,
    isNavItem: true,
  },
  {
    id: 'forecast',
    path: '/admin/forecast/:id',
    component: ForecastView,
  },
  {
    id: 'actuals',
    text: 'actuals.actuals',
    icon: <CheckOutlined />,
    path: '/admin/actuals',
    component: Actuals,
    isNavItem: true,
  },
  {
    id: 'actual',
    text: 'actuals.actual',
    icon: <CheckOutlined />,
    path: '/admin/actual/:id',
    component: ActualView,
  },
  {
    id: 'invoices',
    text: 'invoices.g1cForms',
    icon: <DollarOutlined />,
    path: '/admin/payment-application',
    component: InvoicesList,
    isNavItem: true,
  },
  {
    id: 'invoices',
    path: '/admin/payment-application/:id',
    component: InvoiceView,
  },
  {
    id: 'glossary',
    text: 'glossary.glossary',
    icon: <AlignLeftOutlined />,
    path: '/glossary',
    component: GlossaryPage,
    isNavItem: true,
  },
  {
    id: 'settings',
    text: 'settings',
    icon: <SettingOutlined />,
    items: [
      {
        id: 'exchangeRates',
        text: 'exchangeRates.exchangeRates',
        path: '/admin/exchange-rates',
        component: ExchangeRateListPage,
      },
      {
        id: 'migrations',
        text: 'migrations.menuTitle',
        path: '/admin/migrations',
        component: MigrationListPage,
      },
      {
        id: 'reports.countriesAndCities',
        text: 'reports.countriesAndCities',
        path: '/admin/countries-and-cities',
        component: CountriesCitiesList,
      },
    ],

    isNavItem: true,
  },
  {
    id: 'migrations.new',
    path: '/admin/migrations/new',
    component: MigrationUploadPage,
  },
];

const AdminRoutes: React.FC = () => {
  const { user, notices } = useContext(UserContext);
  const location = useLocation();

  const [adminRoutes, setAdminRoutes] = useState<RouteConfig>(adminRoutesInit);

  useEffect(() => {
    setAdminRoutes((oldRoutes) =>
      oldRoutes.map((route) => {
        switch (route.id) {
          case 'actuals': {
            return {
              ...route,
              notice: notices?.actuals ?? 0,
            };
          }
          case 'forecasts': {
            return {
              ...route,
              notice: notices?.forecasts ?? 0,
            };
          }
          case 'forms': {
            return {
              ...route,
              notice: notices?.paymentApplications ?? 0,
            };
          }
          case 'contractors': {
            return {
              ...route,
              notice: notices?.amendments ?? 0,
            };
          }
          case 'invoices': {
            return {
              ...route,
              notice: notices?.paymentApplications ?? 0,
            };
          }
          default: {
            return route;
          }
        }
      })
    );
  }, [notices]);

  const filteredAdminRoutes = useMemo(
    () =>
      adminRoutes.filter((route) =>
        route.permissions ? route.permissions.every((p) => user?.permissions.includes(p)) : true
      ),
    [adminRoutes, user]
  );

  return (
    <DefaultLayout
      navItems={filteredAdminRoutes}
      isEmbedContent={location.pathname === '/admin/reports'}
    >
      <Switch>
        {filteredAdminRoutes.map((route: RouteItem, i) => {
          if (route.component) {
            return <Route key={`${i}.0`} exact path={route.path} component={route.component} />;
          }

          return route.items?.map((subRoute: RouteItem, j) => (
            <Route key={`${i}.${j}`} exact path={subRoute.path} component={subRoute.component} />
          ));
        })}
        <Route exact path="/" render={() => <Redirect to="/profile" />} />
        {/* <Route component={ErrorPage} /> */}
      </Switch>
    </DefaultLayout>
  );
};
export default AdminRoutes;
