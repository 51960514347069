import { useMemo, useState } from 'react';

type ModalState<T = boolean> = {
  readonly isOpen: boolean;
  readonly activeRecord: T | undefined;
  readonly handleOpen: (recordId: T) => void;
  readonly handleClose: () => void;
};

/**
 * Provides state for modal windows. Could be used for opening regular modals (boolean state)
 * or opening modals with custom state (for example opening edit modal for particular record)
 *
 * @param defaultState
 * @returns Object with isOpen
 */
export function useModalState<T = boolean>(defaultState?: T): ModalState<T> {
  const [activeRecord, setActiveRecord] = useState<T | undefined>(defaultState);
  const isOpen = useMemo(() => Boolean(activeRecord), [activeRecord]);

  const handleOpen = (recordId: T): void => {
    setActiveRecord(recordId);
  };

  const handleClose = (): void => {
    // eslint-disable-next-line
    setActiveRecord(undefined);
  };

  return { isOpen, activeRecord, handleOpen, handleClose };
}
