import { Space, Typography } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UserguideTemplate } from '@components/templates/UserguideTemplate';
import { contractorUserguides } from '@utils/userguides/contractor';

export const ContractorUserGuide: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Title>{t("login.userGuide")}</Title>
      <UserguideTemplate guides={contractorUserguides} />
    </Space>
  );
};
