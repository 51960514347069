import { Button, Col, Form, Input, message, Modal, Row, Space } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type { CreateCountryFormProps } from './props';

export const CreateCountryForm: FC<CreateCountryFormProps> = ({ refetchCountriesList }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const {
    handleSubmit,
    setError,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const handleOpenModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    reset();
    setIsModalVisible(false);
  }, [reset]);

  const addCountryMutation = useMutation(
    async (values: unknown) => axios.post(`/api/country`, values).then((res) => res.data),
    {
      onSuccess: () => {
        void message.success(t('countriesAndCities.countryWasSuccessfullyAdded'));
        refetchCountriesList();
        handleCloseModal();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: { readonly name: string; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('error', { message: errData.message });
        }
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    void handleSubmit((values: { readonly comment: string }) => {
      addCountryMutation.mutate(values);
    })();
  }, [handleSubmit, addCountryMutation]);

  return (
    <>
      <Button type="primary" onClick={handleOpenModal}>
        {t('countriesAndCities.addNewCountry')}
      </Button>
      <Modal
        title={t('countriesAndCities.addNewCountry')}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            label={t('countriesAndCities.enName')}
            validateStatus={errors.nameEn?.message && 'error'}
            help={errors.nameEn?.message}
          >
            <Input {...register('nameEn')} />
          </Form.Item>
          <Form.Item
            label={t('countriesAndCities.ruName')}
            validateStatus={errors.nameRu?.message && 'error'}
            help={errors.nameRu?.message}
          >
            <Input {...register('nameRu')} />
          </Form.Item>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button htmlType="button" onClick={handleCloseModal}>
                  {t('cancel')}
                </Button>
                <Button htmlType="submit" type="primary" loading={addCountryMutation.isLoading}>
                  {t('add')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
