import { DeleteOutlined, EditOutlined, PoweroffOutlined, RedoOutlined } from '@ant-design/icons';
import { adminApi } from '@api/adminApi';
import type { AdminMember } from '@components/types/models/AdminMember';
import type { Member } from '@components/types/models/Member';
import { MemberStatus } from '@components/types/models/Member';
import { UserContext } from '@contexts/userContext';
import { Button, Col, notification, Popconfirm, Space, Tooltip } from 'antd';
import type { AxiosError } from 'axios';
import { differenceInHours, format, parseISO } from 'date-fns';
import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type { MembersTableProps } from './props';
import { MemberTable } from './Table';

export const MembersTable: FC<MembersTableProps> = ({
  members,
  deleteMemberMutation,
  resendInviteMutation,
  deleteMemberInviteMutation,
  onEditClick,
  disableActionButton,
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const activeMembers = members.data?.filter(
    (member: AdminMember) => member.status === MemberStatus.Active
  );

  const inactiveMembers = members.data?.filter(
    (member: AdminMember) => member.status === MemberStatus.Inactive
  );

  const invitedMembers = members.data?.filter(
    (member: AdminMember) => member.status === MemberStatus.Invited
  );

  const activateMemberMutation = useMutation(
    async (id: number) => adminApi.activateAdminMember(id),
    {
      onSuccess() {
        notification.success({ message: t('members.memberActivated') });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const handleActivateMember = useCallback(
    (am: AdminMember) => () => {
      activateMemberMutation.mutate(am.userId);
    },
    [activateMemberMutation]
  );

  const handleDeleteInvite = useCallback(
    (email) => () => {
      deleteMemberInviteMutation.mutate(email);
    },
    [deleteMemberInviteMutation]
  );

  const invitationExpirationDeadline = 24;
  const dateFormat = 'yyyy-MM-dd HH:mm';

  const handleDeleteMember = useCallback(
    (userId: number) => () => {
      deleteMemberMutation.mutate(userId);
    },
    [deleteMemberMutation]
  );

  const handleResendInvite = useCallback(
    (email: string) => () => {
      resendInviteMutation.mutate(email);
    },
    [resendInviteMutation]
  );

  const handleEditClick = useCallback(
    (member?: Member) => {
      onEditClick(member);
    },
    [onEditClick]
  );

  return (
    <Col span={24}>
      <MemberTable
        title={t('members.activeMembers')}
        subtitle={`${t('members.membersSum')}: ${activeMembers?.length}`}
        members={activeMembers}
        isLoading={members.isLoading}
        renderActions={(am) => (
          <Space size="middle">
            <Button
              title={t('edit')}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              disabled={user?.id === am.userId || disableActionButton}
              // eslint-disable-next-line react/jsx-handler-names
              onClick={() => {
                handleEditClick(am);
              }}
            />

            <Popconfirm
              title={`${t('members.confirmDeleteMember')} ${am.firstName} ${am.lastName}?`}
              cancelText={t('cancel')}
              okText={t('yes')}
              disabled={
                deleteMemberMutation.isLoading || user?.id === am.userId || disableActionButton
              }
              onConfirm={handleDeleteMember(am.userId)}
            >
              <Button
                danger
                title={t('delete')}
                shape="circle"
                disabled={user?.id === am.userId || disableActionButton}
                loading={
                  deleteMemberMutation.isLoading && deleteMemberMutation.variables === am.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />
      <MemberTable
        title={t('members.inactiveMembers')}
        subtitle={`${t('members.membersSum')}: ${inactiveMembers?.length}`}
        members={inactiveMembers}
        isLoading={members.isLoading}
        renderActions={(am) => (
          <Space size="middle">
            <Button
              title={t('members.activate')}
              type="primary"
              shape="circle"
              icon={<PoweroffOutlined />}
              loading={false}
              disabled={disableActionButton}
              onClick={handleActivateMember(am)}
            />

            <Popconfirm
              title={`${t('members.confirmDeleteMember')} ${am.firstName} ${am.lastName}?`}
              cancelText={t('cancel')}
              okText={t('yes')}
              disabled={deleteMemberMutation.isLoading || disableActionButton}
              onConfirm={handleDeleteMember(am.userId)}
            >
              <Button
                danger
                title={t('delete')}
                shape="circle"
                disabled={deleteMemberMutation.isLoading || disableActionButton}
                loading={
                  deleteMemberMutation.isLoading && deleteMemberMutation.variables === am.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />
      <MemberTable
        title={t('members.invitedMembers')}
        subtitle={`${t('members.membersSum')}: ${invitedMembers?.length}`}
        members={invitedMembers}
        isLoading={members.isLoading}
        extraColumns={[
          {
            title: t('members.invitationDate'),
            render: (am: AdminMember) => format(parseISO(am.invitedAt), dateFormat),
          },
        ]}
        renderActions={(am) => {
          const inviteDate = parseISO(am.invitedAt);
          const hourDiff = differenceInHours(Date.now(), inviteDate);
          const isInviteExpired = hourDiff >= invitationExpirationDeadline;

          const tooltipTitle = isInviteExpired
            ? t('members.resendPossible')
            : t('members.previousInviteNotExpired');

          return (
            <Space size="middle">
              <Tooltip title={disableActionButton ? null : tooltipTitle}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<RedoOutlined />}
                  disabled={
                    !isInviteExpired || resendInviteMutation.isLoading || disableActionButton
                  }
                  loading={
                    resendInviteMutation.isLoading && resendInviteMutation.variables === am.email
                  }
                  onClick={handleResendInvite(am.email)}
                />
              </Tooltip>

              <Popconfirm
                title={`${t('members.confirmDeleteInvite')} ${am.email} ?`}
                cancelText={t('cancel')}
                okText={t('yes')}
                disabled={deleteMemberInviteMutation.isLoading || disableActionButton}
                onConfirm={handleDeleteInvite(am.email)}
              >
                <Button
                  danger
                  title={t('delete')}
                  shape="circle"
                  disabled={deleteMemberInviteMutation.isLoading || disableActionButton}
                  loading={
                    deleteMemberInviteMutation.isLoading &&
                    deleteMemberInviteMutation.variables === am.email
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          );
        }}
      />
    </Col>
  );
};
