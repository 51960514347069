import { styled } from '@linaria/react';
import { Tag } from 'antd';
import tw from 'twin.macro';

import type { TagRoundedProps } from './props';

export const TagRounded = styled(Tag)<TagRoundedProps>`
  ${tw`h-8 rounded-2xl m-1 bg-lightGray`}
  padding-top: 5px;
`;
