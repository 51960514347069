import { contractorApi } from '@api/contractorApi';
import { forecastApi } from '@api/forecastApi';
import { notification, Typography } from 'antd';
import type { AxiosError } from 'axios';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QueryFunctionContext } from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { ForecastsTable } from './libs/components/ForecastsTable';
import { SearchSection } from './libs/components/SearchSection';
import type { SearchQueryType } from './libs/components/SearchSection/libs/types/SearchQueryType';

export const ContractorForecastsList: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  const pageSize = 15;
  const {
    data: forecastsList,
    isLoading,
    refetch,
  } = useQuery(
    ['getForecastsPaginated', { searchQuery }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [string, { readonly searchQuery: SearchQueryType | undefined }]
      >
    ) =>
      contractorApi
        .getForecastsPaginated(
          page,
          pageSize,
          searchQueryCallback.queryKey[1].searchQuery?.contractNumber,
          searchQueryCallback.queryKey[1].searchQuery?.forecastStatus
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  const createForecast = useMutation(
    async (contractId: number) => forecastApi.createForecast(contractId),
    {
      onSuccess() {
        notification.success({ message: t('forecasts.forecastCreated') });
        void refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
  }, []);

  return (
    <>
      <Title>{t('forecasts.forecasts')}</Title>
      <SearchSection onSearchQuery={handleSearchQuery} />
      <ForecastsTable
        forecastsList={forecastsList?.results}
        isLoading={isLoading}
        isCreateButtonLoading={createForecast.isLoading}
        pagination={{
          onChange: (p: number) => {
            setPage(p);
          },
          total: forecastsList?.totalItems,
          current: forecastsList?.currentPage,
          pageSize: forecastsList?.pageSize,
        }}
        handleCreateForecast={createForecast.mutate}
      />
    </>
  );
};
