import { PlusOutlined } from '@ant-design/icons';
import { invoiceApi } from '@api/invoiceApi';
import type { Contract } from '@components/types/models/Contract';
import { UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks/useDefaultQuery';
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import type { SelectValue } from 'antd/lib/select';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import type { CreateInvoiceFormProps } from './props';

type FormValues = {
  readonly startDate?: string;
  readonly endDate?: string;
  readonly contractId: number;
};

export const CreateInvoiceForm: FC<CreateInvoiceFormProps> = ({ refetchInvoicesList }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const contractorId = user?.userRoles[0]?.entityId;
  const { Option } = Select;
  const { Paragraph } = Typography;

  const {
    reset,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();

  const handleCloseModal = (): void => {
    reset();
    setIsModalVisible(false);
  };

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const handleChange = useCallback(
    (name) => (value: Moment | SelectValue | null) => {
      setValue(name, value);
    },
    [setValue]
  );

  const contractsList = useDefaultQuery<readonly Contract[]>(
    ['getAdminMembers', contractorId],
    async () =>
      contractorId && invoiceApi.getContractsForInvoices(contractorId).then((res) => res.data)
  );

  const createInvoiceMutation = useMutation(
    async (values: FormValues) => axios.post(`/api/invoices`, values),
    {
      onSuccess: (res) => {
        handleCloseModal();
        void message.success(t('invoices.invoiceCreated'));
        history.push(`/contractor/payment-application/${res.data}`);
        refetchInvoicesList();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: { readonly name: string; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('error', { message: errData.message });
        }
      }
    }
  );

  // A little hack to ignore timezones where they are not needed
  // TODO: refactor later
  const formatTime = (dateTime: string) => {
    const date = moment(dateTime).format('YYYY-MM-DD');
    return moment(date).add(12, 'hours').format()
  }

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues): void => {
      const formData = {
        ...values,
        endDate: values.endDate ?  formatTime(values.endDate) : global.undefined,
        startDate: values.startDate ? formatTime(values.startDate) : global.undefined
      };
      createInvoiceMutation.mutate(formData);
    })();
  }, [clearErrors, handleSubmit, createInvoiceMutation]);

  const selectedContract = useMemo(
    () => contractsList.data?.filter((contract) => contract.id === watch('contractId'))?.[0],
    [contractsList, watch]
  );
  const defaultPickerValue = useMemo(
    () =>
      selectedContract?.contractStartDate
        ? moment(selectedContract.contractStartDate, 'YYYY-MM-DD')
        : global.undefined,
    [selectedContract]
  );

  const disabledDate = (current: Moment): boolean => {
    if (current < moment(selectedContract?.contractStartDate)) {
      return true;
    } else if (current > moment(selectedContract?.contractEndDate)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Button ghost type="primary" icon={<PlusOutlined />} onClick={handleOpenModal}>
        {t('invoices.createInvoice')}
      </Button>
      <Modal
        centered
        destroyOnClose
        width={600}
        title={t('invoices.newInvoice')}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical" onFinish={handleFormSubmit}>
          <Row>
            <Col span={11}>
              <Form.Item
                label={t('contract')}
                validateStatus={errors.contractId?.message && 'error'}
                help={errors.contractId?.message}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleChange('contractId')}
                >
                  {contractsList.data?.map((contract, index) => (
                    <Option key={index} value={contract.id}>
                      <span>{contract.contractNumber}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label={t('invoices.g1cDateFrom')}
                validateStatus={errors.startDate?.message && 'error'}
                help={errors.startDate?.message}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  value={watch('startDate')}
                  format="YYYY-MM-DD"
                  defaultPickerValue={defaultPickerValue}
                  disabledDate={watch('contractId') ? disabledDate : global.undefined}
                  showToday={false}
                  onChange={handleChange('startDate')}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label={t('invoices.g1cDateTo')}>
                <DatePicker
                  style={{ width: '100%' }}
                  value={watch('endDate')}
                  format="YYYY-MM-DD"
                  disabledDate={watch('contractId') ? disabledDate : global.undefined}
                  defaultPickerValue={defaultPickerValue}
                  onChange={handleChange('endDate')}
                />
                <Paragraph
                  type="danger"
                  ellipsis={{
                    rows: 3,
                    expandable: true
                  }}
                >
                  {errors.endDate?.message}
                </Paragraph>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={24}>
              <br />
              <Form.Item noStyle>
                <Row justify="end">
                  <Col>
                    <Space align="end">
                      <Button htmlType="button" onClick={handleCloseModal}>
                        {t('cancel')}
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={createInvoiceMutation.isLoading}
                      >
                        {t('create')}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
