import { Select } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next/';

import type { ContractTypeSelectorProps } from './props';

export const ContractTypeSelector: FC<ContractTypeSelectorProps> = ({ contractTypes, ...rest }) => {
  const { i18n } = useTranslation();
  return (
    <Select
      options={contractTypes?.map((c) => ({
        value: c.id,
        label: i18n.language === 'en' ? c.enName : c.ruName,
      }))}
      mode="multiple"
      maxTagCount="responsive"
      filterOption={(input, option) =>
        (option?.label as string).toLowerCase().includes(input.toLowerCase())
      }
      {...rest}
    />
  );
};
