import type { ForecastSorting } from '@components/types/models/Forecast';
import { useEffect, useState } from 'react';

type SortItem = {
  readonly title: string;
  readonly asc: number;
  readonly desc: number;
};

type UseSortProps = {
  readonly sortList?: readonly SortItem[];
  readonly defaultSortValue?: number;
};

type UseSortOutput = {
  readonly handleSort: (title: string) => number | undefined;
  readonly rotate: number;
};

export const useSort = ({ sortList, defaultSortValue }: UseSortProps): UseSortOutput => {
  const [rotate, setRotate] = useState(0);
  const downDegree = 180;
  const upDegree = 0;

  const initRotate = (initSort: Partial<SortItem>, sortValue: ForecastSorting): void => {
    Object.entries(initSort).forEach(([key, value]) => {
      if (Number(value) === sortValue) {
        setRotate(key === 'asc' ? upDegree : downDegree);
      }

      return [key, value];
    });
  };

  useEffect(() => {
    if (defaultSortValue) {
      const currentSort = sortList?.find((sortObj) =>
        Object.values(sortObj).includes(defaultSortValue)
      );

      initRotate({ ...currentSort }, defaultSortValue);
    }
  }, [defaultSortValue, sortList]);

  const handleSort = (title: string): number | undefined => {
    const changingSort = sortList?.find((item) => item.title === title);

    if (changingSort?.asc === defaultSortValue) {
      setRotate(downDegree);
      return changingSort?.desc;
    }

    setRotate(0);
    return changingSort?.asc;
  };

  return { rotate, handleSort };
};
