// Had to disable due to false positive ESLint error
/* eslint-disable import/no-deprecated */
import { DownloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import axios from 'axios';
import { saveAs } from 'file-saver';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type DownloadLinkProps = {
  readonly url: string;
  readonly fileName?: string;
  readonly size?: SizeType;
  readonly type?: 'dashed' | 'default' | 'ghost' | 'link' | 'primary' | 'text' | undefined;
  readonly label?: string;
  readonly disabled?: boolean;
};

export const DownloadLink: FC<DownloadLinkProps> = ({
  url,
  fileName,
  size = 'small',
  type,
  label,
  disabled,
}) => {
  const [t] = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = (): boolean => {
    setIsDownloading(true);
    axios
      .get(url, { responseType: 'arraybuffer' })
      .then((response): void => {
        setIsDownloading(false);
        saveAs(new Blob([response.data]), fileName);
      })
      .catch((): void => {
        setIsDownloading(false);
        notification.error({
          message: t('error.errorDownload'),
        });
      });
    return false;
  };

  return (
    <Button
      type={type ?? 'link'}
      size={size}
      icon={<DownloadOutlined />}
      loading={isDownloading}
      disabled={disabled}
      onClick={handleDownload}
    >
      {label ?? fileName}
    </Button>
  );
};

export default DownloadLink;
