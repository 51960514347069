export const enActuals = {
  actuals: 'Actuals',
  submissionPeriod: 'Submission period',
  submissionDate: 'Submission date',
  period: 'Period',
  fromTo: 'From {{from}} to {{to}}',
  submitNewActuals: 'Submit new actuals',
  periodAscending: 'Period (ascending)',
  periodDescending: 'Period (descending)',
  saveAndGoToTheNextSection: 'Save and go to the next section',
  proceedAndGoToTheNextSection: 'Proceed and go to the next section',
  configureCities: 'Configure cities',
  selectCitiesForJobsReport: 'Select cities for jobs report',
  citiesUpdated: 'List of cities updated',
  kzNationalsForecast: 'KZ Nationals forecast',
  nonKzNationalsForecast: 'Non - KZ nationals forecast',
  nonKzMobilized: 'Mobilized - non-KZ',
  kzMobilized: 'Mobilized - KZ',
  kzDemobilized: 'Demobilized - KZ',
  nonKzDemobilized: 'Demobilized - non-KZ',
  nationalized: 'Nationalized',
  jobsPositions: 'Jobs positions',
  kzNationals: 'KZ Nationals',
  nonKzNationals: 'Non-KZ Nationals',
  total: 'Total positions',
  plannedToNationalize: 'Planned to nationalize',
  graduateSection: 'Graduate section',
  newGradInternships: 'Internship for New-graduates',
  newGradInternshipsForecast: 'Internship for New-graduates forecast',
  newGradJobs: 'Jobs for New-graduates',
  newGradJobsForecast: 'Jobs for New-graduates forecast',
  addCtKzCertificate: 'Add CT-KZ% certificate',
  certificateNumber: 'Certificate number',
  expirationDate: 'Expiration date',
  addCertificate: 'ADD CERTIFICATE',
  file: 'File',
  ctKzCertificate: 'CT-KZ certificate #',
  ctKzCertificateGoodName: 'CT-KZ certificate good name',
  certificateExpirationDate: 'Certificate expiration date',
  goodName: 'Good name',
  youMayUpload: 'You may upload following formats only .pdf .jpg .jpeg .png',
  addRow: 'Add row',
  certificateSuccessfullySaved: 'CT-KZ% certificate successfully saved',
  actualValue: 'Actual value',
  attachFile: 'Attach file',
  attachFileNotice: 'Attachments(up to 3 files)',
  attachments: 'Attachments',
  goToTheNextSection: 'Go to the next section',
  saveAndGoToTheNextCity: 'Save and go to the next city',
  saveAndGoToTotal: 'Save and go to total',
  deleteSection: 'Delete section',
  addLegacy: 'Add Legacy',
  addTechnologyTransfer: 'Add Technology Transfer',
  addSuccessStories: 'Add Success Stories',
  successActualSubmit:
    'Actual metrics were successfully  submitted, please wait for a feedback!',
  sectionIsRequired: 'Section is required',
  submissionError:
    'Submitting error occurred. Make sure all required filed is filled out ',
  approvedActual: 'Actual metrics have been successfully approved',
  actualsForStart: '',
  actualsForEnd: ' actuals for {{period}}',
  setDueDate: 'Set a due date for a actual update',
  rejectActual: 'Reject actual',
  rejectedActual: 'Actual metrics have been successfully rejected',
  prepopulate: 'Pre-populate',
  prepopulateWithDataFrom: 'With data from',
  newSubcontractor: 'New subcontractor',
  restorePreviousVersion: 'Restore previous version',
  missedDueDate: 'You have missed due date',
  areYouSureDeleteAttachment:
    'Are you sure you want to delete attachment "{{file}}"?',
  jobError: 'The {{mouCategory}} {{category}} {{period}} field is required',
  trainingError: 'The {{mouCategory}} field is required',
  section: 'Section',
  kcActualValue: 'KC Actual value',
  kc: 'KC %',
  actualValueInOriginalCurrency: 'Actual value in original currency',
  actualValueInUsd: 'Actual value in USD',
  actualsKcValueInOriginalCurrency: 'Actual KC value in original currency',
  actualKcValueInUsd: 'Actual KC value in USD',
  actualKcShare: 'Actual KC share',
};
