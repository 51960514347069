export const gettingStarted = [
  {
    title: "How to see information about my company?",
    html: `
  <ol class="c5 lst-kix_sd20kioibnt6-0 start" start="1">
    <li class="c0 c7"><span class="c4">Log in to the system.</span></li>
    <li class="c0 c7"><span>P</span><span>ress on </span><span class="c1">Your company name</span><span
        class="c4">&nbsp;button.</span></li>
  </ol>
  <p class="c0 c3"><span class="c4"></span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%; height: 290.67px;"><img
        alt="" src="/images/userguides/contractor/image19.png"
        style="width: 80%; height: 290.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to change my password?",
    html: `
  <ol class="c5 lst-kix_28ekd78n4b44-0 start" start="1">
  <li class="c0 c7"><span class="c4">Log in to the system.</span></li>
  <li class="c0 c7"><span>Press on </span><span class="c1">Change password</span><span class="c4">&nbsp;button on your
      Account info page.</span></li>
  </ol>
  <p class="c0 c3"><span class="c4"></span></p>
  <p class="c0"><span class="c8 c1">Note: </span></p>
  <p class="c0"><span class="c4">Password Length - minimum of 8 characters</span></p>
  <p class="c0"><span class="c4">Password Complexity must be composed of a combination of at least two of the following
      character types: </span></p>
  <ul class="c5 lst-kix_ovp4pktynkjh-0 start">
    <li class="c0 c7"><span class="c4">uppercase letters</span></li>
    <li class="c0 c7"><span class="c4">lower case letters</span></li>
    <li class="c0 c7"><span class="c4">numbers</span></li>
    <li class="c0 c7"><span class="c4">symbols</span></li>
  </ul>
  <p class="c0 c3"><span class="c4"></span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%; height: 290.67px;"><img
        alt="" src="/images/userguides/contractor/image5.png"
        style="width: 80%; height: 290.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>`
  }
];

