import { ContractorLink as contractorLink } from '@components/organisms/ContractorsTable/libs/components/ContractorLink';
import type { ContractorsTableProps } from '@components/organisms/ContractorsTable/props';
import type { Contractor } from '@components/types/models/Contractor';
import { Table } from 'antd';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next/';

import { ContractorActions as contractorActions } from './libs/components/ContractorActions';

export const ContractorsTable: FC<ContractorsTableProps> = ({
  data,
  isLoading,
  pagination,
  onModal,
  handleDelete,
  isDeleteButtonLoading,
  deleteContractorId,
}) => {
  const { t, i18n } = useTranslation();

  const parseData = (
    tableData: ContractorsTableProps['data']
  ): ReadonlyArray<Record<string, unknown>> =>
    tableData?.map((item) =>
      Object.entries(item).reduce(
        (prev, entry) => ({
          ...prev,
          [entry[0]]:
            (!entry[1] || entry[1] === '') && entry[0] !== 'newContractsCount' ? '—' : entry[1],
        }),
        {}
      )
    ) ?? [];

  const handleDeleteContractor = useCallback(
    (id: number) => () => {
      handleDelete(id);
    },
    [handleDelete]
  );

  const handleShowEditModal = useCallback(
    (item: Contractor) => () => {
      onModal(item);
    },
    [onModal]
  );

  const columns = [
    {
      title: t('contractors.contractorName'),
      key: 'name',
      render: ({ id, name, newContractsCount }: Contractor) =>
        contractorLink({
          id,
          name,
          newContractsCount,
        }),
    },
    {
      title: t('bin'),
      dataIndex: 'bin',
      key: 'bin',
    },
    {
      title: t('workPhoneNumber'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('country'),
      dataIndex: ['country', i18n.language === 'en' ? 'nameEn' : 'nameRu'],
    },
    {
      title: t('city'),
      dataIndex: ['city', i18n.language === 'en' ? 'nameEn' : 'nameRu'],
    },
    {
      title: t('actions'),
      key: 'actions',
      render: (item: Contractor) =>
        contractorActions({
          item,
          handleDeleteContractor,
          handleShowEditModal,
          isDeleteButtonLoading,
          deleteContractorId,
        }),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={parseData(data)}
      loading={isLoading}
      pagination={pagination}
      rowKey="id"
    />
  );
};
