/* eslint-disable @typescript-eslint/naming-convention */
import type { ActualsListResponse, ActualSorting } from '@components/types/models/Actuals';
import type { ForecastsListResponse, ForecastSorting } from '@components/types/models/Forecast';
import type { InvoicesListResponse, InvoiceSorting } from '@components/types/models/Invoices';
import type { Notice } from '@components/types/models/Notice';
import type { TrainingCenterMember } from '@components/types/models/TrainingCenterMember';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { stringify } from 'qs';

const getAdminMembers = async (): Promise<
  AxiosResponse<readonly TrainingCenterMember[] | undefined>
> => axios.get<readonly TrainingCenterMember[]>(`/api/admin/member`);

const getAllCategories = async (): Promise<AxiosResponse> =>
  axios.get('/api/static-data/kc/categories');

const getAllNotifications = async (): Promise<AxiosResponse> =>
  axios.get('/api/admin/notification');

const deleteAdminMember = async (id: number): Promise<AxiosResponse> =>
  axios.delete(`/api/admin/member/${id}`);

const getAdminMember = async (id: number): Promise<AxiosResponse> =>
  axios.get(`/api/admin/member/${id}`);

const activateAdminMember = async (id: number): Promise<AxiosResponse> =>
  axios.get(`/api/admin/member/${id}/activate`);

const resendInviteMember = async (email: string): Promise<AxiosResponse> =>
  axios.get(`/api/admin/member/invite/resend`, {
    params: {
      email,
    },
  });

const deleteInviteMember = async (email: string): Promise<AxiosResponse> =>
  axios.delete('/api/admin/member/invite/delete/', {
    params: {
      email,
    },
  });

const getForecastsPaginated = async (
  page: number,
  pageSize: number,
  sort?: ForecastSorting,
  approvalStatus?: string,
  contractNumber?: number | string,
  contractorsId?: readonly number[],
  kcCategoryType?: readonly number[],
  contractTypesId?: readonly number[]
): Promise<
  AxiosResponse<ForecastsListResponse>
  // eslint-disable-next-line max-params
> =>
  axios.get(`/api/forecasts/paginated`, {
    params: {
      ContractNumber: contractNumber,
      ApprovalStatus: approvalStatus,
      ContractorsId: contractorsId,
      KcCategoryType: kcCategoryType,
      ContractTypesId: contractTypesId,
      Page: page,
      PageSize: pageSize,
      SortBy: sort,
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  });

const getOverdueForecastsPaginated = async (
  page: number,
  pageSize: number,
  sort?: ForecastSorting,
  contractNumber?: number | string,
  contractorsId?: readonly number[],
  kcCategoryType?: readonly number[]
): Promise<
  AxiosResponse<ForecastsListResponse>
  // eslint-disable-next-line max-params
> =>
  axios.get(`/api/forecasts/overdue`, {
    params: {
      ContractNumber: contractNumber,
      ContractorsId: contractorsId,
      KcCategoryType: kcCategoryType,
      Page: page,
      PageSize: pageSize,
      SortBy: sort,
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  });

const getNotices = async (): Promise<AxiosResponse<Notice>> => axios.get(`/api/admin/notices`);

const getActualsPaginated = async (
  page: number,
  pageSize: number,
  sort?: ActualSorting,
  approvalStatus?: string,
  contractNumber?: number | string,
  contractorsId?: readonly number[],
  kcCategoryType?: readonly number[],
  contractTypesId?: readonly number[],
  year?: number,
  month?: number
): Promise<
  AxiosResponse<ActualsListResponse>
  // eslint-disable-next-line max-params
> =>
  axios.get(`/api/actual`, {
    params: {
      ContractNumber: contractNumber,
      Status: approvalStatus,
      ContractorIds: contractorsId,
      KcCategoryTypes: kcCategoryType,
      ContractTypes: contractTypesId,
      Page: page,
      PageSize: pageSize,
      OrderingVector: sort,
      Year: year,
      Month: month,
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  });

const getOverdueActualsPaginated = async (
  page: number,
  pageSize: ActualSorting,
  contractNumber?: number | string,
  contractorsId?: readonly number[],
  kcCategoryType?: readonly number[],
  year?: number,
  month?: number
  // eslint-disable-next-line max-params
): Promise<AxiosResponse<ActualsListResponse>> =>
  axios.get(`/api/actuals/overdue`, {
    params: {
      ContractNumber: contractNumber,
      ContractorsIds: contractorsId,
      KcCategoryTypes: kcCategoryType,
      Page: page,
      PageSize: pageSize,
      Year: year,
      Month: month,
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  });

const getAdminInvoices = async (
  page: number,
  pageSize: number,
  sort?: InvoiceSorting,
  status?: readonly number[],
  kcCategories?: readonly number[],
  invoiceNumber?: string,
  contracts?: readonly number[],
  contractors?: readonly number[],
  dateStart?: string,
  dateEnd?: string
  // eslint-disable-next-line max-params
): Promise<AxiosResponse<InvoicesListResponse>> =>
  axios.get(`/api/invoices/for-kc-lead`, {
    params: {
      Statuses: status,
      Page: page,
      PageSize: pageSize,
      OrderingType: sort,
      KcCategories: kcCategories,
      ContractIds: contracts,
      ContractorIds: contractors,
      DateStart: dateStart,
      DateEnd: dateEnd,
      InvoiceNumberOrdNull: invoiceNumber,
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
  });

const approveInvoice = async (invoiceId: number): Promise<AxiosResponse> =>
  axios.put(`/api/invoices/${invoiceId}/approve`);

const rejectInvoice = async (
  invoiceId: number,
  payload: { readonly dueDate: string }
): Promise<AxiosResponse> =>
  axios.put(`/api/invoices/${invoiceId}/reject`, null, {
    params: payload,
  });

export const adminApi = {
  getAdminMembers,
  getAdminMember,
  getAllCategories,
  getAllNotifications,
  deleteAdminMember,
  activateAdminMember,
  resendInviteMember,
  deleteInviteMember,
  getForecastsPaginated,
  getOverdueForecastsPaginated,
  getNotices,
  getActualsPaginated,
  getOverdueActualsPaginated,
  getAdminInvoices,
  approveInvoice,
  rejectInvoice,
};
