/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CompanySelectorTemp } from '@components/atoms/CompanySelectorTemp';
import { CountrySelectorTemp } from '@components/atoms/CountrySelectorTemp';
import type { NationalizeListItem } from '@components/organisms/JobsTraining/types';
import i18n from '@i18n/i18n';
import { css } from '@linaria/core';
import { Button, Col, Form, Input, Modal, Row, Select, Table, Tooltip, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { NationalizationModalProps, NationalizeListItemPayload } from './props';

export const NationalizationModal: FC<NationalizationModalProps> = ({
  isActual,
  isModalOpen,
  onClose,
  nationalizeItem,
  countries,
  companiesList,
  addNationalizeDataMutation,
  isEditable,
}) => {
  const { t } = useTranslation();

  const defaultNationalizeItem = {
    position: '',
    category: null,
    foreignerName: '',
    kzName: '',
    countryId: null,
    companyId: null,
    comment: '',
  };

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [nationalizeList, setNationalizeList] = useState<readonly NationalizeListItem[]>([]);

  const handleClose = (): void => {
    onClose?.();
  };

  const nationalizationTitle = (): string =>
    `${t('forecasts.nationalization.nationalizationFor')} ${nationalizeItem.title}, ${moment(
      nationalizeItem.month,
      'M'
    )
      .locale(i18n.language)
      .format('MMMM')} ${nationalizeItem.year}`;

  const fwlCategoryList = [
    { id: 1, text: t('forecasts.nationalization.category.1') },
    { id: 2, text: t('forecasts.nationalization.category.2') },
    { id: 3, text: t('forecasts.nationalization.category.3') },
    { id: 4, text: t('forecasts.nationalization.category.4') },
  ];

  const handleAddRow = (): void => {
    const updatedList = [...nationalizeList];
    updatedList.push(defaultNationalizeItem);

    setNationalizeList(updatedList);
  };

  const handleRemoveRow = useCallback(
    (deleteIndex: number) => () => {
      const updatedList = [...nationalizeList].filter((_, index: number) => deleteIndex !== index);

      setNationalizeList(updatedList);
    },
    [nationalizeList]
  );

  const handleValueChange = useCallback(
    ({ field, rowIndex }: { readonly field: string; readonly rowIndex: number }) =>
      (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const updatedList = [...nationalizeList];

        updatedList[rowIndex] = {
          ...updatedList[rowIndex],
          [field]: event.currentTarget.value,
        };

        setNationalizeList(updatedList);
      },
    [nationalizeList]
  );

  const handleSelectChange = useCallback(
    ({ field, rowIndex }: { readonly field: string; readonly rowIndex: number }) =>
      (value: number | string) => {
        const updatedList = [...nationalizeList];

        updatedList[rowIndex] = {
          ...updatedList[rowIndex],
          [field]: value,
        };

        setNationalizeList(updatedList);
      },
    [nationalizeList]
  );

  const handleModalOkClick = useCallback(() => {
    const { year, month, mouCategory } = nationalizeItem;

    const payload: NationalizeListItemPayload = {
      year,
      month,
      mouCategory,
      positions: nationalizeList,
    };

    addNationalizeDataMutation?.mutate(payload);
  }, [nationalizeList]);

  const getFwlCategoryTooltip = (): React.ReactNode => (
    <Row>
      <Typography.Paragraph
        className={css`
          ${tw`text-white`}
        `}
      >
        {t('forecasts.nationalization.categoryInfo.1')}
      </Typography.Paragraph>
      <Typography.Paragraph
        className={css`
          ${tw`text-white`}
        `}
      >
        {t('forecasts.nationalization.categoryInfo.2')}
      </Typography.Paragraph>
      <Typography.Paragraph
        className={css`
          ${tw`text-white`}
        `}
      >
        {t('forecasts.nationalization.categoryInfo.3')}
      </Typography.Paragraph>
      <Typography.Paragraph
        className={css`
          ${tw`text-white`}
        `}
      >
        {t('forecasts.nationalization.categoryInfo.4')}
      </Typography.Paragraph>
    </Row>
  );

  const deleteIconTableCell = {
    key: 'deleteIcon',
    title: '',
    dataIndex: '',
    render: function getDeleteAction(_: unknown, _item: unknown, index: number) {
      return (
        <Tooltip title={t('delete')}>
          <Button
            danger
            title={t('delete')}
            shape="circle"
            icon={<CloseOutlined />}
            onClick={handleRemoveRow(index)}
          />
        </Tooltip>
      );
    },
  };

  const nationalizeHeaders = [
    {
      key: 'position',
      title: t('forecasts.nationalization.position'),
      dataIndex: 'position',
      width: 200,
      render: function getPosition(position: string, _: unknown, index: number) {
        return (
          <Col>
            {isEditable ? (
              <Form.Item
                validateStatus={errors.positions?.[index]?.Position && 'error'}
                help={errors.positions?.[index]?.Position?.message}
                style={{ marginBottom: '0px' }}
              >
                <Input
                  value={position}
                  disabled={!isEditable}
                  onChange={handleValueChange({
                    field: 'position',
                    rowIndex: index,
                  })}
                />
              </Form.Item>
            ) : (
              <Text>{position}</Text>
            )}
          </Col>
        );
      },
    },
    {
      key: 'category',
      title: function getCategoryTitle() {
        return (
          <Row justify="space-between" align="middle">
            <Text>{t('forecasts.nationalization.fwlCategory')}</Text>
            <Tooltip
              overlayStyle={{ maxWidth: 600 }}
              title={getFwlCategoryTooltip()}
              placement="bottom"
            >
              <QuestionCircleOutlined
                className={css`
                  ${tw`text-xl`}
                `}
              />
            </Tooltip>
          </Row>
        );
      },
      dataIndex: 'category',
      width: 250,
      render: function getCategory(categoryId: number, _: unknown, index: number) {
        return (
          <Col>
            {isEditable ? (
              <Select
                style={{ width: '100%' }}
                value={categoryId}
                disabled={!isEditable}
                onChange={handleSelectChange({
                  field: 'category',
                  rowIndex: index,
                })}
              >
                {fwlCategoryList.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.text}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Text>{fwlCategoryList.find((category) => category.id === categoryId)?.text}</Text>
            )}
          </Col>
        );
      },
    },
    {
      key: 'foreignerName',
      title: t('forecasts.nationalization.foreignerName'),
      dataIndex: 'foreignerName',
      width: 300,
      render: function getForeignerName(foreignerName: string, _: unknown, index: number) {
        const foreignerNameInput = (
          <Input
            value={foreignerName}
            disabled={!isEditable}
            onChange={handleValueChange({
              field: 'foreignerName',
              rowIndex: index,
            })}
          />
        );

        const outputInput = isActual ? (
          <Form.Item
            validateStatus={errors.positions?.[index]?.ForeignerName && 'error'}
            help={errors.positions?.[index]?.ForeignerName?.message}
            style={{ marginBottom: '0px' }}
          >
            {foreignerNameInput}
          </Form.Item>
        ) : (
          foreignerNameInput
        );

        return isEditable ? outputInput : <Text>{foreignerName}</Text>;
      },
    },
    {
      key: 'countryId',
      title: t('forecasts.nationalization.country'),
      dataIndex: 'countryId',
      render: function getCountry(countryId: number, item: NationalizeListItem, index: number) {
        const countrySelector = (
          <CountrySelectorTemp
            selectedCountry={countryId}
            countries={countries}
            className={css`
              ${tw`w-full`}
            `}
            disabled={!isEditable}
            onChange={handleSelectChange({
              field: 'countryId',
              rowIndex: index,
            })}
          />
        );

        const outputSelector = isActual ? (
          <Form.Item
            validateStatus={errors.positions?.[index]?.CompanyId && 'error'}
            help={errors.positions?.[index]?.CompanyId?.message}
            style={{ marginBottom: '0px' }}
          >
            {countrySelector}
          </Form.Item>
        ) : (
          countrySelector
        );

        return isEditable ? (
          outputSelector
        ) : (
          <Text>{i18n.language === 'ru' ? item.country?.nameRu : item.country?.nameEn}</Text>
        );
      },
    },
    {
      key: 'companyId',
      title: t('forecasts.nationalization.companyName'),
      dataIndex: 'companyId',
      width: 300,
      render: function getCompany(companyId: number, item: NationalizeListItem, index: number) {
        const companySelector = (
          <CompanySelectorTemp
            selectedCompany={companyId}
            companiesList={companiesList}
            className={css`
              ${tw`w-full`}
            `}
            disabled={!isEditable}
            onChange={handleSelectChange({
              field: 'companyId',
              rowIndex: index,
            })}
          />
        );

        const outputSelector = isActual ? (
          <Form.Item
            validateStatus={errors.positions?.[index]?.CompanyId && 'error'}
            help={errors.positions?.[index]?.CompanyId?.message}
            style={{ marginBottom: '0px' }}
          >
            {companySelector}
          </Form.Item>
        ) : (
          companySelector
        );

        return isEditable ? outputSelector : <Text>{item.company?.name}</Text>;
      },
    },
    {
      key: 'kzName',
      title: t('forecasts.nationalization.kzName'),
      dataIndex: 'kzName',
      width: 300,
      render: function getKzName(kzName: string, _: unknown, index: number) {
        const kzNameInput = (
          <Input
            value={kzName}
            disabled={!isEditable}
            onChange={handleValueChange({
              field: 'kzName',
              rowIndex: index,
            })}
          />
        );

        const outputSelector = isActual ? (
          <Form.Item
            validateStatus={errors.positions?.[index]?.KzName && 'error'}
            help={errors.positions?.[index]?.CompanyId?.message}
            style={{ marginBottom: '0px' }}
          >
            {kzNameInput}
          </Form.Item>
        ) : (
          kzNameInput
        );

        return isEditable ? outputSelector : <Text>{kzName}</Text>;
      },
    },
    {
      key: 'comment',
      title: t('forecasts.nationalization.comment'),
      dataIndex: 'comment',
      width: 300,
      render: function getComment(comment: string, _: unknown, index: number) {
        return isEditable ? (
          <Input.TextArea
            value={comment}
            disabled={!isEditable}
            onChange={handleValueChange({
              field: 'comment',
              rowIndex: index,
            })}
          />
        ) : (
          <Text
            className={css`
              ${tw`whitespace-pre-wrap`}
            `}
          >
            {comment}
          </Text>
        );
      },
    },
  ];

  useEffect(() => {
    if (addNationalizeDataMutation?.error) {
      const errData = addNationalizeDataMutation?.error.response.data;

      errData?.validationErrors.forEach(
        (error: { readonly name: string; readonly description: string }): void => {
          setError(error.name, { message: error.description });
        }
      );
    }
  }, [addNationalizeDataMutation?.error]);

  useEffect(() => {
    if (nationalizeItem.items && nationalizeItem.items.length > 0) {
      setNationalizeList(nationalizeItem.items);
    } else if (isEditable) {
      setNationalizeList([defaultNationalizeItem]);
    } else {
      setNationalizeList([]);
    }
  }, [nationalizeItem.items]);

  useEffect(() => {
    if (addNationalizeDataMutation?.isSuccess) {
      handleClose();
      clearErrors();
    }
  }, [addNationalizeDataMutation?.isSuccess]);

  return (
    <Modal
      visible={isModalOpen}
      title={nationalizationTitle()}
      width={1400}
      confirmLoading={addNationalizeDataMutation?.isLoading}
      footer={[
        <Button key="cancel" type="default" onClick={handleClose}>
          {t('cancel')}
        </Button>,
        isEditable && (
          <Button
            key="ok"
            type="primary"
            loading={addNationalizeDataMutation.isLoading}
            onClick={handleModalOkClick}
          >
            {t('save')}
          </Button>
        ),
      ]}
    >
      <Col
        span={24}
        className={css`
          ${tw`space-y-4`}
        `}
      >
        <Row align="middle">
          <QuestionCircleOutlined
            className={css`
              ${tw`text-xl text-primary mr-2`}
            `}
          />
          <Text strong>{t('forecasts.nationalization.pleaseProvide')}</Text>
        </Row>

        <Table
          scroll={{ x: 2000 }}
          columns={isEditable ? [...nationalizeHeaders, deleteIconTableCell] : nationalizeHeaders}
          dataSource={nationalizeList}
          pagination={false}
        />

        {isEditable && (
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAddRow}>
            {t('forecasts.nationalization.addPosition')}
          </Button>
        )}
      </Col>
    </Modal>
  );
};
