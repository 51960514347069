/* eslint-disable react-hooks/exhaustive-deps */

import { ActualAttachments } from '@components/organisms/ActualAttachments';
import { CommentCard } from '@components/organisms/CommentCard';
import { SectionType } from '@components/types/models/Forecast';
import { css } from '@linaria/core';
import { Button, Col, Input, notification, Row, Typography } from 'antd';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { ActualStoriesLegacyTechnologyFormProps } from './props';

const textSectionTitles = {
  [SectionType.TechnologyTransfer]: 'technology-transfer',
  [SectionType.Legacy]: 'legacy',
  [SectionType.SuccessStories]: 'success-stories',
};

export const ActualStoriesLegacyTechnologyForm: FC<ActualStoriesLegacyTechnologyFormProps> = ({
  placeholder,
  isEditable,
  sectionType,
  initialDescription,
  saveTextSectionMutation,
  activeCollapseItem,
  setActiveCollapseItem,
  refetchData,
  actualId,
  period,
}) => {
  const { TextArea } = Input;
  const { Text } = Typography;
  const [description, setDescription] = useState('');

  const { t } = useTranslation();

  const handleChangeDescription = useCallback(
    (event: React.FormEvent<HTMLTextAreaElement>) => {
      setDescription(event.currentTarget.value);
    },
    [setDescription]
  );

  const handleSubmitForm = useCallback(() => {
    saveTextSectionMutation?.mutate({ sectionTitle: textSectionTitles[sectionType], description });
  }, [description, saveTextSectionMutation]);

  useEffect(() => {
    if (saveTextSectionMutation?.isSuccess) {
      refetchData?.();
      notification.success({ message: t('saved') });
      setActiveCollapseItem(activeCollapseItem + 1);
      saveTextSectionMutation?.reset();
    }
  }, [saveTextSectionMutation?.isSuccess]);

  useEffect(() => {
    if (saveTextSectionMutation?.isError) {
      const error = saveTextSectionMutation?.error.response.data;
      notification.error({ message: error.validationErrors[0].description });
    }
  }, [saveTextSectionMutation?.isError]);

  useEffect(() => {
    if (initialDescription) {
      setDescription(initialDescription);
    }
  }, [initialDescription]);

  return (
    <Row
      wrap
      className={css`
        ${tw`space-y-4`}
      `}
    >
      {isEditable ? (
        <TextArea
          style={{ width: '100%' }}
          placeholder={placeholder}
          value={description}
          maxLength={255}
          autoSize={{ minRows: 3, maxRows: 6 }}
          onChange={handleChangeDescription}
        />
      ) : (
        <Text>{description}</Text>
      )}

      {isEditable && (
        <Col span={24}>
          <Row justify="end">
            <Button
              className="secondary-button"
              loading={saveTextSectionMutation?.isLoading}
              onClick={handleSubmitForm}
            >
              {t('forecasts.saveAndGoToTheNextSection')}
            </Button>
          </Row>
        </Col>
      )}

      <Col span={24}>
        <ActualAttachments actualId={actualId} isEditable={isEditable} sectionType={sectionType} />
      </Col>

      <Col span={24}>
        <CommentCard
          isActual
          id={actualId}
          status={period?.approvalStatus}
          sectionType={sectionType}
        />
      </Col>
    </Row>
  );
};
