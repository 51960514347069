/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-handler-names */
import { ADMIN_PERMISSION_OPTION_LIST } from '@components/constants/AdminPermissions';
import { Checkbox, Col, Row } from 'antd';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  readonly options: ReadonlyArray<{
    readonly label?: string;
    readonly values: any;
    readonly dependsOn?: number;
  }>;

  readonly value: readonly number[];
  readonly onChange: (nextValue: readonly number[]) => void;
};

export const PermissionCheckboxGroup: FC<Props> = (props: Props) => {
  const [t] = useTranslation();

  return (
    <Row gutter={8} style={{ marginBottom: '24px', rowGap: '8px' }}>
      {ADMIN_PERMISSION_OPTION_LIST.map((po) => {
        const isChecked = po.values.every((p) => props.value.includes(p));

        return (
          <Col key={po.label} xs={12}>
            <Checkbox
              checked={isChecked}
              disabled={Boolean(po.dependsOn && !props.value.includes(po.dependsOn))}
              onChange={(e) => {
                const _isChecked = (e.nativeEvent.target as HTMLInputElement).checked;
                const dependentPermissions = ADMIN_PERMISSION_OPTION_LIST.find(
                  (_po) => _po.dependsOn === po.values[0]
                )?.values;

                if (_isChecked) {
                  props.onChange([...props.value, ...po.values]);
                } else {
                  const nextVal = props.value
                    .filter((checkedPermission: number) => !po.values.includes(checkedPermission))
                    .filter((cp: number) => !dependentPermissions?.includes(cp));
                  props.onChange([...nextVal]);
                }
              }}
            >
              {t(`permissions.${po.label ?? '-'}`)}
            </Checkbox>
          </Col>
        );
      })}
    </Row>
  );
};
