import type { UnitsOfMeasure } from '@components/types/models/UnitsOfMeasure';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

const getUnitsOfMeasure = async (): Promise<AxiosResponse<readonly UnitsOfMeasure[]>> =>
  axios.get('/api/units');

export const unitsOfMeasureApi = {
  getUnitsOfMeasure,
};
