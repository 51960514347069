/* eslint-disable @typescript-eslint/naming-convention */

export enum PermissionsEnum {
  MEMBER_VIEW = 1,
  MEMBER_EDIT = 2,

  CONTRACTOR_VIEW = 3,
  CONTRACTOR_EDIT = 4,

  CONTRACT_VIEW = 5,
  CONTRACT_EDIT = 6,

  EXCHANGE_RATE_VIEW = 7,
  EXCHANGE_RATE_EDIT = 8,
  DASHBOARDS_EDIT = 9,
  REPORTS_DOWNLOAD_DUMP = 10,
  MIGRATION_EDIT = 11,
  CURRENCY_COUNTRY_CITY_EDIT = 12,
}

export const PERMISSIONS: Record<string, PermissionsEnum> = {
  MEMBER_VIEW: PermissionsEnum.MEMBER_VIEW,
  MEMBER_EDIT: PermissionsEnum.MEMBER_EDIT,

  CONTRACTOR_VIEW: PermissionsEnum.CONTRACTOR_VIEW,
  CONTRACTOR_EDIT: PermissionsEnum.CONTRACTOR_EDIT,

  CONTRACT_VIEW: PermissionsEnum.CONTRACT_VIEW,
  CONTRACT_EDIT: PermissionsEnum.CONTRACT_EDIT,

  EXCHANGE_RATE_VIEW: PermissionsEnum.EXCHANGE_RATE_VIEW,
  EXCHANGE_RATE_EDIT: PermissionsEnum.EXCHANGE_RATE_EDIT,
  DASHBOARDS_EDIT: PermissionsEnum.DASHBOARDS_EDIT,
  REPORTS_DOWNLOAD_DUMP: PermissionsEnum.REPORTS_DOWNLOAD_DUMP,
  MIGRATION_EDIT: PermissionsEnum.MIGRATION_EDIT,
  CURRENCY_COUNTRY_CITY_EDIT: PermissionsEnum.CURRENCY_COUNTRY_CITY_EDIT,
};
