import { notification } from 'antd';
import type { AxiosError } from 'axios';
import type { QueryKey } from 'react-query';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query/types/react/types';

export const useDefaultQuery = <T = unknown>(
  queryName: QueryKey,
  queryFunction: () => Promise<T>,
  onSuccess?: (res: T) => void
): UseQueryResult<T> =>
  useQuery(queryName, queryFunction, {
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: (error: AxiosError) => {
      notification.error({
        message: error.response?.data.message,
      });
    },
    onSuccess,
  });
