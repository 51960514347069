import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse, Layout, Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";

const { Sider, Content } = Layout;
const { Panel } = Collapse;

type UserguideTemplateProps = {
  guides: ReadonlyArray<{
    title: string,
    icon: JSX.Element,
    data: ReadonlyArray<{
      title: string,
      html: string
    }>
  }>
};

export const UserguideTemplate: FC<UserguideTemplateProps> = ({ guides }) => {
  const { t } = useTranslation();
  const [activeGuide, setActiveGuide] = useState<number>(0);
  const handleClick = (info: MenuInfo) => {
    setActiveGuide(Number(info.key));
  }

  return (
    <Layout>
      <Sider width={256} className="site-layout-background">
        <Menu
          onClick={handleClick}
          style={{ width: 256 }}
          defaultSelectedKeys={[`${activeGuide}`]}
          mode="inline"
        >
          {guides.map((guide, idx) => (
            <Menu.Item
              key={idx}
              icon={guide.icon}
            >
              {t(`userguides.${guide.title}`)}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Content>
        <Collapse
          defaultActiveKey={['0']}
        // onChange={callback}
        >
          {guides[activeGuide].data.map((guideItem, idx) => (
            <Panel header={guideItem.title} key={idx}>
              <div dangerouslySetInnerHTML={{ __html: guideItem.html }}></div>
            </Panel>
          ))}
        </Collapse>
      </Content>
    </Layout>
  )
};
