import React from 'react';
import { Helmet } from 'react-helmet';

import type { HelmetProps } from './props';

export const PageMeta: React.FC<HelmetProps> = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title} - KC Portal</title>
  </Helmet>
);
