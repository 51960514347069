import { Select } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { CountrySelectorProps } from './props';

export const CountrySelectorTemp: React.FC<CountrySelectorProps> = ({
  selectedCountry,
  countries,
  onChange,
  ...rest
}) => {
  const [, i18n] = useTranslation();
  const { Option } = Select;

  const handleSelectChange = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      showSearch
      value={selectedCountry}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={handleSelectChange}
      {...rest}
    >
      {countries.map((item, index) => (
        <Option key={index} value={item.id}>
          <span>{i18n.language === 'en' ? item.nameEn : item.nameRu}</span>
        </Option>
      ))}
    </Select>
  );
};
