type Params = {
  readonly [key in string]:
    | number
    | string
    | readonly number[]
    | readonly string[]
    | null
    | undefined;
};

export const stripUnusedObjectFields = (obj: Params): Params =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));

export const capitalizeText = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const unCapitalizeText = (text: string): string =>
  text.charAt(0).toLowerCase() + text.slice(1);
