/* eslint-disable @typescript-eslint/naming-convention */

export const ruForecasts = {
  forecasts: 'Планы',
  forecastCreated: 'План успешно создан',
  forecastForContract: 'План для контракта',
  forecastVersion: 'Версия плана',
  from: 'С',
  to: 'по',
  name: 'Название',
  goods: 'Товары',
  newGood: 'Новый товар',
  newWork: 'Новая работа',
  newService: 'Новая  услуга',
  newCompany: 'Новый подрядчик',
  companyName: 'Название компании',
  goodsDescription: 'Описание товара',
  contractualRelationship: 'Договорные отношения',
  goodQuantity: 'Количество товара',
  goodUnitsOfMeasure: 'Единица измерения товара',
  forecastValue: 'Сумма',
  kcForecastValue: 'Итого KC план',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CTKZ: 'CT-KZ%',
  addGoods: 'Добавить товар',
  subcontractor: 'Субподрядчик',
  affiliatedCompany: 'Дочерняя компания',
  supplier: 'Поставщик',
  primeContractor: 'Генеральная компания',
  contractor: 'Подрядчик',
  subcontractorAdded: 'Субподрядчик успешно сохранен',
  dataSaved: 'Данные сохранены',
  goodAdded: 'Новый товар добавлен',
  workAdded: 'Новая работа добавлена',
  serviceAdded: 'Новая услуга добавлена',
  worksDescription: 'Описание работ',
  serviceDescription: 'Описание услуг',
  works: 'Работы',
  addWork: 'Добавить работы',
  addService: 'Добавить услуги',
  staffPercent: 'Доля казахстанцев в ФОТ %',
  comments: 'Комментарии',
  leaveComment: 'Оставить комментарий',
  noCommentsYet: 'Пока никаких комментариев',
  commentToTheSection: 'Комментарий к секции',
  services: 'Услуги',
  goodsWorksServicesSummary: 'Итого товары, работы, услуги',
  jobsAndNationalization: 'Количество сотрудников и национализация',
  training: 'Обучение',
  mouCategory: {
    engineering: 'Инженерно-технические работники',
    supervisorForeman: 'Руководящий состав',
    administration: 'Административный персонал',
    craft: 'Квалифицированные рабочие разных специальностей',
    heavyEquipment: 'Операторы оборудования',
    total: 'Всего',
    grandTotal: 'Общий итог',
  },
  nationalization: {
    nationalizationFor: 'Национализация для',
    position: 'Позиция',
    fwlCategory: 'Категория FWL',
    foreignerName: 'Фамилия, имя иностранного сотрудника',
    pleaseProvide: 'Пожалуйста укажите позиции, которые планируется национализировать',
    country: 'Страна',
    companyName: 'Компания',
    kzName: 'Фамилия, имя местного сотрудника',
    comment: 'Комментарий',
    category: {
      1: 'Категория I',
      2: 'Категория II',
      3: 'Категория III',
      4: 'Категория IV',
    },
    categoryInfo: {
      1: 'Категория I - руководители и их заместители',
      2: 'Категория II - руководители структурных подразделений, соответствующие квалификационным требованиям, установленным профессиональными стандартами, квалификационным справочником должностей руководителей, специалистов и других служащих, типовыми квалификационными характеристиками должностей руководителей, специалистов и других служащих организаций',
      3: 'Категория III - специалисты, соответствующие квалификационным требованиям, установленным профессиональными стандартами, квалификационным справочником должностей руководителей, специалистов и других служащих, типовыми квалификационными характеристиками должностей руководителей, специалистов и других служащих организаций',
      4: 'Категория IV - квалифицированные рабочие, соответствующие квалификационным требованиям, установленным профессиональными стандартами, Единым тарифно-квалификационным справочником работ и профессий рабочих, тарифно-квалификационными характеристиками профессий рабочих',
    },
    addPosition: 'Добавить позицию',
    saved: 'Подробная информация по национализации сохранена',
  },
  jobsPositions: 'Рабочие позиции',
  kzNationals: 'Казахстанцы',
  nonKzNationals: 'Не-казахстанцы',
  total: 'Итого',
  plannedToNationalize: 'План национализации',
  graduateSection: 'Выпускники',
  newGradInternships: 'Стажировки для выпускников',
  newGradJobs: 'Трудоустройство выпускников',
  saved: 'Сохранен',
  grandTotal: 'Общий итог',
  saveAndGoToTheNextYear: 'Сохранить и перейти к следующему году',
  saveAndGoToTheNextSection: 'Сохранить и перейти к следующей секции',
  saveAndGoToTheNextCategory: 'Сохранить и перейти к следующей категории',
  proceedAndGoToTheNextCategory: 'Продолжить и перейти к следующей категории',
  proceedAndGoToTheNextSection: 'Продолжить и перейти к следующей секции',
  proceedAndGoToTheNextYear: 'Продолжить и перейти к следующему году',
  technologyTransfer: 'Передача технологии',
  legacy: 'Наследие',
  successStories: 'История успеха',
  saveAndProceed: 'Сохранить и продолжить',
  proceed: 'Продолжить',
  section: 'Секция',
  kc: 'KC план %',
  all: 'ALL',
  opts: {
    status: {
      overdue: 'Просрочен',
      approved: 'Утвержден',
      rejected: 'Отклонен',
      pending: 'На рассмотрении',
    },
  },
  applyFilters: 'Применить фильтры',
  subAgreementNumber: 'Номер доп.соглашения',
  forecastValueInOriginalCurrency: 'Сумма в исходной валюте',
  forecastValueInUsd: 'Сумма в USD',
  forecastKcValueInOriginalCurrency: 'Сумма KC в исходной валюте',
  forecastKcValueInUsd: 'Сумма KC в USD',
  forecastKcShare: 'KC %',
  dueDate: 'Рассмотреть до',
  submissionWarningMessage:
    'После отправки будет создана новая версия плана для утверждения КС специалистом',
  successForecastSubmit:
    'План был успешно отправлен на утверждение KC менеджеру, пожалуйста ожидайте обратной связи!',
  submissionDate: 'Дата предоставления: {{date}}',
  rejectForecast: 'Отклонить план',
  setDueDate: 'Укажите срок предоставления изменений',
  forecastApproved: 'План одобрен',
  forecastRejected: 'План отклонен',
  restorePrevVersion: 'Восстановить предыдущую версию',
  legacyPlaceholder: 'Пожалуйста опишите план по наследию',
  technologyPlaceholder: 'Пожалуйста опишите план по передачи технологий',
  downloadKcPlan: 'Скачать KC план',
  timeLeftForReview_0: 'Осталось времени на рассмотрение: {{count}} день',
  timeLeftForReview_1: 'Осталось времени на рассмотрение: {{count}} дня',
  timeLeftForReview_2: 'Осталось времени на рассмотрение: {{count}} дней',
  timeLeft_0: 'Осталось времени - {{count}} день',
  timeLeft_1: 'Осталось времени - {{count}} дня',
  timeLeft_2: 'Осталось времени - {{count}} дней',
  youHaveMissedDueDate: 'Вы пропустили срок сдачи',
};
