/* eslint-disable import/no-unassigned-import */
import './App.css';
import 'moment/locale/ru';

import { interceptHttpErrors } from '@api';
import { adminApi } from '@api/adminApi';
import { USER_ROLES } from '@components/types/models/Roles';
import type { User } from '@components/types/models/User';
import type {
  Credentials,
  UserContextType,
} from '@contexts/types/UserContextType';
import { defineUserRole, UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks';
import { useIdleChecker } from '@hooks/useIdleChecker';
import { useLocalStorageListener } from '@hooks/useLocalStorageListener';
import { styled } from '@linaria/react';
import { ConfigProvider, Layout, Spin } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import axios from 'axios';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import AppRoutes from './routes/AppRoutes';

const App: FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userCredentials, setUserCredentials] = useState<Credentials | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [, i18n] = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('KC_PORTAL_USER_TOKEN');
    if (token) {
      axios
        .get<User>('/api/auth/me')
        .then((res) => {
          interceptHttpErrors(history);
          setUser(res.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [history]);

  const { data: notices, refetch: refetchNotices } = useDefaultQuery(
    'getNotices',
    async () => {
      if (
        user?.userRoles.find((role) => role.role === USER_ROLES.ADMINISTRATOR)
      ) {
        return adminApi.getNotices().then((res) => res.data);
      }
      return global.undefined;
    }
  );

  useEffect(() => {
    void refetchNotices();
  }, [user, refetchNotices]);

  const userProviderValue = useMemo<UserContextType>(
    () => ({
      user,
      setUser,
      userRole: defineUserRole(user?.userRoles[0].role),
      userEntityId: user?.userRoles[0].entityId ?? 0,
      isUserLoggedIn: Boolean(user),
      notices,
      refetchNotices,
      userCredentials,
      setUserCredentials,
    }),
    [
      user,
      setUser,
      notices,
      refetchNotices,
      userCredentials,
      setUserCredentials,
    ]
  );

  const logout = async (): Promise<boolean> => {
    setUser(null);
    return Promise.resolve(true);
  };

  const { subscribeToIdleChecker } = useIdleChecker({
    idleTimeOutSeconds: 900,
    onTimeOut: async () => {
      await logout();
    },
    checkEvery: 1000,
  });

  useEffect(() => {
    subscribeToIdleChecker();
  }, [subscribeToIdleChecker]);

  useLocalStorageListener();

  const LoaderLayout = styled(Layout)`
  ${tw`h-screen flex justify-center items-center`}
  }
`;
  return (
    <UserContext.Provider value={userProviderValue}>
      {isLoading && !user ? (
        <LoaderLayout>
          <Spin size="large" />
        </LoaderLayout>
      ) : (
        <ConfigProvider locale={i18n.language === 'en' ? enUS : ruRU}>
          <AppRoutes />
        </ConfigProvider>
      )}
    </UserContext.Provider>
  );
};

export default App;
