import { contractApi } from '@api/contractApi';
import { contractorApi } from '@api/contractorApi';
import { forecastApi } from '@api/forecastApi';
import { ForecastGws } from '@components/organisms/ForecastGws';
import { ForecastJobsForm } from '@components/organisms/ForecastJobsForm';
import { ForecastTrainingForm } from '@components/organisms/ForecastTrainingForm';
import { LegacyTechnologyForm } from '@components/organisms/LegacyTechnologyForm';
import { MasterAgreementGwsTable } from '@components/organisms/MasterAgreementGwsTable';
import type { Contract as ContractType } from '@components/types/models/Contract';
import {
  CONTRACT_TYPE,
  defineContractType,
  SCOPE_OF_WORK,
} from '@components/types/models/Contract';
import type { Contractor } from '@components/types/models/Contractor';
import type { ForecastType } from '@components/types/models/Forecast';
import {
  ForecastApprovalStatus,
  SectionType,
} from '@components/types/models/Forecast';
import { defineStatus } from '@components/types/models/Statuses';
import { UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Modal,
  notification,
  Row,
  Tag,
  Typography,
} from 'antd';
import type { AxiosError } from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import type { FC } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { ForecastVersionSelector } from './libs/components/ForecastVersionSelector';

const daysFromTodayForNewDueDate = 3;
const today = new Date();

export const ForecastView: FC = () => {
  const badgeOffsetLeft = 16;
  const [dueDate, setDueDate] = useState(
    moment().add(daysFromTodayForNewDueDate, 'days')
  );
  const [daysLeftToDuedate, setDaysLeftToDueDate] = useState<number | null>(
    null
  );
  const { refetchNotices } = useContext(UserContext);
  const { id } = useParams<{ readonly id?: string }>();
  const [forecastId, setForecastId] = useState<number>(Number(id));
  const { t, i18n } = useTranslation();
  const { Text, Title } = Typography;
  const { Panel } = Collapse;
  const [activeCollapseItem, setActiveCollapseItem] = useState<number>();
  const scopeOfWorks = useMemo<Record<SCOPE_OF_WORK, string>>(
    () => ({
      [SCOPE_OF_WORK.GOODS]: t('contracts.goods'),
      [SCOPE_OF_WORK.WORKS]: t('contracts.works'),
      [SCOPE_OF_WORK.SERVICES]: t('contracts.services'),
    }),
    [t]
  );
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const {
    data: forecast,
    isLoading,
    refetch: refetchForecast,
  } = useDefaultQuery<ForecastType>('gerForecast', async () =>
    forecastApi.getForecastDetails(Number(forecastId)).then((res) => res.data)
  );

  const { data: contractData, refetch: refetchContractData } =
    useDefaultQuery<ContractType>(
      'getContract',
      async () =>
        forecast &&
        contractApi
          .getContract(Number(forecast.contractId))
          .then((res) => res.data)
    );

  const { data: contractorData, refetch: refetchContractorData } =
    useDefaultQuery<Contractor>(
      'getContractor',
      async () =>
        contractData &&
        contractorApi
          .getContractorData(Number(contractData.contractorId))
          .then((res) => res.data)
    );

  useEffect(() => {
    void refetchContractData();
    if (forecast?.dueDate) {
      setDaysLeftToDueDate(-moment().diff(forecast.dueDate, 'days'));
    }
  }, [forecast, refetchContractData]);

  useEffect(() => {
    void refetchContractorData();
  }, [contractData, refetchContractorData]);

  const forecastApprovalStatus = defineStatus(forecast?.approvalStatus);

  const contractPeriod = `${t('forecasts.from')} ${moment(
    forecast?.contractStartDate
  ).format('DD-MM-YYYY')} ${t('forecasts.to')} ${moment(
    forecast?.contractEndDate
  ).format('DD-MM-YYYY')}`;
  const forecastVersion = `v${forecast?.forecastVersion}`;
  const forecastKcTarget = `${forecast?.kcTarget}%`;

  const handleNewDueDateChange = useCallback((e) => {
    setDueDate(e);
  }, []);

  const approveMutation = useMutation(
    async ({ forecastId }: { readonly forecastId: string }) => {
      if (forecastId) {
        await forecastApi.approveForecast(forecastId);
      }
    },
    {
      onSuccess() {
        notification.success({
          message: t('forecasts.forecastApproved'),
        });
        void refetchForecast();
        void refetchNotices?.();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const rejectMutation = useMutation(
    async ({
      forecastId,
      date,
    }: {
      readonly forecastId: string;
      readonly date: string;
    }) => {
      if (forecastId && date) {
        await forecastApi.rejectForecast(forecastId, date);
      }
    },
    {
      onSuccess() {
        notification.success({
          message: t('forecasts.forecastRejected'),
        });
        setIsRejectModalOpen(false);
        void refetchForecast();
        void refetchNotices?.();
      },
      onError(err: AxiosError) {
        setIsRejectModalOpen(false);
        if (err.response?.data?.message) {
          notification.error({ message: err.response.data?.message });
        }
        if (err.response?.data.validationErrors.length > 0) {
          notification.error({
            message: err.response?.data.validationErrors
              .map(
                (error: { readonly description: string }) => error.description
              )
              .join(', '),
          });
        }
      },
    }
  );

  const handleApprove = useCallback(() => {
    if (forecastId) {
      approveMutation.mutate({
        forecastId: forecastId.toString(),
      });
    }
  }, [approveMutation, forecastId]);

  const handleOpenRejectModal = useCallback(() => {
    setIsRejectModalOpen(true);
  }, []);

  const handleRejectModalOkClick = useCallback(() => {
    if (forecastId) {
      rejectMutation.mutate({
        forecastId: forecastId.toString(),
        date: dueDate.format(),
      });
    }
  }, [dueDate, forecastId, rejectMutation]);
  const handleCloseRejectModal = useCallback(() => {
    setIsRejectModalOpen(false);
  }, []);

  const getCommentsBadge = (sectionType: SectionType): string => {
    if (
      forecast?.unresolvedComments.some(
        (item) => item.sectionType === sectionType
      )
    ) {
      return '!';
    }
    return '';
  };

  const handleOpenCollapse = useCallback((key) => {
    setActiveCollapseItem(key);
  }, []);

  const handleVersionSelectChange = (value: number): void => {
    setForecastId(value);
  };

  useEffect(() => {
    refetchForecast();
  }, [forecastId]);

  return (
    <>
      <Row>
        <Col span={12}>
          <Title level={4}>{contractorData?.name}</Title>
        </Col>
        <Col
          span={12}
          className={css`
            ${tw`text-right`}
          `}
        >
          {forecast?.reviewedBy && (
            <>
              <Text>
                {t(
                  `contracts.${
                    forecast.approvalStatus === ForecastApprovalStatus.Approved
                      ? 'approvedBy'
                      : 'rejectedBy'
                  }`,
                  {
                    name: `${forecast.reviewedBy.firstName} ${forecast.reviewedBy.lastName}`,
                  }
                )}
              </Text>
              <br />
            </>
          )}
          {forecast?.reviewedAt && (
            <>
              <Text>
                {t(
                  `contracts.${
                    forecast.approvalStatus === ForecastApprovalStatus.Approved
                      ? 'approvalDate'
                      : 'rejectionDate'
                  }`,
                  {
                    date: moment(forecast.reviewedAt).format(
                      'YYYY-MM-DD HH:mm:ss'
                    ),
                  }
                )}
              </Text>
              <br />
            </>
          )}
          {forecast?.submittedAt && (
            <Text>
              {t('forecasts.submissionDate', {
                date: moment(forecast.submittedAt).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
              })}
            </Text>
          )}
        </Col>
      </Row>
      <Row
        className={css`
          ${tw`mb-8`}
        `}
      >
        <Col span={24}>
          <ForecastVersionSelector
            contractId={contractData?.id}
            forecast={forecast}
            onVersionSelectChange={handleVersionSelectChange}
          />
        </Col>
      </Row>

      {forecast?.approvalStatus === ForecastApprovalStatus.Pending &&
        daysLeftToDuedate !== null && (
          <Row
            className={css`
              ${tw`mb-8`}
            `}
          >
            <Col span={24}>
              {daysLeftToDuedate > 0 ? (
                <Alert
                  showIcon
                  message={
                    <Translation>
                      {(tr) =>
                        tr('forecasts.timeLeftForReview', {
                          count: daysLeftToDuedate,
                        })
                      }
                    </Translation>
                  }
                  type="warning"
                />
              ) : (
                <Alert
                  showIcon
                  message={t('forecasts.youHaveMissedDueDate')}
                  type="error"
                />
              )}
            </Col>
          </Row>
        )}
      <Card
        loading={isLoading}
        title={
          <Text>
            {t('forecasts.forecastForContract')}{' '}
            <Link to={`/admin/contract/${forecast?.contractId}`}>
              {forecast?.contractNumber}
            </Link>
            <Tag
              style={{ marginLeft: '24px' }}
              color={forecastApprovalStatus.color}
            >
              {i18n.language === 'en'
                ? forecastApprovalStatus.enName
                : forecastApprovalStatus.ruName}
            </Tag>
          </Text>
        }
      >
        <div
          className={css`
            ${tw`m-4!`}
          `}
        >
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={6}>
              <Text type="secondary">{t('forecasts.forecastVersion')}</Text>
              <br />
              <Title level={5}>{forecastVersion}</Title>
            </Col>
          </Row>
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={8}>
              <Text type="secondary">{t('contracts.contractPeriod')}</Text>
              <br />
              <Title level={5}>{contractPeriod}</Title>
            </Col>
            <Col span={6}>
              <Text type="secondary">{t('contracts.contractType')}</Text>
              <br />
              <Title level={5}>
                {i18n.language === 'en'
                  ? defineContractType(forecast?.contractType).enName
                  : defineContractType(forecast?.contractType).ruName}
              </Title>
            </Col>
            <Col span={6}>
              <Text type="secondary">{t('contracts.masterAgreement')}</Text>
              <br />
              <Title level={5}>{forecast?.masterAgreementNumber ?? '-'}</Title>
            </Col>
          </Row>
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={8}>
              <Text type="secondary">{t('contracts.kcCategoryAndArea')}</Text>
              <br />
              <Title level={5}>{forecast?.kcCategory.name}</Title>
            </Col>
            <Col span={6}>
              <Text type="secondary">{t('contracts.amount')}</Text>
              <br />
              <Title level={5}>
                {numeral(forecast?.contractAmount).format('0,000.00')}{' '}
                {forecast?.currencyDto.code}
              </Title>
            </Col>
            <Col span={6}>
              <Text type="secondary">{t('contracts.kcTarget')}</Text>
              <br />
              <Title level={5}>{forecastKcTarget}</Title>
            </Col>
          </Row>
          {forecast?.contractType !== CONTRACT_TYPE.MASTER_AGREEMENT && (
            <Row
              className={css`
                ${tw`mb-6`}
              `}
            >
              <Col span={6}>
                <Text type="secondary">{t('contracts.scopeOfWorks')}</Text>
                <br />
                <Title level={5}>
                  {forecast?.scopes.map(
                    (scope: SCOPE_OF_WORK, index) =>
                      (index ? ', ' : '') + scopeOfWorks[scope]
                  )}
                </Title>
              </Col>
            </Row>
          )}
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={6}>
              <Text type="secondary">{t('contracts.detailsOnScopeWork')}</Text>
              <br />
              <Title level={5}>{forecast?.detailsOnScopeOfWork} </Title>
            </Col>
          </Row>
        </div>
      </Card>
      <Collapse
        accordion
        destroyInactivePanel
        activeKey={activeCollapseItem}
        onChange={handleOpenCollapse}
      >
        {forecast?.contractType === CONTRACT_TYPE.MASTER_AGREEMENT ? (
          <Panel key="4" header={t('forecasts.goodsWorksServicesSummary')}>
            <MasterAgreementGwsTable forecastId={forecastId} />
          </Panel>
        ) : (
          <ForecastGws
            scopes={forecast?.scopes}
            startDateString={forecast?.contractStartDate}
            endDateString={forecast?.contractEndDate}
            forecastId={forecastId}
            contractCurrency={forecast?.currencyDto.code}
            forecastStatus={forecast?.approvalStatus}
            getCommentsBadge={getCommentsBadge}
            activeCollapseItem={activeCollapseItem}
          />
        )}
        {forecast?.contractType !== CONTRACT_TYPE.SUB_AGREEMENT && (
          <>
            <Panel
              key="5"
              header={
                <Badge
                  offset={[badgeOffsetLeft, 0]}
                  count={getCommentsBadge(SectionType.JobAndNationalization)}
                >
                  {t('forecasts.jobsAndNationalization')}
                </Badge>
              }
            >
              <ForecastJobsForm
                startDate={forecast?.contractStartDate}
                endDate={forecast?.contractEndDate}
                forecastId={forecastId}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={5}
                forecastStatus={forecast?.approvalStatus}
              />
            </Panel>
            <Panel
              key="6"
              header={
                <Badge
                  offset={[badgeOffsetLeft, 0]}
                  count={getCommentsBadge(SectionType.Training)}
                >
                  {t('forecasts.training')}
                </Badge>
              }
            >
              <ForecastTrainingForm
                startDate={forecast?.contractStartDate}
                endDate={forecast?.contractEndDate}
                forecastId={forecastId}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={6}
                forecastStatus={forecast?.approvalStatus}
              />
            </Panel>
            <Panel
              key="7"
              header={
                <Badge
                  offset={[badgeOffsetLeft, 0]}
                  count={getCommentsBadge(SectionType.TechnologyTransfer)}
                >
                  {t('forecasts.technologyTransfer')}
                </Badge>
              }
            >
              <LegacyTechnologyForm
                forecastId={forecastId}
                sectionType={SectionType.TechnologyTransfer}
                placeholder={t('forecasts.technologyPlaceholder')}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={7}
                url={`/api/forecasts/techtransfer/${forecastId}`}
                forecastStatus={forecast?.approvalStatus}
              />
            </Panel>
            <Panel
              key="8"
              header={
                <Badge
                  offset={[badgeOffsetLeft, 0]}
                  count={getCommentsBadge(SectionType.Legacy)}
                >
                  {t('forecasts.legacy')}
                </Badge>
              }
            >
              <LegacyTechnologyForm
                forecastId={forecastId}
                sectionType={SectionType.Legacy}
                placeholder={t('forecasts.legacyPlaceholder')}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={8}
                url={`/api/forecasts/techtransfer/${forecastId}`}
                forecastStatus={forecast?.approvalStatus}
              />
            </Panel>
          </>
        )}
      </Collapse>
      {forecast?.approvalStatus === ForecastApprovalStatus.Pending && (
        <Row
          className={css`
            ${tw`mt-4`}
          `}
        >
          <Col span={12}>
            <Button type="default" onClick={handleOpenRejectModal}>
              {t('contracts.reject')}
            </Button>
          </Col>
          <Col
            span={12}
            className={css`
              ${tw`text-right`}
            `}
          >
            <Button type="primary" onClick={handleApprove}>
              {t('contracts.approve')}
            </Button>
          </Col>
        </Row>
      )}
      <Modal
        visible={isRejectModalOpen}
        title={t('forecasts.rejectForecast')}
        cancelText={t('cancel')}
        okText={t('yes')}
        confirmLoading={rejectMutation.isLoading}
        onOk={handleRejectModalOkClick}
        onCancel={handleCloseRejectModal}
      >
        <Text>{t('forecasts.setDueDate')}</Text>
        <Form
          className={css`
            ${tw`mt-2`}
          `}
        >
          <Form.Item>
            <DatePicker
              defaultValue={dueDate}
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
              disabledDate={(date) => date < moment(today).add(1, 'day')}
              onChange={handleNewDueDateChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
