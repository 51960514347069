/* eslint-disable @typescript-eslint/naming-convention */

import { CONTRACTUAL_RELATIONS } from '@components/types/models/ContractualRelations';

export const CONTRACTUAL_RELATIONS_LIST: ReadonlyArray<{
  readonly label: string;
  readonly value: number;
}> = [
  { value: CONTRACTUAL_RELATIONS.SUBCONTRACTOR, label: 'subcontractor' },
  {
    value: CONTRACTUAL_RELATIONS.AFFILIATED_COMPANY,
    label: 'affiliatedCompany',
  },
  {
    value: CONTRACTUAL_RELATIONS.SUPPLIER,
    label: 'supplier',
  },
  {
    value: CONTRACTUAL_RELATIONS.PRIME_CONTRACTOR,
    label: 'primeContractor',
  },
  {
    value: CONTRACTUAL_RELATIONS.CONTRACTOR,
    label: 'contractor',
  },
];

export function defineRelationType(relationType?: CONTRACTUAL_RELATIONS): {
  readonly enName: string;
  readonly ruName: string;
} {
  switch (relationType) {
    case CONTRACTUAL_RELATIONS.SUBCONTRACTOR:
      return {
        enName: 'Subcontractor',
        ruName: 'Субподрядчик',
      };
    case CONTRACTUAL_RELATIONS.AFFILIATED_COMPANY:
      return {
        enName: 'Affiliated company',
        ruName: 'Дочерняя компания',
      };
    case CONTRACTUAL_RELATIONS.PRIME_CONTRACTOR:
      return {
        enName: 'Prime contractor',
        ruName: 'Генеральная компания',
      };
    case CONTRACTUAL_RELATIONS.SUPPLIER:
      return {
        enName: 'Supplier',
        ruName: 'Поставщик',
      };
    case CONTRACTUAL_RELATIONS.CONTRACTOR:
      return {
        enName: 'Contractor',
        ruName: 'Подрядчик',
      };
    default:
      return {
        enName: 'Undefined',
        ruName: 'Undefined',
      };
  }
}
