import { UserContext } from '@contexts/userContext';
import type { FC } from 'react';
import React, { useContext } from 'react';

import AdminRoutes from './AdminRoutes';
import ContractorRoutes from './ContractorRoutes';
import GuestRoutes from './GuestRoutes';

const AppRoutes: FC = () => {
  const { userRole } = useContext(UserContext);

  switch (userRole) {
    case 'admin':
      return <AdminRoutes />;
    case 'contractor':
      return <ContractorRoutes />;
    default:
      return <GuestRoutes />;
  }
};
export default AppRoutes;
