export const actuals = [
  {
    title: "How to create Actual for your Contract?",
    html: `
    <p class="c0"><span class="c4">Once KC Lead approved your Contract, you can add Actual:</span></p>
    <ol class="c5 lst-kix_4v5wo8l1wld1-0 start" start="1">
      <li class="c0 c7"><span class="c4">Press on Actual tab on the side menu</span></li>
      <li class="c0 c7"><span>Press on the </span><span class="c1">Submit new actuals </span><span
          class="c4">button</span></li>
      <li class="c0 c7"><span class="c4">Select Contract number from Contract dropdown and Actual period from Submission
          period</span></li>
    </ol>
    <p class="c0"><span
        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
          alt="" src="/images/userguides/contractor/image10.png"
          style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""></span><span
        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
          alt="" src="/images/userguides/contractor/image32.png"
          style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""></span></p>
    <p class="c0 c3"><span class="c4"></span></p>  
`
  },
  {
    title: "How to create Actual for your Stand-alone agreement?",
    html: `
    <ol class="c5 lst-kix_o7x4mb1337h6-0 start" start="1">
    <li class="c0 c7"><span class="c4">Press on Actual tab on the side menu</span></li>
    <li class="c0 c7"><span>Press on the </span><span class="c1">Submit new actuals </span><span
        class="c4">button</span></li>
    <li class="c0 c7"><span>Select Stand-alone contract from Contract dropdown and Actual period from Submission
        period</span><span
        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
          alt="" src="/images/userguides/contractorimage12.png"
          style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""></span></li>
  </ol>
  <p class="c0 c3"><span class="c4"></span></p>
`
  },
  {
    title: "How to create Actual for your Master agreement?",
    html: `
  <ol class="c5 lst-kix_z0pka463qq10-0 start" start="1">
    <li class="c0 c7"><span class="c4">Press on Actual tab on the side menu</span></li>
    <li class="c0 c7"><span>Press on the </span><span class="c1">Submit new actuals </span><span
        class="c4">button</span></li>
    <li class="c0 c7"><span class="c4">Select Master-agreement contract from Contract dropdown and Actual period from
        Submission period</span></li>
  </ol>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image31.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  
`
  },
  {
    title: "How to create Actual for your Sub-agreement?",
    html: `
  <ol class="c5 lst-kix_ksil8dbdl3mi-0 start" start="1">
    <li class="c0 c7"><span class="c4">Press on Actual tab on the side menu</span></li>
    <li class="c0 c7"><span>Press on the </span><span class="c1">Submit new actuals </span><span
        class="c4">button</span></li>
    <li class="c0 c7"><span class="c4">Select Sub-agreement contract from Contract dropdown and Actual period from
        Submission period</span></li>
  </ol>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image18.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image27.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image22.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
`
  },
  {
    title: "How to fill out Goods section in your Actual?",
    html: `
    <p class="c0"><span class="c4">Fill out each field according to your spending.</span></p>
    <p class="c0"><span>Press on </span><span class="c1">Add Good </span><span class="c4">button - system show you one
        more extra row.</span></p>
    <p class="c0"><span>Press on </span><span class="c1">Delete </span><span class="c4">button (x mark) if you are not
        planning to buy anything on particular month.</span></p>
    <p class="c0"><span>Once you filled out data press on </span><span class="c1">Save and go to the next section
      </span><span class="c4">button to navigate to the next available section.</span></p>
    <p class="c0"><span
        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
          alt="" src="/images/userguides/contractor/image15.png"
          style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""></span></p>
    <p class="c0 c3"><span class="c4"></span></p>
`
  },
  {
    title: "How to fill out update CT-KZ Certificate in your Actual?",
    html: `
  <ol class="c5 lst-kix_r27n02otv7ua-0 start" start="1">
    <li class="c0 c7"><span>Press on </span><span class="c1">Add new CT-KZ% certificate</span><span
        class="c4">&nbsp;button.</span></li>
    <li class="c0 c7"><span>Fill out Add CT-KZ% certificate modal window and press the </span><span
        class="c1">Save</span><span class="c4">&nbsp;button.</span></li>
  </ol>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image24.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
`
  },
  {
    title: "How to fill out Works section in your Actual?",
    html: `  
    <p class="c0"><span class="c4">Fill out each field according to your spending.</span></p>
    <p class="c0"><span>Press on </span><span class="c1">Add Work </span><span class="c4">button - system show you one
        more extra row.</span></p>
    <p class="c0"><span>Press on </span><span class="c1">Delete </span><span class="c4">button (x mark) if you are not
        planning to buy anything on particular month.</span></p>
    <p class="c0"><span>Once you filled out data press on </span><span class="c1">Save and go to the next section
      </span><span class="c4">button to navigate to the next available section.</span></p>
    <h3 class="c2" id="h.x7bv7hjdfsgt"><span
        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
          alt="" src="/images/userguides/contractor/image23.png"
          style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""></span></h3>
`
  },
  {
    title: "How to fill out Service section in your Actual?",
    html: `
    <p class="c0"><span class="c4">Fill out each field according to your spending.</span></p>
    <p class="c0"><span>Press on </span><span class="c1">Add Service </span><span class="c4">button - system show you one
        more extra row.</span></p>
    <p class="c0"><span>Press on </span><span class="c1">Delete </span><span class="c4">button (x mark) if you are not
        planning to buy anything on particular month.</span></p>
    <p class="c0"><span>Once you filled out data press on </span><span class="c1">Save and go to the next section
      </span><span class="c4">button to navigate to the next available section.</span></p>
    <p class="c0"><span
        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
          alt="" src="/images/userguides/contractor/image17.png"
          style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""></span></p>  
`
  },
  {
    title: "How to fill out Jobs and Nationalization section in your Actual?",
    html: `
    <p class="c0"><span class="c4">In order to fill out Jobs and Nationalization section, follow the steps:</span></p>
    <ol class="c5 lst-kix_vi7lwbdsj1qs-0 start" start="1">
      <li class="c0 c7"><span>Press on </span><span class="c1">Configure cities</span><span
          class="c4">&nbsp;button.</span></li>
      <li class="c0 c7"><span>Select cities then press on </span><span class="c1">Apply </span><span
          class="c4">button.</span></li>
      <li class="c0 c7"><span>Fill out all fields then press on </span><span class="c1">Save and go to the next
          section</span><span>&nbsp;button.</span></li>
    </ol>
    <p class="c0"><span
        style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
          alt="" src="/images/userguides/contractor/image7.png"
          style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""></span></p>
    <p class="c0 c3"><span class="c4"></span></p>
    `
  },
  {
    title: "How to fill out Technology transfer section in your Actual?",
    html: `
<p class="c0"><span>Describe Technology transfer and press </span><span class="c1">Save and go the next
    section</span><span class="c4">&nbsp;button.</span></p>
<p class="c0"><span
    style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
      alt="" src="/images/userguides/contractor/image8.png"
      style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span></p>
`
  },
  {
    title: "How to fill out Legacy section in your Actual?",
    html: `
<p class="c0"><span>Describe Legacy plan and press </span><span class="c1">Save and go the next section</span><span
    class="c4">&nbsp;button.</span></p>
<p class="c0"><span
    style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
      alt="" src="/images/userguides/contractor/image28.png"
      style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span></p>
<p class="c0"><span
    style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
      alt="" src="/images/userguides/contractor/image30.png"
      style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span><span
    style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
      alt="" src="/images/userguides/contractor/image13.png"
      style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span><span
    style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
      alt="" src="/images/userguides/contractor/image3.png"
      style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span></p>
<p class="c0 c3"><span class="c4"></span></p>
`
  },
  {
    title: "How to confirm Training section in your Actual?",
    html: `
<p class="c0"><span>In Training section press on </span><span class="c1">Confirm and go to the next
    section</span><span class="c4">&nbsp;button to navigate to the next available section.</span></p>
<p class="c0"><span
    style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
      alt="" src="/images/userguides/contractor/image25.png"
      style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span></p>
`
  },
  {
    title: "How to set Due date for my Actual?",
    html: ` 
<p class="c0"><span>Once you confirm Training section </span><span class="c1">Due date</span><span
    class="c4">&nbsp;button will be shown for you.</span></p>
<p class="c0"><span class="c4">Due date for review your Actual by default calculated as current day plus 3 (three)
    days.</span></p>
<p class="c0 c3"><span class="c4"></span></p>
<p class="c0 c3"><span class="c4"></span></p>
`
  }
];

export default actuals;
