import type { CategoryItem } from '@components/pages/admin/Forecasts/libs/components/CategorySelector';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CATEGORIES: readonly CategoryItem[] = [
  { key: 1, title: 'ENGINEERING & MANAGEMENT' },
  { key: 2, title: 'MATERIALS & EQUIPMENT' },
  { key: 3, title: 'FABRICATION' },
  { key: 4, title: 'LOGISTICS' },
  { key: 10, title: 'CaTRo (Prorva)' },
  { key: 11, title: 'DRILLING & COMPLETION' },
  { key: 12, title: 'OPERATIONS' },
  { key: 13, title: 'SYSTEMS & COMPLETION' },
  { key: 14, title: 'CRUDE STORAGE' },
  {
    title: 'CONSTRUCTION',
    key: 0,
    children: [
      { key: 5, title: '3GI' },
      { key: 6, title: '3GP' },
      { key: 7, title: 'GATHERING SYSTEMS' },
      { key: 8, title: 'TENGIZ GENERAL SERVICES' },
      { key: 9, title: 'CONTROLS & POWER' },
    ],
  },
];
