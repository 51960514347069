import { Space, Typography } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UserguideTemplate } from '@components/templates/UserguideTemplate';
import { tcoUserguides } from '@utils/userguides/tco';

export const UserGuide: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;


  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Title>{t("login.userGuide")}</Title>
      <UserguideTemplate guides={tcoUserguides} />
    </Space>
  );

};
