import { InfoOutlined, PlusOutlined } from '@ant-design/icons';
import type { Contract } from '@components/types/models/Contract';
import { defineContractType, defineScopeOfWorks } from '@components/types/models/Contract';
import { defineStatus } from '@components/types/models/Statuses';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import type { ForecastsTableProps } from './props';

export const ForecastsTable: FC<ForecastsTableProps> = ({
  forecastsList,
  isLoading,
  pagination,
  handleCreateForecast,
  isCreateButtonLoading,
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [, i18n] = useTranslation();
  const [contractId, setContractId] = useState<number>();
  const history = useHistory();

  const handleCreate = useCallback(
    (id: number) => () => {
      handleCreateForecast?.(id);
      setContractId(id);
    },
    [handleCreateForecast]
  );

  const handleOpenForecast = useCallback(
    (id: number) => () => {
      history.replace(`/contractor/forecast/${id}`);
    },
    [history]
  );

  const columns = [
    {
      title: t('contracts.contractNumber'),
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      render: function linkToContractView(contractNumber: string, contract: Contract) {
        return contract.forecastId ? (
          <Link to={`/contractor/forecast/${contract.forecastId}`}>{contractNumber}</Link>
        ) : (
          <Text>{contractNumber}</Text>
        );
      },
    },
    {
      title: t('contracts.type'),
      dataIndex: 'contractType',
      key: 'contractType',
      render: function getContractType(contractType: number) {
        return (
          <Text>
            {i18n.language === 'en'
              ? defineContractType(contractType).enName
              : defineContractType(contractType).ruName}
          </Text>
        );
      },
    },
    {
      title: t('contracts.scopeOfWorks'),
      dataIndex: 'scopes',
      key: 'scopes',
      render: function getScopeOfWorks(scopeOfWorks: readonly number[]) {
        return (
          <>
            {scopeOfWorks.map((item: number, index) => (
              <Text key={index}>
                {(index ? ', ' : '') +
                  (i18n.language === 'en'
                    ? defineScopeOfWorks(item).enName
                    : defineScopeOfWorks(item).ruName)}
              </Text>
            ))}
          </>
        );
      },
    },
    {
      title: t('contracts.startDate'),
      dataIndex: 'contractStartDate',
      key: 'contractStartDate',
      render: function getContractStartDate(date: string) {
        return <Text>{moment(date).format('YYYY-MM-DD')}</Text>;
      },
    },
    {
      title: t('contracts.endDate'),
      dataIndex: 'contractEndDate',
      key: 'contractEndDate',
      render: function getContractEndDate(date: string) {
        return <Text>{moment(date).format('YYYY-MM-DD')}</Text>;
      },
    },
    {
      title: t('contracts.version'),
      dataIndex: 'forecastVersion',
      key: 'forecastVersion',
      render: function getContractVersion(forecastVersion?: number) {
        return <Text>{forecastVersion ?? '-'}</Text>;
      },
    },
    {
      title: t('contracts.status'),
      dataIndex: 'forecastStatus',
      key: 'forecastStatus',
      render: function getContractStatus(forecastsStatus: number) {
        const status = defineStatus(forecastsStatus);
        return (
          <Text>
            {forecastsStatus ? (
              <Tag color={status.color}>
                {i18n.language === 'en' ? status.enName : status.ruName}
              </Tag>
            ) : (
              '-'
            )}
          </Text>
        );
      },
    },
    {
      title: t('actions'),
      key: 'actions',
      render: function renderActions(contract: Contract) {
        return (
          <Space>
            {contract.forecastVersion ? (
              <Button
                shape="circle"
                icon={<InfoOutlined />}
                onClick={handleOpenForecast(contract.forecastId)}
              />
            ) : (
              <Tooltip title={t('add')}>
                <Button
                  loading={isCreateButtonLoading && contractId === contract.id}
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={handleCreate(contract.id)}
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      locale={{ emptyText: t('noData') }}
      columns={columns}
      loading={isLoading}
      dataSource={forecastsList}
      pagination={pagination}
    />
  );
};
