import { adminApi } from '@api/adminApi';
import { SearchSection } from '@components/pages/admin/Forecasts/libs/components/SearchSection';
import type { SearchQueryType } from '@components/pages/admin/Forecasts/libs/components/SearchSection/libs/types/SearchQueryType';
import { ForecastApprovalStatus, ForecastSorting } from '@components/types/models/Forecast';
import { Typography } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';

import { ForecastsListTabs } from './libs/components/ForecastsListTabs';

export const Forecasts: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [tabNum, setTabNum] = useState<string>('0');
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();
  const [sort, setSort] = useState<number | undefined>(ForecastSorting.ByContractorNameAsc);
  const pageSize = 15;

  const {
    data: forecastsList,
    isLoading,
    refetch,
  } = useQuery(
    ['getForecastsPaginated', { searchQuery, sort }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [
          string,
          {
            readonly searchQuery: SearchQueryType | undefined;
            readonly sort?: ForecastSorting;
          }
        ]
      >
    ) => {
      if (searchQueryCallback.queryKey[1].searchQuery) {
        return tabNum === `${ForecastApprovalStatus.Overdue}`
          ? adminApi
              .getOverdueForecastsPaginated(
                page,
                pageSize,
                sort,
                searchQueryCallback.queryKey[1].searchQuery.contractNumber,
                searchQueryCallback.queryKey[1].searchQuery.contractors,
                searchQueryCallback.queryKey[1].searchQuery.categories
              )
              .then((res) => res.data)
          : adminApi
              .getForecastsPaginated(
                page,
                pageSize,
                sort,
                tabNum === `${ForecastApprovalStatus.Undefined}` ? global.undefined : tabNum,
                searchQueryCallback.queryKey[1].searchQuery.contractNumber,
                searchQueryCallback.queryKey[1].searchQuery.contractors,
                searchQueryCallback.queryKey[1].searchQuery.categories,
                searchQueryCallback.queryKey[1].searchQuery.contractTypes
              )
              .then((res) => res.data);
      }
      return tabNum === `${ForecastApprovalStatus.Overdue}`
        ? adminApi.getOverdueForecastsPaginated(page, pageSize, sort).then((res) => res.data)
        : adminApi
            .getForecastsPaginated(
              page,
              pageSize,
              sort,
              tabNum === `${ForecastApprovalStatus.Undefined}` ? global.undefined : tabNum
            )
            .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    void refetch();
  }, [page, tabNum, refetch]);

  const handleTabChange = useCallback((e) => {
    setTabNum(e);
    setPage(1);
    setSort(ForecastSorting.ByContractorNameAsc);
  }, []);

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
  }, []);

  const handleSetPage = useCallback(setPage, [setPage]);

  const handleSort = useCallback(setSort, [setSort]);

  return (
    <>
      <Title>{t('forecasts.forecasts')}</Title>
      <SearchSection onSearchQuery={handleSearchQuery} />
      <ForecastsListTabs
        forecastsList={forecastsList}
        isLoading={isLoading}
        sort={sort}
        onChange={handleTabChange}
        onSetPage={handleSetPage}
        onSetSort={handleSort}
      />
    </>
  );
};
