import { contractorApi } from '@api/contractorApi';
import { CreateInvoiceForm } from '@components/organisms/CreateInvoiceForm';
import { SearchSection } from '@components/pages/contractor/Invoice/libs/SearchSection';
import type { SearchQueryType } from '@components/pages/contractor/Invoice/libs/SearchSection/libs/types/SearchQueryType';
import { Row, Typography } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';

import { InvoicesTable } from './libs/InvoicesTable';

export const InvoicesList: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  const pageSize = 15;

  const {
    data: invoicesList,
    isLoading,
    refetch,
  } = useQuery(
    ['getInvoices', { searchQuery }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [string, { readonly searchQuery: SearchQueryType | undefined }]
      >
    ) =>
      contractorApi
        .getContractorInvoices(
          page,
          pageSize,
          searchQueryCallback.queryKey[1].searchQuery?.status,
          searchQueryCallback.queryKey[1].searchQuery?.invoiceNumber,
          searchQueryCallback.queryKey[1].searchQuery?.dateStart?.format('YYYY-MM-01'),
          searchQueryCallback.queryKey[1].searchQuery?.dateEnd?.format('YYYY-MM-01')
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
  }, []);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Title>{t('invoices.g1cForms')}</Title>
        <CreateInvoiceForm refetchInvoicesList={refetch} />
      </Row>
      <SearchSection onSearchQuery={handleSearchQuery} />
      <InvoicesTable
        invoicesList={invoicesList?.results ?? []}
        isLoading={isLoading}
        refetchInvoicesList={refetch}
        pagination={{
          onChange: (p: number) => {
            setPage(p);
          },
          total: invoicesList?.totalItems,
          current: invoicesList?.currentPage,
          pageSize: invoicesList?.pageSize,
        }}
      />
    </>
  );
};
