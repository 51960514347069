import { countryCityApi } from '@api/countryCityApi';
import { useDefaultQuery } from '@hooks';
import { Row, Space, Typography } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CountriesCitiesTable } from './libs/CountriesCitiesTable';
import { CreateCityForm } from './libs/CreateCityForm';
import { CreateCountryForm } from './libs/CreateCountyForm';

export const CountriesCitiesList: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const {
    data: countriesCitiesList,
    isLoading: isCountriesCitiesLoading,
    refetch,
  } = useDefaultQuery('getCountriesCities', async () =>
    countryCityApi.getReportsCountriesCities().then((res) => res.data)
  );

  return (
    <Space direction="vertical">
      <Row align="middle" justify="space-between">
        <Title level={3}>{t('countriesAndCities.accessibleCountriesAndCities')}</Title>
        <Space>
          <CreateCountryForm refetchCountriesList={refetch} />
          <CreateCityForm
            refetchCountriesList={refetch}
            countriesList={countriesCitiesList ?? []}
          />
        </Space>
      </Row>
      <CountriesCitiesTable
        countriesCitiesList={countriesCitiesList ?? []}
        isLoading={isCountriesCitiesLoading}
      />
    </Space>
  );
};
