import { DeleteOutlined, EditOutlined, TeamOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@components/types/models/Permissions';
import { UserContext } from '@contexts/userContext';
import { isUserHasPermission } from '@utils/permissionHelper';
import { Button, Popconfirm, Space } from 'antd';
import type { FC } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next/';

import type { ContractorActionsProps } from './props';

export const ContractorActions: FC<ContractorActionsProps> = ({
  item,
  handleShowEditModal,
  handleDeleteContractor,
  isDeleteButtonLoading,
  deleteContractorId,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isDisable = !isUserHasPermission(user?.permissions, PERMISSIONS.CONTRACTOR_EDIT);

  return (
    <Space size="middle">
      <Button
        title={t('members.members')}
        type="link"
        shape="circle"
        icon={<TeamOutlined />}
        href={`/admin/contractor/${item.id}`}
      />
      <Button
        title={t('edit')}
        type="primary"
        shape="circle"
        disabled={isDisable}
        icon={<EditOutlined />}
        onClick={handleShowEditModal(item)}
      />

      <Popconfirm
        title={t('contractors.confirmDelete', {
          name: item.name,
        })}
        cancelText={t('cancel')}
        okText={t('yes')}
        disabled={isDeleteButtonLoading || isDisable}
        onConfirm={handleDeleteContractor(item.id)}
      >
        <Button
          danger
          title={t('delete')}
          shape="circle"
          disabled={isDeleteButtonLoading || isDisable}
          loading={isDeleteButtonLoading && deleteContractorId === item.id}
          icon={<DeleteOutlined />}
        />
      </Popconfirm>
    </Space>
  );
};
