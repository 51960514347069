import { useState } from "react";
import { useHistory } from "react-router";
import { Button, PageHeader, Space } from "antd";
import { useTranslation } from "react-i18next";

import { useDefaultQuery } from "@hooks";
import * as migrationsApi from '../api/migrationsApi';
import { MigrationsTable } from "../components/MigrationsTable";

export const MigrationListPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(1);

  const migrationsQuery = useDefaultQuery(
    ['getMigrations', page],
    () => migrationsApi.getMigrationList(page)
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <PageHeader
        title={t('migrations.title')}
        extra={[
          <Button
            type="primary"
            onClick={() => history.push('/admin/migrations/new')}
          >
            {t('migrations.uploadMigration')}
          </Button>
        ]}
      />

      <MigrationsTable
        onPageChange={(newPage) => setPage(newPage)}
        data={migrationsQuery.data?.results ?? []}
        loading={migrationsQuery.isLoading}
        currentPage={migrationsQuery.data?.currentPage}
        totalItems={migrationsQuery.data?.totalItems}
        pageSize={migrationsQuery.data?.pageSize}
      />
    </Space>
  );
}