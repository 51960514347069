import { UsergroupAddOutlined } from '@ant-design/icons';
import { countryCityApi } from '@api/countryCityApi';
import { CitySelectorTemp } from '@components/atoms/CitySelectorTemp';
import { CountrySelectorTemp } from '@components/atoms/CountrySelectorTemp';
import type { FormValues } from '@components/types/models/Contractor';
import { Button, Col, Form, Input, Modal, notification, Row, Typography } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useMutation, useQuery } from 'react-query';

import type { City } from '../../types/models/CountryCity';
import type { Props } from './props';

export const NewContractorForm: FC<Props> = ({ countries, refetchList, isDisable }) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<number>();
  const [selectedCity, setSelectedCity] = useState<number>();

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      bin: '',
      name: '',
      phone: '',
      address: '',
      countryId: '',
      cityId: '',
      supervisorEmail: '',
      ownerEmail: '',
      ownerPhone: '',
      ownerFirstName: '',
      ownerLastName: '',
      ownerPosition: '',
    },
  });

  const countryList = useQuery(
    'getAllCountries',
    async () => countryCityApi.getAllCountries().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleCitySelectorChange = useCallback((value: number) => {
    setSelectedCity(value);
  }, []);

  const handleInputChange = useCallback(
    (name) => (e: React.FormEvent<HTMLInputElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const handleCountrySelectorChange = useCallback(
    (value: number) => {
      setSelectedCountry(value);
      setSelectedCity(global.undefined);
      if (value !== 1) {
        setValue('bin', '');
      }
    },
    [setValue]
  );

  const mutation = useMutation(
    async (values: FormValues) => axios.post('/api/contractor', values),
    {
      onSuccess() {
        notification.success({
          message: t('contractors.successRegister'),
        });
        setIsModalVisible(false);
        refetchList();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error: { readonly name: any; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('bin', { message: errData.message });
        }
      },
    }
  );

  const handleModalOkClick = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues) => {
      const ownerPhone = values.ownerPhone?.replace(/\D/gu, '');
      const phone = values.phone?.replace(/\D/gu, '');
      const formValues = {
        ...values,
        ownerPhone,
        phone,
        countryId: selectedCountry,
        cityId: selectedCity,
      };
      const data = Object.fromEntries(Object.entries(formValues).filter(([_, v]) => v !== ''));
      mutation.mutate(data);
    })();
  }, [clearErrors, handleSubmit, mutation, selectedCity, selectedCountry]);

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };
  const handleCloseModal = (): void => {
    reset();
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        icon={<UsergroupAddOutlined />}
        disabled={isDisable}
        onClick={handleOpenModal}
      >
        {t('contractors.registerContractor')}
      </Button>
      <Modal
        visible={isModalVisible}
        title={t('contractors.registerContractor')}
        cancelText={t('cancel')}
        okText={t('contractors.register')}
        width={1000}
        confirmLoading={mutation.isLoading}
        onOk={handleModalOkClick}
        onCancel={handleCloseModal}
      >
        <Form>
          <Text strong style={{ fontSize: '16px' }}>
            {t('contractors.companyInformation')}
          </Text>
          <Row gutter={8}>
            <Col md={8}>
              <Form.Item
                required
                validateStatus={errors.name?.message && 'error'}
                help={errors.name?.message}
                label={t('contractors.contractorName')}
                labelCol={{ span: 24 }}
              >
                <Input value={watch('name')} onChange={handleInputChange('name')} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                validateStatus={errors.phone?.message && 'error'}
                help={errors.phone?.message}
                label={t('contractors.workPhoneNumber')}
                labelCol={{ span: 24 }}
              >
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange: handleChange, value } }) => (
                    <InputMask mask="+7(999)999 99 99" value={value} onChange={handleChange}>
                      {(inputProps: unknown) => (
                        <Input {...inputProps} type="tel" className="input" />
                      )}
                    </InputMask>
                  )}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label={t('contractors.address')}
                labelCol={{ span: 24 }}
                validateStatus={errors.address?.message && 'error'}
                help={errors.address?.message}
              >
                <Input value={watch('address')} onChange={handleInputChange('address')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={8}>
              <Form.Item
                required
                validateStatus={errors.countryId?.message && 'error'}
                help={errors.countryId?.message}
                label={t('contractors.countryOfResidence')}
                labelCol={{ span: 24 }}
              >
                <CountrySelectorTemp
                  countries={countries}
                  selectedCountry={selectedCountry}
                  onChange={handleCountrySelectorChange}
                />
              </Form.Item>
            </Col>
            {countryList.data?.find((c: City) => c.id === selectedCountry)?.cities.length > 0 && (
              <Col md={8}>
                <Form.Item
                  validateStatus={errors.cityId?.message && 'error'}
                  help={errors.cityId?.message}
                  label={t('contractors.city')}
                  labelCol={{ span: 24 }}
                >
                  <CitySelectorTemp
                    cities={countries.find((c) => c.id === selectedCountry)?.cities}
                    selectedCity={selectedCity}
                    onChange={handleCitySelectorChange}
                  />
                </Form.Item>
              </Col>
            )}
            {selectedCountry === 1 && (
              <Col md={8}>
                <Form.Item
                  validateStatus={errors.bin?.message && 'error'}
                  help={errors.bin?.message}
                  label={t('bin')}
                  labelCol={{ span: 24 }}
                >
                  <Input value={watch('bin')} maxLength={12} onChange={handleInputChange('bin')} />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item
            validateStatus={errors.supervisorEmail?.message && 'error'}
            help={errors.supervisorEmail?.message}
            label={t('contractors.supervisorEmail')}
            labelCol={{ span: 24 }}
          >
            <Input
              value={watch('supervisorEmail')}
              onChange={handleInputChange('supervisorEmail')}
            />
          </Form.Item>

          <Text strong style={{ fontSize: '16px' }}>
            {t('contractors.memberInformation')}
          </Text>
          <Row gutter={8}>
            <Col md={12}>
              <Form.Item
                required
                validateStatus={errors.ownerEmail?.message && 'error'}
                help={errors.ownerEmail?.message}
                label={t('contractors.email')}
                labelCol={{ span: 24 }}
              >
                <Input value={watch('ownerEmail')} onChange={handleInputChange('ownerEmail')} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                required
                validateStatus={errors.ownerPhone?.message && 'error'}
                help={errors.ownerPhone?.message}
                label={t('contractors.phoneNumber')}
                labelCol={{ span: 24 }}
              >
                <Controller
                  name="ownerPhone"
                  control={control}
                  render={({ field: { onChange: handleChange, value } }) => (
                    <InputMask mask="+7(999)999 99 99" value={value} onChange={handleChange}>
                      {(inputProps: unknown) => (
                        <Input {...inputProps} type="tel" className="input" />
                      )}
                    </InputMask>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={8}>
              <Form.Item
                required
                validateStatus={errors.ownerFirstName?.message && 'error'}
                help={errors.ownerFirstName?.message}
                label={t('contractors.firstName')}
                labelCol={{ span: 24 }}
              >
                <Input
                  value={watch('ownerFirstName')}
                  onChange={handleInputChange('ownerFirstName')}
                />
              </Form.Item>
            </Col>

            <Col md={8}>
              <Form.Item
                required
                validateStatus={errors.ownerLastName?.message && 'error'}
                help={errors.ownerLastName?.message}
                label={t('contractors.lastName')}
                labelCol={{ span: 24 }}
              >
                <Input
                  value={watch('ownerLastName')}
                  onChange={handleInputChange('ownerLastName')}
                />
              </Form.Item>
            </Col>

            <Col md={8}>
              <Form.Item
                required
                validateStatus={errors.ownerPosition?.message && 'error'}
                help={errors.ownerPosition?.message}
                label={t('contractors.jobTitle')}
                labelCol={{ span: 24 }}
              >
                <Input
                  value={watch('ownerPosition')}
                  onChange={handleInputChange('ownerPosition')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
