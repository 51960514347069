/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { TagRounded } from '@components/atoms/TagRounded';
import { AppTable } from '@components/molecules/AppTable';
import type { AdminMember } from '@components/types/models/AdminMember';
import { defineUserRole } from '@contexts/userContext';
import { Button, Space, Table, Typography } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { TableProps } from './props';

const { Column } = Table;

export const MemberTable: FC<TableProps> = ({
  members,
  isLoading,
  extraColumns,
  renderActions,
  ...rest
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const MAX_DISCIPLINES_IN_TABLE_COLUMN = 2;

  return (
    <AppTable
      isLoading={isLoading}
      dataSource={members}
      {...rest}
      locale={{ emptyText: t('noData') }}
      style={{ marginBottom: '80px' }}
      rowKey="userId"
      expandable={{
        expandIconColumnIndex: 5,
        columnWidth: 350,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        rowExpandable: (member: any) =>
          member.kcCategories?.length > MAX_DISCIPLINES_IN_TABLE_COLUMN,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        expandedRowRender: ({ kcCategories }: any) => (
          <Space wrap>
            {kcCategories.map((item: { readonly name: string; readonly id: number }) => (
              <TagRounded key={item.id}>{item.name} </TagRounded>
            ))}
          </Space>
        ),
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        expandIcon: ({ record: member, onExpand }: any) => (
          <Space wrap>
            {member.kcCategories
              ?.slice(0, MAX_DISCIPLINES_IN_TABLE_COLUMN)
              .map((category: { readonly name: string; readonly id: number }) => (
                <TagRounded key={category.id}>{category.name}</TagRounded>
              ))}
            {member.kcCategories?.length > MAX_DISCIPLINES_IN_TABLE_COLUMN && (
              <Button
                size="small"
                shape="round"
                onClick={(event) => {
                  onExpand(member, event);
                }}
              >
                {t('...')}
              </Button>
            )}
          </Space>
        ),
      }}
      onHeaderRow={() => {
        const expandable = document.querySelectorAll(
          'th.ant-table-cell.ant-table-row-expand-icon-cell'
        );
        if (expandable.length > 0) {
          // eslint-disable-next-line functional/no-loop-statement
          for (const item of Array.from(expandable)) {
            item.innerHTML = t('contracts.kcCategoryAndArea');
          }
        }
        return {};
      }}
    >
      <Column title={t('members.firstName')} dataIndex="firstName" />
      <Column title={t('members.lastName')} dataIndex="lastName" />
      <Column title={t('members.jobTitle')} dataIndex="position" />
      <Column title={t('email')} dataIndex="email" />
      <Column
        title={t('profile.role')}
        render={(member) =>
          member?.roles.map((item: { readonly role: number }, i: number) => (
            <Text key={i}> {defineUserRole(item.role)} </Text>
          ))
        }
      />
      {extraColumns?.map((ec, i) => (
        <Column key={i} title={ec.title} dataIndex={ec.dataIndex} render={ec.render} />
      ))}
      {renderActions && (
        <Column title={t('actions')} render={(member: AdminMember) => renderActions(member)} />
      )}
    </AppTable>
  );
};
