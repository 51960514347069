import type { City, Country } from '@components/types/models/CountryCity';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

const createForecast = async (contractId: number): Promise<AxiosResponse> =>
  axios.post(`/api/forecasts/${contractId}`);

const getForecastVersions = async (
  contractId: number
): Promise<AxiosResponse> => axios.get(`/api/forecasts/${contractId}/versions`);

const getForecastDetails = async (forecastId: number): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/${forecastId}`);

const getGoods = async (
  forecastId: number,
  year: number,
  month?: number
): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/goods/${forecastId}`, {
    params: {
      year,
      month,
    },
  });

const getWorks = async (
  forecastId: number,
  year?: number,
  month?: number
): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/works/${forecastId}`, {
    params: {
      year,
      month,
    },
  });

const getServices = async (
  forecastId: number,
  year: number,
  month?: number
): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/services/${forecastId}`, {
    params: {
      year,
      month,
    },
  });

const getForecastComments = async (
  forecastId: number,
  sectionType: number
): Promise<AxiosResponse> =>
  axios.get(`/api/comments/forecast/${forecastId}`, {
    params: {
      sectionType,
    },
  });

const getMasterGws = async (forecastId: number): Promise<AxiosResponse> =>
  axios.get(`/api/masterforecast/subagreement/summary/${forecastId}`);

const approveForecast = async (forecastId: string): Promise<AxiosResponse> =>
  axios.post(`/api/forecasts/forecast/${forecastId}/approve`);

const rejectForecast = async (
  forecastId: string,
  dueDate: string
): Promise<AxiosResponse> =>
  axios.post(
    `/api/forecasts/forecast/reject`,
    {
      forecastId,
    },
    {
      params: {
        dueDate,
      },
    }
  );

const submitForecast = async (
  forecastId: number,
  dueDate?: string
): Promise<AxiosResponse> =>
  axios.put(`/api/forecasts/${forecastId}`, null, {
    params: {
      dueDate,
    },
  });

type GwsGrandTotal = {
  readonly records?: ReadonlyArray<{
    readonly company: {
      readonly id: number;
      readonly name: string;
      readonly country: Country;
      readonly city: City;
      readonly bin: string;
      readonly detailOnScopeOfWork: string;
      readonly contractualRelations: {
        readonly relationType: 1;
        readonly contractorId: number;
        readonly companyId: number;
      };
    };
    readonly recordId: number;
    readonly year: number;
    readonly month: number;
    readonly forecastValue: number;
    readonly forecastKcShare: number;
    readonly forecastKcValue: number;
    readonly kcGood?: {
      readonly kcWorkId: number;
      readonly title: string;
    };
    readonly kcWork?: {
      readonly kcWorkId: number;
      readonly titile: string;
    };
    readonly kcService?: {
      readonly kcWorkId: number;
      readonly title: string;
    };
  }>;
  readonly totalForecastKcShare: number;
  readonly totalForecastKcValue: number;
  readonly totalForecastValue: number;
};

const getGwsGrandTotal = async (
  forecastId: number,
  sectionType: string
): Promise<AxiosResponse<GwsGrandTotal>> =>
  axios.get(`/api/forecasts/${sectionType}/total/${forecastId}`, {
    params: {
      year: 0,
    },
  });

const getGwsSummary = async (
  forecastId: number,
  year?: number,
  month?: number
): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/gws/summary/${forecastId}`, {
    params: {
      year,
      month,
    },
  });

const getForecastLegacy = async (forecastId: number): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/legacy/${forecastId}`);

const getForecastTransfer = async (
  forecastId: number
): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/techtransfer/${forecastId}`);

const updateForecast = async (forecastId: number): Promise<AxiosResponse> =>
  axios.post(`/api/forecasts/update/${forecastId}`);

const restorePrevForecast = async (
  forecastId: number
): Promise<AxiosResponse> => axios.post(`/api/forecasts/restore/${forecastId}`);

const getForecastJobs = async (forecastId?: number): Promise<AxiosResponse> =>
  axios.get(`/api/forecasts/job/${forecastId}`);

const getForecastTraining = async (
  forecastId?: number
): Promise<AxiosResponse> => axios.get(`/api/forecasts/training/${forecastId}`);

export const forecastApi = {
  getForecastVersions,
  createForecast,
  restorePrevForecast,
  getForecastDetails,
  getGoods,
  submitForecast,
  getWorks,
  getForecastLegacy,
  getForecastTransfer,
  getMasterGws,
  getServices,
  getForecastComments,
  approveForecast,
  rejectForecast,
  getGwsGrandTotal,
  getGwsSummary,
  updateForecast,
  getForecastJobs,
  getForecastTraining,
};
