/* eslint-disable @typescript-eslint/naming-convention */
import type { AmendmentFormFields } from '@components/types/models/Amendment';
import type { UploadFile } from 'antd/es/upload/interface';
import i18next from 'i18next';


export const parseAmendmentFormFields = (
  values: AmendmentFormFields,
  file?: UploadFile
): {
  readonly StartDate?: string;
  readonly EndDate?: string | null;
  readonly Amount?: string;
  readonly CurrencyId: number;
  readonly KcTarget?: string;
  readonly ScopeOfWorks: readonly string[];
  readonly DetailsOnScopeOfWork: string;
  readonly Comment: string;
  readonly KcPlanFile?: UploadFile;
} => ({
  StartDate: values.startDate?.toJSON(),
  EndDate: values.endDate?.toJSON(),
  Amount: values.amount ? new Intl.NumberFormat(i18next.language).format(values.amount) : global.undefined,
  CurrencyId: values.currencyId,
  KcTarget: values.kcTarget ?  new Intl.NumberFormat(i18next.language).format(values.kcTarget) : global.undefined,
  ScopeOfWorks: values.scopeOfWorks,
  DetailsOnScopeOfWork: values.detailsOnScopeWork,
  Comment: values.comment,
  KcPlanFile: file,
});
