/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { DownloadFileButton } from '@components/atoms/DownloadFileButton';
import { AppCard } from '@components/molecules/AppCard';
import { SCOPE_OF_WORK } from '@components/types/models/Contract';
import { PERMISSIONS } from '@components/types/models/Permissions';
import { defineStatus, STATUS } from '@components/types/models/Statuses';
import { UserContext } from '@contexts/userContext';
import { useUserRole } from '@hooks/useUserRole';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { isUserHasPermissions } from '@utils/permissionHelper';
import {
  Button,
  Col,
  Comment,
  Divider,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import type { FC } from 'react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { AmendmentProps } from './props';

export const Amendment: FC<AmendmentProps> = ({
  amendment,
  isAdmin,
  onApproveAmendment,
  onOpenRejectAmendmentModal,
  contractStatus,
  headerRightSide,
}) => {
  const { Text, Title } = Typography;
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { getUserRole } = useUserRole();
  const scopeOfWorks = useMemo<Record<SCOPE_OF_WORK, string>>(
    () => ({
      [SCOPE_OF_WORK.GOODS]: t('contracts.goods'),
      [SCOPE_OF_WORK.WORKS]: t('contracts.works'),
      [SCOPE_OF_WORK.SERVICES]: t('contracts.services'),
    }),
    [t]
  );

  const DividerThin = styled(Divider)`
    ${tw`my-0`}
  `;

  const [status, setStatus] = useState(
    defineStatus(amendment ? amendment.contractStatus : global.undefined)
  );

  useEffect(() => {
    setStatus(
      defineStatus(amendment ? amendment.contractStatus : global.undefined)
    );
  }, [amendment]);

  const handleOpenRejectAmendmentModal = (): void => {
    onOpenRejectAmendmentModal?.();
  };
  const handleApproveAmendment = (): void => {
    onApproveAmendment?.();
  };

  return amendment ? (
    <AppCard
      title={t('contracts.versionWithParam', {
        version: amendment.version,
      })}
      className={css`
        ${tw`my-8`}
      `}
      subtitle={
        <Tag color={status.color}>
          {i18n.language === 'en' ? status.enName : status.ruName}
        </Tag>
      }
      headerRightSide={headerRightSide}
      downloadButton={
        <DownloadFileButton
          disabled={!amendment.kcPlanFile}
          url={`/api/amendment/${amendment.id}/kcplan`}
          title={t('forecasts.downloadKcPlan')}
        />
      }
    >
      <DividerThin />
      <div
        className={css`
          ${tw`m-4!`}
        `}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Text type="secondary">{t('contracts.startDate')}</Text>
            <br />
            <Title level={5}>
              {moment(amendment.startDate).format('DD.MM.YYYY') ?? ' -'}
            </Title>
          </Col>
          <Col span={6}>
            <Text type="secondary">{t('contracts.endDate')}</Text>
            <br />
            <Title level={5}>
              {moment(amendment.endDate).format('DD.MM.YYYY') ?? '-'}
            </Title>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Text type="secondary">{t('contracts.kcTarget')}</Text>
            <br />
            <Title level={5}>{`${amendment.kcTarget} %` ?? '-'}</Title>
          </Col>
          <Col span={6}>
            <Text type="secondary">{t('contracts.scopeOfWorks')}</Text>
            <br />
            <Title level={5}>
              {amendment.scopes && amendment.scopes.length > 0
                ? amendment.scopes.map((scope: SCOPE_OF_WORK, index) => (
                    <Text key={index}>
                      {(index ? ', ' : '') + scopeOfWorks[scope]}
                    </Text>
                  ))
                : '-'}
            </Title>
          </Col>
          <Col span={6}>
            <Text type="secondary">{t('contracts.amount')}</Text>
            <br />
            <Title level={5}>
              {amendment.amount
                ? `${numeral(amendment.amount).format('0,000.00')} ${
                    amendment.currency?.code
                  }`
                : '-'}
            </Title>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Text type="secondary">{t('contracts.detailsOnScopeWork')}</Text>
            <br />
            <Title level={5}>{amendment.detailsOnScopeOfWork ?? '-'}</Title>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Text type="secondary">{t('contracts.comments')}</Text>
            <br />
            {amendment.history.length > 0 ? (
              <Space
                className={css`
                  ${tw`px-4 w-full`}
                `}
                direction="vertical"
              >
                {amendment.history.map(
                  (item) =>
                    item.comment && (
                      <Comment
                        key={item.createdAt}
                        author={
                          <p>
                            <Text strong>
                              {item.user.firstName} {item.user.lastName}{' '}
                              {getUserRole(item.user.userRoles)}
                            </Text>
                          </p>
                        }
                        content={<Text>{item.comment}</Text>}
                        datetime={
                          <span>
                            {t('—')}{' '}
                            {moment(item.createdAt).format('HH:mm, DD-MM-YYYY')}
                          </span>
                        }
                      />
                    )
                )}
              </Space>
            ) : (
              <Space
                className={css`
                  ${tw`px-4 text-center w-full`}
                `}
                direction="vertical"
              >
                {t('contracts.noCommentsYet')}
              </Space>
            )}
          </Col>
        </Row>
      </div>
      <DividerThin />
      <Row
        className={css`
          ${tw`mx-2! my-4`}
        `}
        gutter={16}
      >
        <Col
          className={css`
            ${tw`text-left`}
          `}
          span={12}
        >
          {amendment && (
            <>
              <div>
                {t(
                  'contracts.submittedBy',
                  amendment.submittedBy
                    ? {
                        name: `${amendment.submittedBy.user.firstName} ${
                          amendment.submittedBy.user.lastName
                        } ${getUserRole(amendment.submittedBy.user.userRoles)}`,
                      }
                    : { name: '-' }
                )}
              </div>
              <div>
                {t(
                  'contracts.submissionDate',
                  amendment.submittedBy
                    ? {
                        date: moment(amendment.submittedBy?.updatedAt).format(
                          'DD.MM.YYYY HH:mm:ss'
                        ),
                      }
                    : { date: '-' }
                )}
              </div>
            </>
          )}
        </Col>
        <Col
          className={css`
            ${tw`text-right`}
          `}
          span={12}
        >
          {amendment?.contractStatus === STATUS.APPROVED && (
            <>
              <div>
                {t(
                  'contracts.approvedBy',
                  amendment.reviewedBy
                    ? {
                        name: `${amendment.reviewedBy.user.firstName} ${
                          amendment.reviewedBy.user.lastName
                        } ${getUserRole(amendment.reviewedBy.user.userRoles)}`,
                      }
                    : { name: '-' }
                )}
              </div>
              <div>
                {t(
                  'contracts.approvalDate',
                  amendment.reviewedBy
                    ? {
                        date: moment(amendment.reviewedBy.updatedAt).format(
                          'DD.MM.YYYY HH:mm:ss'
                        ),
                      }
                    : { date: '-' }
                )}
              </div>
            </>
          )}
          {amendment?.contractStatus === STATUS.REJECTED && (
            <>
              <div>
                {t(
                  'contracts.rejectedBy',
                  amendment.reviewedBy
                    ? {
                        name: `${amendment.reviewedBy.user.firstName} ${
                          amendment.reviewedBy.user.lastName
                        } ${getUserRole(amendment.reviewedBy.user.userRoles)}`,
                      }
                    : { name: '-' }
                )}
              </div>
              <div>
                {t(
                  'contracts.rejectionDate',
                  amendment.reviewedBy
                    ? {
                        date: moment(amendment.reviewedBy.updatedAt).format(
                          'DD.MM.YYYY HH:mm:ss'
                        ),
                      }
                    : { date: '-' }
                )}
              </div>
            </>
          )}
        </Col>
      </Row>
      {isAdmin && contractStatus === STATUS.PENDING && (
        <Row
          className={css`
            ${tw`mx-2! my-4`}
          `}
          gutter={16}
        >
          <Col
            className={css`
              ${tw`text-left`}
            `}
            span={12}
          >
            <Button
              type="primary"
              disabled={
                !isUserHasPermissions(user?.permissions, [
                  PERMISSIONS.CONTRACT_EDIT,
                  PERMISSIONS.CONTRACT_VIEW,
                ])
              }
              onClick={handleOpenRejectAmendmentModal}
            >
              {t('contracts.reject')}
            </Button>
          </Col>
          <Col
            className={css`
              ${tw`text-right`}
            `}
            span={12}
          >
            <Button
              type="primary"
              disabled={
                !isUserHasPermissions(user?.permissions, [
                  PERMISSIONS.CONTRACT_EDIT,
                  PERMISSIONS.CONTRACT_VIEW,
                ])
              }
              onClick={handleApproveAmendment}
            >
              {t('contracts.approve')}
            </Button>
          </Col>
        </Row>
      )}
    </AppCard>
  ) : (
    <div />
  );
};
