import { styled } from '@linaria/react';
import { Badge } from 'antd';
import tw from 'twin.macro';

export const AppBadge = styled(Badge)`
  .ant-badge-count {
    top: -5px;
    width: 15px;
    ${tw`-right-2 px-0`};
  }
`;

export const AppLinkBadge = styled(Badge)`
  .ant-badge-count {
    top: -2px;
    width: 15px;
    ${tw`-right-3 px-0`};
  }
`;
