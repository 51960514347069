import { adminApi } from '@api/adminApi';
import { ActualsListTabs } from '@components/pages/admin/Actuals/libs/components/ActualsListTabs';
import { SearchSection } from '@components/pages/admin/Actuals/libs/components/SearchSection';
import type { SearchQueryType } from '@components/pages/admin/Actuals/libs/components/SearchSection/libs/types/SearchQueryType';
import { ActualSorting } from '@components/types/models/Actuals';
import { ForecastApprovalStatus } from '@components/types/models/Forecast';
import { Typography } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';

export const Actuals: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [tabNum, setTabNum] = useState<string>('0');
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();
  const [sort, setSort] = useState<ActualSorting | undefined>(
    ActualSorting.PeriodAscending
  );

  const pageSize = 15;
  const { data, isLoading, refetch } = useQuery(
    ['getActualsPaginated', { searchQuery, sort, tabNum, page }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [
          string,
          {
            readonly searchQuery: SearchQueryType | undefined;
            readonly sort?: ActualSorting;
            tabNum: string;
            page: number;
          }
        ]
      >
    ) => {
      const outputStatusValue =
        tabNum === `${ForecastApprovalStatus.Overdue}`
          ? `${ForecastApprovalStatus.Undefined}`
          : tabNum;

      if (searchQueryCallback.queryKey[1].searchQuery) {
        return adminApi
          .getActualsPaginated(
            page,
            pageSize,
            sort,
            tabNum === `${ForecastApprovalStatus.Undefined}`
              ? global.undefined
              : outputStatusValue,
            searchQueryCallback.queryKey[1].searchQuery.contractNumber,
            searchQueryCallback.queryKey[1].searchQuery.contractors,
            searchQueryCallback.queryKey[1].searchQuery.categories,
            searchQueryCallback.queryKey[1].searchQuery.contractTypes,
            searchQueryCallback.queryKey[1].searchQuery.year,
            searchQueryCallback.queryKey[1].searchQuery.month
          )
          .then((res) => res.data);
      }

      return adminApi
        .getActualsPaginated(
          page,
          pageSize,
          sort,
          tabNum === `${ForecastApprovalStatus.Undefined}`
            ? global.undefined
            : outputStatusValue
        )
        .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    void refetch();
  }, [page, tabNum, refetch]);

  const handleTabChange = useCallback((e) => {
    setTabNum(e);
    setPage(1);
    setSort(ActualSorting.PeriodAscending);
  }, []);

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
  }, []);

  const handleSetPage = useCallback(setPage, [setPage]);

  const handleSort = useCallback(setSort, [setSort]);

  return (
    <>
      <Title>{t('actuals.actuals')}</Title>
      {/* TODO: year and month are not null when the page is loaded */}
      <SearchSection onSearchQuery={handleSearchQuery} />
      <ActualsListTabs
        actualsList={data}
        isLoading={isLoading}
        sort={sort}
        onChange={handleTabChange}
        onSetPage={handleSetPage}
        onSetSort={handleSort}
      />
    </>
  );
};
