import { UserContext } from '@contexts/userContext';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Card, Col, Form, Input, message as antdMessage, Row, Typography } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

type FormValues = {
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly oldPassword?: string;
  readonly email?: string;
};

export const ExpiredPasswordPage: FC = () => {
  const history = useHistory();
  const { userCredentials, setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { Meta } = Card;
  const { Text } = Typography;
  const {
    handleSubmit,
    setError,
    formState: { errors },
    register,
    clearErrors,
  } = useForm();

  const mutation = useMutation(
    async (values: FormValues) =>
      axios.post('/api/auth/password/expired-reset', values).then((res) => {
        const token = res.data?.apiToken;
        localStorage.setItem('KC_PORTAL_USER_TOKEN', token);
      }),
    {
      onSuccess() {
        void antdMessage.success({
          content: t('login.passwordIsResetSuccessfully'),
          style: {
            marginTop: '20vh',
          },
        });
        void axios.get('/api/auth/me').then((res) => {
          setUser(res.data);
          history.push('/profile');
        });
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: { readonly name: string; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('errorMessage', { message: errData.message });
        }
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues) => {
      mutation.mutate({
        ...values,
        oldPassword: userCredentials?.password,
        email: userCredentials?.email,
      });
    })();
  }, [clearErrors, handleSubmit, mutation, userCredentials]);

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col span={12}>
        <Card>
          <Meta
            style={{ marginBottom: '24px' }}
            title={t('login.resetPasswordTitle')}
            description={t('login.enterNewPassword')}
          />
          <Form onFinish={handleFormSubmit}>
            <Form.Item
              validateStatus={errors.password?.message && 'error'}
              help={errors.password?.message}
              label={t('login.newPassword')}
              labelCol={{ span: 24 }}
            >
              <Input.Password {...register('password')} />
            </Form.Item>
            <Form.Item
              validateStatus={errors.confirmPassword?.message && 'error'}
              help={errors.confirmPassword?.message}
              label={t('login.confirmPassword')}
              labelCol={{ span: 24 }}
            >
              <Input.Password {...register('confirmPassword')} />
            </Form.Item>
            <ErrorMessage
              errors={errors}
              name="errorMessage"
              render={({ message }) => <Text type="danger">{message}</Text>}
            />
            <Form.Item noStyle>
              <Row justify="end" align="middle">
                <Button type="primary" htmlType="submit">
                  {t('reset')}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
