import { adminApi } from '@api/adminApi';
import { InvoiceSorting } from '@components/types/models/Invoices';
import { Typography } from 'antd';
import { FC, useEffect } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';

import { InvoicesTable } from './libs/components/InvoicesTable';
import { SearchSection } from './libs/components/SearchSection';
import type { SearchQueryType } from './libs/components/SearchSection/libs/types/SearchQueryType';

export const InvoicesList: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();
  const [sort, setSort] = useState<InvoiceSorting | undefined>(
    InvoiceSorting.SubmissionDateAsc
  );

  const pageSize = 15;

  const {
    data: invoicesList,
    isFetching: isInvoiceListLoading,
    refetch: refetchInvoicesList,
  } = useQuery(
    ['getAdminInvoices', { searchQuery, sort }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [
          string,
          {
            readonly searchQuery: SearchQueryType | undefined;
            readonly sort?: InvoiceSorting;
          }
        ]
      >
    ) =>
      adminApi
        .getAdminInvoices(
          page,
          pageSize,
          sort,
          searchQueryCallback.queryKey[1].searchQuery?.status,
          searchQueryCallback.queryKey[1].searchQuery?.kcCategories,
          searchQueryCallback.queryKey[1].searchQuery?.invoiceNumber,
          searchQueryCallback.queryKey[1].searchQuery?.contracts,
          searchQueryCallback.queryKey[1].searchQuery?.contractors,
          searchQueryCallback.queryKey[1].searchQuery?.dateFrom?.format(
            'YYYY-MM-DD'
          ),
          searchQueryCallback.queryKey[1].searchQuery?.dateTo?.format(
            'YYYY-MM-DD'
          )
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
  }, []);

  const handleSort = useCallback(setSort, [setSort]);

  useEffect(() => {
    void refetchInvoicesList();
  }, [page, refetchInvoicesList]);

  return (
    <>
      <Title>{t('invoices.g1cForms')}</Title>
      <SearchSection onSearchQuery={handleSearchQuery} />
      <InvoicesTable
        invoicesList={invoicesList?.results ?? []}
        isLoading={isInvoiceListLoading}
        refetchInvoicesList={refetchInvoicesList}
        pagination={{
          onChange: (p: number) => {
            setPage(p);
          },
          showSizeChanger: false,
          total: invoicesList?.totalItems,
          current: invoicesList?.currentPage,
          pageSize: invoicesList?.pageSize,
        }}
        sort={sort}
        onSetSort={handleSort}
      />
    </>
  );
};
