import { forecastApi } from '@api/forecastApi';
import { defineRelationType } from '@components/constants/ContractualRelations';
import { useDefaultQuery } from '@hooks';
import { Table, Typography } from 'antd';
import numeral from 'numeral';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { GwsTotalTableProps } from './props';

export const GwsTotalTable: FC<GwsTotalTableProps> = ({
  forecastId,
  currency,
  sectionType,
}) => {
  const { t, i18n } = useTranslation();
  const { Text } = Typography;
  const { Column, Summary } = Table;
  const percentSymbol = '%';

  const { data, isLoading } = useDefaultQuery(
    ['getGwsData', forecastId],
    async () =>
      forecastApi
        .getGwsGrandTotal(forecastId, sectionType)
        .then((res) => res.data)
  );

  return (
    <Table
      dataSource={data?.records}
      pagination={false}
      loading={isLoading}
      summary={() => (
        <Summary>
          <Summary.Row>
            <Summary.Cell index={0}>{t('total')}</Summary.Cell>
            <Summary.Cell index={1} />
            <Summary.Cell index={2} />
            <Summary.Cell index={3}>
              {numeral(data?.totalForecastValue).format('0,0.00')} {currency}
            </Summary.Cell>
            <Summary.Cell index={4}>
              {numeral(data?.totalForecastKcShare).format('0,0.00')}{' '}
              {percentSymbol}
            </Summary.Cell>
            <Summary.Cell index={5}>
              {numeral(data?.totalForecastKcValue).format('0,0.00')} {currency}
            </Summary.Cell>
          </Summary.Row>
        </Summary>
      )}
    >
      {sectionType === 'goods' && (
        <Column
          key="description"
          dataIndex="kcGood"
          title={t('forecasts.goodsDescription')}
          render={(kcGood) => kcGood?.title}
        />
      )}
      {sectionType === 'works' && (
        <Column
          key="description"
          dataIndex="kcWork"
          title={t('forecasts.worksDescription')}
          render={(kcWork) => kcWork?.title}
        />
      )}
      {sectionType === 'services' && (
        <Column
          key="description"
          dataIndex="kcService"
          title={t('forecasts.serviceDescription')}
          render={(kcService) => kcService?.title}
        />
      )}

      <Column
        key="companyName"
        dataIndex="company"
        title={t('forecasts.companyName')}
        render={(company) => company.name}
      />
      <Column
        key="contractualRelationship"
        title={t('forecasts.contractualRelationship')}
        dataIndex="company"
        render={(company) =>
          i18n.language === 'en'
            ? defineRelationType(company.contractualRelations.relationType)
                .enName
            : defineRelationType(company.contractualRelations.relationType)
                .ruName
        }
      />
      <Column
        key="forecastValue"
        title={t('forecasts.forecastValue')}
        dataIndex="forecastValue"
        render={(forecastValue) => (
          <Text>
            {numeral(forecastValue).format('0,0.00')} {currency}
          </Text>
        )}
      />
      <Column
        key="CTKZ"
        title={t('forecasts.CTKZ')}
        dataIndex="forecastKcShare"
        render={(forecastKcShare) => (
          <Text>
            {forecastKcShare} {percentSymbol}
          </Text>
        )}
      />
      <Column
        key="KcForecastValue"
        title={t('forecasts.kcForecastValue')}
        dataIndex="forecastKcValue"
        render={(forecastKcValue) => (
          <Text>
            {numeral(forecastKcValue).format('0,0.00')} {currency}
          </Text>
        )}
      />
    </Table>
  );
};
