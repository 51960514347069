import { WarningOutlined } from '@ant-design/icons';
import { actualApi } from '@api/actualApi';
import { companyApi } from '@api/companyApi';
import { unitsOfMeasureApi } from '@api/unitsOfMeasureApi';
import type { PrePopulatedGws } from '@components/types/models/Actuals';
import { PERIOD_STATUS } from '@components/types/models/Actuals';
import { SCOPE_OF_WORK } from '@components/types/models/Contract';
import type { CompanyType } from '@components/types/models/Forecast';
import { SectionType } from '@components/types/models/Forecast';
import type { UnitsOfMeasure } from '@components/types/models/UnitsOfMeasure';
import { UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import { Badge, Button, Collapse, Row, Tag, Typography } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import tw from 'twin.macro';

import { CommentCard } from '../CommentCard';
import { GoodsForm } from '../GoodsForm';
import { GwsSummaryTable } from '../GwsSummaryTable';
import { ServiceForm } from '../ServicesForm';
import { WorksForm } from '../WorksForm';
import type { ActualsGwsProps } from './props';

export const ActualsGws: FC<ActualsGwsProps> = ({
  scopes,
  contractCurrency,
  period,
  errors,
  actualId,
  isEditable,
  freshCommentedSections,
  periodList,
}) => {
  const { Panel } = Collapse;
  const badgeMargin = 16;
  const { t, i18n } = useTranslation();
  const [activeCollapseItem, setActiveCollapseItem] = useState<number>();
  const [prePopulatedData, setPrePopulatedData] = useState<PrePopulatedGws>();
  const { user } = useContext(UserContext);
  const { Text } = Typography;

  const { data: companiesList, refetch: refetchCompaniesList } =
    useDefaultQuery<readonly CompanyType[]>(
      'getCompaniesList',
      async () =>
        user?.userRoles[0].entityId &&
        companyApi
          .getContractorCompaniesList(user.userRoles[0].entityId)
          .then((res) => res.data)
    );

  const { data: unitsOfMeasure } = useDefaultQuery<readonly UnitsOfMeasure[]>(
    'getUnitsOfMeasure',
    async () => unitsOfMeasureApi.getUnitsOfMeasure().then((res) => res.data)
  );

  const onProceedClick = (): void => {
    setActiveCollapseItem(activeCollapseItem && Number(activeCollapseItem) + 1);
  };

  const handleOpenCollapse = useCallback((key) => {
    setActiveCollapseItem(key);
  }, []);

  const previousApprovedActual = useMemo(() => {
    const currentPeriodIndex = periodList?.findIndex(
      (item) => item.year === period.year && item.month === period.month
    );
    const beforePeriodList = periodList?.slice(0, currentPeriodIndex).reverse();

    return beforePeriodList?.find(
      (periodItem) => periodItem.approvalStatus === PERIOD_STATUS.APPROVED
    );
  }, [periodList, period.year, period.month]);

  const previousApprovedActualId = useMemo(
    () =>
      previousApprovedActual?.actuals?.[
        previousApprovedActual.actuals.length - 1
      ].actualId,
    [previousApprovedActual]
  );

  const perPopulateMutation = useMutation(
    async (sectionType: number) =>
      actualApi
        .prePopulateGws(previousApprovedActualId, sectionType)
        .then((res) => res.data),
    {
      onSuccess(res) {
        setPrePopulatedData(res);
      },
    }
  );

  const handlePrePopulate = useCallback(
    (sectionType) => () => {
      perPopulateMutation.mutate(sectionType);
    },
    [perPopulateMutation]
  );

  return (
    <Collapse
      accordion
      activeKey={activeCollapseItem}
      onChange={handleOpenCollapse}
    >
      {scopes?.includes(SCOPE_OF_WORK.GOODS) && (
        <Panel
          key="1"
          header={
            <Badge offset={[badgeMargin, 0]}>
              {t('forecasts.goods')}
              {freshCommentedSections?.[SectionType.Good] && (
                <WarningOutlined
                  className={css`
                    ${tw`ml-2 text-lg text-red-600`}
                  `}
                />
              )}
              {isEditable && errors?.Good && (
                <Tag color="error" style={{ marginLeft: '8px' }}>
                  {t('actuals.sectionIsRequired')}
                </Tag>
              )}
            </Badge>
          }
        >
          {isEditable && (
            <Row
              align="middle"
              className={css`
                ${tw`space-x-2 mb-2`}
              `}
            >
              <Button
                type="primary"
                disabled={!previousApprovedActual}
                loading={perPopulateMutation.isLoading}
                onClick={handlePrePopulate(SectionType.Good)}
              >
                {t('actuals.prepopulate')}
              </Button>
              {previousApprovedActual && (
                <Text>
                  {t('actuals.prepopulateWithDataFrom')}{' '}
                  {moment()
                    .month(Number(previousApprovedActual.month) - 1)
                    .locale(i18n.language)
                    .format('MMMM')}{' '}
                  {previousApprovedActual.year}
                </Text>
              )}
            </Row>
          )}
          <GoodsForm
            isActualForm
            isEditable={isEditable}
            currency={contractCurrency}
            selectedYear={period.year}
            selectedMonth={period.month}
            companiesList={companiesList}
            handleProceed={onProceedClick}
            refetchCompaniesList={refetchCompaniesList}
            unitsOfMeasure={unitsOfMeasure}
            actualId={actualId}
            prePopulatedData={prePopulatedData}
          />
          <CommentCard
            isActual
            status={period.approvalStatus}
            id={actualId}
            sectionType={SectionType.Good}
          />
        </Panel>
      )}
      {scopes?.includes(SCOPE_OF_WORK.WORKS) && (
        <Panel
          key="2"
          header={
            <Badge offset={[badgeMargin, 0]}>
              {t('forecasts.works')}
              {freshCommentedSections?.[SectionType.Work] && (
                <WarningOutlined
                  className={css`
                    ${tw`ml-2 text-lg text-red-600`}
                  `}
                />
              )}
              {isEditable && errors?.Work && (
                <Tag color="error" style={{ marginLeft: '8px' }}>
                  {t('actuals.sectionIsRequired')}
                </Tag>
              )}
            </Badge>
          }
        >
          {isEditable && (
            <Row
              align="middle"
              className={css`
                ${tw`space-x-2 mb-2`}
              `}
            >
              <Button
                type="primary"
                disabled={!previousApprovedActual}
                loading={perPopulateMutation.isLoading}
                onClick={handlePrePopulate(SectionType.Work)}
              >
                {t('actuals.prepopulate')}
              </Button>
              {previousApprovedActual && (
                <Text>
                  {t('actuals.prepopulateWithDataFrom')}{' '}
                  {moment()
                    .month(Number(previousApprovedActual.month) - 1)
                    .locale(i18n.language)
                    .format('MMMM')}{' '}
                  {previousApprovedActual.year}
                </Text>
              )}
            </Row>
          )}
          <WorksForm
            isActualForm
            isEditable={isEditable}
            currency={contractCurrency}
            selectedYear={period.year}
            selectedMonth={period.month}
            handleProceed={onProceedClick}
            companiesList={companiesList}
            actualId={actualId}
            refetchCompaniesList={refetchCompaniesList}
            prePopulatedData={prePopulatedData}
          />
          <CommentCard
            isActual
            id={actualId}
            sectionType={SectionType.Work}
            status={period.approvalStatus}
          />
        </Panel>
      )}
      {scopes?.includes(SCOPE_OF_WORK.SERVICES) && (
        <Panel
          key="3"
          header={
            <Badge offset={[badgeMargin, 0]}>
              {t('forecasts.services')}
              {freshCommentedSections?.[SectionType.Service] && (
                <WarningOutlined
                  className={css`
                    ${tw`ml-2 text-lg text-red-600`}
                  `}
                />
              )}
              {isEditable && errors?.Service && (
                <Tag style={{ marginLeft: '8px' }} color="error">
                  {t('actuals.sectionIsRequired')}
                </Tag>
              )}
            </Badge>
          }
        >
          {isEditable && (
            <Row
              align="middle"
              className={css`
                ${tw`space-x-2 mb-2`}
              `}
            >
              <Button
                type="primary"
                disabled={!previousApprovedActual}
                loading={perPopulateMutation.isLoading}
                onClick={handlePrePopulate(SectionType.Service)}
              >
                {t('actuals.prepopulate')}
              </Button>
              {previousApprovedActual && (
                <Text>
                  {t('actuals.prepopulateWithDataFrom')}{' '}
                  {moment()
                    .month(Number(previousApprovedActual.month) - 1)
                    .locale(i18n.language)
                    .format('MMMM')}{' '}
                  {previousApprovedActual.year}
                </Text>
              )}
            </Row>
          )}
          <ServiceForm
            isActualForm
            isEditable={isEditable}
            currency={contractCurrency}
            selectedYear={period.year}
            selectedMonth={period.month}
            handleProceed={onProceedClick}
            companiesList={companiesList}
            actualId={actualId}
            refetchCompaniesList={refetchCompaniesList}
            prePopulatedData={prePopulatedData}
          />
          <CommentCard
            isActual
            id={actualId}
            sectionType={SectionType.Service}
            status={period.approvalStatus}
          />
        </Panel>
      )}
      <Panel key="4" header={t('forecasts.goodsWorksServicesSummary')}>
        <GwsSummaryTable
          isActualForm
          currency={contractCurrency}
          selectedYear={period.year}
          selectedMonth={period.month}
          actualId={actualId}
        />
      </Panel>
    </Collapse>
  );
};
