import { UserOutlined } from '@ant-design/icons';
import { css } from '@linaria/core';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { LoginButtonProps } from './props';

const loginButton = css`
  ${tw`border-white!	hover:bg-opacity-10`}
`;

export const LoginButton: React.FC<LoginButtonProps> = ({ withIcon }) => {
  const [t] = useTranslation();
  return (
    <Button className={loginButton} type="primary">
      {withIcon && <UserOutlined />}
      {t('login.loginButton')}
    </Button>
  );
};
