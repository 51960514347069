export const enContracts = {
  contractNumber: 'Contract number',
  type: 'Type',
  contracts: 'Contracts',
  totalContracts: 'Total contracts',
  masterAgreement: 'Master agreement',
  contractType: 'Contract type',
  contractTypeColon: 'Contract type: ',
  kcCategoryAndArea: 'KC Category and Area',
  kcCategoryAndAreaColon: 'KC Category and Area: ',
  masterAgreementColon: 'Master agreement: ',
  subAgreements:'Sub-agreements',
  addSubAgreement: 'Add sub-agreement',
  successCreate: 'Contract create successfully',
  version: 'Version',
  versionWithParam: 'Version {{version}}',
  updateContract: 'Update contract',
  updateAndResubmit: 'Update and resubmit contract',
  startDate: 'Start date',
  endDate: 'End date',
  currency: 'Currency',
  amount: 'Contract amount',
  kcTarget: 'KC Target',
  detailsOnScopeWork: 'Details on scope work',
  comment: 'Comment',
  comments: 'Comments',
  attach: 'ATTACH KC PLAN',
  successUpdate: 'Contract update successfully',
  scopeOfWorks: ' Scope of works',
  scopeOfWork: ' Scope of work',
  status: 'Status',
  contractDeleted: 'Contract is deleted',
  approve: 'Approve',
  reject: 'Reject',
  rejectAmendment: 'Reject',
  confirmReject: 'Are you sure you want to reject this amendment?',
  submittedBy: 'Submitted by: {{name}}',
  submissionDate: 'Submission date: {{date}}',
  approvedBy: 'Approved by: {{name}}',
  approvalDate: 'Approval date: {{date}}',
  rejectedBy: 'Rejected by: {{name}}',
  rejectionDate: 'Rejection date: {{date}}',
  compareWithInitial: 'Compare to initial',
  sureWantToReject: 'Are you sure you want to reject this amendment?',
  contractPeriod: 'Contract period',
  period: 'Period',
  addAmendment: 'Add amendment',
  newAmendment: 'New amendment',
  newAmendmentSent: 'New amendment was successfully sent for review to KC Lead',
  amendmentResubmitted: 'Amendment was successfully resubmitted for review to KC Lead',
  confirmDelete: 'Are you sure you want to delete contract {{name}}?',
  confirmDeleteSecondLine: 'Once you press YES action cannot be undone.',
  goods: 'Goods',
  works: 'Works',
  services: 'Services',
  noCommentsYet: 'No comments yet',
  versionComparison: 'Versions comparison',
  ok: 'OK',
  instructionMessage:
    'Please indicate current values of the contract in the fields below, describe updates in the Comments section.',
  instructionExample:
    'Eg: previous contract amount was 100, it has increased by 10, then put 110 in the current contract amount field, text "The contract amount has increased by 10" in the Comments section',
  parentContract: 'Parent contract',
  contractStartDate: 'Contract start date',
  contractEndDate: 'Contract end date',
  contractAmount: 'Contract amount'
};
