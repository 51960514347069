/* eslint-disable @typescript-eslint/no-explicit-any */
import { CATEGORIES } from '@components/constants/AdminCategories';
import type { Category } from '@components/organisms/NewContractForm/libs/types/Category';
import { Tree } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';

type Props = {
  readonly value: any;
  readonly setValue: any;
};

export const KcCategoriesCheckboxGroup: FC<Props> = ({ value, setValue }) => {
  const handleCheck = (checkedKeys: any): void => {
    setValue(checkedKeys);
  };

  // The treeData prop of antd Tree is mutable, so we don't use readonly here
  // eslint-disable-next-line functional/prefer-readonly-type
  const categoriesList = useMemo<Category[]>(() => CATEGORIES.map((c) => c), []);

  return (
    <Tree
      checkable
      defaultExpandAll
      checkedKeys={value}
      treeData={categoriesList}
      selectable={false}
      onCheck={handleCheck}
    />
  );
};
