import { SearchOutlined } from '@ant-design/icons';
import { contractApi } from '@api/contractApi';
import { contractorApi } from '@api/contractorApi';
import { CATEGORIES } from '@components/constants/AdminCategories';
import type { Category } from '@components/organisms/NewContractForm/libs/types/Category';
import { CategorySelector } from '@components/pages/admin/Forecasts/libs/components/CategorySelector';
import { ContractorSelector } from '@components/pages/admin/Forecasts/libs/components/ContractorSelector';
import type { SearchQueryType } from '@components/pages/admin/Invoice/libs/components/SearchSection/libs/types/SearchQueryType';
import { InvoicesStatusSelector } from '@components/pages/contractor/Invoice/libs/SearchSection/InvoicesStatusSelector';
import { invoiceStatuses } from '@utils/statuses';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import type { Moment } from 'moment';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ContractsSelector } from '../ContractsSelector';
import type { SearchSectionProps } from './props';

export const SearchSection: FC<SearchSectionProps> = ({ onSearchQuery }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const lastElementSliceIndex = -1;

  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  useEffect(() => {
    onSearchQuery(searchQuery);
  }, [onSearchQuery, searchQuery]);

  const handleOnFinish = useCallback(
    (vals: {
      readonly invoiceNumber: string;
      readonly contractors: readonly number[];
      readonly contracts: readonly number[];
      readonly kcCategories: readonly number[];
      readonly contractTypes: readonly number[];
      readonly period: string;
      readonly dateFrom: Moment;
      readonly dateTo: Moment;
      readonly status: readonly number[];
    }) => {
      setSearchQuery({
        invoiceNumber: vals.invoiceNumber,
        contractors: vals.contractors,
        contracts: vals.contracts,
        kcCategories: vals.kcCategories,
        dateFrom: vals.dateFrom,
        dateTo: vals.dateTo,
        status: vals.status,
      });
    },
    []
  );

  const contractorsList = useQuery(
    'getContractors',
    async () => contractorApi.getContractors().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const contractList = useQuery(
    'getContracts',
    async () => contractApi.getAllContracts().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const categoriesList = useMemo<readonly Category[]>(
    () =>
      CATEGORIES.reduce<readonly Category[]>(
        (prev, cat) => (cat.children ? prev.concat(cat.children) : [...prev, cat]),
        []
      ),
    []
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="contractors" label={t('contractors.contractors')}>
            <ContractorSelector contractors={contractorsList.data} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="contracts" label={t('contractors.contracts')}>
            <ContractsSelector contracts={contractList.data} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="kcCategories" label={t('members.categoryAndArea')}>
            <CategorySelector categories={categoriesList} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="invoiceNumber" label={t('invoices.invoiceNumber')}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="dateFrom" label={t('dateFrom')}>
            <DatePicker allowClear style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="dateTo" label={t('dateTo')}>
            <DatePicker allowClear style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="status" label={t('contracts.status')}>
            <InvoicesStatusSelector statuses={invoiceStatuses.slice(0, lastElementSliceIndex)} />
          </Form.Item>
        </Col>
        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {t('forecasts.applyFilters')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
