import { contractorApi } from '@api/contractorApi';
import { ActualsTable } from '@components/pages/contractor/Actual/libs/components/ActualsTable';
import { SearchSection } from '@components/pages/contractor/Actual/libs/components/SearchSection';
import type { SearchQueryType } from '@components/pages/contractor/Actual/libs/components/SearchSection/libs/types/SearchQueryType';
import { ActualSortType } from '@components/types/models/Actuals';
import { Button, Col, Row, Typography } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

export const ActualsList: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();
  const [sortType, setSortType] = useState<number>(ActualSortType.Descending);

  const pageSize = 15;

  const {
    data: actualsList,
    isLoading,
    refetch,
  } = useQuery(
    ['getActualsPaginated', { searchQuery }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [string, { readonly searchQuery: SearchQueryType | undefined }]
      >
    ) =>
      contractorApi
        .getActualsPaginated(
          page,
          pageSize,
          sortType,
          searchQueryCallback.queryKey[1].searchQuery?.status,
          searchQueryCallback.queryKey[1].searchQuery?.year,
          searchQueryCallback.queryKey[1].searchQuery?.month
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    void refetch();
  }, [page, refetch, sortType]);

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
  }, []);

  return (
    <>
      <Title>{t('actuals.actuals')}</Title>
      <Row justify="space-between" align="middle">
        <Col flex={1}>
          <SearchSection onSearchQuery={handleSearchQuery} />
        </Col>
        <Link to="/contractor/actual/details">
          <Button type="primary">{t('actuals.submitNewActuals')}</Button>
        </Link>
      </Row>
      <ActualsTable
        sortType={sortType}
        actualsList={actualsList?.results}
        isLoading={isLoading}
        pagination={{
          onChange: (p: number) => {
            setPage(p);
          },
          total: actualsList?.totalItems,
          current: actualsList?.currentPage,
          pageSize: actualsList?.pageSize,
        }}
        setSortType={setSortType}
      />
    </>
  );
};
