import type { FormValues } from '@components/types/models/Member';
import { Col, Form, Input, Modal, notification, Row } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useMutation } from 'react-query';

import type { EditContractMemberFormProps } from './props';

export const EditContractorMemberForm: FC<EditContractMemberFormProps> = ({
  isModalOpen,
  contractorId,
  member,
  onCloseClick,
  refetchMembersList,
}) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      position: '',
      phoneNumber: '',
      email: '',
    },
  });

  useEffect(() => {
    if (member) {
      setValue('firstName', member.firstName);
      setValue('lastName', member.lastName);
      setValue('position', member.position);
      setValue('phoneNumber', member.phoneNumber);
    } else {
      reset();
    }
  }, [member, reset, setValue]);

  const handleInputChange = useCallback(
    (name) => (e: React.FormEvent<HTMLInputElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );
  const handleClose = (): void => {
    onCloseClick();
  };

  const mutation = useMutation(
    async (values: FormValues) =>
      axios.put(`/api/contractor/${contractorId}/member/${member?.userId}/`, values),
    {
      onSuccess: () => {
        handleClose();
        notification.success({
          message: t('successUpdate'),
        });
        refetchMembersList();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        errData.validationErrors.forEach(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: { readonly name: any; readonly description: string }): void => {
            setError(error.name, { message: error.description });
          }
        );
      },
    }
  );
  const handleModalOkClick = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues) => {
      const phoneNumber = values.phoneNumber?.replace(/\D/gu, '');
      const formValues = {
        ...values,
        phoneNumber,
      };
      const data = Object.fromEntries(Object.entries(formValues).filter(([_, v]) => v !== ''));
      mutation.mutate(data);
    })();
  }, [handleSubmit, clearErrors, mutation]);

  return (
    <Modal
      visible={isModalOpen}
      title={t('members.membersData')}
      cancelText={t('cancel')}
      okText={t('update')}
      width={1000}
      confirmLoading={mutation.isLoading}
      onOk={handleModalOkClick}
      onCancel={handleClose}
    >
      <Form layout="vertical">
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              validateStatus={errors.firstName?.message && 'error'}
              help={errors.firstName?.message}
              label={t('members.firstName')}
            >
              <Input
                placeholder={t('members.firstName')}
                value={watch('firstName')}
                onChange={handleInputChange('firstName')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              validateStatus={errors.lastName?.message && 'error'}
              help={errors.lastName?.message}
              label={t('members.lastName')}
            >
              <Input
                placeholder={t('members.lastName')}
                value={watch('lastName')}
                onChange={handleInputChange('lastName')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              validateStatus={errors.position?.message && 'error'}
              help={errors.position?.message}
              label={t('members.jobTitle')}
            >
              <Input
                placeholder={t('members.jobTitle')}
                value={watch('position')}
                onChange={handleInputChange('position')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              validateStatus={errors.phoneNumber?.message && 'error'}
              help={errors.phoneNumber?.message}
              label={t('members.phoneNumber')}
              labelCol={{ span: 24 }}
            >
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field: { onChange: handleChange, value } }) => (
                  <InputMask mask="+7(999)999 99 99" value={value} onChange={handleChange}>
                    {(inputProps: unknown) => (
                      <Input {...inputProps} type="tel" className="input" />
                    )}
                  </InputMask>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
