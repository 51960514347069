/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { forecastApi } from '@api/forecastApi';
import { CommentCard } from '@components/organisms/CommentCard';
import type { MouCategoryList } from '@components/organisms/ForecastTrainingForm/props';
import { ForecastJobsPerMonth } from '@components/organisms/JobsTraining/libs/ForecastJobsPerMonth';
import { ForecastJobsPerYear } from '@components/organisms/JobsTraining/libs/ForecastJobsPerYear';
import { JobsTrainingTimeline } from '@components/organisms/JobsTraining/libs/JobsTrainingTimeline';
import type { YearItem } from '@components/organisms/JobsTraining/libs/JobsTrainingTimeline/props';
import type { NationalizeListItem } from '@components/organisms/JobsTraining/types';
import { SectionType } from '@components/types/models/Forecast';
import { useDefaultQuery } from '@hooks';
import { useActualForecast } from '@hooks/useActualForecast';
import { css } from '@linaria/core';
import { Button, Col, Divider, notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import tw from 'twin.macro';

import type {
  ForecastJobsData,
  ForecastJobsDataItem,
  ForecastJobsFormProps,
  MouCategoryItem,
  MouCategorySubCategory,
} from './props';

export const ForecastJobsForm: FC<ForecastJobsFormProps> = ({
  startDate,
  endDate,
  forecastId,
  setActiveCollapseItem,
  activeCollapseItem,
  forecastStatus,
  isEditable,
}) => {
  const { t } = useTranslation();
  const { mouCategories } = useActualForecast({});

  const [currentPeriod, setCurrentPeriod] = useState<YearItem | null>(null);
  const [currentMouCategory, setCurrentMouCategory] = useState<
    MouCategoryList | 'total'
  >('engineering');
  const [isCurrentPeriodChanged, setCurrentPeriodChanged] =
    useState<boolean>(false);
  const isLastCategory = currentPeriod?.isDetailed
    ? currentMouCategory === 'total'
    : currentMouCategory === 'heavyEquipmentOperator';

  const mouCategoryRowHeaders: MouCategorySubCategory = [
    { text: t('forecasts.kzNationals'), title: 'kzNationals' },
    {
      text: t('forecasts.nonKzNationals'),
      title: 'nonKzNationals',
    },
    {
      text: t('forecasts.total'),
      title: 'total',
    },
    {
      text: t('forecasts.plannedToNationalize'),
      title: 'plannedToNationalize',
    },
    {
      text: t('forecasts.newGradInternships'),
      title: 'newGradInternships',
    },
    { text: t('forecasts.newGradJobs'), title: 'newGradJobs' },
  ];

  const { data: initialData, refetch: refetchJobList } = useDefaultQuery<
    readonly ForecastJobsDataItem[]
  >(['getJobsList', forecastId], async () =>
    forecastApi.getForecastJobs(forecastId).then((res) => res.data)
  );

  const switchMouCategory = (): void => {
    const nextCategoryIndex =
      mouCategories.findIndex(
        (category) => category.title === currentMouCategory
      ) + 1;

    setCurrentMouCategory(mouCategories[nextCategoryIndex].title);
  };

  const handleJobTransition = (): void => {
    const hasNextYear = currentPeriod?.years.includes(
      Number(currentPeriod.year) + 1
    );
    // eslint-disable-next-line functional/no-let
    let canGetJobs = true;

    if (currentPeriod?.isDetailed) {
      if (hasNextYear && isLastCategory) {
        setCurrentPeriodChanged(!isCurrentPeriodChanged);
      } else if (!hasNextYear && isLastCategory) {
        canGetJobs = false;
        setActiveCollapseItem(activeCollapseItem + 1);
      } else {
        switchMouCategory();
      }
    } else if (isLastCategory) {
      canGetJobs = false;
      setActiveCollapseItem(activeCollapseItem + 1);
    } else {
      switchMouCategory();
    }

    if (canGetJobs) {
      void refetchJobList();
    }
  };

  const addJobsDataMutation = useMutation(
    async (values: ForecastJobsData) =>
      axios.post(`api/forecasts/job/${forecastId}`, values),
    {
      onSuccess() {
        notification.success({ message: t('saved') });
        handleJobTransition();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const addNationalizeDataMutation = useMutation(
    async (values: unknown | readonly NationalizeListItem[]) =>
      axios.post(`api/forecasts/job/${forecastId}/nationalization`, values),
    {
      onSuccess() {
        notification.success({ message: t('saved') });
      },
    }
  );

  const handleSelectMouCategory = useCallback(
    (category: MouCategoryList | 'total') => () => {
      void refetchJobList();
      setCurrentMouCategory(category);
    },
    [refetchJobList]
  );

  const getCategoriesList = (): readonly MouCategoryItem[] =>
    currentPeriod?.isDetailed
      ? mouCategories
      : mouCategories.filter((category) => category.title !== 'total');

  const handleSave = (payload: ForecastJobsData): void => {
    if (!isEditable || currentMouCategory === 'total') {
      handleJobTransition();
      return;
    }

    const categoryJobsData = payload[currentMouCategory];

    const outputPayload = JSON.parse(
      JSON.stringify(Object.values(categoryJobsData))
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ).map((item: any) => {
      mouCategoryRowHeaders.map((category) => {
        if (category.title === 'total') {
          return false;
        }

        if (item[category.title] === '') {
          item[category.title] = null;
        } else {
          item[category.title] = Number(item[category.title]);
        }
        return category;
      });
      return item;
    });

    addJobsDataMutation.mutate(outputPayload);
  };

  useEffect(() => {
    setCurrentMouCategory('engineering');
  }, [currentPeriod]);

  return (
    <Col span={24}>
      <Col
        span={24}
        className={css`
          ${tw`flex space-x-4`}
        `}
      >
        <JobsTrainingTimeline
          startDateString={startDate}
          endDateString={endDate}
          getCurrentPeriod={(payload): void => {
            setCurrentPeriod(payload);
          }}
          isCurrentPeriodChanged={isCurrentPeriodChanged}
        />
        <Col
          className={css`
            ${tw`flex flex-wrap flex-1 -mb-2`}
          `}
        >
          {getCategoriesList().map((category) => (
            <Button
              key={category.title}
              className={css`
                ${tw`mr-2 mb-2`}
              `}
              type={
                currentMouCategory === category.title ? 'primary' : 'default'
              }
              onClick={handleSelectMouCategory(category.title)}
            >
              {category.text}
            </Button>
          ))}
        </Col>
      </Col>
      <Divider />

      {currentPeriod?.isDetailed ? (
        <ForecastJobsPerMonth
          mouCategories={[...mouCategories]}
          initialData={initialData}
          currentPeriod={currentPeriod}
          currentMouCategory={currentMouCategory}
          mouCategoryRowHeaders={mouCategoryRowHeaders}
          addNationalizeDataMutation={addNationalizeDataMutation}
          isEditable={isEditable}
          onSave={handleSave}
        />
      ) : (
        <ForecastJobsPerYear
          mouCategories={[...mouCategories]}
          initialData={initialData}
          currentPeriod={currentPeriod!}
          currentMouCategory={currentMouCategory}
          mouCategoryRowHeaders={mouCategoryRowHeaders}
          isEditable={isEditable}
          onSave={handleSave}
        />
      )}

      <CommentCard
        id={forecastId}
        sectionType={SectionType.JobAndNationalization}
        status={forecastStatus}
      />
    </Col>
  );
};
