import { WarningFilled } from '@ant-design/icons';
import { Alert, Col, DatePicker, Form, Modal, Typography } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AdminRejectModalProps } from './props';

export const AdminRejectModal: FC<AdminRejectModalProps> = ({
  visible,
  onClose,
  rejectInvoiceMutation,
}) => {
  const { t } = useTranslation();
  const disableDate = (current: Moment): boolean => current < moment().endOf('day');
  const defaultSubmissionDate = 3;
  const [dueDate, setDueDate] = useState<Moment>(
    moment(new Date(), 'YYYY-MM-DD').add(defaultSubmissionDate, 'days')
  );
  const { Paragraph } = Typography;

  const handleDateChange = useCallback((date) => {
    setDueDate(date);
  }, []);

  const handleReject = useCallback(() => {
    rejectInvoiceMutation.mutate({ dueDate: dueDate.toJSON() });
  }, [rejectInvoiceMutation, dueDate]);

  const handleClose = (): void => {
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      maskClosable={false}
      width={800}
      okText={t('reject')}
      title={t('reject')}
      onCancel={handleClose}
      onOk={handleReject}
      confirmLoading={rejectInvoiceMutation.isLoading}
    >
      <Paragraph>
        <Alert
          showIcon
          message={t('invoices.setDueDate')}
          type="warning"
          icon={<WarningFilled />}
        />
      </Paragraph>

      <Col span={6}>
        <Form.Item>
          <DatePicker
            showNow={false}
            size="large"
            value={dueDate}
            style={{ width: '100%' }}
            disabledDate={disableDate}
            format="YYYY-MM-DD"
            allowClear={false}
            onChange={handleDateChange}
          />
        </Form.Item>
      </Col>
    </Modal>
  );
};
