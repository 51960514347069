import { HttpResponseStatusCodes } from '@components/types/HttpResponseStatusCodes';
import type { User } from '@components/types/models/User';
import { UserContext } from '@contexts/userContext';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Card, Col, Form, Input, notification, Row, Space, Spin, Typography } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

type PageParams = {
  readonly token: string;
};

export const ResetPasswordTokenPage: React.FC = () => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const { setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useParams<PageParams>();
  const history = useHistory();

  const {
    handleSubmit,
    setError,
    formState: { errors },
    register,
    clearErrors,
  } = useForm();

  useEffect(() => {
    if (token) {
      axios
        .get(`api/auth/password/check/${token}`)
        .then((res) => {
          if (!res.data?.result) {
            history.replace('/expired-token');
          }
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [history, token]);

  const mutation = useMutation(
    async (values: unknown) =>
      axios.post(`/api/auth/password/reset/${token}`, values).then((res) => res.data),
    {
      onSuccess: (data: { readonly apiToken: string }) => {
        notification.success({
          message: t('profile.passwordChangeSuccess'),
        });
        localStorage.setItem('KC_PORTAL_USER_TOKEN', data.apiToken);
        void axios.get<User>('/api/auth/me').then((res) => {
          setUser(res.data);
        });
        history.replace('/profile');
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.status === HttpResponseStatusCodes.BadRequest) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: 'email' | 'password';
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        }
        setError('errorMessage', { message: errData.message });
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values): void => {
      mutation.mutate(values);
    })();
  }, [handleSubmit, clearErrors, mutation]);

  const handleCancelClick = (): void => {
    history.replace('/');
  };

  return (
    <div className="guest-pages-bg">
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
          <Col span={8}>
            <Card>
              <Card.Meta
                style={{ marginBottom: 16 }}
                title={<Title level={3}> {t('login.resetPasswordTitle')}</Title>}
                description={
                  <Text disabled style={{ fontSize: 14 }}>
                    {t('login.enterNewPassword')}
                  </Text>
                }
              />
              <Form onFinish={handleFormSubmit}>
                <ErrorMessage
                  errors={errors}
                  name="errorMessage"
                  render={({ message }) => <Text type="danger">{message}</Text>}
                />
                <Form.Item
                  label={t('profile.newPassword')}
                  labelCol={{ span: 24 }}
                  validateStatus={errors.password?.message && 'error'}
                  help={errors.password?.message}
                >
                  <Input.Password {...register('password')} />
                </Form.Item>
                <Form.Item
                  label={t('profile.confirmNewPassword')}
                  labelCol={{ span: 24 }}
                  validateStatus={errors.confirmPassword?.message && 'error'}
                  help={errors.confirmPassword?.message}
                >
                  <Input.Password {...register('confirmPassword')} />
                </Form.Item>
                <Form.Item noStyle>
                  <Row gutter={8} justify="end" align="middle">
                    <Space>
                      <Button onClick={handleCancelClick}>{t('cancel')}</Button>
                      <Button type="primary" htmlType="submit" loading={mutation.isLoading}>
                        {t('reset')}
                      </Button>
                    </Space>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};
