// eslint-disable-next-line prefer-named-capture-group
export const isFloat = (value: string): boolean => /^(\d*)([,.]\d{0,2})?$/gmu.test(value);

const maxPercentage = 100;
export const isPercentage = (value: string): boolean =>
  Number(value) >= 0 && Number(value) <= maxPercentage;

const binMaxLength = 12;


export const isBin = (value: string): boolean => value.length >= 0 && value.length <= binMaxLength;
