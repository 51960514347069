import { AppLinkBadge } from '@components/atoms/AppBadge';
import type { FC } from 'react';
import { Link } from 'react-router-dom';

import type { ContractorLinkProps } from './props';

export const ContractorLink: FC<ContractorLinkProps> = ({ id, name, newContractsCount }) => (
  <AppLinkBadge count={newContractsCount}>
    <Link to={`/admin/contractor/${id}`}>{name}</Link>
  </AppLinkBadge>
);
