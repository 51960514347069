import { Table } from "antd"
import { useTranslation } from "react-i18next";


type MigrationsErrorListProps = {
  readonly errors: Array<{ error: string }>;
}

export const MigrationsErrorList: React.FC<MigrationsErrorListProps> = ({
  errors
}) => {
  const { t } = useTranslation();

  return (
    <Table
      columns={[
        { title: t("migrations.errors"), key: 'error', dataIndex: 'error' }
      ]}
      dataSource={errors}
      pagination={false}
    />
  )
}