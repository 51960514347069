import type { ComparisonTableProps } from '@components/pages/admin/Contract/libs/ComparisonTable/props';
import { styled } from '@linaria/react';
import { Typography } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const Table = styled.table`
  table-layout: auto;
`;

const TdOld = styled.td`
  background-color: #fbe9eb !important;
`;

const TdNew = styled.td`
  background-color: #ecfdf0 !important;
`;

export const ComparisonTable: FC<ComparisonTableProps> = ({ columns, dataSource }) => {
  const { t } = useTranslation();

  return (
    <div className="ant-table">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <Table>
            <thead className="ant-table-thead">
              <tr>
                {columns?.map((col, index) => (
                  <th key={`${col.title}_${index}`} className="ant-table-cell">
                    {col.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {dataSource?.map((item, index) => (
                <tr key={`${item.title}_${index}`} className="ant-table-row ant-table-row-level-0">
                  <td className="ant-table-cell">{item.title}</td>
                  {item.version_first === item.version_second ? (
                    <>
                      <td className="ant-table-cell">{item.version_second}</td>
                      <td className="ant-table-cell">{item.version_first}</td>
                    </>
                  ) : (
                    <>
                      <TdNew className="ant-table-cell">
                        {item.version_second} <Text type="secondary">{t(' (edited)')}</Text>
                      </TdNew>
                      <TdOld className="ant-table-cell">{item.version_first}</TdOld>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
