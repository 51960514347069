import type { Contract } from './Contract';
import type { SectionType } from './Forecast';
import type { STATUS } from './Statuses';

export enum InvoiceSorting {
  IdAscending = 1,
  IdDescending = 2,
  SubmissionDateAsc = 3,
  SubmissionDateDesc = 4,
}

export type InvoiceComment = {
  readonly comment: string;
  readonly createdAt: string;
  readonly sectionType: SectionType;
  readonly createdByUser?: {
    readonly createdAt: string;
    readonly email: string;
    readonly firstName: string;
    readonly id: number;
    readonly lastName: string;
    readonly phoneNumber: string;
    readonly position: string;
  };
};
export type Invoice = {
  readonly id: number;
  readonly contractId: number;
  readonly contract: Contract;
  readonly date: string;
  readonly identityNumber: string;
  readonly cwtNumber: string;
  readonly status: STATUS;
  readonly startDate: string;
  readonly endDate: string;
  readonly submittedAt: string;
  readonly reviewedAt: string;
  readonly version: number;
  readonly attachments: ReadonlyArray<{
    readonly id: number;
    readonly fileName: string;
  }>;
  readonly comments: readonly InvoiceComment[];
  readonly submittedBy: {
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly reviewedBy: {
    readonly firstName: string;
    readonly lastName: string;
    readonly updatedAt: string;
  };
  readonly versions: ReadonlyArray<{
    readonly version: number;
    readonly id: number;
  }>;
};

export type InvoicesListResponse = {
  readonly currentPage: number;
  readonly pageSize: number;
  readonly totalItems: number;
  readonly results: readonly Invoice[];
};

export type InvoiceGwsItem = {
  readonly item: {
    readonly id: number;
    readonly title: string;
  };
  readonly itemId: number;
  readonly kcAmount: number;
  readonly value: number;
  readonly kcShare: number;
};

export type InvoiceGwsName = {
  readonly invoicedValue: number;
  readonly item: { readonly id: number; readonly title: string };
  readonly itemId: number;
  readonly kcShare: number;
  readonly kcValue: number;
};
