import { UserAddOutlined } from '@ant-design/icons';
import { adminApi } from '@api/adminApi';
import { DownloadFileButton } from '@components/atoms/DownloadFileButton';
import { MembersTable } from '@components/organisms/MembersTable';
import { PERMISSIONS } from '@components/types/models/Permissions';
import { UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks/useDefaultQuery';
import { css } from '@linaria/core';
import { isUserHasPermission } from '@utils/permissionHelper';
import { Button, Col, notification, Row, Space, Typography } from 'antd';
import type { AxiosError } from 'axios';
import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import tw from 'twin.macro';

const { Title } = Typography;

export const Member: FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const members = useDefaultQuery('getAdminMembers', async () =>
    adminApi.getAdminMembers().then((res) => res.data)
  );

  const deleteMemberMutation = useMutation(async (id: number) => adminApi.deleteAdminMember(id), {
    onSuccess() {
      notification.success({ message: t('members.memberDeleted') });

      void members.refetch();
    },
    onError(err: AxiosError) {
      notification.error({ message: err.response?.data?.message });
    },
  });

  const resendInviteMutation = useMutation(
    async (email: string) => adminApi.resendInviteMember(email),
    {
      onSuccess() {
        notification.success({ message: t('members.memberInviteResent') });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const deleteMemberInviteMutation = useMutation(
    async (email: string) => adminApi.deleteInviteMember(email),
    {
      onSuccess() {
        notification.success({ message: t('members.memberInviteDeleted') });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const handleEditClick = useCallback(
    (member: { readonly userId: number }) => {
      history.push(`/admin/member/${member.userId}/edit`);
    },
    [history]
  );

  const isDisable = !isUserHasPermission(user?.permissions, PERMISSIONS.MEMBER_EDIT);

  return (
    <Row
      justify="center"
      align="middle"
      className={css`
        ${tw`w-full`}
      `}
    >
      <Col
        span={12}
        className={css`
          ${tw`text-left`}
        `}
      >
        <Title level={1}>{t('members.members')}</Title>
      </Col>
      <Col
        span={12}
        className={css`
          ${tw`text-right`}
        `}
      >
        <Space>
          <DownloadFileButton
            url="/api/admin/export/users/admins"
            disabled={isDisable}
            title={t('members.userExport')}
          />
          <Link to="/admin/member/new">
            <Button icon={<UserAddOutlined />} type="primary" disabled={isDisable}>
              {t('members.inviteMember')}
            </Button>
          </Link>
        </Space>
      </Col>
      <MembersTable
        deleteMemberMutation={deleteMemberMutation}
        resendInviteMutation={resendInviteMutation}
        deleteMemberInviteMutation={deleteMemberInviteMutation}
        members={members}
        disableActionButton={isDisable}
        onEditClick={handleEditClick}
      />
    </Row>
  );
};
