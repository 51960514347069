/* eslint-disable @typescript-eslint/naming-convention */
export const USER_ROLES = {
  NONE: 0,

  SUBCONTRACTOR_MANAGER: 1,
  SUBCONTRACTOR_OWNER: 2,

  CONTRACTOR_MANAGER: 3,
  CONTRACTOR_OWNER: 4,

  ADMINISTRATOR: 5,
};
