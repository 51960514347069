/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { forecastApi } from '@api/forecastApi';
import { CommentCard } from '@components/organisms/CommentCard';
import { ForecastTrainingPerMonth } from '@components/organisms/JobsTraining/libs/ForecastTrainingPerMonth';
import { ForecastTrainingPerYear } from '@components/organisms/JobsTraining/libs/ForecastTrainingPerYear';
import { SectionType } from '@components/types/models/Forecast';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import { Button, Col, Divider, notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import tw from 'twin.macro';

import { JobsTrainingTimeline } from '../JobsTraining/libs/JobsTrainingTimeline';
import type { YearItem } from '../JobsTraining/libs/JobsTrainingTimeline/props';
import type {
  ForecastTrainingData,
  ForecastTrainingFormProps,
  MouCategoryItem,
} from './props';

export const ForecastTrainingForm: FC<ForecastTrainingFormProps> = ({
  startDate,
  endDate,
  forecastId,
  setActiveCollapseItem,
  activeCollapseItem,
  forecastStatus,
  isEditable,
}) => {
  const { t } = useTranslation();

  const [currentPeriod, setCurrentPeriod] = useState<YearItem | null>(null);
  const [isGrandTotal, setGrandTotalMode] = useState<boolean>(false);
  const [isCurrentPeriodChanged, setCurrentPeriodChanged] =
    useState<boolean>(false);

  const rowsHeaders: readonly MouCategoryItem[] = [
    { text: t('forecasts.mouCategory.engineering'), title: 'engineering' },
    {
      text: t('forecasts.mouCategory.supervisorForeman'),
      title: 'supervisorAndForeman',
    },
    {
      text: t('forecasts.mouCategory.administration'),
      title: 'administration',
    },
    { text: t('forecasts.mouCategory.craft'), title: 'craft' },
    {
      text: t('forecasts.mouCategory.heavyEquipment'),
      title: 'heavyEquipmentOperator',
    },
    { text: t('forecasts.mouCategory.total'), title: 'total' },
  ];

  const { data: trainingList, refetch: refetchTrainingList } = useDefaultQuery(
    ['getTrainingList', forecastId],
    async () =>
      forecastApi.getForecastTraining(forecastId).then((res) => res.data)
  );

  const handleTrainingTransition = (): void => {
    const hasNextYear = currentPeriod?.years.includes(
      Number(currentPeriod.year) + 1
    );

    if (currentPeriod?.isDetailed && hasNextYear) {
      setCurrentPeriodChanged(!isCurrentPeriodChanged);
      void refetchTrainingList();
    } else {
      setActiveCollapseItem(activeCollapseItem + 1);
    }
  };

  const addTrainingDataMutation = useMutation(
    async (values: ForecastTrainingData) =>
      axios.post(`api/forecasts/training/${forecastId}`, values),
    {
      onSuccess() {
        notification.success({ message: t('forecasts.saved') });

        handleTrainingTransition();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const handleSetGrandTotal = useCallback(
    (payload: boolean) => () => {
      setGrandTotalMode(payload);
    },
    [setGrandTotalMode]
  );

  const handleSave = (payload: ForecastTrainingData): void => {
    if (isGrandTotal) {
      setActiveCollapseItem(activeCollapseItem + 1);
      return;
    } else if (!isEditable) {
      handleTrainingTransition();
      return;
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputPayload = JSON.parse(
      JSON.stringify(Object.values(payload))
    ).map((item: any) => {
      rowsHeaders.map((header) => {
        if (header.title === 'total') {
          return false;
        }

        if (item[header.title] === '') {
          item[header.title] = null;
        } else {
          item[header.title] = Number(item[header.title]);
        }
        return header;
      });
      return item;
    });

    addTrainingDataMutation.mutate(outputPayload);
  };

  return (
    <Col span={24}>
      <Col
        span={24}
        className={css`
          ${tw`flex space-x-4`}
        `}
      >
        <JobsTrainingTimeline
          startDateString={startDate}
          endDateString={endDate}
          getCurrentPeriod={(payload): void => {
            setCurrentPeriod(payload);
          }}
          isCurrentPeriodChanged={isCurrentPeriodChanged}
          isGrandTotal={isGrandTotal}
        />
        <Button
          type={isGrandTotal ? 'primary' : 'default'}
          onClick={handleSetGrandTotal(!isGrandTotal)}
        >
          {t('forecasts.grandTotal')}
        </Button>
      </Col>
      <Divider />

      {currentPeriod?.isDetailed && !isGrandTotal ? (
        <ForecastTrainingPerMonth
          rowsHeaders={[...rowsHeaders]}
          trainingList={trainingList}
          currentPeriod={currentPeriod}
          isEditable={isEditable}
          onSave={handleSave}
        />
      ) : (
        <ForecastTrainingPerYear
          rowsHeaders={[...rowsHeaders]}
          trainingList={trainingList}
          currentPeriod={currentPeriod!}
          isGrandTotal={isGrandTotal}
          isEditable={isEditable}
          onSave={handleSave}
        />
      )}
      <CommentCard
        id={forecastId}
        sectionType={SectionType.Training}
        status={forecastStatus}
      />
    </Col>
  );
};
