import { Alert, PageHeader, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router";

import * as migrationsApi from '../api/migrationsApi';
import { MigrationType, MigrationUploadVariables } from "../types/MigrationTypes";
import { MigrationUploadForm } from "../components/MigrationUploadForm";
import { MigrationsErrorList } from "../components/MigrationsErrorList";
import { AxiosError } from "axios";
import { useState } from "react";

export const MigrationUploadPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState<Array<{ error: string }>>([]);

  const uploadMigrationMutation = useMutation<unknown, AxiosError, MigrationUploadVariables>({
    mutationFn: ({ migrationType, formData }) => {
      setErrors([]);
      return migrationsApi.postMigration(migrationType, formData);
    },
    onError: (error) => {
      if (error.response) {
        const errorListString: string = error.response.data.message;

        const errorList = errorListString.split("\n").map(error => ({ error }));
        setErrors(errorList);
      }
    },
    onSuccess: () => {
      history.push("/admin/migrations");
    },
    retry: false
  })

  const handleFileUpload = (migrationType: MigrationType, formData: FormData) => {
    uploadMigrationMutation.mutate({ migrationType, formData })
  }

  const handleBackClick = () => {
    history.goBack();
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <PageHeader title={t("migrations.uploadMigration")} onBack={handleBackClick} />

      <Alert
        type="warning"
        showIcon
        message={t("migrations.uploadLimitAlertTitle")}
        description={t("migrations.uploadLimitAlertDescription")}
      />

      <MigrationUploadForm
        onFileUpload={handleFileUpload}
        isLoading={uploadMigrationMutation.isLoading}
      />

      {errors?.length > 0 && (
        <MigrationsErrorList errors={errors} />
      )}
    </Space>
  )
}