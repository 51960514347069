export const enPermissions = {
  permissions: 'Permissions',

  1: 'Member view',
  2: 'Member edit',

  3: 'Contractor view',
  4: 'Contractor edit',

  5: 'Contract view',
  6: 'Contract edit',
  7: 'Exchange rate view',
  8: 'Exchange rate edit',
  9: 'Dashboards edit',
  10: 'Reports download dumps',
  11: 'Migration edit',
  12: 'Currency country city edit',

  // Displayed permissions

  memberView: 'Members - view',
  memberEdit: 'Members - edit',

  contractorView: 'Contractor - view',
  contractorEdit: 'Contractor - edit',

  contractView: 'Contract - view',
  contractEdit: 'Contract - edit',

  exchangeRateView: 'Exchange rate - view',
  exchangeRateEdit: 'Exchange rate - edit',
  dashboardsEdit: ' Dashboards - edit',

  reportDownloadDump: 'Reports - download dumps',
  migrationEdit: 'Migration - edit',
  currencyCountryCityEdit: 'Currency, country, city - edit',
};
