import { adminApi } from '@api/adminApi';
import { ADMIN_PERMISSION_OPTION_LIST } from '@components/constants/AdminPermissions';
import type { AppNotification } from '@components/types/models/AppNotification';
import { useDefaultQuery } from '@hooks';
import { Button, Card, Checkbox, Col, Form, Input, notification, Row, Typography } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';

import { KcCategoriesCheckboxGroup } from './KcCategoriesCheckboxGroup';
import { PermissionCheckboxGroup } from './PermissionCheckboxGroup';

type QuizParams = {
  readonly id: string | undefined;
};

export const EditMember: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams<QuizParams>();

  const [kcCategories, setKcCategories] = useState([]);
  const kcCategoryIds = kcCategories.filter((category) => category !== 0);

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      position: '',
      phoneNumber: '',
      permissions: [],
      notificationIds: [],
    },
  });

  const { Title } = Typography;

  const { data: adminMember } = useDefaultQuery('getAdminMember', async () =>
    adminApi.getAdminMember(Number(id)).then((res) => res.data)
  );

  // TODO replace with API call
  const notifications = [
    {
      id: 1,
      nameEn: 'Actual review expired ',
      nameRu: 'Время рассмотрения отчета истекло',
    },
    {
      id: 2,
      nameEn: 'Forecast review expired ',
      nameRu: 'Время рассмотрения прогноза истекло',
    },
    {
      id: 3,
      nameEn: 'Contact Amendment Approved',
      nameRu: 'Правка контракта одобрена',
    },
  ];

  useEffect(() => {
    if (adminMember) {
      setValue('firstName', adminMember.firstName);
      setValue('lastName', adminMember.lastName);
      setValue('position', adminMember.position);
      setValue('phoneNumber', adminMember.phoneNumber);
      setValue('permissions', adminMember.permissions);
      setKcCategories(adminMember.kcCategories?.map((item: { readonly id: number }) => item.id));
      setValue(
        'notificationIds',
        adminMember.notifications?.map((item: { readonly id: number }) => item.id)
      );
    } else {
      reset();
    }
  }, [adminMember, reset, setValue]);

  const mutation = useMutation(
    async (values: unknown) => axios.post(`/api/admin/member/${id}/update`, values),
    {
      onSuccess: () => {
        notification.success({
          message: t('members.successUpdate'),
        });
        history.replace('/admin/member');
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        errData.validationErrors.forEach(
          (error: {
            readonly name: 'firstName' | 'lastName' | 'permissions' | 'position';
            readonly description: string;
          }): void => {
            setError(error.name, { message: error.description });
          }
        );
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values): void => {
      mutation.mutate({ ...values, kcCategoryIds });
    })();
  }, [handleSubmit, clearErrors, mutation, kcCategoryIds]);

  const handleInputChange = useCallback(
    (name) => (e: React.FormEvent<HTMLInputElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );
  const handleCheckboxChange = useCallback(
    (name) => (value: unknown) => {
      setValue(name, value);
    },
    [setValue]
  );

  return (
    <Row align="middle" justify="center" style={{ width: '100%' }}>
      <Col span={18}>
        <Card title={t('members.editMember')}>
          <Form onFinish={handleFormSubmit}>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  validateStatus={errors.firstName?.message && 'error'}
                  help={errors.firstName?.message}
                >
                  <Input
                    placeholder={t('members.firstName')}
                    value={watch('firstName')}
                    onChange={handleInputChange('firstName')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  validateStatus={errors.lastName?.message && 'error'}
                  help={errors.lastName?.message}
                >
                  <Input
                    placeholder={t('members.lastName')}
                    value={watch('lastName')}
                    onChange={handleInputChange('lastName')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  validateStatus={errors.position?.message && 'error'}
                  help={errors.position?.message}
                >
                  <Input
                    value={watch('position')}
                    placeholder={t('members.jobTitle')}
                    onChange={handleInputChange('position')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Title level={4}>{t('members.accessLevel')}</Title>
            <PermissionCheckboxGroup
              options={ADMIN_PERMISSION_OPTION_LIST}
              value={watch('permissions')}
              onChange={handleCheckboxChange('permissions')}
            />
            <Title level={4}>{t('members.notifications')}</Title>

            <Checkbox.Group
              style={{ marginBottom: '24px' }}
              value={watch('notificationIds')}
              onChange={handleCheckboxChange('notificationIds')}
            >
              <Row style={{ rowGap: '8px' }}>
                {notifications.map((i: AppNotification) => (
                  <Col key={i.id} xs={12}>
                    <Checkbox value={i.id}>{i18n.language === 'en' ? i.nameEn : i.nameRu}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
            <Title level={4}>{t('members.categoryAndArea')}</Title>
            <KcCategoriesCheckboxGroup value={kcCategories} setValue={setKcCategories} />
            <Form.Item>
              <Row justify="end" align="middle">
                <Link to="/admin/member">{t('members.back')}</Link>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ marginLeft: '24px' }}
                  loading={mutation.isLoading}
                >
                  {t('save')}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
