/* eslint-disable react-hooks/exhaustive-deps */

import { grey, red } from '@ant-design/colors';
import { EllipsisOutlined } from '@ant-design/icons';
import type { ForecastJobsDataItem } from '@components/organisms/ForecastJobsForm/props';
import type {
  MouCategoryList,
  NationalizeListItem,
} from '@components/organisms/JobsTraining/types';
import { useActualForecast } from '@hooks/useActualForecast';
import i18n from '@i18n/i18n';
import { css } from '@linaria/core';
import { capitalizeText, unCapitalizeText } from '@utils/helper';
import { Button, Col, Input, message, notification, Row, Typography } from 'antd';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type {
  ActualJobsDataForm,
  ActualJobsDataItem,
  ActualJobsEditFormProps,
  MouCategorySubCategories,
} from './props';

const defaultActual = {
  kzDemobilized: 0,
  kzMobilized: 0,
  kzNationals: 0,
  nationalizationPositions: [],
  newGradInternships: 0,
  newGradJobs: 0,
  nonKzDemobilized: 0,
  nonKzMobilized: 0,
  nonKzNationals: 0,
  plannedToNationalize: 0,
  mouCategory: 0,
  cityId: 0,
};

const defaultValue = {
  engineering: { ...defaultActual },
  supervisorAndForeman: { ...defaultActual },
  administration: { ...defaultActual },
  craft: { ...defaultActual },
  heavyEquipmentOperator: { ...defaultActual },
  total: { ...defaultActual },
};

export const ActualJobsEditForm: FC<ActualJobsEditFormProps> = ({
  period,
  forecastData,
  isEditable,
  jobsData,
  currentCityId,
  addJobsDataMutation,
  addNationalizeDataMutation,
  isTotalMode,
  onOpenNationalizedModal,
  children,
  switchSection,
}) => {
  const greyIndex = 10;
  const { t } = useTranslation();
  const { Text } = Typography;
  const { graduateSection, jobsPositions, mouCategories } = useActualForecast({
    isTotal: isTotalMode,
    isActual: true,
  });
  const [actualJobsForm, setActualJobsForm] = useState<ActualJobsDataForm>(defaultValue);
  const [totalJobsForm, setTotalJobsForm] = useState<ActualJobsDataForm | undefined>();
  const [errors, setErrors] = useState<readonly string[]>([]);

  const tableRowHeaders = [
    {
      title: t('actuals.jobsPositions'),
      rowHeaders: jobsPositions,
    },
    {
      title: t('actuals.graduateSection'),
      rowHeaders: graduateSection,
    },
  ];

  const updatedMouCategories = useMemo(
    () =>
      isTotalMode
        ? mouCategories
        : mouCategories.filter((_, index: number) => mouCategories.length - 1 !== index),
    [isTotalMode, mouCategories]
  );

  const setJobsData = (): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputJobsData: any = {};

    mouCategories.map((mouCategory) => {
      const currentJobData = jobsData.records.find(
        (item) => item.mouCategory === mouCategory.value && item.cityId === currentCityId
      );

      outputJobsData[mouCategory.title] = {
        ...outputJobsData[mouCategory.title],
        mouCategory: mouCategory.value,
        kzDemobilized:
          typeof currentJobData?.kzDemobilized === 'number' ? currentJobData.kzDemobilized : '',
        kzMobilized:
          typeof currentJobData?.kzMobilized === 'number' ? currentJobData.kzMobilized : '',
        kzNationals:
          typeof currentJobData?.kzNationals === 'number' ? currentJobData.kzNationals : '',
        nationalizationPositions: currentJobData?.nationalizationPositions
          ? currentJobData.nationalizationPositions
          : [],
        newGradInternships:
          typeof currentJobData?.newGradInternships === 'number'
            ? currentJobData.newGradInternships
            : '',
        newGradJobs:
          typeof currentJobData?.newGradJobs === 'number' ? currentJobData.newGradJobs : '',
        nonKzDemobilized:
          typeof currentJobData?.nonKzDemobilized === 'number'
            ? currentJobData.nonKzDemobilized
            : '',
        nonKzMobilized:
          typeof currentJobData?.nonKzMobilized === 'number' ? currentJobData.nonKzMobilized : '',
        nonKzNationals:
          typeof currentJobData?.nonKzNationals === 'number' ? currentJobData.nonKzNationals : '',
      };

      return mouCategory;
    });

    setActualJobsForm(outputJobsData);
  };

  const setTotalJobsData = (): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const totalJobsData: any = {};

    mouCategories.map((mouCategory) => {
      const currentJobData = jobsData.records.filter(
        (item) => item.mouCategory === mouCategory.value
      );

      totalJobsData[mouCategory.title] = {
        ...totalJobsData[mouCategory.title],
        mouCategory: mouCategory.value,
        kzDemobilized: currentJobData.reduce((sum, item) => sum + Number(item.kzDemobilized), 0),
        kzMobilized: currentJobData.reduce((sum, item) => sum + Number(item.kzMobilized), 0),
        kzNationals: currentJobData.reduce((sum, item) => sum + Number(item.kzNationals), 0),
        nationalizationPositions: currentJobData.reduce(
          (sum, item) => sum + Number(item.nationalizationPositions.length),
          0
        ),
        newGradInternships: currentJobData.reduce(
          (sum, item) => sum + Number(item.newGradInternships),
          0
        ),
        newGradJobs: currentJobData.reduce((sum, item) => sum + Number(item.newGradJobs), 0),
        nonKzDemobilized: currentJobData.reduce(
          (sum, item) => sum + Number(item.nonKzDemobilized),
          0
        ),
        nonKzMobilized: currentJobData.reduce((sum, item) => sum + Number(item.nonKzMobilized), 0),
        nonKzNationals: currentJobData.reduce((sum, item) => sum + Number(item.nonKzNationals), 0),
      };

      return mouCategory;
    });

    setTotalJobsForm(totalJobsData);
  };

  const handleSaveJobsData = (): void => {
    if (isTotalMode) {
      switchSection();
      return;
    }

    if (!isEditable) {
      return;
    }

    const outputJobsForm = JSON.parse(JSON.stringify(Object.values(actualJobsForm)));
    const filteredJobsPosition = jobsPositions.filter((section) => section.title !== 'total');

    const rowHeaders = [...filteredJobsPosition, ...graduateSection];

    rowHeaders.map((field) => {
      mouCategories.map((mouCategory) => {
        const mouCategoryIndex = mouCategory.value - 1;

        if (mouCategory.title === 'total') {
          outputJobsForm[mouCategoryIndex] = global.undefined;

          return false;
        }

        if (field.title === 'nationalizationPositions') {
          // eslint-disable-next-line no-undefined
          outputJobsForm[mouCategoryIndex][field.title] = undefined;
          return false;
        }

        if (outputJobsForm[mouCategoryIndex][field.title] === '') {
          outputJobsForm[mouCategoryIndex][field.title] = null;
        } else {
          outputJobsForm[mouCategoryIndex][field.title] = Number(
            outputJobsForm[mouCategoryIndex][field.title]
          );
        }

        return mouCategory;
      });
      return field;
    });

    addJobsDataMutation.mutate(outputJobsForm.filter((i: ActualJobsDataItem) => Boolean(i)));
  };

  const calculateKzNationalsTotal = useCallback(
    (mouCategory: MouCategoryList): number => {
      const mouCategoryItem = isTotalMode
        ? totalJobsForm?.[mouCategory]
        : actualJobsForm[mouCategory];

      return Number(mouCategoryItem?.kzNationals) + Number(mouCategoryItem?.nonKzNationals);
    },
    [actualJobsForm, totalJobsForm]
  );

  const setJobsValue = (
    mouCategory: MouCategoryList,
    categoryTitle: string,
    value: number | string
  ): void => {
    setActualJobsForm((prevState) => ({
      ...prevState,
      [mouCategory]: {
        ...prevState[mouCategory],
        [categoryTitle]: value,
      },
    }));
  };

  const saveText = useMemo(() => {
    if (isTotalMode) return t('actuals.goToTheNextSection');

    const currentCityIndex = jobsData.cities.findIndex((city) => city.id === currentCityId);
    const hasNextCity = jobsData.cities.includes(jobsData.cities[currentCityIndex + 1]);

    if (hasNextCity) {
      return t('actuals.saveAndGoToTheNextCity');
    }

    return t('actuals.saveAndGoToTotal');
  }, [isTotalMode, currentCityId, i18n.language]);

  const grandTotalPositionsTotal = useMemo(() => {
    if (isTotalMode) {
      const total = mouCategories.reduce((sum, mouCategory) => {
        const currentMouCategoryItem = totalJobsForm?.[mouCategory.title];

        return (
          sum +
          Number(
            Number(currentMouCategoryItem?.kzNationals) +
              Number(currentMouCategoryItem?.nonKzNationals)
          )
        );
      }, 0);

      return total;
    }

    return 0;
  }, [totalJobsForm]);

  const calculateForecastFieldTotal = (headerTitle: MouCategorySubCategories): number => {
    const currentPeriodData = forecastData?.filter(
      (item: ForecastJobsDataItem) => item.year === period.year && item.month === period.month
    );

    return (
      currentPeriodData?.reduce((total: number, item) => total + Number(item[headerTitle]), 0) ?? 0
    );
  };

  const calculateActualFieldTotal = (headerTitle: MouCategorySubCategories): number =>
    Object.values(totalJobsForm ?? {}).reduce(
      (total: number, item: ActualJobsDataItem) => total + Number(item[headerTitle]),
      0
    );

  const getForecastValue = (
    mouCategory: number,
    headerTitle: MouCategorySubCategories | 'total'
  ): number | string => {
    if (forecastData) {
      const currentMouCategoryItem = forecastData.find(
        (item) =>
          item.month === period.month &&
          item.year === period.year &&
          item.mouCategory === mouCategory
      );

      return Number(currentMouCategoryItem?.[headerTitle] ?? 0);
    }
    return 0;
  };

  const handleChangeJobsData = useCallback(
    (mouCategory: MouCategoryList, categoryTitle: string) =>
      (event: React.FormEvent<HTMLInputElement>) => {
        setJobsValue(mouCategory, categoryTitle, event.currentTarget.value);
      },
    [setJobsValue]
  );

  const setNationalizationPositions = (
    mouCategory: number,
    positions: readonly NationalizeListItem[]
  ): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputJobsData: any = JSON.parse(JSON.stringify(actualJobsForm));

    const changeMouCategory = mouCategories.find((item) => item.value === mouCategory);

    if (changeMouCategory) {
      outputJobsData[changeMouCategory.title] = {
        ...outputJobsData[changeMouCategory.title],
        nationalizationPositions: positions,
      };

      setActualJobsForm(outputJobsData);
    }
  };

  useEffect(() => {
    if (isTotalMode) {
      setTotalJobsData();
    } else {
      setJobsData();
    }
  }, [isTotalMode, jobsData, currentCityId]);

  useEffect(() => {
    if (addJobsDataMutation.error) {
      const validationErrors: ReadonlyArray<{ readonly name: string }> =
        addJobsDataMutation.error.response.data.validationErrors ?? [];

      if (validationErrors.length) {
        const [firstError] = validationErrors;
        const mouCategoryIndex = firstError.name.replace(/[^0-9]/gu, '');
        // eslint-disable-next-line prefer-destructuring
        const categoryTitle = firstError.name.split('.')[1];
        const errorMouCategory = mouCategories.find(
          (mouCategory) => mouCategory.value === Number(mouCategoryIndex) + 1
        );

        const errorCategory = [...jobsPositions, ...graduateSection].find(
          (category) => category.title === unCapitalizeText(categoryTitle)
        );

        void message.error(
          t('actuals.jobError', {
            mouCategory: errorMouCategory?.text,
            category: errorCategory?.text,
            period: `${period.month}-${period.year}`,
          })
        );

        setErrors(validationErrors.map((error) => error.name));
      } else {
        notification.error({
          message: addJobsDataMutation.error?.response?.data?.message,
        });
      }
    }
  }, [addJobsDataMutation.error]);

  useEffect(() => {
    if (addJobsDataMutation.isSuccess) {
      setErrors([]);
    }
  }, [addJobsDataMutation.isSuccess]);

  useEffect(() => {
    if (addNationalizeDataMutation.isSuccess) {
      const { mouCategory, positions } = addNationalizeDataMutation.variables;

      setNationalizationPositions(mouCategory, positions);
    }
  }, [addNationalizeDataMutation.isSuccess]);

  return (
    <>
      {tableRowHeaders.map((table) => (
        <Col key={table.title} span={24}>
          <Row
            justify="space-between"
            className={css`
              ${tw`text-center mb-2`}
            `}
          >
            <Col
              className={css`
                ${tw`text-left flex items-center font-bold`}
              `}
              span={4}
            >
              {table.title}
            </Col>
            {updatedMouCategories.map((mouCategory) => (
              <Col key={mouCategory.title} span={3}>
                {mouCategory.text}
              </Col>
            ))}
          </Row>

          {table.rowHeaders.map((category) => (
            <Row
              key={category.text}
              justify="space-between"
              align="middle"
              className={css`
                ${tw`text-center mb-2`}
              `}
            >
              <Col
                className={css`
                  ${tw`text-left`}
                `}
                span={4}
              >
                {category.text}
              </Col>
              {updatedMouCategories.map((mouCategory, index) => (
                <Col key={mouCategory.title} span={3}>
                  {mouCategory.title === 'total' ? (
                    <Col>
                      {category.title === 'total' ? (
                        <Text>{grandTotalPositionsTotal}</Text>
                      ) : (
                        <Text>
                          {category.forecast
                            ? calculateForecastFieldTotal(category.title)
                            : calculateActualFieldTotal(category.title)}
                        </Text>
                      )}
                    </Col>
                  ) : (
                    <Row justify="center">
                      <>
                        {category.title === 'total' && (
                          <Text>{calculateKzNationalsTotal(mouCategory.title)}</Text>
                        )}

                        {category.forecast && (
                          <Text>{getForecastValue(mouCategory.value, category.title)}</Text>
                        )}

                        {category.title !== 'total' &&
                          category.title !== 'nationalizationPositions' &&
                          !category.forecast && (
                            <Input
                              style={{
                                borderColor: errors.includes(
                                  `[${index}].${capitalizeText(category.title)}`
                                )
                                  ? red.primary
                                  : grey[greyIndex],
                              }}
                              type="number"
                              className={css`
                                ${tw`flex-1 flex justify-center text-center items-center`}
                                min-width: 50px;
                                max-width: 50px;
                              `}
                              disabled={!isEditable || isTotalMode}
                              value={
                                isTotalMode
                                  ? totalJobsForm?.[mouCategory.title]?.[category.title]
                                  : actualJobsForm[mouCategory.title][category.title]
                              }
                              onChange={handleChangeJobsData(mouCategory.title, category.title)}
                            />
                          )}

                        {category.title === 'nationalizationPositions' && (
                          <>
                            <Text>
                              {isTotalMode
                                ? totalJobsForm?.[mouCategory.title]?.nationalizationPositions
                                : actualJobsForm[mouCategory.title].nationalizationPositions.length}
                            </Text>

                            {!isTotalMode && (
                              <div
                                className={css`
                                  ${tw`w-6 h-6 text-center bg-blue-500 ml-1 rounded-full cursor-pointer`}
                                `}
                                /* eslint-disable react/jsx-handler-names */
                                onClick={onOpenNationalizedModal(
                                  mouCategory.value,
                                  actualJobsForm[mouCategory.title].nationalizationPositions
                                )}
                              >
                                <EllipsisOutlined
                                  className={css`
                                    ${tw`text-white text-lg`}
                                  `}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    </Row>
                  )}
                </Col>
              ))}
            </Row>
          ))}
        </Col>
      ))}

      {isEditable && (
        <Col span={24}>
          <Row justify="end">
            <Button
              className="secondary-button"
              loading={addJobsDataMutation.isLoading}
              onClick={handleSaveJobsData}
            >
              {saveText}
            </Button>
          </Row>
        </Col>
      )}

      {children}
    </>
  );
};
