import { CountrySelectorTemp } from '@components/atoms/CountrySelectorTemp';
import { Button, Col, Form, Input, message, Modal, Row, Select, Space } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type { CreateCityFormProps } from './props';

export const CreateCityForm: FC<CreateCityFormProps> = ({
  refetchCountriesList,
  countriesList,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const kazakhId = useMemo(
    () => countriesList.filter((country) => country.nameRu === 'Казахстан')[0]?.id,
    [countriesList]
  );
  const { Option } = Select;
  const {
    handleSubmit,
    setError,
    register,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const handleOpenModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    reset();
    setIsModalVisible(false);
  }, [reset]);

  const handleCountrySelectorChange = useCallback(
    (value: number) => {
      setValue('countryId', value);
    },
    [setValue]
  );

  const handleRegionChange = useCallback(
    (value: string) => {
      setValue('regionNameEn', value);
    },
    [setValue]
  );

  const addCityMutation = useMutation(
    async (values: unknown) => axios.post(`/api/city`, values).then((res) => res.data),
    {
      onSuccess: () => {
        void message.success(t('countriesAndCities.cityWasSuccessfullyAdded'));
        refetchCountriesList();
        handleCloseModal();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: { readonly name: string; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('error', { message: errData.message });
        }
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    void handleSubmit((values: { readonly comment: string }) => {
      addCityMutation.mutate(values);
    })();
  }, [handleSubmit, addCityMutation]);

  return (
    <>
      <Button type="primary" onClick={handleOpenModal}>
        {t('countriesAndCities.addNewCity')}
      </Button>
      <Modal
        title={t('countriesAndCities.addNewCity')}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical" onFinish={handleFormSubmit}>
          <Form.Item
            label={t('country')}
            validateStatus={errors.countryId?.message && 'error'}
            help={errors.countryId?.message}
          >
            <CountrySelectorTemp
              countries={countriesList}
              selectedCountry={watch('countryId')}
              onChange={handleCountrySelectorChange}
            />
          </Form.Item>
          {watch('countryId') === kazakhId && (
            <Form.Item
              label={t('reports.region')}
              validateStatus={errors.regionNameEn?.message && 'error'}
              help={errors.regionNameEn?.message}
            >
              <Select
                showSearch
                value={watch('regionNameEn')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleRegionChange}
              >
                {countriesList
                  .filter((country) => country.id === kazakhId)[0]
                  ?.cities?.map((city) => (
                    <Option key={city.id} value={city.regionNameEn}>
                      {city.regionNameEn}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label={t('countriesAndCities.enName')}
            validateStatus={errors.nameEn?.message && 'error'}
            help={errors.nameEn?.message}
          >
            <Input {...register('nameEn')} />
          </Form.Item>
          <Form.Item
            label={t('countriesAndCities.ruName')}
            validateStatus={errors.nameRu?.message && 'error'}
            help={errors.nameRu?.message}
          >
            <Input {...register('nameRu')} />
          </Form.Item>
          <Row justify="end">
            <Col>
              <Space align="end">
                <Button htmlType="button" onClick={handleCloseModal}>
                  {t('cancel')}
                </Button>
                <Button htmlType="submit" type="primary" loading={addCityMutation.isLoading}>
                  {t('add')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
