import { contractApi } from '@api/contractApi';
import { Amendment } from '@components/organisms/Amendment';
import { NewContractForm } from '@components/organisms/NewContractForm';
import { SubAgreementsTable } from '@components/organisms/SubAgreementsTable';
import { UpdateContractForm } from '@components/organisms/UpdateContractForm';
import type { Contract as ContractType } from '@components/types/models/Contract';
import { CONTRACT_TYPE, defineContractType } from '@components/types/models/Contract';
import { defineStatus, STATUS } from '@components/types/models/Statuses';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import { Col, Row, Select, Skeleton, Typography } from 'antd';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

const { Title, Text } = Typography;

export const ContractView: FC = () => {
  const { id } = useParams<{ readonly id?: string }>();
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const defaultVersionOptionValue =
    i18n.language === 'en' ? 'Version 1 - DRAFT' : 'Версия 1 - ЧЕРНОВИК';

  const { data: contractData, refetch: refetchContractData } = useDefaultQuery<ContractType>(
    'getContract',
    async () => contractApi.getContract(Number(id)).then((res) => res.data)
  );
  const { data: currencyList } = useDefaultQuery('getCurrencyList', async () =>
    contractApi.getCurrencyList().then((res) => res.data)
  );

  const { data: amendments, refetch: refetchAmendments } = useDefaultQuery(
    'getContractAmendment',
    async () => contractApi.getContractAmendment(Number(id)).then((res) => res.data)
  );

  const { data: subAgreements, refetch: refetchSubAgreements } = useDefaultQuery(
    'getSubAgreements',
    async () => contractApi.getSubAgreements(Number(id)).then((res) => res.data)
  );

  const [amendmentVersion, setAmendmentVersion] = useState<number | null | undefined>(null);

  const handleVersionSelectChange = (value: string): void => {
    setAmendmentVersion(parseInt(value));
  };

  const isAddAmendmentButtonVisible = useMemo(
    () => amendments?.length === amendmentVersion || amendments?.length === 0,
    [amendments, amendmentVersion]
  );

  useEffect(() => {
    setAmendmentVersion(contractData?.version);
  }, [contractData]);

  useEffect(() => {
    void refetchContractData();
    void refetchAmendments();
  }, [id, refetchAmendments, refetchContractData]);

  const getContractVersionTitle = (value?: {
    readonly version?: number | string;
    readonly contractStatus: STATUS;
  }): string =>
    value
      ? `${t('contracts.version')} ${value.version} - ${
          i18n.language === 'en'
            ? defineStatus(value.contractStatus).enName
            : defineStatus(value.contractStatus).ruName
        }`
      : '';

  const refetchAll = useCallback(() => {
    void refetchContractData();
    void refetchAmendments();
  }, [refetchContractData, refetchAmendments]);

  return (
    <div>
      {contractData ? (
        <>
          <Title level={2}>{contractData.contractNumber}</Title>
          <div>
            <Text
              className={css`
                ${tw`inline text-xl`}
              `}
            >
              {t('contracts.contractTypeColon')}
            </Text>
            <Title
              level={3}
              className={css`
                ${tw`inline`}
              `}
            >
              {i18n.language === 'en'
                ? defineContractType(contractData.contractType).enName
                : defineContractType(contractData.contractType).ruName}
            </Title>
          </div>

          <div>
            <Text
              className={css`
                ${tw`inline text-xl`}
              `}
            >
              {t('contracts.kcCategoryAndAreaColon')}
            </Text>
            <Title
              level={3}
              className={css`
                ${tw`inline`}
              `}
            >
              {contractData.kcCategory.name}
            </Title>
          </div>
          {contractData.contractType === CONTRACT_TYPE.SUB_AGREEMENT && (
            <div>
              <Text
                className={css`
                  ${tw`inline text-xl`}
                `}
              >
                {t('contracts.masterAgreementColon')}
              </Text>
              <Title
                level={3}
                className={css`
                  ${tw`inline`}
                `}
              >
                {contractData.masterAgreement?.contractNumber}
              </Title>
            </div>
          )}
        </>
      ) : (
        <Skeleton active />
      )}
      {amendmentVersion && (
        <Row
          className={css`
            ${tw`my-8`}
          `}
        >
          <Col span={12}>
            <Select
              style={{ width: 'max-content' }}
              placeholder={getContractVersionTitle(contractData)}
              onChange={handleVersionSelectChange}
            >
              {amendments && amendments.length > 0 ? (
                amendments.map(
                  (
                    amendment: { readonly version: number; readonly contractStatus: number },
                    index: number
                  ) => (
                    <Option key={index} value={`${amendment.version}`}>
                      {getContractVersionTitle(amendment)}
                    </Option>
                  )
                )
              ) : (
                <Option value={1}> {defaultVersionOptionValue}</Option>
              )}
            </Select>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {(contractData?.contractStatus === STATUS.DRAFT ||
              contractData?.contractStatus === STATUS.REJECTED ||
              contractData?.contractStatus === STATUS.APPROVED) &&
              isAddAmendmentButtonVisible && (
                <UpdateContractForm
                  contract={contractData}
                  currencyList={currencyList}
                  refetch={refetchAll}
                  amendment={amendments?.[amendmentVersion - 1]}
                />
              )}
          </Col>
        </Row>
      )}
      {amendments?.length > 0 && amendmentVersion && (
        <Amendment amendment={amendments[amendmentVersion - 1]} />
      )}
      {contractData?.contractType === CONTRACT_TYPE.MASTER_AGREEMENT && (
        <>
          <Row justify="space-between" align="middle">
            <Title level={3}>{t('contracts.subAgreements')}</Title>
            <NewContractForm
              contractType={3}
              refetchContractsList={refetchSubAgreements}
              contractorId={contractData.contractorId}
              masterAgreementId={contractData.id}
            />
          </Row>
          <SubAgreementsTable
            subAgreementsList={subAgreements}
            linkToContract="/contractor/contract/"
          />
        </>
      )}
    </div>
  );
};
