import { defineRelationType } from '@components/constants/ContractualRelations';
import { AppTable } from '@components/molecules/AppTable';
import { css } from '@linaria/core';
import { Table } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { SubcontractorsTableProps } from './props';

export const SubcontractorsTable: FC<SubcontractorsTableProps> = ({
  subcontractorsList,
  isLoading,
  total,
  pagination,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const { Column } = Table;

  return (
    <AppTable
      className={css`
        ${tw`mt-8`}
      `}
      isLoading={isLoading}
      dataSource={subcontractorsList}
      subtitle={`${t('members.subcontractorsSum')}: ${total}`}
      pagination={pagination}
      {...rest}
    >
      <Column title={t('profile.name')} dataIndex="name" render={(name) => name ?? '-'} />
      <Column title={t('bin')} dataIndex="bin" render={(bin) => bin ?? '-'} />
      <Column
        title={t('country')}
        dataIndex="country"
        render={(country) => (i18n.language === 'en' ? country?.nameEn : country?.nameRu) ?? '-'}
      />
      <Column
        title={t('city')}
        dataIndex="city"
        render={(city) => (i18n.language === 'en' ? city?.nameEn : city?.nameRu) ?? '-'}
      />
      <Column
        title={t('contractors.details')}
        dataIndex="detailOnScopeOfWork"
        render={(detailOnScopeOfWork) => detailOnScopeOfWork ?? '-'}
      />
      <Column
        title={t('forecasts.contractualRelationship')}
        dataIndex="contractualRelations"
        render={(contractualRelation) => {
          if (contractualRelation) {
            return i18n.language === 'en'
              ? defineRelationType(contractualRelation?.relationType).enName
              : defineRelationType(contractualRelation?.relationType).ruName;
          }
          return '-';
        }}
      />
    </AppTable>
  );
};
