import { Select } from 'antd';
import React from 'react';

import type { LanguageSelectorProps } from './props';

export const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
  const { Option } = Select;

  return (
    <Select
      value={props.currentLangCode}
      data-whitetext={props.whiteText}
      bordered={false}
      className={props.className}
      onSelect={(value) => props.onLangSwitch(value)}
    >
      {props.langs.map((lang, index) => (
        <Option key={index} value={lang.code}>
          {props.withoutFlags}
          <span> {props.withoutFlags ? lang.title : lang.shortTitle} </span>
        </Option>
      ))}
    </Select>
  );
};
