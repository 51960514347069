import { exchangeRateApi } from '@api/exchangeRateApi';
import type { Currency } from '@components/types/models/Currency';
import type {
  ExchangeRateType,
  UpdateExchangeRatePayloadType,
  UpdateExchangeRateValidationErrorsType,
} from '@components/types/models/ExchangeRates';
import { useModalState } from '@hooks';
import { Button, Modal, notification } from 'antd';
import type { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { EditExchangeRatesForm } from './EditExchangeRatesForm';

type EditExchangeRatesModalProps = {
  readonly currencies: Currency[];
  readonly onSuccess: () => void;
  readonly onPeriodChange: (period: string) => void;
  readonly exchangeRates: readonly ExchangeRateType[];
  readonly exchangeRatesLoading: boolean;
  readonly period: string;
};

export const EditExchangeRatesModal: React.FC<EditExchangeRatesModalProps> = ({
  currencies,
  onSuccess,
  period,
  onPeriodChange: handlePeriodChange,
  exchangeRates,
  exchangeRatesLoading,
}) => {
  const { t } = useTranslation();

  const editModalState = useModalState();
  const handleOpenEditModal = (): void => {
    editModalState.handleOpen(true);
  };

  const [updateExchangeRatesValidationErrors, setUpdateExchangaRatesValidationErrors] =
    useState<UpdateExchangeRateValidationErrorsType[]>();
  const updateExchangeRatesMutation = useMutation('updateExchangeRates', {
    mutationFn: async (paylod: UpdateExchangeRatePayloadType) =>
      exchangeRateApi.updateExchangeRates(paylod),
    onSuccess: () => {
      notification.success({ message: t('exchangeRates.updateExchangeRatesSuccess') });
      editModalState.handleClose();
      onSuccess();
    },
    onError: (error: AxiosError) => {
      if (error.response?.data.validationErrors) {
        setUpdateExchangaRatesValidationErrors(error.response.data.validationErrors);
      } else {
        notification.error({ message: t('exchangeRates.updateExchangeRatesError') });
      }
    },
  });
  const handleUpdateExchangeRatesSubmit = (payload: UpdateExchangeRatePayloadType): void => {
    updateExchangeRatesMutation.mutate(payload);
    setUpdateExchangaRatesValidationErrors([]);
  };

  return (
    <>
      <Button key="header-update-rates-button" type="primary" onClick={handleOpenEditModal}>
        {t('update')}
      </Button>

      <Modal
        destroyOnClose
        visible={editModalState.isOpen}
        maskClosable={false}
        title={t('exchangeRates.updateMonth')}
        footer={null}
        width={800}
        onCancel={editModalState.handleClose}
      >
        <EditExchangeRatesForm
          loading={updateExchangeRatesMutation.isLoading}
          currencies={currencies}
          errors={updateExchangeRatesValidationErrors}
          period={period}
          exchangeRates={exchangeRates}
          exchangeRatesLoading={exchangeRatesLoading}
          onSubmit={handleUpdateExchangeRatesSubmit}
          onCancel={editModalState.handleClose}
          onPeriodChange={handlePeriodChange}
        />
      </Modal>
    </>
  );
};
