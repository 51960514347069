import { ArrowUpOutlined } from '@ant-design/icons';
import { defineContractType } from '@components/types/models/Contract';
import type { ForecastType } from '@components/types/models/Forecast';
import { ForecastSorting } from '@components/types/models/Forecast';
import { defineStatus } from '@components/types/models/Statuses';
import { useSort } from '@hooks/useSort';
import { css } from '@linaria/core';
import { Row, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import type { ForecastsTableProps } from './props';

const { Text } = Typography;

const sortList = [
  {
    title: 'contractor',
    asc: ForecastSorting.ByContractorNameAsc,
    desc: ForecastSorting.ByContractorNameDesc,
  },
  {
    title: 'submittedBy',
    asc: ForecastSorting.ByForecastSubmissionDateAsc,
    desc: ForecastSorting.ByForecastSubmissionDateDesc,
  },
];

export const ForecastsTable: FC<ForecastsTableProps> = ({
  forecastsList,
  isLoading,
  pagination,
  sort,
  onSetSort,
}) => {
  const { t, i18n } = useTranslation();

  const { handleSort, rotate } = useSort({ sortList, defaultSortValue: sort });

  const handleChange = useCallback(
    (title: string) => (_: unknown) => {
      const outputSortValue = handleSort(title);
      onSetSort?.(outputSortValue);
    },
    [onSetSort, handleSort]
  );

  const columns = [
    {
      title: t('contracts.contractNumber'),
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      render: function linkToForecastView(
        contractNumber: string,
        forecast: ForecastType
      ) {
        return (
          <Link to={`/admin/forecast/${forecast.id}`}>{contractNumber}</Link>
        );
      },
    },
    {
      title: function getContractorTitle() {
        return (
          <Row
            className={css`
              ${tw`cursor-pointer`}
            `}
            justify="space-between"
            align="middle"
            onClick={handleChange('contractor')}
          >
            <Text>{t('contractor')}</Text>
            {sort &&
              [
                ForecastSorting.ByContractorNameAsc,
                ForecastSorting.ByContractorNameDesc,
              ].includes(sort) && (
                <ArrowUpOutlined
                  style={{
                    transition: 'transform 0.3s linear',
                    transform: `rotate(${rotate}deg)`,
                  }}
                />
              )}
          </Row>
        );
      },
      dataIndex: 'createdBy',
      key: 'contractor',
      render: function getContractor(_: unknown, forecast: ForecastType) {
        return (
          <Text>
            {forecast?.contractorName ? forecast?.contractorName : '-'}
          </Text>
        );
      },
    },
    {
      title: t('contracts.type'),
      dataIndex: 'contractType',
      key: 'contractType',
      render: function getContractType(contractType: number) {
        return (
          <Text>
            {i18n.language === 'en'
              ? defineContractType(contractType).enName
              : defineContractType(contractType).ruName}
          </Text>
        );
      },
    },
    {
      title: t('contracts.kcCategoryAndArea'),
      dataIndex: 'kcCategory',
      key: 'kcCategory',
      render: function getKcCategoryAndArea(
        _: unknown,
        forecast: ForecastType
      ) {
        return <Text>{forecast?.kcCategory?.name}</Text>;
      },
    },
    {
      title: t('contracts.version'),
      dataIndex: 'forecastVersion',
      key: 'forecastVersion',
      render: function getContractVersion(forecastVersion?: number) {
        return <Text>{forecastVersion ?? '-'}</Text>;
      },
    },
    {
      title: t('contracts.status'),
      dataIndex: 'approvalStatus',
      key: 'forecastStatus',
      render: function getContractStatus(forecastsStatus: number) {
        const status = defineStatus(forecastsStatus);
        return (
          <Text>
            {forecastsStatus ? (
              <Tag color={status.color}>
                {i18n.language === 'en' ? status.enName : status.ruName}
              </Tag>
            ) : (
              '-'
            )}
          </Text>
        );
      },
    },
    {
      title: function getSubmissionTitle() {
        return (
          <Row
            className={css`
              ${tw`cursor-pointer`}
            `}
            justify="space-between"
            align="middle"
            onClick={handleChange('submittedBy')}
          >
            <Text strong>{t('submissionDate')}</Text>
            {sort &&
              [
                ForecastSorting.ByForecastSubmissionDateAsc,
                ForecastSorting.ByForecastSubmissionDateDesc,
              ].includes(sort) && (
                <ArrowUpOutlined
                  style={{
                    transition: 'transform 0.3s linear',
                    transform: `rotate(${rotate}deg)`,
                  }}
                />
              )}
          </Row>
        );
      },
      key: 'submittedBy',
      render: function getSubmissionDate(_: unknown, forecast: ForecastType) {
        return (
          <Text>
            {forecast?.submittedAt
              ? moment(forecast.submittedAt).format('YYYY-MM-DD HH:mm:ss')
              : '-'}
          </Text>
        );
      },
    },
  ];

  return (
    <Table
      locale={{ emptyText: t('noData') }}
      columns={columns}
      loading={isLoading}
      dataSource={forecastsList}
      pagination={pagination}
    />
  );
};
