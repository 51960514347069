/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import type {
  ForecastTrainingData,
  ForecastTrainingDataItem,
  MouCategoryItem,
} from '@components/organisms/ForecastTrainingForm/props';
import i18n from '@i18n/i18n';
import { css } from '@linaria/core';
import { truncateText } from '@utils/truncate';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { ForecastTrainingPerYearProps } from './props';

export const ForecastTrainingPerYear: FC<ForecastTrainingPerYearProps> = ({
  currentPeriod,
  rowsHeaders = [],
  trainingList,
  onSave,
  isGrandTotal,
  isEditable,
}) => {
  const { t } = useTranslation();
  const [yearsList, setYearsList] = useState<readonly number[]>([]);
  const [yearsTrainingData, setYearsTrainingData] = useState<ForecastTrainingData>({});
  const sliceCount = 20;

  const setTrainingData = (years: readonly number[]): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputTrainingData: any = {};

    years.map((year: number) => {
      if (isGrandTotal) {
        const currentYearData = trainingList?.filter(
          (item: ForecastTrainingDataItem) => item.year === year
        );

        outputTrainingData[year] = {
          year,
          engineering: currentYearData?.reduce(
            (sum: number, item: ForecastTrainingDataItem) => sum + Number(item.engineering),
            0
          ),
          supervisorAndForeman: currentYearData?.reduce(
            (sum: number, item: ForecastTrainingDataItem) =>
              sum + Number(item.supervisorAndForeman),
            0
          ),
          administration: currentYearData?.reduce(
            (sum: number, item: ForecastTrainingDataItem) => sum + Number(item.administration),
            0
          ),
          craft: currentYearData?.reduce(
            (sum: number, item: ForecastTrainingDataItem) => sum + Number(item.craft),
            0
          ),
          heavyEquipmentOperator: currentYearData?.reduce(
            (sum: number, item: ForecastTrainingDataItem) =>
              sum + Number(item.heavyEquipmentOperator),
            0
          ),
        };
      } else {
        const initialData = trainingList?.find(
          (item: ForecastTrainingDataItem) => item.year === year
        );

        outputTrainingData[year] = {
          year,
          engineering: typeof initialData?.engineering === 'number' ? initialData?.engineering : '',
          supervisorAndForeman:
            typeof initialData?.supervisorAndForeman === 'number'
              ? initialData?.supervisorAndForeman
              : '',
          administration:
            typeof initialData?.administration === 'number' ? initialData?.administration : '',
          craft: typeof initialData?.craft === 'number' ? initialData?.craft : '',
          heavyEquipmentOperator:
            typeof initialData?.heavyEquipmentOperator === 'number'
              ? initialData?.heavyEquipmentOperator
              : '',
        };
      }

      return year;
    });

    setYearsTrainingData(outputTrainingData);
  };

  const getYearTotal = (): { readonly [key in string]: number } => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const total: any = {};

    Object.keys(yearsTrainingData).forEach((year: string) => {
      const currentYear: ForecastTrainingDataItem = yearsTrainingData[year];
      total[year] = rowsHeaders.reduce(
        (sum: number, header: MouCategoryItem) => sum + Number(currentYear[header.title] ?? 0),
        0
      );
    });

    return total;
  };

  const setTrainingValue = ({
    value,
    headerTitle,
    year,
  }: {
    readonly value: string;
    readonly headerTitle: string | undefined;
    readonly year: number;
  }): void => {
    const updatedTrainingData = { ...yearsTrainingData };

    updatedTrainingData[year] = {
      ...updatedTrainingData[year],
      ...(headerTitle ? { [headerTitle]: value } : {}),
    };

    setYearsTrainingData(updatedTrainingData);
  };

  const handleSaveTrainingData = useCallback(
    (payload: ForecastTrainingData) => () => {
      onSave?.(payload);
    },
    [onSave]
  );

  const handleSetTrainingValue = useCallback(
    (payload: { readonly headerTitle: string; readonly year: number }) =>
      (event: React.FormEvent<HTMLInputElement>) => {
        const outputValue = {
          ...payload,
          value: event.currentTarget.value,
        };
        setTrainingValue(outputValue);
      },
    [setTrainingValue]
  );

  const saveText = useMemo(() => {
    if (isGrandTotal || !isEditable) {
      return t('forecasts.proceedAndGoToTheNextSection');
    }

    return t('forecasts.saveAndGoToTheNextSection');
  }, [currentPeriod?.year, i18n.language]);

  useEffect(() => {
    setTrainingData(yearsList);
  }, [yearsList]);

  useEffect(() => {
    if (currentPeriod) {
      const years = isGrandTotal
        ? currentPeriod.years
        : currentPeriod.years.filter((year: number) => year >= currentPeriod.year);
      setYearsList(years);
    }
  }, [currentPeriod, isGrandTotal]);

  return (
    <Col span={24}>
      <Row>
        <Col span={4} />
        <Col span={20}>
          <Row
            className={css`
              ${tw`space-x-6`}
            `}
          >
            {yearsList.map((year: number) => (
              <div
                key={year}
                className={css`
                  ${tw`flex-1 flex justify-center font-bold capitalize`}
                  min-width: 50px;
                  max-width: 50px;
                  color: #9e9e9e;
                `}
              >
                {year}
              </div>
            ))}
          </Row>
        </Col>
      </Row>

      <Row
        className={css`
          ${tw`space-y-2`}
        `}
      >
        {rowsHeaders.map((header: MouCategoryItem, index: number) => (
          <React.Fragment key={index}>
            <Col
              span={4}
              className={css`
                ${tw`flex items-center`}
              `}
            >
              <Tooltip title={header.text}>
                {header.text ? truncateText(header.text, sliceCount) : ''}
              </Tooltip>
            </Col>
            <Col span={20}>
              <Row
                className={css`
                  ${tw`space-x-6`}
                `}
              >
                {yearsList.map((year: number) => (
                  <div
                    key={year}
                    className={css`
                      ${tw`flex-1 flex justify-center items-center`}
                      min-width: 50px;
                      max-width: 50px;
                    `}
                  >
                    {header.title === 'total' ? (
                      <span>{getYearTotal()[year]}</span>
                    ) : (
                      <Input
                        type="number"
                        className={css`
                          ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                          width: 100%;
                          border: 1px solid #e2e2e2;
                        `}
                        disabled={isGrandTotal || !isEditable}
                        value={yearsTrainingData[year]?.[header.title]}
                        onChange={handleSetTrainingValue({
                          headerTitle: header.title || '',
                          year,
                        })}
                      />
                    )}
                  </div>
                ))}
              </Row>
            </Col>
          </React.Fragment>
        ))}
      </Row>
      <Col
        span={24}
        className={css`
          ${tw`mt-4 flex justify-end`}
        `}
      >
        <Button className="secondary-button" onClick={handleSaveTrainingData(yearsTrainingData)}>
          {saveText}
        </Button>
      </Col>
    </Col>
  );
};
