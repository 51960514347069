export const ruLogin = {
  title: 'Авторизация',
  subtitle: 'Войдите чтобы продолжить',
  email: 'Email',
  password: 'Пароль',
  loginButton: 'Войти',
  errorEmail: 'Некорректный email',
  requiredEmail: 'Обязательне поле',
  requiredPassword: 'Обязательное поле',
  contactUs: 'Свяжитесь с нами',
  resetPasswordTitle: 'Сбросить пароль',
  resetPasswordSubtitle: 'Укажите адрес электронной почты',
  resetPasswordSuccess: 'Запрос на сброс пароля отправлен на',
  nextButton: 'Далее',
  forgotPassword: 'Забыли пароль?',
  logoutButton: 'Выйти',
  newPassword: 'Новый пароль',
  confirmPassword: 'Подтвердить пароль',
  confirmInvite: 'Подтвердить приглашение',
  enterNewPassword: 'Введите новый пароль',
  loginViaSso: 'Войти через SSO',
  userGuide: 'Руководство пользователя',
  linkIsExpired: 'Срок действия ссылки истек',
  yourPasswordHasExpired: 'Срок действия вашего пароля истек, его необходимо изменить',
  resendLink: 'Отправить ссылку для подтверждения еще раз',
  passwordIsSaved: 'Пароль сохранен, пожалуйста войдите в портал',
  passwordIsResetSuccessfully: 'Пароль успешно сброшен',
};
