import { EnvironmentOutlined, FlagOutlined, HomeOutlined, PhoneOutlined } from '@ant-design/icons';
import { contractApi } from '@api/contractApi';
import { contractorApi } from '@api/contractorApi';
import { ContractorMembersTable } from '@components/organisms/ContractorMembersTable';
import { ContractsTable } from '@components/organisms/ContractsTable';
import { EditContractorMemberForm } from '@components/organisms/EditContractorMemberForm';
import { InviteContractorMemberForm } from '@components/organisms/InviteContractorMemberForm';
import { NewContractForm } from '@components/organisms/NewContractForm';
import { SubcontractorsTable } from '@components/organisms/SubcontractorsTable';
import type { Contract } from '@components/types/models/Contract';
import { CONTRACT_TYPE } from '@components/types/models/Contract';
import type { Member } from '@components/types/models/Member';
import { PERMISSIONS } from '@components/types/models/Permissions';
import { STATUS } from '@components/types/models/Statuses';
import { UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import { isUserHasPermission } from '@utils/permissionHelper';
import { Card, Col, List, notification, Row, Typography } from 'antd';
import type { AxiosError } from 'axios';
import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

export const Contractor: FC = () => {
  const { id } = useParams<{ readonly id?: string }>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editableMember, setEditableMember] = useState<Member>();
  const { user } = useContext(UserContext);
  const pageSize = 20;
  const [page, setPage] = useState(1);

  const hasContractorEditPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACTOR_EDIT
  );
  const hasContractViewPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACT_VIEW
  );

  const contractsList = useQuery(
    'getContractsList',
    async () => contractApi.getContractsList(Number(id)).then((res) => res.data),
    { enabled: hasContractViewPermission }
  );

  const handleEditClick = useCallback((item?: Member) => {
    setEditableMember(item);
    setIsEditModalOpen((i) => !i);
  }, []);

  const handleCloseEditModal = (): void => {
    setIsEditModalOpen(false);
  };

  const { t } = useTranslation();

  const { isLoading: isContractorLoading, data: contractorData } = useDefaultQuery(
    'getContractorData',
    async () => contractorApi.getContractorData(Number(id)).then((res) => res.data)
  );
  const members = useDefaultQuery('getContractorMembers', async () =>
    contractorApi.getContractorMembers(Number(id)).then((res) => res.data)
  );

  const {
    data: subcontractors,
    isLoading,
    refetch: refetchSubcontractors,
  } = useQuery(
    'getSubcontractors',
    async () =>
      contractorApi.getContractorSubcontractors(page, pageSize, Number(id)).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    void refetchSubcontractors();
  }, [page, refetchSubcontractors]);

  const masterAgreementsList = contractsList.data?.filter(
    (contract: Contract) =>
      contract.contractStatus === STATUS.APPROVED &&
      contract.contractType === CONTRACT_TYPE.MASTER_AGREEMENT
  );

  const { Title } = Typography;

  const deleteMemberMutation = useMutation(
    async (memberId: number) => contractorApi.deleteContractorMember(Number(id), memberId),
    {
      onSuccess() {
        notification.success({ message: t('members.memberDeleted') });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const resendInviteMutation = useMutation(
    async (email: string) => contractorApi.resendInviteMember(Number(id), email),
    {
      onSuccess() {
        notification.success({ message: t('members.memberInviteResent') });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const activateMemberMutation = useMutation(
    async (memberId: number) => contractorApi.activateContractorMember(Number(id), memberId),
    {
      onSuccess() {
        notification.success({ message: t('members.memberActivated') });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const deleteMemberInviteMutation = useMutation(
    async (email: string) => contractorApi.deleteInviteMember(Number(id), email),
    {
      onSuccess() {
        notification.success({ message: t('members.memberInviteDeleted') });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  return (
    <>
      <Card loading={isContractorLoading} title={contractorData?.name}>
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<FlagOutlined />}
              title={contractorData?.country?.nameEn ?? '-'}
              description={t('contractors.country')}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<HomeOutlined />}
              title={contractorData?.city?.nameEn ?? '-'}
              description={t('contractors.city')}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<EnvironmentOutlined />}
              title={contractorData?.address ?? '-'}
              description={t('contractors.address')}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<PhoneOutlined />}
              title={contractorData?.phone ?? '-'}
              description={t('contractors.phoneNumber')}
            />
          </List.Item>
        </List>
      </Card>
      {hasContractViewPermission && (
        <>
          <Row
            className={css`
              ${tw`my-6`}
            `}
          >
            <Col
              span={12}
              className={css`
                ${tw`text-left`}
              `}
            >
              <Title level={3}>{t('contractors.contracts')}</Title>
            </Col>
            <Col
              span={12}
              className={css`
                ${tw`text-right`}
              `}
            >
              <NewContractForm
                contractorId={Number(id)}
                masterAgreementsList={masterAgreementsList || []}
                refetchContractsList={contractsList.refetch}
                addButtonTitle={t('contractors.addContract')}
              />
            </Col>
          </Row>
          <ContractsTable
            isLoading={contractsList.isLoading}
            contractsList={contractsList.data || []}
            refetch={contractsList.refetch}
            linkToContract="/admin/contract/"
          />
        </>
      )}

      <Row
        className={css`
          ${tw`my-6`}
        `}
      >
        <Col
          span={12}
          className={css`
            ${tw`text-left`}
          `}
        >
          <Title level={3}>{t('members.members')}</Title>
        </Col>
        <Col
          span={12}
          className={css`
            ${tw`text-right`}
          `}
        >
          <InviteContractorMemberForm
            contractorId={Number(id)}
            refetchMembersList={members.refetch}
            isDisable={!hasContractorEditPermission}
          />
          <EditContractorMemberForm
            contractorId={Number(id)}
            member={editableMember}
            isModalOpen={isEditModalOpen}
            refetchMembersList={members.refetch}
            onCloseClick={handleCloseEditModal}
          />
        </Col>
      </Row>
      <ContractorMembersTable
        membersList={members.data ?? []}
        isMembersListLoading={members.isLoading}
        deleteMemberMutation={deleteMemberMutation}
        resendInviteMutation={resendInviteMutation}
        deleteMemberInviteMutation={deleteMemberInviteMutation}
        disableActionButton={!hasContractorEditPermission}
        activateMemberMutation={activateMemberMutation}
        contractorName={contractorData?.name}
        onEditClick={handleEditClick}
      />
      <Title
        className={css`
          ${tw`my-6`}
        `}
        level={3}
      >
        {t('contractors.subcontractors')}
      </Title>
      <SubcontractorsTable
        isLoading={isLoading}
        total={subcontractors?.totalItems}
        subcontractorsList={subcontractors?.results ?? []}
        pagination={{
          onChange: (p: number) => {
            setPage(p);
          },
          total: subcontractors?.totalItems,
          current: subcontractors?.currentPage,
          pageSize: subcontractors?.pageSize,
        }}
      />
    </>
  );
};
