import { PlusOutlined } from '@ant-design/icons';
import { countryCityApi } from '@api/countryCityApi';
import { CitySelectorTemp } from '@components/atoms/CitySelectorTemp';
import { CountrySelectorTemp } from '@components/atoms/CountrySelectorTemp';
import type { City } from '@components/types/models/CountryCity';
import { ErrorMessage } from '@hookform/error-message';
import { useDefaultQuery } from '@hooks';
import { isBin } from '@utils/validate';
import {
  Button,
  Col,
  Form,
  Input,
  message as AntdMessage,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC, FormEvent } from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type { NewCompanyFormProps } from './props';

type FormValues = {
  readonly name: string;
  readonly countryId: number;
  readonly cityId?: number;
  readonly bin?: string;
  readonly detailsOnScopeOfWork?: string;
};

export const NewCompanyForm: FC<NewCompanyFormProps> = ({
  refetchCountriesList,
  handleValueChange,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<number>();
  const { TextArea } = Input;
  const { Text } = Typography;

  const {
    formState: { errors },
    reset,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    register,
  } = useForm();

  const { data: countries } = useDefaultQuery('getAllCountries', async () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const handleCloseModal = (): void => {
    reset();
    setIsModalVisible(false);
  };

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const mutation = useMutation(async (values: FormValues) => axios.post('/api/company', values), {
    onSuccess(res) {
      void AntdMessage.success({
        content: t('forecasts.subcontractorAdded'),
        style: { marginTop: '20vh' },
      });
      handleValueChange(res.data.id);
      refetchCountriesList();
      handleCloseModal();
    },
    onError(err: AxiosError) {
      const errData = err.response?.data;
      if (errData.validationErrors) {
        errData.validationErrors.forEach(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: { readonly name: any; readonly description: string }): void => {
            setError(error.name, { message: error.description });
          }
        );
      } else {
        setError('errorMessage', { message: errData.message });
      }
    },
  });

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues) => {
      mutation.mutate(values);
    })();
  }, [handleSubmit, mutation, clearErrors]);

  const handleCountrySelectorChange = useCallback(
    (value: number) => {
      setSelectedCountry(value);
      setValue('countryId', value);
      setValue('cityId', global.undefined);
    },
    [setValue]
  );

  const handleCitySelectorChange = useCallback(
    (value: number) => {
      setValue('cityId', value);
    },
    [setValue]
  );

  const handleTextareaChange = useCallback(
    (name) => (e: FormEvent<HTMLTextAreaElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const handleBinChange = (e: FormEvent<HTMLInputElement>): void => {
    if (isBin(e.currentTarget.value)) {
      setValue('bin', e.currentTarget.value);
    }
  };

  return (
    <>
      <Tooltip title={t('add')}>
        <Button
          ghost
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          onClick={handleOpenModal}
        />
      </Tooltip>
      <Modal
        centered
        destroyOnClose
        width={800}
        title={t('forecasts.newCompany')}
        visible={isModalVisible}
        okText={t('add')}
        cancelText={t('cancel')}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical" onFinish={handleFormSubmit}>
          <ErrorMessage
            errors={errors}
            name="errorMessage"
            render={({ message }) => <Text type="danger">{message}</Text>}
          />
          <Form.Item
            labelCol={{ span: 24 }}
            label={t('forecasts.companyName')}
            validateStatus={errors.name?.message && 'error'}
            help={errors.name?.message}
          >
            <Input value={watch('name')} {...register('name')} />
          </Form.Item>
          <Row gutter={8}>
            <Col md={8}>
              <Form.Item
                label={t('contractors.country')}
                validateStatus={errors.countryId?.message && 'error'}
                help={errors.countryId?.message}
                labelCol={{ span: 24 }}
              >
                <CountrySelectorTemp
                  countries={countries || []}
                  selectedCountry={watch('countryId')}
                  onChange={handleCountrySelectorChange}
                />
              </Form.Item>
            </Col>
            {countries?.find((c: City) => c.id === selectedCountry)?.cities.length > 0 && (
              <Col md={8}>
                <Form.Item
                  validateStatus={errors.cityId?.message && 'error'}
                  help={errors.cityId?.message}
                  label={t('contractors.city')}
                  labelCol={{ span: 24 }}
                >
                  <CitySelectorTemp
                    cities={countries?.find((c: City) => c.id === selectedCountry)?.cities}
                    selectedCity={watch('cityId')}
                    onChange={handleCitySelectorChange}
                  />
                </Form.Item>
              </Col>
            )}
            {selectedCountry === 1 && (
              <Col md={8}>
                <Form.Item
                  validateStatus={errors.bin?.message && 'error'}
                  help={errors.bin?.message}
                  label={t('contractors.bin')}
                  labelCol={{ span: 24 }}
                >
                  <Input value={watch('bin')} type="number" onChange={handleBinChange} />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label={t('contracts.detailsOnScopeWork')}>
                <TextArea
                  value={watch('detailsOnScopeOfWork')}
                  onChange={handleTextareaChange('detailsOnScopeOfWork')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={24}>
              <br />
              <Form.Item noStyle>
                <Row justify="end">
                  <Col>
                    <Space align="end">
                      <Button htmlType="button" onClick={handleCloseModal}>
                        {t('cancel')}
                      </Button>
                      <Button htmlType="submit" type="primary" loading={mutation.isLoading}>
                        {t('add')}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
