import type { AppCardProps } from '@components/molecules/AppCard/props';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Card, Space, Typography, Button, Row } from 'antd';
import type { FC } from 'react';
import React from 'react';
import tw from 'twin.macro';

const AppCardBase = styled(Card)`
  .ant-card-body {
    ${tw`p-0`};
  }
`;

export const AppCard: FC<AppCardProps> = ({
  title,
  subtitle,
  headerRightSide,
  children,
  downloadButton,
  ...rest
}) => (
  <AppCardBase {...rest}>
    <Row
      className={css`
        ${tw`p-4`}
      `}
    >
      {(title || subtitle) && (
        <Space align="center">
          {title && (
            <Typography.Title
              level={5}
              className={css`
                ${tw`mb-0!`}
              `}
            >
              {title}
            </Typography.Title>
          )}
          {subtitle && <Typography.Text type="secondary">{subtitle}</Typography.Text>}
        </Space>
      )}
      <div
        className={css`
          ${tw`ml-auto`}
        `}
      >
        {headerRightSide}
        {downloadButton}
      </div>
    </Row>
    {children}
  </AppCardBase>
);
