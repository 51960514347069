/* eslint-disable functional/no-let */
/* eslint-disable functional/no-loop-statement */
/* eslint-disable react-hooks/exhaustive-deps */

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import type { JobsTrainingTimelineProps, YearItem } from './props';

const { Title } = Typography;

export const JobsTrainingTimeline: FC<JobsTrainingTimelineProps> = ({
  startDateString,
  endDateString,
  getCurrentPeriod,
  isCurrentPeriodChanged,
  isGrandTotal,
}) => {
  const presentTime = moment(new Date(), 'YYYY-M-DD');
  const startDate = moment(startDateString, 'YYYY-M-DD');
  const endDate = moment(endDateString, 'YYYY-M-DD');

  const [currentPeriod, setCurrentPeriod] = useState<YearItem | null | undefined>(null);
  const [monthsYearList, setMonthsYearList] = useState<readonly YearItem[]>([]);

  const getPeriodList = (): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const allMonthsInPeriod: any = {};

    while (startDate.isBefore(endDate)) {
      if (allMonthsInPeriod[startDate.format('YYYY')]) {
        allMonthsInPeriod[startDate.format('YYYY')].push(Number(startDate.format('M')));
      } else {
        allMonthsInPeriod[startDate.format('YYYY')] = [Number(startDate.format('M'))];
      }
      startDate.add(1, 'month');
    }

    const outputPeriods = Object.entries(allMonthsInPeriod).map(([year, months]) => ({
      year: Number(year),
      years: Object.keys(allMonthsInPeriod).map((periodYear: string) => Number(periodYear)),
      months,
      isDetailed: Number(year) <= presentTime.year(),
    }));

    setMonthsYearList(outputPeriods as readonly YearItem[]);
  };

  const handlePrevYearClick = (): void => {
    const prevYearIndex =
      monthsYearList.findIndex((item: YearItem | undefined) => item?.year === currentPeriod?.year) -
      1;

    setCurrentPeriod(monthsYearList[prevYearIndex]);
  };

  const handleNextYearClick = (): void => {
    const nextYearIndex = monthsYearList.findIndex(
      (item: YearItem) => item.year === currentPeriod?.year
    );

    setCurrentPeriod(monthsYearList[Number(nextYearIndex) + 1]);
  };

  const isYearIncluded = (year: number | string): boolean =>
    Boolean(monthsYearList.find((item: YearItem) => item.year === year));

  const currentPeriodTitle = useMemo(() => {
    if (!currentPeriod?.isDetailed) {
      const isCurrentYearLast =
        currentPeriod?.year === monthsYearList[monthsYearList.length - 1]?.year;

      return `${currentPeriod?.year}${
        isCurrentYearLast ? '' : `-${monthsYearList[monthsYearList.length - 1].year}`
      }`;
    }

    return currentPeriod.year || '-';
  }, [currentPeriod]);

  useEffect(() => {
    getPeriodList();
  }, []);

  useEffect(() => {
    if (getCurrentPeriod && currentPeriod) {
      getCurrentPeriod(currentPeriod);
    }
  }, [currentPeriod]);

  useEffect(() => {
    const current = monthsYearList.find(
      (item: YearItem) => Number(item.year) === presentTime.year()
    );

    setCurrentPeriod(current ?? monthsYearList[monthsYearList.length - 1]);
  }, [monthsYearList]);

  useEffect(() => {
    handleNextYearClick();
  }, [isCurrentPeriodChanged]);

  return (
    <Row align="middle">
      <Col>
        <Row align="middle" justify="space-between">
          <Button
            type="link"
            icon={<LeftOutlined />}
            disabled={!isYearIncluded(Number(currentPeriod?.year) - 1) || isGrandTotal}
            onClick={handlePrevYearClick}
          />
          <Title
            style={{
              color: `${isGrandTotal ? 'rgba(0, 0, 0, 0.25)' : '#1890ff'}`,
              marginBottom: 0,
            }}
            level={4}
          >
            {currentPeriodTitle}
          </Title>
          <Button
            type="link"
            icon={<RightOutlined />}
            disabled={
              !isYearIncluded(Number(currentPeriod?.year) + 1) ||
              !currentPeriod?.isDetailed ||
              isGrandTotal
            }
            onClick={handleNextYearClick}
          />
        </Row>
      </Col>
    </Row>
  );
};
