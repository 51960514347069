import { PlusOutlined } from '@ant-design/icons';
import { UserContext } from '@contexts/userContext';
import { Button, Col, Form, Input, message, Modal, Row, Space } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type { CreateGwsFormProps } from './props';

type FormValues = {
  readonly name: string;
};
export const CreateGwsForm: FC<CreateGwsFormProps> = ({
  refetch,
  title,
  api,
  successTitle,
  handleValueChange,
  sectionType,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { TextArea } = Input;
  const {
    reset,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleCloseModal = (): void => {
    reset();
    setIsModalVisible(false);
  };

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const mutation = useMutation(
    async (values: FormValues) =>
      axios.post(api, { ...values, contractorId: user?.userRoles[0].entityId, sectionType: sectionType }),
    {
      onSuccess(res) {
        handleValueChange(res.data.id);
        void message.success({
          content: successTitle,
          style: { marginTop: '20vh' },
        });
        refetch();
        handleCloseModal();
      },
      onError(err: AxiosError) {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error: { readonly name: any; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('title', { message: errData.message });
        }
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues) => {
      mutation.mutate(values);
    })();
  }, [handleSubmit, mutation, clearErrors]);

  const handleTextareaChange = useCallback(
    (name) => (e: React.FormEvent<HTMLTextAreaElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  return (
    <>
      <Button
        ghost
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={handleOpenModal}
      />
      <Modal
        centered
        destroyOnClose
        width={800}
        title={title}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form onFinish={handleFormSubmit}>
          <Form.Item validateStatus={errors.title?.message && 'error'} help={errors.title?.message}>
            <TextArea
              placeholder={t('forecasts.name')}
              value={watch('title')}
              maxLength={255}
              autoSize={{ minRows: 1, maxRows: 6 }}
              onChange={handleTextareaChange('title')}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col md={24}>
              <br />
              <Form.Item noStyle>
                <Row justify="end">
                  <Col>
                    <Space align="end">
                      <Button htmlType="button" onClick={handleCloseModal}>
                        {t('cancel')}
                      </Button>
                      <Button htmlType="submit" type="primary" loading={mutation.isLoading}>
                        {t('add')}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
