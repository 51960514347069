import { DownloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import axios from 'axios';
// eslint-disable-next-line import/no-deprecated
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DownloadFileButtonProps } from './props';

export const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({ url, disabled, title, shape, isGhost, type }) => {
  const [t] = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = (): boolean => {
    setIsDownloading(true);
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        setIsDownloading(false);
        const filename = response.headers['content-disposition']
          .split(';')
          .find((n: string) => n.includes('filename='))
          .replace('filename=', '')
          .replace('"', '')
          .replace('"', '');
        // eslint-disable-next-line import/no-deprecated
        saveAs(new Blob([response.data]), filename);
      })
      .catch(() => {
        setIsDownloading(false);
        notification.error({
          message: t('downloadError'),
        });
      });
    return false;
  };

  return (
    <Button
      type={type ?? "primary"}
      icon={<DownloadOutlined />}
      loading={isDownloading}
      disabled={disabled}
      onClick={handleDownload}
      ghost={isGhost}
      shape={shape}
    >
      {title}
    </Button>
  );
};
