export const forecasts = [
  {
    title: "How to create Forecast for your Contract?",
    html: `
  <p class="c0"><span class="c4">Once KC Lead approved your Contract, you can add forecast:</span></p>
  <ol class="c5 lst-kix_qncjp6s4iklh-0 start" start="1">
    <li class="c0 c7"><span class="c4">Press on Forecast tab on the side menu</span></li>
    <li class="c0 c7"><span>Press on the </span><span class="c1">Add</span><span class="c4">&nbsp;button (plus
        icon)</span></li>
    <li class="c0 c7"><span>Status of Forecast became </span><span class="c11">Draft</span></li>
  </ol>
  <p class="c0 c3"><span class="c4"></span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image2.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to create Forecast for your Stand-alone agreement?",
    html: `
  <ol class="c5 lst-kix_qzbvvz6wd14-0 start" start="1">
    <li class="c0 c7"><span class="c4">Press on Forecast tab on the side menu</span></li>
    <li class="c0 c7"><span>Press on the </span><span class="c1">Add</span><span class="c4">&nbsp;button (plus
        icon)</span></li>
    <li class="c0 c7"><span>Status of Forecast became </span><span class="c11">Draft</span></li>
    <li class="c0 c7"><span class="c4">Press on Contract number or i - icon</span></li>
    <li class="c0 c7"><span class="c4">System shows Forecast details page</span></li>
  </ol>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image16.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to create Forecast for your Master agreement?",
    html: `<ol class="c5 lst-kix_5h6uadbxljb8-0 start" start="1">
    <li class="c0 c7"><span class="c4">Press on Forecast tab on the side menu</span></li>
    <li class="c0 c7"><span>Press on the </span><span class="c1">Add</span><span class="c4">&nbsp;button (plus
        icon)</span></li>
    <li class="c0 c7"><span>Status of Forecast became </span><span class="c11">Draft</span></li>
    <li class="c0 c7"><span class="c4">Press on Contract number or i - icon</span></li>
    <li class="c0 c7"><span class="c4">System shows Forecast details page</span></li>
  </ol>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image29.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to create Forecast for your Sub-agreement?",
    html: `
  <ol class="c5 lst-kix_w8zvbt2u89hd-0 start" start="1">
    <li class="c0 c7"><span class="c4">Press on Forecast tab on the side menu</span></li>
    <li class="c0 c7"><span>Press on the </span><span class="c1">Add</span><span class="c4">&nbsp;button (plus
        icon)</span></li>
    <li class="c0 c7"><span>Status of Forecast became </span><span class="c11">Draft</span></li>
    <li class="c0 c7"><span class="c4">Press on Contract number or i - icon</span></li>
    <li class="c0 c7"><span class="c4">System shows Forecast details page</span></li>
  </ol>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image26.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image20.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image21.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  }
];

export const forecasts_fillout = [
  {
    title: "How to fill out Forecast for your Contract?",
    html: `
  <p class="c0"><span class="c4">For Sub-agreements and Stand-alone agreements following sections are required:</span>
  </p>
  <ul class="c5 lst-kix_c7ac3u2twhd1-0 start">
    <li class="c0 c7"><span class="c4">Goods</span></li>
    <li class="c0 c7"><span class="c4">Works</span></li>
    <li class="c0 c7"><span class="c4">Services</span></li>
  </ul>
  <p class="c0"><span class="c4">For Master and Stand-alone agreements following sections are required:</span></p>
  <ul class="c5 lst-kix_c7ac3u2twhd1-0">
    <li class="c0 c7"><span class="c4">Jobs and Nationalization</span></li>
    <li class="c0 c7"><span class="c4">Training</span></li>
  </ul>
  <p class="c0"><span class="c4">For Master and Stand-alone agreements following sections are not required:</span></p>
  <ul class="c5 lst-kix_c7ac3u2twhd1-0">
    <li class="c0 c7"><span class="c4">Technology transfer</span></li>
    <li class="c0 c7"><span class="c4">Legacy</span></li>
  </ul>
  <p class="c0"><span>Once you have filled out all field, set Due date and press on </span><span
      class="c1">Submit</span><span class="c4">&nbsp;button.</span></p>
  `
  },
  {
    title: "How to fill out Goods section in your Forecast?",
    html: `
  <p class="c0"><span class="c4">Navigate between years and months to fill out your Forecast.</span></p>
  <p class="c0"><span class="c4">Fill out each field according to your plan.</span></p>
  <p class="c0"><span>Press on </span><span class="c1">Add good </span><span class="c4">button - system show you one
      more extra row.</span></p>
  <p class="c0"><span>Press on </span><span class="c1">Delete </span><span class="c4">button (x mark) if you are not
      planning to buy anything on particular month.</span></p>
  <p class="c0"><span>Once you entered data press on </span><span class="c1">Save and proceed </span><span
      class="c4">button to avoid losing data.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image4.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to fill out Works section in your Forecast?",
    html: `
  <p class="c0"><span class="c4">Navigate between years and months to fill out your Forecast.</span></p>
  <p class="c0"><span class="c4">Fill out each field according to your plan.</span></p>
  <p class="c0"><span>Press on </span><span class="c1">Add Work </span><span class="c4">button - system show you one
      more extra row.</span></p>
  <p class="c0"><span>Press on </span><span class="c1">Delete </span><span class="c4">button (x mark) if you are not
      planning to buy anything on particular month.</span></p>
  <p class="c0"><span>Once you entered data press on </span><span class="c1">Save and proceed </span><span
      class="c4">button to avoid losing data.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image11.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  `
  },
  {
    title: "How to fill out Services section in your Forecast?",
    html: `
  <p class="c0"><span class="c4">Navigate between years and months to fill out your Forecast.</span></p>
  <p class="c0"><span class="c4">Fill out each field according to your plan.</span></p>
  <p class="c0"><span>Press on </span><span class="c1">Add Service </span><span class="c4">button - system show you one
      more extra row.</span></p>
  <p class="c0"><span>Press on </span><span class="c1">Delete </span><span class="c4">button (x mark) if you are not
      planning to buy anything on particular month.</span></p>
  <p class="c0"><span>Once you entered data press on </span><span class="c1">Save and proceed </span><span
      class="c4">button to avoid losing data.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image9.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "Where I can see summary of GWS sections?",
    html: `
  <p class="c0"><span class="c4">Press on Goods, works, services summary section.</span></p>
  <p class="c0"><span class="c4">Navigate between years and months to see subtotal.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image6.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  `
  },
  {
    title: "How to fill out Jobs and Nationalization section in your Forecast?",
    html: `
  <p class="c0"><span class="c4">Navigate between years and MoU Categories to fill out your Forecast.</span></p>
  <p class="c0"><span class="c4">Fill out each field according to your plan.</span></p>
  <p class="c0"><span class="c4">By pressing on MoU Categories system saves entered data.</span></p>
  <p class="c0"><span>Always press on </span><span class="c1">Save and proceed</span><span class="c4">&nbsp;button to
      skip avoiding data.</span></p>
  <p class="c0"><span>You can leave fields blank and press on </span><span class="c1">Save and go the next
      category</span><span class="c4">&nbsp;button.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image14.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to fill out Jobs and Nationalization section in your Forecast?",
    html: `
  <p class="c0"><span class="c4">Navigate between years to fill out your Forecast.</span></p>
  <p class="c0"><span class="c4">Fill out each field according to your plan.</span></p>
  <p class="c0"><span>By pressing </span><span class="c1">Grand total </span><span class="c4">system will show you
      Subtotal of entered data.</span></p>
  <p class="c0"><span>Always press on </span><span class="c1">Save and proceed</span><span class="c4">&nbsp;button to
      skip avoiding data.</span></p>
  <p class="c0"><span>You can leave fields blank and press on </span><span class="c1">Save and go the next
      category</span><span class="c4">&nbsp;button.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image1.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to fill out Technology transfer section in your Forecast?",
    html: `
  <p class="c0"><span>Describe Technology transfer and press </span><span class="c1">Save and go the next
      section</span><span class="c4">&nbsp;button.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image8.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  `
  },
  {
    title: "How to fill out Legacy section in your Forecast?",
    html: `
  <p class="c0"><span>Describe Legacy plan and press </span><span class="c1">Save and go the next section</span><span
      class="c4">&nbsp;button.</span></p>
  <p class="c0"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 80%;"><img
        alt="" src="/images/userguides/contractor/image28.png"
        style="width: 80%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
        title=""></span></p>
  `
  },
  {
    title: "How to set Due date for my Forecast?",
    html: `
  <p class="c0"><span class="c4">Set the date in Date-picker.</span></p>
  <p class="c0"><span class="c4">Due date for review your forecast by default calculated as current day plus 3 (three)
      days.</span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  }
];

export default forecasts;
