import { exchangeRateApi } from '@api/exchangeRateApi';
import type { Currency } from '@components/types/models/Currency';
import { useDefaultQuery } from '@hooks';
import { notification } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseQueryResult } from 'react-query';

type CurrenciesServiceType = {
  allCurrenciesQuery: UseQueryResult<readonly Currency[]>;
  disabledCurrencies: Currency[];
  enabledCurrencies: Currency[];
};

export const useCurrenciesService: () => CurrenciesServiceType = () => {
  const { t } = useTranslation();

  const allCurrenciesQuery = useDefaultQuery('getAllCurrencies', async () =>
    exchangeRateApi
      .getCurrencyList()
      .then((res) => res.data)
      .catch((error) => {
        notification.error({
          message: error.response?.data.message ?? t('exchangeRates.getCurrenciesError'),
        });
        return [];
      })
  );

  const disabledCurrencies = useMemo(
    () =>
      allCurrenciesQuery.data
        ? allCurrenciesQuery.data.filter((currency) => !currency.enabled)
        : [],
    [allCurrenciesQuery.data]
  );
  const enabledCurrencies = useMemo(
    () =>
      allCurrenciesQuery.data ? allCurrenciesQuery.data.filter((currency) => currency.enabled) : [],
    [allCurrenciesQuery.data]
  );

  return {
    allCurrenciesQuery,
    disabledCurrencies,
    enabledCurrencies,
  };
};
