import { DownloadLink } from '@components/molecules/DownloadLink';
import type { DatadumpRecord } from '@components/types/models/Datadump';
import { DatadumpStatuses } from '@components/types/models/Datadump';
import { Space, Table, Tag, Typography } from 'antd';
import { unix } from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  readonly reports: readonly DatadumpRecord[];
  readonly isLoading: boolean;
  readonly getDownloadLink: (report: DatadumpRecord) => string;
};

export const DatadumpReportTable: React.FC<Props> = ({ reports, isLoading, getDownloadLink }) => {
  const [t] = useTranslation();

  return (
    <Table
      bordered
      title={() => (
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {t('reports.datadumpTitle')}
          </Typography.Title>

          <Typography.Text type="secondary">{t('reports.datadumpSubtitle')}</Typography.Text>
        </Space>
      )}
      locale={{ emptyText: t('noData') }}
      loading={isLoading}
      tableLayout="auto"
      pagination={false}
      rowKey="userId"
      dataSource={reports}
    >
      <Table.Column title={t('reports.datadumpName')} dataIndex="fileName" />
      <Table.Column
        title={t('reports.status')}
        render={(report: DatadumpRecord) => (
          <Tag color={report.status === DatadumpStatuses.completed ? 'green' : 'yellow'}>
            {t(report.status === DatadumpStatuses.completed ? 'completed' : 'inProgress')}
          </Tag>
        )}
      />
      <Table.Column
        title={t('reports.datadumpDate')}
        render={(report) => unix(report.unixTime).format('D MMM YYYY, HH:mm')}
      />
      <Table.Column
        title={t('actions')}
        render={(report: DatadumpRecord) => (
          <DownloadLink
            type="primary"
            size="middle"
            url={getDownloadLink(report)}
            fileName={report.fileName}
            label={t('download')}
            disabled={report.status === DatadumpStatuses.inProgress}
          />
        )}
      />
    </Table>
  );
};

export default DatadumpReportTable;
