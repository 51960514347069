

import { CheckOutlined, CopyOutlined, FileTextOutlined, QuestionOutlined } from "@ant-design/icons";
import { contracts } from "./contracts";
import { forecasts } from "./forecasts";
import { actuals } from "./actuals";
import { gettingStarted } from "./gettingStarted";

export const contractorUserguides = [
  {
    title: "general",
    data: gettingStarted,
    icon: <QuestionOutlined />
  },
  {
    title: "contracts",
    data: contracts,
    icon: <CopyOutlined />
  },
  {
    title: "forecasts",
    data: forecasts,
    icon: <FileTextOutlined />,
  },
  {
    title: "actuals",
    data: actuals,
    icon: <CheckOutlined />,
  },
];