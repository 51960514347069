import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';

type ExchangeRatePeriodPickerProps = {
  readonly value: string;
  readonly onChange: (period: string) => void;
};

const lastMonth = moment().subtract(1, 'months').format();

export const ExchangeRatePeriodPicker: React.FC<ExchangeRatePeriodPickerProps> = ({
  value,
  onChange,
}) => {
  const handleDatePickerChange = (newValue: Moment | null): void => {
    onChange(newValue ? newValue.format() : lastMonth);
  };

  return (
    <DatePicker
      picker="month"
      value={moment(value, 'YYYY-MM')}
      disabledDate={(date) => date.isSameOrAfter(moment().startOf('month'))}
      clearIcon={false}
      format="MMM YYYY"
      onChange={handleDatePickerChange}
    />
  );
};
