import { SearchOutlined } from '@ant-design/icons';
import { contractorApi } from '@api/contractorApi';
import { CATEGORIES } from '@components/constants/AdminCategories';
import type { Category } from '@components/organisms/NewContractForm/libs/types/Category';
import { CategorySelector } from '@components/pages/admin/Forecasts/libs/components/CategorySelector';
import { ContractorSelector } from '@components/pages/admin/Forecasts/libs/components/ContractorSelector';
import { ContractTypeSelector } from '@components/pages/admin/Forecasts/libs/components/ContractTypeSelector';
import { ContractTypes } from '@utils/contractTypes';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import tw from 'twin.macro';

import type { SearchQueryType } from './libs/types/SearchQueryType';
import type { SearchSectionProps } from './props';

export const SearchSection: FC<SearchSectionProps> = ({ onSearchQuery }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  useEffect(() => {
    onSearchQuery(searchQuery);
  }, [onSearchQuery, searchQuery]);

  const handleOnFinish = useCallback(
    (vals: {
      readonly contractNumber: string;
      readonly contractors: readonly number[];
      readonly categories: readonly number[];
      readonly contractTypes: readonly number[];
      readonly period: string;
    }) => {
      setSearchQuery({
        contractNumber: vals.contractNumber,
        contractors: vals.contractors,
        categories: vals.categories,
        contractTypes: vals.contractTypes,
        year: vals.period ? moment(vals.period).get('year') : global.undefined,
        month: vals.period
          ? moment(vals.period).get('month') + 1
          : global.undefined,
      });
    },
    []
  );

  const contractorsList = useQuery(
    'getContractors',
    async () => contractorApi.getContractors().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const categoriesList = useMemo<readonly Category[]>(
    () =>
      CATEGORIES.reduce<readonly Category[]>(
        (prev, cat) =>
          cat.children ? prev.concat(cat.children) : [...prev, cat],
        []
      ),
    []
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={3}>
          <Form.Item
            name="contractNumber"
            label={t('contractors.contractNumber')}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="contractors" label={t('contractors.contractors')}>
            <ContractorSelector contractors={contractorsList.data} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="categories" label={t('members.categoryAndArea')}>
            <CategorySelector categories={categoriesList} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="contractTypes" label={t('contracts.contractType')}>
            <ContractTypeSelector contractTypes={ContractTypes} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="period" label={t('actuals.submissionPeriod')}>
            <DatePicker
              picker="month"
              format="MMMM YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col
          span={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {t('forecasts.applyFilters')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
