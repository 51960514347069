import { LoadingOutlined } from '@ant-design/icons';
import { getPowerBiParams } from '@api/powerBiApi';
import { UserContext } from '@contexts/userContext';
import { Button, Result } from 'antd';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import type { FC } from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

export const ContractorDashboardPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const workspaceId =
    process.env.REACT_APP_CT_POWERBI_WORKSPACE_ID ?? 'e008f62d-0f75-4ed0-9772-42cbf2b96c40';
  const reportId =
    process.env.REACT_APP_CT_CONTRACTOR_POWERBI_REPORT_ID ?? '588f9f48-1ec7-4f7a-a79b-b30637aa7ccc';

  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    // eslint-disable-next-line no-undefined
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    // eslint-disable-next-line no-undefined
    accessToken: undefined,
    permissions: models.Permissions.All,
    viewMode: models.ViewMode.View,
    filters: [
      {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'v_contract_dashboard',
          column: 'ContractorId',
        },
        operator: 'In',
        values: [userContext.userEntityId],
        filterType: models.FilterType.Basic,
      },
    ],
    settings: {
      panes: {
        filters: { expanded: false, visible: true },
        pageNavigation: { visible: false },
      },
    },
  });

  const powerBiParamsQuery = useQuery({
    queryFn: async () => getPowerBiParams(workspaceId, reportId),
    onSuccess: ({ token, ...data }) => {
      setReportConfig({
        ...reportConfig,
        accessToken: token,
        ...data,
      });
    },
  });

  const handleBackButtonClick = (): void => {
    history.push('/');
  };

  if (powerBiParamsQuery.isLoading) {
    return <Result icon={<LoadingOutlined />} title={t('powerBi.loading')} />;
  }
  return powerBiParamsQuery.data ? (
    <PowerBIEmbed embedConfig={reportConfig} cssClassName="powerbi-dashboard" />
  ) : (
    <Result
      status="500"
      style={{ height: '100%', minHeight: '100vh' }}
      title="500"
      subTitle={t('powerBi.errorMessage')}
      extra={
        <Button type="primary" onClick={handleBackButtonClick}>
          {t('powerBi.backHome')}
        </Button>
      }
    />
  );
};
