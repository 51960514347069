import type { ContractorSelectorProps } from '@components/pages/admin/Forecasts/libs/components/ContractorSelector/props';
import { Select } from 'antd';
import type { FC } from 'react';

export const ContractorSelector: FC<ContractorSelectorProps> = ({ contractors, ...rest }) => (
  <Select
    options={contractors?.map((c) => ({
      value: c.id,
      label: c.name,
    }))}
    mode="multiple"
    maxTagCount="responsive"
    filterOption={(input, option) =>
      (option?.label as string).toLowerCase().includes(input.toLowerCase())
    }
    {...rest}
  />
);
