import { forecastApi } from '@api/forecastApi';
import { defineStatus } from '@components/types/models/Statuses';
import { useDefaultQuery } from '@hooks';
import { Select } from 'antd';
import { FC, useCallback } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ForecastVersionSelectorProps } from './props';

const { Option } = Select;

export const ForecastVersionSelector: FC<ForecastVersionSelectorProps> = ({
  forecast,
  contractId,
  onVersionSelectChange,
}) => {
  const { t, i18n } = useTranslation();
  const defaultVersionOptionValue =
    i18n.language === 'en' ? 'Version 1 - DRAFT' : 'Версия 1 - ЧЕРНОВИК';

  const { data: forecastVersionsList } = useDefaultQuery(
    ['getForecastVersions', contractId],
    async () =>
      contractId &&
      forecastApi
        .getForecastVersions(Number(contractId))
        .then((res) => res.data)
  );

  const getForecastVersionTitle = (value?: {
    readonly forecastVersion?: number;
    readonly approvalStatus?: number;
  }): string =>
    `${t('contracts.version')} ${value?.forecastVersion} - ${
      i18n.language === 'en'
        ? defineStatus(value?.approvalStatus).enName
        : defineStatus(value?.approvalStatus).ruName
    }`;

  const handleVersionSelectChange = useCallback(onVersionSelectChange, [
    onVersionSelectChange,
  ]);

  return (
    <Select
      style={{ width: 'max-content' }}
      placeholder={getForecastVersionTitle({
        forecastVersion: forecast?.forecastVersion,
        approvalStatus: forecast?.approvalStatus,
      })}
      onChange={handleVersionSelectChange}
    >
      {forecastVersionsList && forecastVersionsList.length > 0 ? (
        forecastVersionsList.map(
          (
            forecast: {
              readonly forecastVersion: number;
              readonly approvalStatus: number;
              readonly id: number;
            },
            index: number
          ) => (
            <Option key={index} value={forecast.id}>
              {getForecastVersionTitle(forecast)}
            </Option>
          )
        )
      ) : (
        <Option value={1}> {defaultVersionOptionValue}</Option>
      )}
    </Select>
  );
};
