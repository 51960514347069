import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@linaria/core';
import { Button, Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { TimelineProps } from './props';

export const Timeline: FC<TimelineProps> = ({
  selectedMonth,
  setSelectedMonth,
  setSelectedYear,
  selectedYear,
  period,
  lastYear,
  firstYear,
}) => {
  const { Title } = Typography;
  const grandTotal = 0;

  const { t } = useTranslation();

  const handleNextYearClick = (): void => {
    setSelectedYear(selectedYear === lastYear ? grandTotal : selectedYear + 1);
  };

  const handlePrevYearClick = (): void => {
    setSelectedYear(selectedYear === grandTotal ? lastYear : selectedYear - 1);
  };

  const handleMonthClick = useCallback(
    (id: number) => () => {
      setSelectedMonth(id);
    },
    [setSelectedMonth]
  );

  useEffect(() => {
    if (selectedYear && selectedYear <= moment().year()) {
      setSelectedMonth(period?.[selectedYear][0] ?? 0);
    } else {
      setSelectedMonth(0);
    }
  }, [selectedYear, period, setSelectedMonth]);

  return (
    <Row align="middle">
      <Col span={5}>
        <Row align="middle" justify="space-between">
          <Button
            type="link"
            icon={<LeftOutlined />}
            disabled={selectedYear === firstYear}
            onClick={handlePrevYearClick}
          />
          <Title
            className={css`
              ${tw`uppercase! text-primary! mb-0!`}
            `}
            level={4}
          >
            {selectedYear === grandTotal ? t('forecasts.grandTotal') : selectedYear}
          </Title>
          <Button
            type="link"
            icon={<RightOutlined />}
            disabled={selectedYear === grandTotal}
            onClick={handleNextYearClick}
          />
        </Row>
      </Col>
      {selectedYear !== grandTotal && (
        <Col span={19}>
          <Row wrap justify="start" gutter={8} style={{ marginLeft: '24px' }}>
            {selectedYear <= moment().year() ? (
              <Row gutter={6}>
                {period?.[selectedYear].map((month: number, index: number) => (
                  <Col key={index}>
                    <Button
                      type={selectedMonth === month ? 'primary' : 'default'}
                      className={css`
                        ${tw`mb-2`}
                      `}
                      onClick={handleMonthClick(month)}
                    >
                      {t(`months.${month}`)}
                    </Button>
                  </Col>
                ))}
                <Button
                  type={selectedMonth === 0 ? 'primary' : 'default'}
                  onClick={handleMonthClick(0)}
                >
                  {t(`months.${0}`)}
                </Button>
              </Row>
            ) : (
              <Col>
                <Button type="primary" onClick={handleMonthClick(0)}>
                  {t('months.annual')}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      )}
      <Divider />
    </Row>
  );
};
