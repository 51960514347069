/* eslint-disable react-hooks/exhaustive-deps */

import { adminApi } from '@api/adminApi';
import { invoiceApi } from '@api/invoiceApi';
import { CommentCard } from '@components/organisms/CommentCard';
import { InvoiceCard } from '@components/organisms/InvoiceCard';
import { InvoiceGwsForm } from '@components/organisms/InvoiceGwsForm';
import { InvoicePreviewModal } from '@components/organisms/InvoicePreviewModal';
import { SCOPE_OF_WORK } from '@components/types/models/Contract';
import { SectionType } from '@components/types/models/Forecast';
import { STATUS } from '@components/types/models/Statuses';
import { useDefaultQuery, useModalState } from '@hooks';
import { css } from '@linaria/core';
import { Button, Card, Col, message, notification, Row, Tabs } from 'antd';
import type { AxiosError } from 'axios';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router';
import tw from 'twin.macro';

import { AdminRejectModal } from './libs/components/AdminRejectModal';

type PageParams = {
  readonly id: string;
};

export const InvoiceView: FC = () => {
  const { id } = useParams<PageParams>();
  const { t } = useTranslation();
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState(1);

  const {
    data: invoiceData,
    refetch: refetchInvoiceData,
    isLoading: isInvoiceDataLoading,
  } = useDefaultQuery('getInvoice', async () =>
    invoiceApi.getInvoiceById(id).then((res) => res.data)
  );

  const handleToggleRejectModal = useCallback(() => {
    setRejectModalOpen((prev) => !prev);
  }, []);

  const approveInvoiceMutation = useMutation(async () => adminApi.approveInvoice(Number(id)), {
    onSuccess() {
      void refetchInvoiceData();
      void message.success(t('invoices.g1cFormApproved'));
    },
  });

  const rejectInvoiceMutation = useMutation(
    async (payload: { readonly dueDate: string }) => adminApi.rejectInvoice(Number(id), payload),
    {
      onSuccess() {
        setRejectModalOpen(false);
        void refetchInvoiceData();
        void message.error(t('invoices.g1cFormRejected'));
      },
      onError(error: AxiosError) {
        notification.error({
          message: error.response?.data?.message,
        });
      },
    }
  );

  const handleApproveInvoice = useCallback(() => {
    approveInvoiceMutation.mutate();
  }, []);

  const handleRejectInvoice = useCallback(() => {
    handleToggleRejectModal();
  }, []);

  const handleCloseRejectModal = useCallback(() => {
    handleToggleRejectModal();
  }, []);

  const handleTabChange = (activeKey: string): void => {
    setActiveTab(Number(activeKey));
  };

  const onProceed = (): void => {
    setActiveTab(activeTab + 1);
  };


  const pdfPreviewModal = useModalState();

  return (
    <>
      <InvoiceCard
        invoiceData={invoiceData}
        refetchInvoiceData={refetchInvoiceData}
        isInvoiceDataLoading={isInvoiceDataLoading}
        cancelUrl="/admin/payment-application"
        onPreviewInvoicePdf={() => {
          pdfPreviewModal.handleOpen(true)
        }}
      />

      <Card
        className={css`
          ${tw`mt-4`}
        `}
        loading={isInvoiceDataLoading}
      >
        <Tabs activeKey={`${activeTab}`} onChange={handleTabChange}>
          {invoiceData?.contract.scopes.includes(SCOPE_OF_WORK.GOODS) && (
            <TabPane key="1" tab={t('contracts.goods')}>
              <InvoiceGwsForm
                getGwsNamesListUrl={`/api/actual/gws/good-positions/${invoiceData.contractId}`}
                gwsColumnTitle={t('invoices.goodName')}
                addRowButtonTitle={t('forecasts.addGoods')}
                kcShareColumnTitle={t('forecasts.CTKZ')}
                addGwsMutationUrl={`/api/invoices/${id}/goods`}
                submissionSuccessMessage={t('invoices.goodsSectionUpdated')}
                handleProceed={onProceed}
                getGwsListUrl={`/api/invoices/${id}/goods`}
                activeTab={activeTab}
                invoiceStartDate={invoiceData.startDate}
                invoiceEndDate={invoiceData.endDate}
                currency={invoiceData.contract.lastApprovedAmendment.currency.code}
              />
              <CommentCard
                isInvoice
                id={Number(id)}
                sectionType={SectionType.Good}
                status={invoiceData.status}
              />
            </TabPane>
          )}
          {invoiceData?.contract.scopes.includes(SCOPE_OF_WORK.WORKS) && (
            <TabPane key="2" tab={t('contracts.works')}>
              <InvoiceGwsForm
                getGwsNamesListUrl={`/api/actual/gws/work-positions/${invoiceData.contractId}`}
                gwsColumnTitle={t('invoices.workName')}
                addRowButtonTitle={t('forecasts.addWork')}
                kcShareColumnTitle={t('forecasts.staffPercent')}
                addGwsMutationUrl={`/api/invoices/${id}/works`}
                submissionSuccessMessage={t('invoices.worksSectionUpdated')}
                handleProceed={onProceed}
                getGwsListUrl={`/api/invoices/${id}/works`}
                activeTab={activeTab}
                invoiceStartDate={invoiceData.startDate}
                invoiceEndDate={invoiceData.endDate}
                currency={invoiceData.contract.lastApprovedAmendment.currency.code}
              />
              <CommentCard
                isInvoice
                id={Number(id)}
                sectionType={SectionType.Work}
                status={invoiceData.status}
              />
            </TabPane>
          )}
          {invoiceData?.contract.scopes.includes(SCOPE_OF_WORK.SERVICES) && (
            <TabPane key="3" tab={t('contracts.services')}>
              <InvoiceGwsForm
                getGwsNamesListUrl={`/api/actual/gws/service-positions/${invoiceData.contractId}`}
                gwsColumnTitle={t('invoices.serviceName')}
                addRowButtonTitle={t('forecasts.addService')}
                kcShareColumnTitle={t('forecasts.staffPercent')}
                addGwsMutationUrl={`/api/invoices/${id}/services`}
                submissionSuccessMessage={t('invoices.servicesSectionUpdated')}
                handleProceed={onProceed}
                getGwsListUrl={`/api/invoices/${id}/services`}
                activeTab={activeTab}
                invoiceStartDate={invoiceData.startDate}
                invoiceEndDate={invoiceData.endDate}
                currency={invoiceData.contract.lastApprovedAmendment.currency.code}
              />
              <CommentCard
                isInvoice
                id={Number(id)}
                sectionType={SectionType.Service}
                status={invoiceData.status}
              />
            </TabPane>
          )}
        </Tabs>
      </Card>

      {invoiceData?.status === STATUS.PENDING && (
        <Col
          span={24}
          className={css`
            ${tw`mt-4`}
          `}
        >
          <Row justify="space-between">
            <Button
              type="primary"
              onClick={handleRejectInvoice}
            >
              {t('reject')}
            </Button>
            <Button
              type="primary"
              loading={approveInvoiceMutation.isLoading}
              onClick={handleApproveInvoice}
            >
              {t('approve')}
            </Button>
          </Row>
        </Col>
      )}

      <AdminRejectModal
        visible={isRejectModalOpen}
        rejectInvoiceMutation={rejectInvoiceMutation}
        onClose={handleCloseRejectModal}
      />


      <InvoicePreviewModal
        invoiceId={id}
        isModalVisible={pdfPreviewModal.isOpen}
        handleModalClick={pdfPreviewModal.handleClose}
        invoiceName={invoiceData?.identityNumber}
      />
    </>
  );
};
