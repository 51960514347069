import type { AxiosResponse } from 'axios';
import axios from 'axios';

export const createAmendment = async (
  contractId: number,
  formData: FormData
): Promise<AxiosResponse<null>> =>
  axios.post(`/api/contract/${contractId}/amendment`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const resubmitAmendment = async (
  contractId: number,
  amendmentId: number,
  formData: FormData
): Promise<AxiosResponse<null>> =>
  axios.post(`/api/contract/${contractId}/amendment/${amendmentId}/resubmit`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
