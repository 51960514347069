/* eslint-disable @typescript-eslint/naming-convention */
import type { UploadFile } from 'antd/es/upload/interface';

import type { ActualAttachmentForm, ParsedActualAttachmentForm } from './props';

export const parseAttachmentFormFields = (
  values: ActualAttachmentForm,
  file?: UploadFile
): ParsedActualAttachmentForm => ({
  KcPlanFile: file,
  SectionType: values.sectionType,
});
