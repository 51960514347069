import type { AxiosResponse } from 'axios';
import axios from 'axios';

const getCompaniesList = async (): Promise<AxiosResponse> => axios.get(`/api/company`);

const getContractorCompaniesList = async (contractorId: number): Promise<AxiosResponse> =>
  axios.get(`/api/contractor/${contractorId}/company`);

const getContractorRelatedCompaniesList = async (
  contractorId: number | null | undefined
): Promise<AxiosResponse> => axios.get(`/api/contractor/${contractorId}/related-company`);

const createCompany = async (): Promise<AxiosResponse> => axios.get('/api/company');

export const companyApi = {
  getCompaniesList,
  createCompany,
  getContractorCompaniesList,
  getContractorRelatedCompaniesList,
};
