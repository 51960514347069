import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { actualApi } from "@api/actualApi";
import { contractorApi } from "@api/contractorApi";
import { forecastApi } from "@api/forecastApi";
import { goodWorkServiceApi } from "@api/goodWorksServiceApi";
import { CompanySelectorTemp } from "@components/atoms/CompanySelectorTemp";
import { DownloadFileButton } from "@components/atoms/DownloadFileButton";
import { CONTRACTUAL_RELATIONS_LIST } from "@components/constants/ContractualRelations";
import { CreateCertificateForm } from "@components/organisms/CreateCerificateForm";
import { CreateGwsForm } from "@components/organisms/CreateGwsForm";
import type { GoodsFormValues } from "@components/organisms/GoodsForm/libs/types/GoodsFormValues";
import { NewCompanyForm } from "@components/organisms/NewCompanyForm";
import type { ActualGoodsListItem } from "@components/types/models/Actuals";
import type { ContractorCertificate } from "@components/types/models/Certificate";
import { CONTRACTUAL_RELATIONS } from "@components/types/models/ContractualRelations";
import type {
  ForecastGoodType,
  GoodWorkServiceType,
  RelationshipType,
} from "@components/types/models/Forecast";
import { USER_ROLES } from "@components/types/models/Roles";
import type { UnitsOfMeasure } from "@components/types/models/UnitsOfMeasure";
import { UserRoles } from "@contexts/types/UserRoles";
import { UserContext } from "@contexts/userContext";
import { useDefaultQuery } from "@hooks";
import { css } from "@linaria/core";
import { isFloat, isPercentage } from "@utils/validate";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import type { SelectValue } from "antd/es/select";
import type { AxiosError } from "axios";
import axios from "axios";
import moment from "moment";
import type { FC } from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { SectionType } from "@components/types/models/Forecast";
import tw from "twin.macro";

import type { GoodsFormProps } from "./props";
import numeral from "numeral";

export const GoodsForm: FC<GoodsFormProps> = ({
  forecastId,
  currency,
  selectedYear,
  selectedMonth,
  isEditable,
  handleProceed,
  companiesList,
  refetchCompaniesList,
  unitsOfMeasure,
  isActualForm,
  actualId,
  prePopulatedData,
}) => {
  const { i18n, t } = useTranslation();
  const { Text } = Typography;
  const { Option } = Select;
  const { Column } = Table;
  const { user, userRole } = useContext(UserContext);
  const [selectedRow, setSelectedRow] = useState<number>(0);

  const [data, setData] = useState<readonly GoodsFormValues[]>([]);
  const contractorId = user?.userRoles[0]?.entityId;
  const percentSymbol = "%";

  const {
    formState: { errors },
    clearErrors,
    setError,
  } = useForm();

  const {
    data: goodsList,
    isLoading: isForecastGoodsLoading,
    refetch,
  } = useDefaultQuery(
    ["getForecastGoods", selectedYear, selectedMonth, forecastId],
    async () => {
      if (!isActualForm && forecastId) {
        return forecastApi
          .getGoods(forecastId, selectedYear, selectedMonth)
          .then((res) => res.data);
      }

      return actualId && actualApi.getGoods(actualId).then((res) => res.data);
    }
  );

  const { data: gwsList, refetch: refetchGoodsList } = useDefaultQuery<
    readonly GoodWorkServiceType[] | undefined
  >("getGoodsList", async () =>
    user &&
    [USER_ROLES.CONTRACTOR_OWNER, USER_ROLES.CONTRACTOR_MANAGER].includes(
      user.userRoles[0]?.role
    )
      ? goodWorkServiceApi
          .getGoodsList(Number(contractorId))
          .then((res) => res.data)
      : []
  );

  const { data: certificatesList, refetch: refetchCertificates } =
    useDefaultQuery<readonly ContractorCertificate[] | undefined>(
      ["getContractorCertificates", userRole],
      async () => {
        if (userRole !== UserRoles.Admin) {
          return contractorApi
            .getContractorCertificates(Number(contractorId))
            .then((res) => res.data);
        }
      }
    );

  const addGoodsMutation = useMutation(
    async (values: unknown) =>
      isActualForm
        ? axios.post(`api/actual/gws/goods/${actualId}`, values)
        : axios.post(`api/forecasts/goods/${forecastId}`, values),
    {
      onSuccess() {
        void message.success(t("monthDataSaved"));
        handleProceed();
      },
      onError(err: AxiosError) {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("message", { message: errData.message });
        }
      },
    }
  );

  const handleFormSubmit = (): void => {
    const filteredData = data.map((object) =>
      Object.fromEntries(
        // eslint-disable-next-line no-eq-null
        Object.entries(object).filter(
          ([key, v]) => v !== null && v !== "" && key !== "rowIndex"
        )
      )
    );
    const forecastFormValues = {
      year: selectedYear,
      month: selectedMonth,
      gwsRecords: filteredData.map((item) => ({
        forecastValue: item.value,
        kcShare: item.kcShare,
        companyId: item.companyId,
        relationType: item.relationType,
        kcGoodsId: item.kcGoodsId,
        goodsQuantity: item.goodsQuantity,
        unitId: item.unitId,
      })),
    };
    const actualFormValues = {
      goods: filteredData.map((item) => ({
        companyId: item.companyId,
        gwsId: item.kcGoodsId,
        relationType: item.relationType,
        actualValue: item.value,
        kcShare: item.kcShare,
        ctKzCertificateId: item.ctKzCertificateId,
        ctKzCertificateGoodName: item.ctKzCertificateGoodName,
        goodQuantity: item.goodsQuantity,
        unitsOfMeasureId: item.unitId,
      })),
    };

    clearErrors();
    addGoodsMutation.mutate(
      isActualForm ? actualFormValues : forecastFormValues
    );
  };

  const emptyRow = useMemo(
    () => ({
      year: selectedYear,
      month: selectedMonth,
      value: null,
      kcShare: null,
      companyId: null,
      relationType: null,
      kcGoodsId: null,
      goodsQuantity: null,
      unitId: null,
      rowIndex: data.length > 1 ? data.length - 1 : 0,
    }),
    [selectedMonth, selectedYear, data]
  );

  useEffect(() => {
    if (!isActualForm && goodsList?.length > 0) {
      setData(
        goodsList.map((good: ForecastGoodType, index: number) => ({
          year: good.year,
          month: good.month,
          value: good.forecastValue,
          kcValue: good.forecastKcValue,
          kcShare: good.forecastKcShare,
          companyId: good.company.contractualRelations.companyId,
          companyName: good.company.name,
          relationType: good.company.contractualRelations.relationType,
          kcGoodsId: good.kcGood.id,
          kcGoodsTitle: good.kcGood.title,
          goodsQuantity: good.goodsQuantity,
          unitId: good.unitsOfMeasure.id,
          rowIndex: index,
        }))
      );
    } else if (isActualForm && goodsList?.length > 0) {
      setData(
        goodsList.map((good: ActualGoodsListItem, index: number) => ({
          value: good.actualValue,
          kcValue: good.kcActualValue,
          kcShare: good.kcShare,
          companyId: good.companyId,
          companyName: good.companyTitle,
          relationType: good.relationType,
          kcGoodsId: good.goodsDto.id,
          kcGoodsTitle: good.goodsDto.title,
          goodsQuantity: good.goodsQuantity,
          unitId: good.unitsOfMeasureDto?.unitId,
          ctKzCertificateId: good?.ctKzCertificateDto?.id,
          ctKzCertificateGoodName: good?.ctKzCertificateKcGoodDto?.kcGood.title,
          certificateExpirationDate: good?.ctKzCertificateDto?.expirationDate,
          rowIndex: index,
        }))
      );
    } else if (!isEditable && goodsList?.length === 0) {
      setData([]);
    } else {
      setData([emptyRow]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodsList]);

  useEffect(() => {
    if (prePopulatedData) {
      return setData(
        prePopulatedData.map((item, index) => ({
          kcGoodsId: item.kcItemModel.id,
          relationType: item.relationType,
          companyId: item.companyDto.id,
          rowIndex: index,
        }))
      );
    }
    return;
  }, [prePopulatedData]);

  const handleAddRowClick = (): void => {
    setData([...data, { ...emptyRow, rowIndex: data.length }]);
  };
  const handleRowChange = (value?: number): void => {
    setSelectedRow(value ?? 0);
  };

  const handleValueChange = (
    value: SelectValue | string,
    fieldName: string,
    rowId: number
  ): void => {
    setData((prevData) =>
      prevData.map((item, index) => {
        if (index === rowId) {
          return { ...prevData[index], [fieldName]: value };
        }
        return item;
      })
    );
  };

  const handleDeleteClick = (index: number): void => {
    setData(data.filter((item) => item.rowIndex !== index));
  };

  const isTotalForm = useMemo(
    () => selectedYear <= moment().year() && selectedMonth === 0,
    [selectedYear, selectedMonth]
  );

  const isApprovedMonth = useMemo(
    () => goodsList?.[0]?.isApproved,
    [goodsList]
  );

  return isEditable && !isApprovedMonth && !isTotalForm ? (
    <Form onFinish={handleFormSubmit}>
      <Table
        bordered
        loading={isForecastGoodsLoading || addGoodsMutation.isLoading}
        locale={{ emptyText: t("noData") }}
        dataSource={data}
        pagination={false}
        scroll={{ x: 2600 }}
        onRow={(_, rowIndex) => ({
          onClick: () => {
            handleRowChange(rowIndex);
          },
        })}
      >
        <Column
          key="rowIndex"
          fixed
          dataIndex="rowIndex"
          width={50}
          render={(title) => <Text>{Number(title) + 1}</Text>}
        />
        <Column
          key="kcGoodsId"
          fixed
          title={t("forecasts.goodsDescription")}
          width={300}
          render={(item) => (
            <Row gutter={8}>
              <Col span={20}>
                <Form.Item
                  validateStatus={
                    (errors.gwsRecords?.[item?.rowIndex]?.KcGoodsId ||
                      errors.goods?.[item?.rowIndex]?.GWSId) &&
                    "error"
                  }
                  help={
                    errors.gwsRecords?.[item?.rowIndex]?.KcGoodsId?.message ||
                    errors.goods?.[item?.rowIndex]?.GWSId?.message
                  }
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    value={item.kcGoodsId}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      handleValueChange(value, "kcGoodsId", selectedRow);
                    }}
                  >
                    {gwsList?.map(
                      (good: GoodWorkServiceType, index: number) => (
                        <Option key={index} value={good.id}>
                          {good.title}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <CreateGwsForm
                  refetch={refetchGoodsList}
                  title={t("forecasts.newGood")}
                  api={`/api/contractor/${contractorId}/good`}
                  successTitle={t("forecasts.goodAdded")}
                  handleValueChange={(value: number) => {
                    handleValueChange(value, "kcGoodsId", selectedRow);
                  }}
                  sectionType={SectionType.Good}
                />
              </Col>
            </Row>
          )}
        />
        <Column
          key="companyId"
          title={t("forecasts.companyName")}
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.CompanyId ||
                  errors.goods?.[item.rowIndex]?.CompanyId) &&
                "error"
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.CompanyId?.message ||
                errors.goods?.[item.rowIndex]?.CompanyId?.message
              }
            >
              <Row gutter={8}>
                <Col span={20}>
                  <CompanySelectorTemp
                    selectedCompany={item.companyId}
                    companiesList={companiesList}
                    className={css`
                      ${tw`w-full`}
                    `}
                    onChange={(value) => {
                      handleValueChange(value, "companyId", selectedRow);
                      if (companiesList?.[0].id === value) {
                        setData((prevData) =>
                          prevData.map((_, index) => ({
                            ...prevData[index],
                            relationType: CONTRACTUAL_RELATIONS.CONTRACTOR,
                          }))
                        );
                      }
                    }}
                  />
                </Col>
                <Col span={4}>
                  <NewCompanyForm
                    handleValueChange={(value: number) => {
                      handleValueChange(value, "companyId", selectedRow);
                    }}
                    refetchCountriesList={refetchCompaniesList}
                  />
                </Col>
              </Row>
            </Form.Item>
          )}
        />
        <Column
          key="relationType"
          title={t("forecasts.contractualRelationship")}
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.RelationType ||
                  errors.goods?.[item?.rowIndex]?.RelationType) &&
                "error"
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.RelationType?.message ||
                errors.goods?.[item?.rowIndex]?.RelationType?.message
              }
            >
              <Select
                disabled={companiesList?.[0].id === item.companyId}
                value={item.relationType}
                style={{ width: "100%" }}
                onChange={(value) => {
                  handleValueChange(value, "relationType", selectedRow);
                }}
              >
                {CONTRACTUAL_RELATIONS_LIST.map(
                  (relationship: RelationshipType, index: number) => (
                    <Option key={index} value={relationship.value}>
                      {t(`forecasts.${relationship.label}`)}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          )}
        />
        {isActualForm && (
          <>
            <Column
              key="certificate"
              title={t("actuals.ctKzCertificate")}
              width={320}
              render={(item) => (
                <Form.Item
                  validateStatus={
                    errors.goods?.[item?.rowIndex]?.ctKzCertificateId && "error"
                  }
                  help={
                    errors.goods?.[item?.rowIndex]?.ctKzCertificateId?.message
                  }
                >
                  <Row gutter={8} align="middle">
                    {item.ctKzCertificateId && (
                      <Col span={4}>
                        <Tooltip title={t("actuals.downloadCertificate")}>
                          <DownloadFileButton
                            url={`/api/certificate/${item.ctKzCertificateId}/file`}
                            disabled={!item.ctKzCertificateId}
                            shape="circle"
                            isGhost
                          />
                        </Tooltip>
                      </Col>
                    )}
                    <Col span={16}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        value={item.ctKzCertificateId}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          handleValueChange(
                            value,
                            "ctKzCertificateId",
                            selectedRow
                          );
                        }}
                      >
                        {certificatesList?.map(
                          (
                            certificate: ContractorCertificate,
                            index: number
                          ) => (
                            <Option key={index} value={certificate.id}>
                              {certificate.certificateNumber}
                            </Option>
                          )
                        )}
                      </Select>
                    </Col>
                    <Col span={4}>
                      <CreateCertificateForm
                        refetchCertificates={refetchCertificates}
                        gwsList={gwsList ?? []}
                        refetchGoodsList={refetchGoodsList}
                        onChange={(value) => {
                          handleValueChange(
                            value,
                            "ctKzCertificateId",
                            selectedRow
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              )}
            />
            <Column
              key="certificate"
              title={t("actuals.ctKzCertificateGoodName")}
              width={150}
              render={(item) => {
                const selectedCertificateGoods = certificatesList?.filter(
                  (certificate: ContractorCertificate) =>
                    certificate.id === item.ctKzCertificateId
                )?.[0]?.ctKzCertificateKcGoodDtos;
                return (
                  <Form.Item
                    validateStatus={
                      errors.goods?.[item?.rowIndex]?.ctKzCertificateGoodName &&
                      "error"
                    }
                    help={
                      errors.goods?.[item?.rowIndex]?.ctKzCertificateGoodName
                        ?.message
                    }
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={item.ctKzCertificateGoodName}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        const currentGood = selectedCertificateGoods?.find(
                          (good) => good.kcGood.title === value
                        );

                        handleValueChange(
                          value,
                          "ctKzCertificateGoodName",
                          selectedRow
                        );
                        handleValueChange(
                          currentGood?.kcPercent,
                          "kcShare",
                          selectedRow
                        );
                      }}
                    >
                      {selectedCertificateGoods?.map((good, index) => (
                        <Option key={index} value={good.kcGood.title}>
                          {good.kcGood.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }}
            />
          </>
        )}
        <Column
          title={t("forecasts.goodQuantity")}
          width={150}
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.GoodsQuantity ||
                  errors.goods?.[item?.rowIndex]?.GoodQuantity) &&
                "error"
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.GoodsQuantity?.message ||
                errors.goods?.[item?.rowIndex]?.GoodQuantity?.message
              }
            >
              <Input
                type="number"
                value={item.goodsQuantity}
                onChange={(e) => {
                  handleValueChange(
                    e.target.value,
                    "goodsQuantity",
                    selectedRow
                  );
                }}
              />
            </Form.Item>
          )}
        />
        <Column
          title={t("forecasts.goodUnitsOfMeasure")}
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.UnitId ||
                  errors.goods?.[item?.rowIndex]?.UnitsOfMeasureId) &&
                "error"
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.UnitId?.message ||
                errors.goods?.[item?.rowIndex]?.UnitsOfMeasureId?.message
              }
            >
              <Select
                value={item.unitId}
                style={{ width: "100%" }}
                onChange={(value) => {
                  handleValueChange(value, "unitId", selectedRow);
                }}
              >
                {unitsOfMeasure?.map((unit: UnitsOfMeasure, index: number) => (
                  <Option key={index} value={unit.unitId}>
                    {i18n.language === "en" ? unit.title_En : unit.title_Ru}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        />
        <Column
          key="value"
          title={
            isActualForm
              ? t("actuals.actualValue")
              : t("forecasts.forecastValue")
          }
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.ForecastValue ||
                  errors.goods?.[item?.rowIndex]?.ActualValue) &&
                "error"
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.ForecastValue?.message ||
                errors.goods?.[item?.rowIndex]?.ActualValue?.message
              }
            >
              <Row gutter={8} align="middle">
                <Col span={18}>
                  <Input
                    value={item.value}
                    type="number"
                    onChange={(e) => {
                      if (isFloat(e.target.value)) {
                        handleValueChange(e.target.value, "value", selectedRow);
                      }
                    }}
                  />
                </Col>
                <Col span={6}>
                  <Text>{currency}</Text>
                </Col>
              </Row>
            </Form.Item>
          )}
        />
        <Column
          key="kcShare"
          width={150}
          title={t("forecasts.CTKZ")}
          render={(item) => {
            const selectedCertificateGoods = certificatesList?.filter(
              (certificate: ContractorCertificate) =>
                certificate.id === item.ctKzCertificateId
            )?.[0]?.ctKzCertificateKcGoodDtos;
            const selectedKcShare = selectedCertificateGoods?.filter(
              (certificateGood) =>
                certificateGood.kcGood.title === item.ctKzCertificateGoodName
            )?.[0]?.kcPercent;
            return (
              <Form.Item
                validateStatus={
                  (errors.gwsRecords?.[item?.rowIndex]?.KcShare ||
                    errors.goods?.[item?.rowIndex]?.KcShare) &&
                  "error"
                }
                help={
                  errors.gwsRecords?.[item?.rowIndex]?.KcShare?.message ||
                  errors.goods?.[item?.rowIndex]?.KcShare?.message
                }
              >
                <Row gutter={8} align="middle">
                  {selectedKcShare ? (
                    <Text>{selectedKcShare}</Text>
                  ) : (
                    <Col span={20}>
                      <Input
                        value={item.kcShare}
                        type="number"
                        onChange={(e) => {
                          if (
                            isPercentage(e.currentTarget.value) &&
                            isFloat(e.currentTarget.value)
                          ) {
                            handleValueChange(
                              e.target.value,
                              "kcShare",
                              selectedRow
                            );
                          }
                        }}
                      />
                    </Col>
                  )}
                  <Col span={4}>
                    <Text>{percentSymbol}</Text>
                  </Col>
                </Row>
              </Form.Item>
            );
          }}
        />
        {isActualForm && (
          <Column
            key="certificateExpirationDate"
            width={150}
            title={t("actuals.certificateExpirationDate")}
            render={(item) => {
              const expirationDate = certificatesList?.filter(
                (certificate: ContractorCertificate) =>
                  certificate.id === item.ctKzCertificateId
              )?.[0]?.expirationDate;
              return (
                <Text>
                  {expirationDate
                    ? moment(expirationDate).format("YYYY-MM-DD")
                    : "-"}
                </Text>
              );
            }}
          />
        )}
        <Column
          title={
            isActualForm
              ? t("actuals.kcActualValue")
              : t("forecasts.kcForecastValue")
          }
          render={(item) => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            const roundedKcForecastValue = item.value * (item.kcShare / 100);
            return (
              <Text>
                {roundedKcForecastValue
                  ? `${numeral(roundedKcForecastValue).format(
                      "0,0.00"
                    )} ${currency}`
                  : "-"}
              </Text>
            );
          }}
        />
        <Column
          key="action"
          width={100}
          render={(item) => (
            <Form.Item>
              <Button
                danger
                title={t("delete")}
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => {
                  handleDeleteClick(item.rowIndex);
                }}
              />
            </Form.Item>
          )}
        />
      </Table>
      <Row style={{ marginTop: "24px" }} justify="space-between">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddRowClick}
        >
          {t("forecasts.addGoods")}
        </Button>
        <Button
          htmlType="submit"
          className="secondary-button"
          loading={addGoodsMutation.isLoading}
          style={{ marginLeft: "auto" }}
        >
          {isActualForm
            ? t("forecasts.saveAndGoToTheNextSection")
            : t("forecasts.saveAndProceed")}
        </Button>
      </Row>
    </Form>
  ) : (
    <>
      <Table
        bordered
        loading={isForecastGoodsLoading}
        locale={{ emptyText: t("noData") }}
        dataSource={data}
        pagination={false}
        scroll={{ x: 2600 }}
      >
        <Column
          fixed
          dataIndex="rowIndex"
          width={50}
          render={(title) => <Text>{Number(title) + 1}</Text>}
        />
        <Column
          fixed
          title={t("forecasts.goodsDescription")}
          width={300}
          dataIndex="kcGoodsTitle"
        />
        <Column title={t("forecasts.companyName")} dataIndex="companyName" />
        <Column
          key="relationType"
          title={t("forecasts.contractualRelationship")}
          dataIndex="relationType"
          render={(relationType) => (
            <Text>
              {t(
                `forecasts.${
                  CONTRACTUAL_RELATIONS_LIST.find(
                    (relationship) => relationship.value === relationType
                  )?.label
                }`
              )}
            </Text>
          )}
        />
        {isActualForm && (
          <>
            <Column
              key="certificate"
              title={t("actuals.ctKzCertificate")}
              width={320}
              render={(item) => (
                <Space>
                  {item.ctKzCertificateId ? (
                    <Tooltip title={t("actuals.downloadCertificate")}>
                      <DownloadFileButton
                        url={`/api/certificate/${item.ctKzCertificateId}/file`}
                        disabled={false}
                        shape="circle"
                        isGhost
                      />
                    </Tooltip>
                  ) : (
                    <Text>-</Text>
                  )}
                  <Text>{item.ctKzCertificateId}</Text>
                </Space>
              )}
            />
            <Column
              key="certificate"
              title={t("actuals.ctKzCertificateGoodName")}
              width={150}
              render={(item) =>
                item?.ctKzCertificateGoodName
                  ? item.ctKzCertificateGoodName
                  : "-"
              }
            />
          </>
        )}

        <Column
          title={t("forecasts.goodQuantity")}
          width={150}
          dataIndex="goodsQuantity"
        />
        <Column
          title={t("forecasts.goodUnitsOfMeasure")}
          render={(item) => {
            const unitOfMeasure = unitsOfMeasure?.find(
              (unit) => unit.unitId === item.unitId
            );
            return (
              <Text>
                {i18n.language === "en"
                  ? unitOfMeasure?.title_En
                  : unitOfMeasure?.title_Ru}
              </Text>
            );
          }}
        />
        <Column
          key="value"
          title={
            isActualForm
              ? t("actuals.actualValue")
              : t("forecasts.forecastValue")
          }
          dataIndex="value"
          render={(value) => (
            <Text>
              {numeral(value).format("0,0.00")} {currency}
            </Text>
          )}
        />
        <Column
          width={150}
          title={t("forecasts.CTKZ")}
          dataIndex="kcShare"
          render={(kcShare) => (
            <Text>
              {kcShare} {percentSymbol}
            </Text>
          )}
        />
        {isActualForm && (
          <Column
            key="certificateExpirationDate"
            width={150}
            title={t("actuals.certificateExpirationDate")}
            render={(item) => {
              const expirationDate =
                certificatesList?.filter(
                  (certificate: ContractorCertificate) =>
                    certificate.id === item.ctKzCertificateId
                )?.[0]?.expirationDate || item.certificateExpirationDate;
              return (
                <Text>
                  {item.certificateExpirationDate
                    ? moment(item.certificateExpirationDate).format(
                        "YYYY-MM-DD"
                      )
                    : "-"}
                </Text>
              );
            }}
          />
        )}
        <Column
          title={
            isActualForm
              ? t("actuals.kcActualValue")
              : t("forecasts.kcForecastValue")
          }
          render={(item) => {
            return (
              <Text>
                {item.kcValue || item.kcValue === 0
                  ? `${numeral(item.kcValue).format("0,0.00")} ${currency}`
                  : "-"}
              </Text>
            );
          }}
        />
      </Table>
      <Row style={{ marginTop: "24px" }} justify="space-between">
        <Button
          className="secondary-button"
          onClick={handleProceed}
          style={{ marginLeft: "auto" }}
        >
          {t("forecasts.proceed")}
        </Button>
      </Row>
    </>
  );
};
