import { contractorApi } from '@api/contractorApi';
import { countryCityApi } from '@api/countryCityApi';
import { ContractorsTable } from '@components/organisms/ContractorsTable';
import { SearchSection } from '@components/organisms/ContractorsTable/libs/components/SearchSection';
import type { SearchQueryType } from '@components/organisms/ContractorsTable/libs/types/SearchQueryType';
import { EditContractorForm } from '@components/organisms/EditContractorForm';
import { NewContractorForm } from '@components/organisms/NewContractorForm';
import type { Contractor, ContractorPaginated } from '@components/types/models/Contractor';
import { PERMISSIONS } from '@components/types/models/Permissions';
import { UserContext } from '@contexts/userContext';
import { css } from '@linaria/core';
import { objectParsers } from '@utils/objectParsers';
import { isUserHasPermission } from '@utils/permissionHelper';
import { Col, Modal, notification, Row, Space, Typography } from 'antd';
import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QueryFunctionContext } from 'react-query';
import { useMutation, useQuery } from 'react-query';
import tw from 'twin.macro';

const { Title } = Typography;

export const ContractorsPage: FC = () => {
  const { t } = useTranslation();
  const { user, notices } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType | undefined>();
  const [editableContractor, setEditableContractor] = useState<Contractor>();
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [, setIsModalShown] = useState(false);
  const hasContractorEditPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACTOR_EDIT
  );

  const fetchContractors = async (
    pageNum: number,
    bin?: string,
    name?: string,
    categories?: readonly number[]
  ): Promise<ContractorPaginated> =>
    contractorApi.getContractorsPaginated(pageNum, bin, name, categories).then((res) => res.data);

  const handleToggleEditModal = useCallback((item?: Contractor): void => {
    setEditableContractor(item);
    setIsEditModalShown((i) => !i);
  }, []);

  const handleCloseModal = (): void => {
    setIsEditModalShown(false);
  };

  const { data, refetch, isLoading } = useQuery(
    ['getContractorsPaginated', { searchQuery }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [string, { readonly searchQuery: SearchQueryType | undefined }]
      >
    ) =>
      searchQueryCallback.queryKey[1].searchQuery
        ? fetchContractors(
            page,
            searchQueryCallback.queryKey[1].searchQuery.bin,
            searchQueryCallback.queryKey[1].searchQuery.name,
            searchQueryCallback.queryKey[1].searchQuery.categories
          )
        : fetchContractors(page),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const contractorsList = useMemo<readonly Contractor[]>(
    () =>
      data?.results.map((res) =>
        notices
          ? {
              ...res,
              newContractsCount:
                notices.contractors.find((c) => c.contractorId === res.id)?.amendments ?? 0,
            }
          : res
      ) ?? [],
    [notices, data]
  );

  const categories = useQuery(
    'getCategories',
    async () =>
      contractorApi.getCategories().then((res) => objectParsers.parseCategories(res.data)),
    {
      refetchOnWindowFocus: false,
    }
  );

  const countries = useQuery(
    'getAllCountries',
    async () => countryCityApi.getAllCountries().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteContractorMutation = useMutation(
    async (id: number) => contractorApi.deleteContractor(id),
    {
      onSuccess() {
        notification.success({
          message: t('contractors.contractorDeleted'),
        });
        void refetch();
        setIsModalShown(false);
      },
    }
  );

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
  }, []);

  useEffect(() => {
    void refetch();
  }, [page, refetch]);

  const handleEditContractorFormSubmitted = useCallback(() => {
    void refetch();
  }, [refetch]);

  return (
    <Row>
      <Col
        span={12}
        className={css`
          ${tw`text-left`}
        `}
      >
        <Title level={1}>{t('contractors.contractors')}</Title>
      </Col>
      <Col
        span={12}
        className={css`
          ${tw`text-right`}
        `}
      >
        <Space>
          <NewContractorForm
            countries={countries.data || []}
            refetchList={refetch}
            isDisable={!hasContractorEditPermission}
          />
        </Space>
      </Col>
      <Col
        span={24}
        className={css`
          ${tw`text-left`}
        `}
      >
        <SearchSection categories={categories.data} onSearchQuery={handleSearchQuery} />
        <ContractorsTable
          data={contractorsList}
          setEditedContractor={setEditableContractor}
          handleDelete={deleteContractorMutation.mutate}
          isDeleteButtonLoading={deleteContractorMutation.isLoading}
          deleteContractorId={deleteContractorMutation.variables}
          isLoading={isLoading}
          pagination={{
            total: data?.totalItems,
            current: data?.currentPage,
            pageSize: data?.pageSize,
            onChange: (p) => {
              setPage(p);
            },
          }}
          onModal={handleToggleEditModal}
        />
        <Modal
          destroyOnClose
          visible={isEditModalShown}
          maskClosable={false}
          title={t('contractors.editContractor')}
          footer={null}
          width={800}
          onCancel={handleCloseModal}
        >
          <EditContractorForm
            contractorId={editableContractor?.id}
            countries={countries.data || []}
            setIsEditModalShown={setIsEditModalShown}
            onFormSubmitted={handleEditContractorFormSubmitted}
          />
        </Modal>
      </Col>
    </Row>
  );
};
