import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { SearchSectionProps } from './props';

export const SearchSection: FC<SearchSectionProps> = ({ categories, onSearchQuery }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleOnFinish = useCallback(
    (vals) => {
      onSearchQuery({
        bin: vals.bin ? vals.bin : global.undefined,
        name: vals.name ? vals.name : global.undefined,
        categories: vals.categories,
      });
    },
    [onSearchQuery]
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="bin" label={t('bin')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label={t('contractors.contractorName')}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="categories" label={t('contractors.kcCategoryAndArea')}>
            <Select
              options={categories?.map((cat) => ({
                label: cat.name,
                value: cat.id,
              }))}
              mode="multiple"
              maxTagCount="responsive"
            />
          </Form.Item>
        </Col>
        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {t('applyFilters')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
