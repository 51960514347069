import { CheckOutlined, CopyOutlined, FileTextOutlined } from "@ant-design/icons";
import { contracts } from "./contracts";
import { forecasts } from "./forecasts";
import { actuals } from "./actuals";
import { g1c } from "./g1c";

export const tcoUserguides = [
  {
    title: "contracts",
    data: contracts,
    icon: <CopyOutlined />
  },
  {
    title: "forecasts",
    data: forecasts,
    icon: <FileTextOutlined />,
  },
  {
    title: "actuals",
    data: actuals,
    icon: <CheckOutlined />,
  },
  {
    title: "g1c",
    data: g1c,
    icon: <CopyOutlined />,
  },
];