import { Badge } from "antd";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MigrationStatus } from "../types/MigrationTypes"

type MigrationStatusBadgeProps = {
  status: MigrationStatus;
}
type StatusColorFetcher = () => PresetStatusColorType;

export const MigrationStatusBadge: React.FC<MigrationStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation();

  const getStatusColor = useCallback<StatusColorFetcher>(() => {
    switch (status) {
      case MigrationStatus.Undefined:
        return "default";
      case MigrationStatus.Completed:
        return "success";
      case MigrationStatus.Failed:
        return "error";
      case MigrationStatus.Pending:
        return "warning";
      case MigrationStatus.InProgress:
        return "processing";
      default:
        return "default";
    }
  }, [status]);

  return (
    <Badge status={getStatusColor()} text={t(`migrations.statusCodes.${status}`)} />
  );
}