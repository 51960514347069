/* eslint-disable react-hooks/exhaustive-deps */

import { notification } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export const useLocalStorageListener = (): void => {
  const history = useHistory();
  const { t } = useTranslation();

  const checkTokenForExistence = (): void => {
    const userToken = localStorage.getItem('KC_PORTAL_USER_TOKEN');

    if (!userToken) {
      history.push('/');
      notification.warning({ message: t('sessionExpired'), key: 'session-expired' });
    }
  };

  useEffect(() => {
    window.addEventListener('storage', checkTokenForExistence);

    return () => {
      window.removeEventListener('storage', checkTokenForExistence);
    };
  }, []);
};
