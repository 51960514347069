import { styled } from '@linaria/react';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { LogoutButtonProps } from './props';

const LogoutButtonBase = styled(Button)`
  ${tw`border-white`}
`;

export const LogoutButton: React.FC<LogoutButtonProps> = ({ ...rest }) => {
  const [t] = useTranslation();
  return (
    <LogoutButtonBase type="primary" {...rest}>
      {t('login.logoutButton')}
    </LogoutButtonBase>
  );
};
