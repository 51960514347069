import { Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MigrationRecord, MigrationStatus, MigrationType } from "../types/MigrationTypes"
import { MigrationStatusBadge } from "./MigrationStatusBadge"

type MigrationsTableProps = {
  readonly data: readonly MigrationRecord[];
  loading: boolean;
  onPageChange: (page: number, pageSize?: number) => void;
  totalItems?: number;
  currentPage?: number;
  pageSize?: number;
}

export const MigrationsTable: React.FC<MigrationsTableProps> = ({
  onPageChange: handlePageChange,
  data,
  loading,
  totalItems,
  currentPage,
  pageSize,
}) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo<ColumnsType<MigrationRecord>>(() => ([
    {
      title: t("migrations.filename"),
      key: 'filename',
      dataIndex: 'fileName'
    },
    {
      title: t("migrations.dataType"),
      key: 'type',
      dataIndex: 'type',
      render: (value: MigrationType) => <>{t(`migrations.typeCodes.${value}`)}</>
    },
    {
      title: t("migrations.username"),
      key: 'username',
      render: (_, { createdByUser: { firstName, lastName } }: MigrationRecord) => <>{firstName + lastName}</>
    },
    {
      title: t("migrations.date"),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value: string) => <>{moment(value).format('YYYY.MM.DD HH:mm')}</>
    },
    {
      title: t("migrations.status"),
      key: 'status',
      dataIndex: 'status',
      render: (value: MigrationStatus) => <MigrationStatusBadge status={value} />
    },
  ]), [i18n.language]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={{
        onChange: handlePageChange,
        current: currentPage,
        pageSize: pageSize,
        total: totalItems,
      }}

    />
  )
}