import { EnvironmentOutlined, FlagOutlined, HomeOutlined, PhoneOutlined } from '@ant-design/icons';
import { contractorApi } from '@api/contractorApi';
import { ContractorMembersTable } from '@components/organisms/ContractorMembersTable';
import { InviteContractorMemberForm } from '@components/organisms/InviteContractorMemberForm';
import { SubcontractorsTable } from '@components/organisms/SubcontractorsTable';
import { UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import { formatPhoneNumber } from '@utils/phoneNumberFormatter';
import { Card, Col, List, Row, Typography } from 'antd';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import tw from 'twin.macro';

export const ContractorMembersList: FC = () => {
  const { user } = useContext(UserContext);
  const { Title } = Typography;
  const { i18n, t } = useTranslation();
  const [page, setPage] = useState(1);
  const contractorId = user?.userRoles[0].entityId;

  const { data: contractor, isLoading: isContractorLoading } = useDefaultQuery(
    'getAllCategories',
    async () => contractorApi.getContractorData(contractorId).then((res) => res.data)
  );
  const members = useDefaultQuery('getContractorMembers', async () =>
    contractorApi.getContractorMembers(contractorId).then((res) => res.data)
  );

  const pageSize = 20;
  const {
    data: subcontractors,
    isLoading,
    refetch: refetchSubcontractors,
  } = useQuery(
    'getSubcontractors',
    async () =>
      contractorApi
        .getContractorSubcontractors(page, pageSize, contractorId)
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    void refetchSubcontractors();
  }, [page, refetchSubcontractors]);

  return (
    <>
      <Title>{t('members.informationAboutCompany')}</Title>
      <Card title={contractor?.name} loading={isContractorLoading}>
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<FlagOutlined />}
              title={contractor?.bin ?? '-'}
              description={t('contractors.bin')}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<FlagOutlined />}
              title={
                i18n.language === 'en' ? contractor?.country?.nameEn : contractor?.country?.nameRu
              }
              description={t('contractors.country')}
            />
          </List.Item>

          <List.Item>
            <List.Item.Meta
              avatar={<HomeOutlined />}
              title={i18n.language === 'en' ? contractor?.city?.nameEn : contractor?.city?.nameRu}
              description={t('contractors.city')}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<EnvironmentOutlined />}
              title={contractor?.address ?? '-'}
              description={t('contractors.address')}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<PhoneOutlined />}
              title={contractor?.phone ? formatPhoneNumber(contractor?.phone) : '-'}
              description={t('contractors.phoneNumber')}
            />
          </List.Item>
        </List>
      </Card>
      <Row
        className={css`
          ${tw`my-8`}
        `}
      >
        <Col
          span={12}
          className={css`
            ${tw`text-left`}
          `}
        >
          <Title>{t('members.members')}</Title>
        </Col>
        <Col
          span={12}
          className={css`
            ${tw`text-right`}
          `}
        >
          <InviteContractorMemberForm
            contractorId={contractorId}
            refetchMembersList={members.refetch}
          />
        </Col>
      </Row>
      <ContractorMembersTable
        membersList={members.data ?? []}
        isMembersListLoading={members.isLoading}
        contractorName={contractor?.name}
      />
      <Title
        className={css`
          ${tw`my-8`}
        `}
      >
        {t('contractors.subcontractors')}
      </Title>
      <SubcontractorsTable
        total={subcontractors?.totalItems}
        isLoading={isLoading}
        subcontractorsList={subcontractors?.results ?? []}
        pagination={{
          onChange: (p: number) => {
            setPage(p);
          },
          total: subcontractors?.totalItems,
          current: subcontractors?.currentPage,
          pageSize: subcontractors?.pageSize,
        }}
      />
    </>
  );
};
