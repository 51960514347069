/* eslint-disable react/jsx-handler-names,*/
/* eslint-disable no-plusplus */
/* eslint-disable functional/no-loop-statement */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  GoodWorkServiceType,
  SectionType,
} from '@components/types/models/Forecast';
import { UserContext } from '@contexts/userContext';
import { css } from '@linaria/core';
import { isFloat, isPercentage } from '@utils/validate';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Upload,
} from 'antd';
import type { SelectValue } from 'antd/es/select';
import type { UploadChangeParam } from 'antd/es/upload/interface';
import Modal from 'antd/lib/modal/Modal';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Moment } from 'moment';
import type { FC } from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import tw from 'twin.macro';

import { CreateGwsForm } from '../CreateGwsForm';
import type { CreateCertificateFormProps } from './props';

type GoodsValues = {
  readonly kcPercent: any;
  readonly kcGoodId: any;
  readonly rowIndex: number;
};

type FormValues = {
  readonly certificateNumber: string;
  readonly expirationDate?: Moment;
  readonly file: any;
  readonly goods: readonly GoodsValues[];
};

export const CreateCertificateForm: FC<CreateCertificateFormProps> = ({
  refetchCertificates,
  gwsList,
  refetchGoodsList,
  onChange,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { Column } = Table;
  const { Title, Text } = Typography;
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const contractorId = user?.userRoles[0]?.entityId;
  const [file, setFile] = useState<any>();
  const percentSymbol = '%';
  const [goodsData, setGoodsData] = useState<readonly GoodsValues[]>([
    {
      kcGoodId: null,
      kcPercent: null,
      rowIndex: 0,
    },
  ]);
  const {
    reset,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm();

  const handleCloseModal = (): void => {
    reset();
    setFile(global.undefined);
    setGoodsData([
      {
        kcGoodId: null,
        kcPercent: null,
        rowIndex: 0,
      },
    ]);
    setIsModalVisible(false);
  };

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const handleFileChange = (fileList: UploadChangeParam): void => {
    setFile(fileList.fileList[0]?.originFileObj);
  };

  const handleRowChange = (value?: number): void => {
    setSelectedRow(value ?? 0);
  };

  const handleDateChange = useCallback(
    (name) => (date: Moment | null) => {
      setValue(name, date);
    },
    [setValue]
  );

  const handleValueChange = (
    value: SelectValue | string,
    fieldName: string,
    rowId: number
  ): void => {
    setGoodsData((prevData) =>
      prevData.map((item, index) => {
        if (index === rowId) {
          return { ...prevData[index], [fieldName]: value };
        }
        return item;
      })
    );
  };

  const emptyRow = useMemo(
    () => ({
      kcGoodId: null,
      kcPercent: null,
      rowIndex: goodsData.length,
    }),
    [goodsData]
  );

  const handleAddRowClick = (): void => {
    setGoodsData([...goodsData, emptyRow]);
  };

  const handleDeleteClick = (index?: number): void => {
    setGoodsData(goodsData.filter((item) => item.rowIndex !== index));
  };

  const mutation = useMutation(
    async (values: FormValues) => {
      const formData = new FormData();
      // eslint-disable-next-line functional/no-let
      for (let i = 0; i < goodsData.length; i++) {
        formData.append(
          `Goods[${i}][kcPercent]`,
          new Intl.NumberFormat(i18n.language).format(goodsData[i].kcPercent)
        );
        formData.append(`Goods[${i}][kcGoodId]`, goodsData[i].kcGoodId);
      }
      const formValues = {
        certificateNumber: values.certificateNumber,
        expirationDate: values.expirationDate?.toJSON(),
        file,
      };
      Object.entries(formValues).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
      return axios
        .post(`/api/certificate/contractor/${contractorId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => res.data);
    },
    {
      onSuccess(res) {
        onChange(res);
        handleCloseModal();
        refetchCertificates();
        void message.success(t('actuals.certificateSuccessfullySaved'));
      },
      onError(err: AxiosError) {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('error', { message: errData.message });
        }
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues): void => {
      mutation.mutate(values);
    })();
  }, [clearErrors, handleSubmit, mutation]);

  return (
    <>
      <Button
        ghost
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={handleOpenModal}
      />
      <Modal
        centered
        destroyOnClose
        width={800}
        title={t('actuals.addCtKzCertificate')}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical" onFinish={handleFormSubmit}>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.certificateNumber?.message && 'error'}
                help={errors.certificateNumber?.message}
                label={t('actuals.certificateNumber')}
              >
                <Input
                  placeholder={t('actuals.certificateNumber')}
                  value={watch('certificateNumber')}
                  {...register('certificateNumber')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t('actuals.expirationDate')}
                validateStatus={errors.expirationDate?.message && 'error'}
                help={errors.expirationDate?.message}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  value={watch('expirationDate')}
                  format="YYYY-MM-DD"
                  onChange={handleDateChange('expirationDate')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.file?.message && 'error'}
                help={errors.file?.message}
                label={t('actuals.file')}
              >
                <Upload
                  style={{ alignItems: 'flex-end' }}
                  maxCount={1}
                  accept=".pdf,.jpg, .jpeg, .png"
                  beforeUpload={() => false}
                  disabled={!watch('certificateNumber')?.length}
                  onChange={handleFileChange}
                >
                  {!file && (
                    <Button
                      type="primary"
                      htmlType="button"
                      icon={<PlusOutlined color="primary" />}
                      disabled={!watch('certificateNumber')?.length}
                    >
                      {t('actuals.addCertificate')}
                    </Button>
                  )}
                </Upload>
                <Text
                  className={css`
                    ${tw`text-xs leading-4 block`}
                  `}
                  type="secondary"
                >
                  {t('actuals.youMayUpload')}
                </Text>
              </Form.Item>
            </Col>
          </Row>
          <Table
            dataSource={goodsData}
            pagination={false}
            footer={() => (
              <Button
                ghost
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddRowClick}
              >
                {t('actuals.addRow')}
              </Button>
            )}
            locale={{ emptyText: t('noData') }}
            onRow={(_, rowIndex) => ({
              onClick: () => {
                handleRowChange(rowIndex);
              },
            })}
          >
            <Column
              key="kcGoodsId"
              fixed
              title={t('actuals.goodName')}
              width={300}
              render={(item) => (
                <Row gutter={8}>
                  <Col span={20}>
                    <Form.Item
                      validateStatus={
                        errors.goods?.[item.rowIndex]?.KcGoodId?.message &&
                        'error'
                      }
                      help={errors.goods?.[item.rowIndex]?.KcGoodId?.message}
                    >
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        value={item.kcGoodId}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          handleValueChange(value, 'kcGoodId', selectedRow);
                        }}
                      >
                        {gwsList.map(
                          (good: GoodWorkServiceType, index: number) => (
                            <Option key={index} value={good.id}>
                              {good.title}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <CreateGwsForm
                      sectionType={SectionType.Good}
                      refetch={refetchGoodsList}
                      title={t('forecasts.newGood')}
                      api={`/api/contractor/${contractorId}/good`}
                      successTitle={t('forecasts.goodAdded')}
                      handleValueChange={(value: number) => {
                        handleValueChange(value, 'kcGoodId', selectedRow);
                      }}
                    />
                  </Col>
                </Row>
              )}
            />

            <Column
              key="kcPercent"
              width={150}
              title={t('forecasts.CTKZ')}
              render={(item) => (
                <Form.Item
                  validateStatus={
                    errors.goods?.[item.rowIndex]?.KcPercent?.message && 'error'
                  }
                  help={errors.goods?.[item.rowIndex]?.KcPercent?.message}
                >
                  <Row gutter={8} align="middle">
                    <Col span={20}>
                      <Input
                        value={item.kcPercent}
                        type="number"
                        onChange={(e) => {
                          if (
                            isPercentage(e.currentTarget.value) &&
                            isFloat(e.currentTarget.value)
                          ) {
                            handleValueChange(
                              e.target.value,
                              'kcPercent',
                              selectedRow
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col span={4}>
                      <Title level={5}>{percentSymbol}</Title>
                    </Col>
                  </Row>
                </Form.Item>
              )}
            />
            <Column
              key="action"
              width={100}
              render={(item) => (
                <Form.Item>
                  <Button
                    danger
                    title={t('delete')}
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      handleDeleteClick(item.rowIndex);
                    }}
                  />
                </Form.Item>
              )}
            />
          </Table>

          <Row gutter={8}>
            <Col md={24}>
              <br />
              <Form.Item noStyle>
                <Row justify="end">
                  <Col>
                    <Space align="end">
                      <Button htmlType="button" onClick={handleCloseModal}>
                        {t('cancel')}
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={mutation.isLoading}
                      >
                        {t('save')}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
