export const enInvoices = {
  g1cForms: 'G1-C forms',
  invoiceNumber: 'Invoice number',
  g1cDateFrom: 'G1-C form date from',
  g1cDateTo: 'G1-C form date to',
  createInvoice: 'Create G1-C form',
  newInvoice: 'New G1-C form',
  invoiceIsDeleted: 'G1-C form is deleted',
  deleteInvoiceConfirmation: 'Are you sure you want to delete this G1-C form?',
  invoiceCreated: 'G1-C form is created',
  invoiceFor: 'G1-C form to {{contract}}',
  restorePreviousVersion: 'Restore previous version',
  version: 'Version {{version}}',
  invoiceDateFrom: 'Invoice date from',
  invoiceDateTo: 'Invoice date to',
  cwtNumber: 'CWT number',
  invoiceDate: 'Invoice date',
  attachment: 'Attachment',
  acceptedAttachments: 'Attachment (xlsx, docx, pdf, png, jpeg*)',
  goodName: 'Good name',
  workName: 'Work name',
  serviceName: 'Service name',
  invoicedValue: 'Invoiced value',
  kcValue: 'KC value',
  goodsSectionUpdated: 'Goods section updated',
  worksSectionUpdated: 'Works section updated',
  servicesSectionUpdated: 'Service section updated',
  preview: 'Preview',
  openG1c: 'Open G1-C',
  g1cFormUpdated: 'G1-C form details updated',
  setDueDate: 'Set a due date for the G1-C update*',
  g1cFormApproved: 'G1-C form approved',
  g1cFormRejected: 'G1-C form rejected',
};
