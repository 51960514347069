import { Button, Space } from 'antd';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ContractFiltersProps } from './props';

const filters = [
  {
    enName: 'All',
    ruName: 'Все',
    id: 0,
  },
  {
    enName: 'Pending',
    ruName: 'На рассмотрении',
    id: 1,
  },
  {
    enName: 'Draft',
    ruName: 'Черновик',
    id: 4,
  },
  {
    enName: 'Rejected',
    ruName: 'Отклонен',
    id: 2,
  },
  {
    enName: 'Approved',
    ruName: 'Утвержден',
    id: 3,
  },
];
export const ContractFilters: FC<ContractFiltersProps> = ({ onFilterClick, activeFilter }) => {
  const [, i18n] = useTranslation();
  return (
    <Space style={{ marginBottom: 16 }}>
      {filters.map((filter, index) => (
        <Button
          key={index}
          type={filter.id === activeFilter ? 'primary' : 'default'}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={onFilterClick(filter.id)}
        >
          {i18n.language === 'en' ? filter.enName : filter.ruName}
        </Button>
      ))}
    </Space>
  );
};
