export const contracts = [
  {
    title: "How to update Contract?",
    html: `
  <p class="c0"><span class="c4">Once KC Lead created Contract, email notification will be sent to you.</span></p>
  <p class="c0"><span class="c4">In the system 3 types of Contracts:</span></p>
  <ul class="c5 lst-kix_fpz22gji5z1p-0 start">
    <li class="c0 c7"><span class="c4">Stand-alone</span></li>
    <li class="c0 c7"><span class="c4">Master-agreement</span></li>
  </ul>
  <ul class="c5 lst-kix_fpz22gji5z1p-1 start">
    <li class="c0 c15"><span class="c4">Sub-agreement</span></li>
  </ul>
  <p class="c0 c3"><span class="c4"></span></p>
  <p class="c0"><span class="c4">In order to update information about your Contract, please follow the steps:</span></p>
  <ol class="c5 lst-kix_sbwwudhogi85-0 start" start="1">
    <li class="c0 c7"><span class="c4">On Company Information page navigate to Contracts block.</span></li>
    <li class="c0 c7"><span class="c4">Press on Contract number.</span></li>
    <li class="c0 c7"><span>On Contract details page press on </span><span class="c1">Update Contract </span><span
        class="c4">button.</span></li>
    <li class="c0 c7"><span>Fill out all fields and press on </span><span class="c1">Update </span><span
        class="c4">button.</span></li>
  </ol>
  <p class="c0 c3"><span class="c4"></span></p>
  <p class="c0"><span class="c1">Note: </span><span class="c4">System will send an email to KC Lead. Information is not
      editable. You can update only contracts in status Draft.</span></p>
  `
  },
  {
    title: "How to attach KC Plan?",
    html: `
  <p class="c0"><span class="c4">On Update contract modal window press on Attach KC plan button.</span></p>
  <p class="c0"><span class="c4">Upload file from your PC.</span></p>
  <p class="c0 c3"><span class="c8 c1"></span></p>
  <p class="c0"><span class="c1">Note: </span><span class="c4">file format must be .pdf .doc and .docx.</span></p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  },
  {
    title: "How to add amendments to your existing contract?",
    html: `
  <p class="c0"><span class="c4">In order to add amendments to your Contract, please follow the steps:</span></p>
  <ol class="c5 lst-kix_80bdllde9tvo-0 start" start="1">
    <li class="c0 c7"><span class="c4">On Company Information page navigate to Contracts block.</span></li>
    <li class="c0 c7"><span class="c4">Press on Contract number.</span></li>
    <li class="c0 c7"><span>On Contract details page press on </span><span class="c1">Add amendment
      </span><span>button</span><span class="c4">.</span></li>
    <li class="c0 c7"><span>Fill out all fields and press on the Add</span><span class="c1">&nbsp;</span><span
        class="c4">button.</span></li>
  </ol>
  <p class="c0 c3"><span class="c4"></span></p>
  <p class="c0"><span class="c1">Note: </span><span class="c4">You can add amendment only to approved Contracts.</span>
  </p>
  <p class="c0 c3"><span class="c4"></span></p>
  `
  }
];
