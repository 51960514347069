import { exchangeRateApi } from '@api/exchangeRateApi';
import { useDefaultQuery, useModalState } from '@hooks';
import { useCurrenciesService } from '@hooks/services';
import { Button, Modal, notification, PageHeader } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { AddCurrencyForm } from './components/AddCurrencyForm';
import { EditExchangeRatesModal } from './components/EditExchangeRatesModal';
import { ExchangeRatePeriodPicker } from './components/ExchangeRatePeriodPicker';
import { ExchangeRateTable } from './components/ExchangeRateTable';

const lastMonth = moment().subtract(1, 'months').format();

export const ExchangeRateListPage: React.FC = () => {
  const { t } = useTranslation();
  const { allCurrenciesQuery, disabledCurrencies, enabledCurrencies } = useCurrenciesService();

  const [ratePeriod, setRatePeriod] = useState<string>(lastMonth);
  const handleRatePeriodChange = (period: string): void => {
    setRatePeriod(period);
  };

  const exchangeRateQuery = useDefaultQuery(['getExchangeRates', ratePeriod], async () =>
    exchangeRateApi.getExchangeRates(ratePeriod).then((res) => res.data)
  );
  // eslint-disable-next-line
  useEffect((): void => { void exchangeRateQuery.refetch() }, [ratePeriod])

  const addCurrencyModalState = useModalState();
  const handleOpenAddCurrencyModal = (): void => {
    addCurrencyModalState.handleOpen(true);
  };

  const addCurrencyMutation = useMutation('addCurrencyMutation', {
    mutationFn: async (codes: readonly string[]) => exchangeRateApi.addCurrency(codes),
    onSuccess: () => {
      notification.success({ message: t('exchangeRates.addCurrencySuccess') });
      addCurrencyModalState.handleClose();
      void exchangeRateQuery.refetch();
      void allCurrenciesQuery.refetch();
    },
    onError: () => {
      notification.error({ message: t('exchangeRates.addCurrencyError') });
    },
  });
  const handleAddCurrencySubmit = (values: { readonly codes: readonly string[] }): void => {
    addCurrencyMutation.mutate(values.codes);
  };

  const lastUpdatedAt = useMemo(
    (): string =>
      exchangeRateQuery.data?.updatedAt
        ? moment(exchangeRateQuery.data.updatedAt).format('DD MMM YYYY HH:mm')
        : '–',
    [exchangeRateQuery.data?.updatedAt]
  );

  const handleUpdateRatesSuccess = (): void => {
    void exchangeRateQuery.refetch();
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={t('exchangeRates.exchangeRates')}
        subTitle={t('exchangeRates.updated', {
          date: lastUpdatedAt,
          name: exchangeRateQuery.data?.updatedByUserFullName ?? '–',
        })}
        extra={[
          <ExchangeRatePeriodPicker
            key="header-rate-period-picker"
            value={ratePeriod}
            onChange={handleRatePeriodChange}
          />,
          <EditExchangeRatesModal
            key="header-update-rates-button"
            currencies={enabledCurrencies}
            period={ratePeriod}
            exchangeRates={exchangeRateQuery.data?.exchangeRateDtos ?? []}
            exchangeRatesLoading={exchangeRateQuery.isLoading}
            onSuccess={handleUpdateRatesSuccess}
            onPeriodChange={handleRatePeriodChange}
          />,
          <Button key="header-add-currency" type="primary" onClick={handleOpenAddCurrencyModal}>
            {t('exchangeRates.addCurrency')}
          </Button>,
        ]}
      />
      <ExchangeRateTable
        loading={exchangeRateQuery.isLoading}
        data={exchangeRateQuery.data?.exchangeRateDtos}
      />
      <Modal
        destroyOnClose
        visible={addCurrencyModalState.isOpen}
        maskClosable={false}
        title={t('exchangeRates.addCurrency')}
        footer={null}
        onCancel={addCurrencyModalState.handleClose}
      >
        <AddCurrencyForm
          loading={addCurrencyMutation.isLoading}
          currencies={disabledCurrencies}
          currenciesLoading={allCurrenciesQuery.isLoading}
          onSubmit={handleAddCurrencySubmit}
          onCancel={addCurrencyModalState.handleClose}
        />
      </Modal>
    </>
  );
};
