import { interceptHttpErrors } from '@api';
import { HttpResponseStatusCodes } from '@components/types/HttpResponseStatusCodes';
import { UserContext } from '@contexts/userContext';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Card, Col, Form, Input, message as antdMessage, notification, Row, Typography } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

const { Text } = Typography;

export type FormValues = {
  readonly email: string;
  readonly password: string;
};

const ssoLink = process.env.REACT_APP_CT_API_URL
  ? `${process.env.REACT_APP_CT_API_URL}/api/auth/sso`
  : '';

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const { Meta } = Card;
  const { setUser, setUserCredentials } = useContext(UserContext);

  const {
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    register,
  } = useForm();

  const history = useHistory();
  const mutation = useMutation(
    async (values: FormValues) =>
      axios.post('/api/auth/login', values).then((res) => {
        const token = res.data?.apiToken;
        localStorage.setItem('KC_PORTAL_USER_TOKEN', token);
      }),
    {
      onSuccess: () => {
        interceptHttpErrors(history);
        void axios.get('/api/auth/me').then((res) => {
          setUser(res.data);
          notification.close('session-expired');
          history.push('/profile');
        });
      },
      onError: (err: AxiosError, values) => {
        const errData = err.response?.data;
        if (errData.status === HttpResponseStatusCodes) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: 'email' | 'password';
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else if (errData.status === HttpResponseStatusCodes.ExpiredPassword) {
          void antdMessage.error(t('login.yourPasswordHasExpired'));
          setUserCredentials({ password: values.password, email: values.email });
          history.push('/expired-password');
        }
        setError('errorMessage', { message: errData.message });
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues): void => {
      mutation.mutate(values);
    })();
  }, [handleSubmit, clearErrors, mutation]);

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }} className="guest-pages-bg">
      <Col span={8}>
        <Card>
          <Meta title={t('login.title')} description={t('login.subtitle')} />
          <Form onFinish={handleFormSubmit}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={t('email')}
              validateStatus={errors.email?.message && 'error'}
              help={errors.email?.message}
            >
              <Input size="large" placeholder={t('email')} {...register('email')} />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={t('login.password')}
              validateStatus={errors.password?.message && 'error'}
              help={errors.password?.message}
            >
              <Input.Password size="large" placeholder={t('password')} {...register('password')} />
            </Form.Item>
            <ErrorMessage
              errors={errors}
              name="errorMessage"
              render={({ message }) => <Text type="danger">{message}</Text>}
            />
            <Form.Item noStyle>
              <Row justify="space-between" align="middle">
                <Col span={8}>
                  <Link to="/reset">{t('login.forgotPassword')}</Link>
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <a
                    href={
                      `${ssoLink}?redirectUri=${`${window.location.origin}/sso-callback`}` || '#'
                    }
                  >
                    <Button type="link" htmlType="button">
                      {t('login.loginViaSso')}
                    </Button>
                  </a>
                  <Button htmlType="submit" type="primary" loading={mutation.isLoading}>
                    {t('login.loginButton')}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
