export const ruActuals = {
  actuals: 'Фактические показатели',
  submissionPeriod: 'Период сдачи',
  submissionDate: 'Дата отправки',
  period: 'Период',
  fromTo: 'С {{from}} по {{to}}',
  submitNewActuals: 'Отправить новые фактические показатели',
  periodAscending: 'Период (по возрастанию)',
  periodDescending: 'Период (по убыванию)',
  saveAndGoToTheNextSection: 'Сохранить и перейти к следующей секции',
  proceedAndGoToTheNextSection: 'Продолжить и перейти к следующей секции',
  configureCities: 'Редактировать города',
  selectCitiesForJobsReport: 'Выберите города для отчета о вакансиях',
  citiesUpdated: 'Список городов обновлен',
  kzNationalsForecast: 'План Казахстанцы',
  nonKzNationalsForecast: 'План Не-казахстанцы',
  nonKzMobilized: 'Моб-не казахстанцев',
  kzMobilized: 'Моб казахстанцев',
  kzDemobilized: 'Демобилизировано казахстанцев',
  nonKzDemobilized: 'Демобилизировано не-казахстанцев',
  nationalized: 'Национализировано',
  jobsPositions: 'Рабочие позиции',
  kzNationals: 'Казахстанцы',
  nonKzNationals: 'Не-казахстанцы',
  total: 'Итого',
  plannedToNationalize: 'План национализации',
  graduateSection: 'Выпускники',
  newGradInternshipsForecast: 'План Стажировки для выпускников',
  newGradInternships: 'Стажировки для выпускников',
  newGradJobsForecast: 'План Трудоустройство выпускников',
  newGradJobs: 'Трудоустройство выпускников',
  addCtKzCertificate: 'Добавить CT-KZ% сертификат',
  certificateNumber: 'Номер сертификата',
  expirationDate: 'Дата окончания срока действия',
  addCertificate: 'ДОБАВИТЬ СЕРТИФИКАТ',
  file: 'Файл',
  ctKzCertificate: 'CT-KZ сертификат #',
  ctKzCertificateGoodName: 'Название товара в CT-KZ сертификате',
  certificateExpirationDate: 'Дата окончания срока действия CT-KZ% сертификата',
  goodName: 'Название товара',
  youMayUpload: 'Вы можете загружать файлы в формате .pdf .jpg .jpeg .png',
  addRow: 'Добавить позицию',
  certificateSuccessfullySaved: 'CT-KZ% сертификат успешно сохранен',
  attachFile: 'Прикрепить файл',
  attachFileNotice: 'Приложения(не более 3 файлов)',
  attachments: 'Приложения',
  goToTheNextSection: 'Перейти к следующей секции',
  saveAndGoToTheNextCity: 'Сохранить и перейти к следующему городу',
  saveAndGoToTotal: 'Сохранить и перейти к итогу',
  actualValue: 'Сумма',
  deleteSection: 'Удалить секцию',
  addLegacy: 'Добавить Наследие',
  addTechnologyTransfer: 'Добавить Технологический процесс',
  addSuccessStories: 'Добавить История успеха',
  successActualSubmit:
    'Фактические показатели были успешно отправлены, пожалуйста ожидайте обратной связи!',
  sectionIsRequired: 'Секция обязательна к заполнению',
  submissionError:
    'Произошла ошибка отправки. Убедитесь, что все необходимые поля заполнены',
  approvedActual: 'Фактические показатели были одобрены',
  actualsForStart: 'Фактические показатели ',
  actualsForEnd: ' за {{period}}',
  setDueDate: 'Укажите срок предоставления изменений',
  rejectActual: 'Отклонить показатель',
  rejectedActual: 'Фактические показатели были отклонены',
  prepopulate: 'Заполнить',
  prepopulateWithDataFrom: 'Данными за',
  newSubcontractor: 'Новый субподрядчик',
  restorePreviousVersion: 'Восстановить предыдущую версию',
  missedDueDate: 'Вы пропустили срок сдачи',
  areYouSureDeleteAttachment:
    'Вы уверены, что хотите удалить приложение "{{file}}"?',
  jobError:
    'Поле {{mouCategory}} {{category}} {{period}} обязательно для заполнения',
  trainingError: 'Поле {{mouCategory}} обязательно для заполнения',
  section: 'Секция',
  kcActualValue: 'Сумма KC',
  kc: 'KC %',
  actualValueInOriginalCurrency: 'Сумма в исходной валюте',
  actualValueInUsd: 'Сумма в USD',
  actualsKcValueInOriginalCurrency: 'Сумма KC в исходной валюте',
  actualKcValueInUsd: 'Сумма KC в USD',
  actualKcShare: 'KC %',
};
