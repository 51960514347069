export const ContractTypes = [
  {
    enName: 'Standalone',
    ruName: 'Отдельное соглашение',
    id: 1,
  },
  {
    enName: 'Master agreement',
    ruName: 'Генеральное соглашение',
    id: 2,
  },
  {
    enName: 'Sub-agreement',
    ruName: 'Дополнительное соглашение',
    id: 3,
  },
];
