/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { actualApi } from '@api/actualApi';
import { forecastApi } from '@api/forecastApi';
import { ActualCard } from '@components/organisms/ActualCard';
import { ActualJobsForm } from '@components/organisms/ActualJobsForm';
import { ActualsGws } from '@components/organisms/ActualsGws';
import { ActualStoriesLegacyTechnologyForm } from '@components/organisms/ActualStoriesLegacyTechnologyForm';
import type { ActualStoriesLegacyTechnologyData } from '@components/organisms/ActualStoriesLegacyTechnologyForm/props';
import { ActualTrainingForm } from '@components/organisms/ActualTrainingForm';
import { MasterAgreementGwsTable } from '@components/organisms/MasterAgreementGwsTable';
import type { Actual, ActualPeriod } from '@components/types/models/Actuals';
import type { Contract } from '@components/types/models/Contract';
import { CONTRACT_TYPE } from '@components/types/models/Contract';
import { SectionType } from '@components/types/models/Forecast';
import { STATUS } from '@components/types/models/Statuses';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import {
  Alert,
  Button,
  Col,
  Collapse,
  notification,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import tw from 'twin.macro';

import { AdminRejectModal } from './libs/AdminRejectModal';

export const ActualView: FC = () => {
  const { Panel } = Collapse;
  const { Text } = Typography;
  const { id } = useParams<{ readonly id?: string }>();
  const [activeCollapseItem, setActiveCollapseItem] = useState<number>();
  const { t } = useTranslation();
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState<ActualPeriod | null>(null);
  const [daysLeftToDuedate, setDaysLeftToDueDate] = useState<number | null>(
    null
  );

  const { data: actualData, refetch: refetchActualData } =
    useDefaultQuery<Actual>(
      'getActual',
      async () => axios.get(`/api/actual/${id}`).then((res) => res.data),
      (response) => {
        setDaysLeftToDueDate(response.reviewLeftDays);
      }
    );

  const contractId = useMemo(() => actualData?.contractId, [actualData]);

  const {
    data: contractData,
    isFetching: isContractDataLoading,
    refetch: refetchContractData,
  } = useQuery<Contract>(
    ['getContract'],
    async () =>
      axios.get(`/api/contract/${contractId}`).then((res) => res.data),
    {
      enabled: false,
    }
  );

  const forecastId = useMemo(() => contractData?.forecastId, [contractData]);

  const { data: forecastTrainingData, refetch: refetchForecastTrainingData } =
    useQuery(
      'getTrainingData',
      async () =>
        forecastApi
          .getForecastTraining(contractData?.forecastId)
          .then((res) => res.data),
      {
        enabled: false,
      }
    );

  const { data: forecastJobsData, refetch: refetchForecastJobsData } = useQuery(
    'getJobsData',
    async () =>
      forecastApi
        .getForecastJobs(contractData?.forecastId)
        .then((res) => res.data),
    {
      enabled: false,
    }
  );

  const handleOpenCollapse = useCallback((key) => {
    setActiveCollapseItem(key);
  }, []);

  const approveActualMutation = useMutation(
    async () => actualApi.approveActual(Number(id)),
    {
      onSuccess() {
        notification.success({ message: t('actuals.approvedActual') });
        void refetchActualData();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data.message });
      },
    }
  );

  const rejectActualMutation = useMutation(
    async (dueDate: string) => actualApi.rejectActual(Number(id), dueDate),
    {
      onSuccess() {
        notification.success({ message: t('actuals.rejectedActual') });
        setRejectModalOpen(false);
        void refetchActualData();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data.message });
      },
    }
  );

  const handleSwitchRejectModal = useCallback(
    (state: boolean) => () => {
      setRejectModalOpen(state);
    },
    [setRejectModalOpen]
  );

  const handleSubmitActual = useCallback(() => {
    approveActualMutation.mutate();
  }, [approveActualMutation]);

  const { data: successStoriesData, refetch: refetchSuccessStoriesData } =
    useDefaultQuery<ActualStoriesLegacyTechnologyData>(
      'getSuccessStoriesData',
      async () =>
        actualApi.getSuccessStories(Number(id)).then((res) => res.data)
    );

  const { data: legacyData, refetch: refetchLegacyData } =
    useDefaultQuery<ActualStoriesLegacyTechnologyData>(
      'getLegacyData',
      async () => actualApi.getLegacy(Number(id)).then((res) => res.data)
    );

  const {
    data: technologyTransferData,
    refetch: refetchTechnologyTransferData,
  } = useDefaultQuery<ActualStoriesLegacyTechnologyData>(
    'getTechnologyTransferData',
    async () =>
      actualApi.getTechnologyTransfer(Number(id)).then((res) => res.data)
  );

  useEffect(() => {
    if (contractId) {
      void refetchContractData();
    }
  }, [contractId, refetchContractData]);

  useEffect(() => {
    if (forecastId) {
      void refetchForecastTrainingData();
      void refetchForecastJobsData();
    }
  }, [forecastId, refetchForecastJobsData, refetchForecastTrainingData]);

  useEffect(() => {
    if (actualData) {
      setCurrentPeriod({
        year: actualData.year,
        month: actualData.month,
        approvalStatus: actualData.status,
      });
    }
  }, [actualData]);

  return (
    <Row
      wrap
      className={css`
        ${tw`space-y-4`}
      `}
    >
      {actualData?.status === STATUS.PENDING && daysLeftToDuedate !== null && (
        <Col span={24}>
          <Row>
            {daysLeftToDuedate > 0 ? (
              <Alert
                showIcon
                className={css`
                  ${tw`w-full`}
                `}
                message={
                  <Translation>
                    {(tr) =>
                      tr('forecasts.timeLeftForReview', {
                        count: daysLeftToDuedate,
                      })
                    }
                  </Translation>
                }
                type="warning"
              />
            ) : (
              <Alert
                showIcon
                className={css`
                  ${tw`w-full`}
                `}
                message={t('forecasts.youHaveMissedDueDate')}
                type="error"
              />
            )}
          </Row>
        </Col>
      )}

      <Col span={24}>
        <ActualCard
          isAdmin
          loading={!contractData || isContractDataLoading}
          contract={contractData}
          actual={actualData}
        />
      </Col>

      <Col span={24}>
        {currentPeriod ? (
          <Collapse
            accordion
            destroyInactivePanel
            activeKey={activeCollapseItem}
            onChange={handleOpenCollapse}
          >
            {contractData?.contractType === CONTRACT_TYPE.MASTER_AGREEMENT ? (
              <Panel key="4" header={t('forecasts.goodsWorksServicesSummary')}>
                <MasterAgreementGwsTable isActual actualId={Number(id)} />
              </Panel>
            ) : (
              <ActualsGws
                period={currentPeriod}
                scopes={contractData?.scopes}
                contractCurrency={
                  contractData?.lastApprovedAmendment.currency.code
                }
                actualId={Number(id)}
              />
            )}

            {contractData?.contractType !== CONTRACT_TYPE.SUB_AGREEMENT && (
              <>
                <Panel
                  key="5"
                  header={<Text>{t('forecasts.jobsAndNationalization')}</Text>}
                >
                  <ActualJobsForm
                    forecastData={forecastJobsData}
                    contractId={contractData?.id}
                    activeCollapseItem={5}
                    setActiveCollapseItem={setActiveCollapseItem}
                    actualId={Number(id)}
                    period={currentPeriod}
                  />
                </Panel>
                <Panel key="6" header={<Text> {t('forecasts.training')}</Text>}>
                  <ActualTrainingForm
                    forecastData={forecastTrainingData}
                    actualId={Number(id)}
                    activeCollapseItem={6}
                    setActiveCollapseItem={setActiveCollapseItem}
                    period={currentPeriod}
                  />
                </Panel>
              </>
            )}
            {technologyTransferData && (
              <Panel key="7" header={t('forecasts.technologyTransfer')}>
                <ActualStoriesLegacyTechnologyForm
                  activeCollapseItem={7}
                  setActiveCollapseItem={setActiveCollapseItem}
                  initialDescription={technologyTransferData.description}
                  refetchData={refetchTechnologyTransferData}
                  actualId={Number(id)}
                  sectionType={SectionType.TechnologyTransfer}
                  period={currentPeriod}
                />
              </Panel>
            )}
            {legacyData && (
              <Panel key="8" header={t('forecasts.legacy')}>
                <ActualStoriesLegacyTechnologyForm
                  activeCollapseItem={8}
                  setActiveCollapseItem={setActiveCollapseItem}
                  initialDescription={legacyData.description}
                  refetchData={refetchLegacyData}
                  actualId={Number(id)}
                  sectionType={SectionType.Legacy}
                  period={currentPeriod}
                />
              </Panel>
            )}

            {successStoriesData && (
              <Panel key="9" header={t('forecasts.successStories')}>
                <ActualStoriesLegacyTechnologyForm
                  activeCollapseItem={9}
                  setActiveCollapseItem={setActiveCollapseItem}
                  initialDescription={successStoriesData.description}
                  refetchData={refetchSuccessStoriesData}
                  actualId={Number(id)}
                  sectionType={SectionType.SuccessStories}
                  period={currentPeriod}
                />
              </Panel>
            )}
          </Collapse>
        ) : (
          <Skeleton />
        )}
      </Col>

      <AdminRejectModal
        isModalOpen={isRejectModalOpen}
        rejectActualMutation={rejectActualMutation}
        onClose={handleSwitchRejectModal(false)}
      />

      {actualData?.status === STATUS.PENDING && (
        <Col span={24}>
          <Row justify="space-between">
            <Button onClick={handleSwitchRejectModal(true)}>
              {t('reject')}
            </Button>
            <Button
              type="primary"
              loading={approveActualMutation.isLoading}
              onClick={handleSubmitActual}
            >
              {t('approve')}
            </Button>
          </Row>
        </Col>
      )}
    </Row>
  );
};
