import type { AxiosResponse } from 'axios';
import axios from 'axios';

const getGoodsList = async (contractorId: number): Promise<AxiosResponse> =>
  axios.get(`/api/contractor/${contractorId}/good`);

const getWorksList = async (contractorId: number): Promise<AxiosResponse> =>
  axios.get(`/api/contractor/${contractorId}/work`);

const getServicesList = async (contractorId: number): Promise<AxiosResponse> =>
  axios.get(`/api/contractor/${contractorId}/service`);

export const goodWorkServiceApi = {
  getGoodsList,
  getWorksList,
  getServicesList,
};
