/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CitySelectorTemp } from '@components/atoms/CitySelectorTemp';
import { CountrySelectorTemp } from '@components/atoms/CountrySelectorTemp';
import type { City } from '@components/types/models/CountryCity';
import i18n from '@i18n/i18n';
import { css } from '@linaria/core';
import { Button, Col, Modal, Row, Tag } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { ActualConfigureCitiesModalProps } from './props';

export const ActualConfigureCitiesModal: FC<ActualConfigureCitiesModalProps> = ({
  isModalOpen,
  onClose,
  countries = [],
  initialCities,
  addCitiesJobMutation,
}) => {
  const { t } = useTranslation();
  const [selectedCitiesList, setSelectedCitiesList] = useState<readonly City[]>([]);
  const [allCitiesList, setAllCitiesList] = useState<readonly City[]>([]);

  const [selectCountryCities, setSelectCountryCities] = useState<readonly City[]>([]);

  const [baseCountryCities, setBaseCountryCities] = useState<readonly City[]>([]);

  const [countryCityForm, setCountryCityForm] = useState<{
    readonly countryId: number | null;
    readonly cityId: number | null;
  }>({
    countryId: null,
    cityId: null,
  });

  const handleClose = (): void => {
    setSelectedCitiesList([]);
    setCountryCityForm({
      countryId: null,
      cityId: null,
    });
    onClose();
  };

  const handleSelectChange = useCallback(
    (field: string) => (value: number) => {
      setCountryCityForm((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    },
    [setCountryCityForm]
  );

  const handleDeleteCity = useCallback(
    (city: City) => () => {
      const outputCities = selectedCitiesList.filter((selectedCity) => selectedCity.id !== city.id);

      setSelectedCitiesList(outputCities);
    },
    [selectedCitiesList]
  );

  const handleModalOkClick = useCallback(() => {
    addCitiesJobMutation?.mutate(selectedCitiesList.map((item) => item.id));
  }, [selectedCitiesList]);

  const checkForExistingCities = (): void => {
    if (selectedCitiesList.length) {
      const outputCities = [...baseCountryCities];

      selectedCitiesList.map((selectedCity) => {
        const existingCityIndex = outputCities.findIndex((city) => city.id === selectedCity.id);

        if (existingCityIndex >= 0) {
          outputCities.splice(existingCityIndex, 1);
        }

        return selectedCity;
      });

      setSelectCountryCities(outputCities);
    } else {
      setSelectCountryCities(baseCountryCities);
    }
  };

  useEffect(() => {
    if (countries.length) {
      countries.map((country) => {
        setAllCitiesList((prevState) => [...prevState, ...country.cities]);

        return country;
      });
    }
  }, [countries]);

  useEffect(() => {
    if (countryCityForm.countryId) {
      const selectedCountry = countries.find((country) => country.id === countryCityForm.countryId);

      setBaseCountryCities(selectedCountry?.cities ?? []);
    }
  }, [countryCityForm.countryId]);

  useEffect(() => {
    checkForExistingCities();
  }, [baseCountryCities]);

  useEffect(() => {
    if (countryCityForm.cityId) {
      const selectedCity = allCitiesList.find((city) => city.id === countryCityForm.cityId)!;

      setSelectedCitiesList((prevState) => [...prevState, selectedCity]);

      setSelectCountryCities((prevState) =>
        prevState.filter((city) => city.id !== selectedCity.id)
      );
    }
  }, [countryCityForm.cityId]);

  useEffect(() => {
    checkForExistingCities();

    setCountryCityForm((prevState) => ({
      ...prevState,
      cityId: null,
    }));
  }, [selectedCitiesList]);

  useEffect(() => {
    if (initialCities.length && isModalOpen) {
      setSelectedCitiesList(initialCities);
    } else {
      setSelectedCitiesList([]);
    }
  }, [initialCities, isModalOpen]);

  return (
    <Modal
      visible={isModalOpen}
      title={t('actuals.selectCitiesForJobsReport')}
      cancelText={t('cancel')}
      width={1240}
      confirmLoading={addCitiesJobMutation?.isLoading}
      footer={null}
      onCancel={handleClose}
    >
      <Row
        wrap
        className={css`
          ${tw`space-y-4`}
        `}
      >
        <Col
          span={24}
          className={css`
            ${tw`space-y-2`}
          `}
        >
          {selectedCitiesList.map((city) => (
            <Tag key={city.id} closable onClose={handleDeleteCity(city)}>
              {i18n.language === 'ru' ? city.nameRu : city.nameEn}
            </Tag>
          ))}
        </Col>

        <Col span={24}>
          <Row
            className={css`
              ${tw`space-x-4`}
            `}
          >
            <Col span={6}>
              <CountrySelectorTemp
                className={css`
                  ${tw`w-full`}
                `}
                placeholder={t('country')}
                countries={countries}
                selectedCountry={countryCityForm.countryId!}
                onChange={handleSelectChange('countryId')}
              />
            </Col>
            <Col span={6}>
              <CitySelectorTemp
                className={css`
                  ${tw`w-full`}
                `}
                placeholder={t('city')}
                disabled={!countryCityForm.countryId}
                cities={selectCountryCities}
                selectedCity={countryCityForm.cityId!}
                onChange={handleSelectChange('cityId')}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row
            justify="end"
            className={css`
              ${tw`space-x-4`}
            `}
          >
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <Button
              loading={addCitiesJobMutation?.isLoading}
              disabled={!selectedCitiesList.length}
              type="primary"
              onClick={handleModalOkClick}
            >
              {t('apply')}
            </Button>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
