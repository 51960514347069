export const ruProfile = {
  changePassword: 'Изменить пароль',
  currentPassword: 'Текущий пароль',
  newPassword: 'Новый пароль',
  confirmNewPassword: 'Подтверждение нового пароля',
  cancel: 'Отменить',
  change: 'Поменять',
  name: 'Имя',
  accountInfo: 'Учетная запись',
  personalInformation: 'Ваша личная информация',
  requiredField: 'Обязательное поле',
  doNotMatch: 'Пароли не совпадают',
  passwordChangeSuccess: 'Пароль успешно изменен',
  role: 'Роль',
};
