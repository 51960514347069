import { PaperClipOutlined } from '@ant-design/icons';
import { actualApi } from '@api/actualApi';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import { Button, Col, Modal, notification, Row, Spin, Typography, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload/interface';
import type { UploadFile } from 'antd/lib/upload/interface';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import tw from 'twin.macro';

import { parseAttachmentFormFields } from './parseAttachmentFormFields';
import type {
  ActualAttachmentForm,
  ActualAttachmentsProps,
  AttachmentItem,
  UploadAttachmentItem,
} from './props';

export const ActualAttachments: FC<ActualAttachmentsProps> = ({
  actualId,
  sectionType,
  isEditable,
}) => {
  const maxCount = 3;
  const { Text } = Typography;
  const { t } = useTranslation();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteFile, setDeleteFile] = useState<UploadFile>();

  const {
    data: initialAttachmentList = [],
    refetch: refetchAttachmentList,
    isFetching: isAttachmentListLoading,
  } = useDefaultQuery<readonly AttachmentItem[]>('getAttachmentList', async () =>
    actualApi.getAttachments(actualId).then((res) => res.data)
  );

  const attachmentList = useMemo<ReadonlyArray<UploadFile<UploadAttachmentItem>>>(
    () =>
      initialAttachmentList
        .filter((attachment) => attachment.actualsSectionType === sectionType)
        .map((attachment) => ({
          uid: attachment.attachmentId.toString(),
          attachmentId: attachment.attachmentId,
          name: attachment.fileName,
          status: 'done',
        })),
    [initialAttachmentList, sectionType]
  );

  const handleOpenDeleteModal = (file: UploadFile): boolean => {
    setDeleteModalOpen(true);
    setDeleteFile(file);
    return false;
  };

  const handleToggleDeleteModal = (): void => {
    setDeleteModalOpen((prev) => !prev);
  };

  const uploadAttachmentMutation = useMutation(
    async (payload: FormData) => actualApi.uploadAttachment(actualId, payload),
    {
      onSuccess() {
        void refetchAttachmentList();
      },
      onError(_: unknown, variables: FormData) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const attachmentFile: any = variables.get('KcPlanFile');
        notification.error({
          message: t('fileUploadError', { fileName: attachmentFile?.name, size: 8 }),
        });
      },
    }
  );

  const deleteAttachmentMutation = useMutation(
    async (attachmentId: number) => actualApi.deleteAttachment(attachmentId),
    {
      onSuccess() {
        void refetchAttachmentList();
        setDeleteModalOpen(false);
      },
    }
  );

  const downloadAttachmentMutation = useMutation(
    async ({ attachmentId }: { readonly attachmentId: number; readonly fileName: string }) =>
      actualApi.getAttachment(attachmentId),
    {
      onSuccess(response, values) {
        saveAs(new Blob([response.data]), values.fileName);
      },
    }
  );

  const handleFileChange = (uploadFile: UploadChangeParam): void => {
    const formData = new FormData();

    const payload: ActualAttachmentForm = {
      kcPlanFile: uploadFile.file,
      sectionType,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedValues: any = {
      ...parseAttachmentFormFields(payload, uploadFile.file),
    };

    Object.keys(parsedValues).forEach((key) => {
      formData.append(key, parsedValues[key]);
    });

    uploadAttachmentMutation.mutate(formData);
  };

  const handleDownloadFile = (file: UploadFile): void => {
    downloadAttachmentMutation.mutate({ attachmentId: Number(file.uid), fileName: file.name });
  };

  const handleDeleteFile = (): void => {
    deleteAttachmentMutation.mutate(Number(deleteFile?.uid));
  };

  return (
    <Col span={8}>
      <Row wrap>
        <Text
          type="secondary"
          className={css`
            ${tw`mb-3`}
          `}
        >
          {isEditable ? t('actuals.attachFileNotice') : t('actuals.attachments')}
        </Text>
        <Col span={24}>
          <Spin spinning={isAttachmentListLoading || uploadAttachmentMutation.isLoading}>
            <>
              <Upload
                style={{ alignItems: 'flex-end' }}
                showUploadList={{
                  showDownloadIcon: true,
                  showRemoveIcon: true,
                }}
                beforeUpload={() => false}
                disabled={!isEditable}
                // eslint-disable-next-line
                // @ts-ignore: bypass Upload component dumb check
                fileList={attachmentList}
                accept=".xlsx, .xls, .docx, .doc, .pdf, .zip, .rar"
                customRequest={global.undefined}
                onChange={handleFileChange}
                onDownload={handleDownloadFile}
                onRemove={handleOpenDeleteModal}
              >
                {isEditable && (
                  <Button
                    disabled={attachmentList.length === maxCount}
                    type="primary"
                    htmlType="button"
                    icon={<PaperClipOutlined />}
                  >
                    {t('actuals.attachFile')}
                  </Button>
                )}
              </Upload>
              <Modal
                okText={t('yes')}
                okType="danger"
                cancelText={t('cancel')}
                visible={isDeleteModalOpen}
                confirmLoading={deleteAttachmentMutation.isLoading}
                title={t('delete')}
                closable={false}
                maskClosable={false}
                onCancel={handleToggleDeleteModal}
                onOk={handleDeleteFile}
              >
                {t('actuals.areYouSureDeleteAttachment', { file: deleteFile?.name })}
              </Modal>
            </>
          </Spin>
        </Col>
      </Row>
    </Col>
  );
};
