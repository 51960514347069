import { Button, Card, Form, Radio, RadioChangeEvent, Space, Spin, Typography, Upload } from "antd"
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next"
import { MigrationType } from "../types/MigrationTypes";
import * as migrationsApi from '../api/migrationsApi';
import { DownloadFileButton } from "@components/atoms/DownloadFileButton";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { useMutation } from "react-query";

type MigrationUploadFormProps = {
  onFileUpload: (migrationType: MigrationType, formData: FormData) => void;
  isLoading: boolean;
}

export const MigrationUploadForm: React.FC<MigrationUploadFormProps> = ({
  onFileUpload,
  isLoading
}) => {
  const { t } = useTranslation();

  const [migrationType, setMigrationType] = useState<MigrationType>(MigrationType.Undefined);
  const handleMigrationTypeChange = useCallback((e: RadioChangeEvent) => {
    setMigrationType(e.target.value);
  }, [setMigrationType]);

  const handleMigrationUpload = useCallback((uploadFile: RcFile) => {
    const formData = new FormData();

    if (uploadFile) {
      formData.append('File', uploadFile);
      onFileUpload(migrationType, formData);
    }

    // To prevent upload from ant.design component itself
    return false;
  }, [onFileUpload, migrationType])

  return (
    <Spin spinning={isLoading}>
      <Card>
        <Space direction="vertical" size="large">
          <Typography.Title level={4}>{t("migrations.selectType")}</Typography.Title>

          <Radio.Group onChange={handleMigrationTypeChange} value={migrationType}>
            <Radio value={MigrationType.Metrics}>{t(`migrations.typeCodes.${MigrationType.Metrics}`)}</Radio>
            <Radio value={MigrationType.Contracts}>{t(`migrations.typeCodes.${MigrationType.Contracts}`)}</Radio>
            <Radio value={MigrationType.Contractors}>{t(`migrations.typeCodes.${MigrationType.Contractors}`)}</Radio>
          </Radio.Group>

          <Space align="start">
            <DownloadFileButton
              disabled={migrationType === MigrationType.Undefined}
              url={`/api/migrations/${migrationType}/template`}
              title={t('migrations.downloadTemplate')}
              type="link"
            />

            <Upload
              fileList={[]}
              style={{ alignItems: 'flex-end' }}
              beforeUpload={handleMigrationUpload}
              accept=".xlsx, .xls"
              maxCount={1}
            >
              <Button disabled={migrationType === MigrationType.Undefined} type="primary">
                {t('migrations.upload')}
              </Button>
            </Upload>
          </Space>
        </Space>
      </Card>
    </Spin>
  )
}