/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import type {
  ForecastJobsData,
  ForecastJobsDataItem,
  // MouCategorySubCategory,
  MouCategorySubCategoryItem,
} from '@components/organisms/ForecastJobsForm/props';
import i18n from '@i18n/i18n';
import { css } from '@linaria/core';
import { truncateText } from '@utils/truncate';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import type { ForecastJobsPerYearProps } from './props';

const defaultValue = {
  engineering: {},
  supervisorAndForeman: {},
  administration: {},
  craft: {},
  heavyEquipmentOperator: {},
  total: {},
};

export const ForecastJobsPerYear: FC<ForecastJobsPerYearProps> = ({
  currentPeriod,
  mouCategoryRowHeaders,
  initialData,
  currentMouCategory,
  mouCategories,
  onSave,
  isEditable,
}) => {
  const [periodJobsData, setPeriodJobsData] = useState<ForecastJobsData>(defaultValue);
  const [yearsList, setYearsList] = useState<readonly number[]>([]);

  const { t } = useTranslation();

  const sliceCount = 20;
  const jobsPositionRowHeadersIndex = 4;
  const graduateSectionRowHeadersIndex = -2;
  const jobsPositionRowHeaders = mouCategoryRowHeaders.slice(0, jobsPositionRowHeadersIndex);
  const graduateSectionRowHeaders = mouCategoryRowHeaders.slice(graduateSectionRowHeadersIndex);

  const setJobsData = (): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputJobsData: any = {};

    mouCategories?.map((mouCategory) => {
      if (mouCategory.title === 'total') return false;

      yearsList.map((year: number) => {
        const currentPeriodData = initialData?.find(
          (item: ForecastJobsDataItem) =>
            item.year === year && item.mouCategory === mouCategory.value
        );

        outputJobsData[mouCategory.title] = {
          ...outputJobsData[mouCategory.title],
          [year]: {
            year,
            kzNationals:
              typeof currentPeriodData?.kzNationals === 'number'
                ? currentPeriodData.kzNationals
                : '',
            nonKzNationals:
              typeof currentPeriodData?.nonKzNationals === 'number'
                ? currentPeriodData.nonKzNationals
                : '',
            plannedToNationalize:
              typeof currentPeriodData?.plannedToNationalize === 'number'
                ? currentPeriodData.plannedToNationalize
                : '',
            newGradInternships:
              typeof currentPeriodData?.newGradInternships === 'number'
                ? currentPeriodData.newGradInternships
                : '',
            newGradJobs:
              typeof currentPeriodData?.newGradJobs === 'number'
                ? currentPeriodData.newGradJobs
                : '',
            nationalizationPositions: currentPeriodData?.nationalizationPositions
              ? currentPeriodData.nationalizationPositions
              : [],
            mouCategory: mouCategory.value,
          },
        };

        return year;
      });

      return mouCategory;
    });

    setPeriodJobsData(outputJobsData);
  };

  const setJobsValue = ({
    value,
    headerTitle,
    year,
  }: {
    readonly value: string;
    readonly headerTitle: string;
    readonly year: number;
  }): void => {
    const updatedList = { ...periodJobsData };

    updatedList[currentMouCategory] = {
      ...updatedList[currentMouCategory],
      [year]: {
        ...updatedList[currentMouCategory][year],
        [headerTitle]: value,
      },
    };

    setPeriodJobsData(updatedList);
  };

  const getTotalPositionsTotal = (year: number): number => {
    const currentMonth = periodJobsData[currentMouCategory]?.[year];

    return Number(currentMonth?.kzNationals) + Number(currentMonth?.nonKzNationals);
  };

  const handleOnSave = useCallback(
    (jobsData: ForecastJobsData) => () => {
      onSave(jobsData);
    },
    [onSave]
  );

  const handleSetJobsValue = useCallback(
    (payload: { readonly headerTitle: string; readonly year: number }) =>
      (event: React.FormEvent<HTMLInputElement>) => {
        const outputValue = {
          ...payload,
          value: event.currentTarget.value,
        };

        setJobsValue(outputValue);
      },
    [setJobsValue]
  );

  const saveText = useMemo(() => {
    if (!isEditable) {
      if (currentMouCategory === 'heavyEquipmentOperator') {
        return t('forecasts.proceedAndGoToTheNextSection');
      }
      return t('forecasts.proceedAndGoToTheNextCategory');
    }

    if (currentMouCategory === 'heavyEquipmentOperator') {
      return t('forecasts.saveAndGoToTheNextSection');
    }

    return t('forecasts.saveAndGoToTheNextCategory');
  }, [currentMouCategory, i18n.language]);

  useEffect(() => {
    setJobsData();
  }, [yearsList]);

  useEffect(() => {
    if (currentPeriod) {
      setYearsList(currentPeriod.years.filter((year: number) => year >= currentPeriod.year));
    }
  }, [currentPeriod]);

  useEffect(() => {
    setJobsData();
  }, [currentMouCategory]);

  return (
    <Row
      className={css`
        ${tw`space-y-4`}
      `}
    >
      <Col span={24}>
        <Row>
          <Col
            span={4}
            className={css`
              ${tw`font-bold`}
            `}
          >
            {t('forecasts.jobsPositions')}
          </Col>
          <Col span={20}>
            <Row
              className={css`
                ${tw`space-x-6`}
              `}
            >
              {yearsList.map((year: number) => (
                <div
                  key={year}
                  className={css`
                    ${tw`flex-1 flex justify-center font-bold capitalize`}
                    min-width: 50px;
                    max-width: 50px;
                    color: #9e9e9e;
                  `}
                >
                  {year}
                </div>
              ))}
            </Row>
          </Col>
        </Row>

        <Row
          className={css`
            ${tw`space-y-2`}
          `}
        >
          {jobsPositionRowHeaders.map((header: MouCategorySubCategoryItem, index: number) => (
            <React.Fragment key={index}>
              <Col
                span={4}
                className={css`
                  ${tw`flex items-center`}
                `}
              >
                <Tooltip title={header.text}>{truncateText(header.text ?? '', sliceCount)}</Tooltip>
              </Col>
              <Col span={20}>
                <Row
                  className={css`
                    ${tw`space-x-6`}
                  `}
                >
                  {yearsList.map((year: number) => (
                    <div
                      key={year}
                      className={css`
                        ${tw`flex-1 flex justify-center items-center`}
                        min-width: 50px;
                        max-width: 50px;
                      `}
                    >
                      <Row>
                        {header.title === 'total' ? (
                          <span>{getTotalPositionsTotal(year)}</span>
                        ) : (
                          <Input
                            type="number"
                            className={css`
                              ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                              width: 100%;
                              border: 1px solid #e2e2e2;
                            `}
                            value={periodJobsData[currentMouCategory]?.[year][header.title]}
                            disabled={!isEditable}
                            onChange={handleSetJobsValue({
                              headerTitle: header.title ?? '',
                              year,
                            })}
                          />
                        )}
                      </Row>
                    </div>
                  ))}
                </Row>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </Col>

      <Col span={24}>
        <Row>
          <Col
            span={4}
            className={css`
              ${tw`font-bold`}
            `}
          >
            {t('forecasts.graduateSection')}
          </Col>
          <Col span={20}>
            <Row
              className={css`
                ${tw`space-x-6`}
              `}
            >
              {yearsList.map((year: number) => (
                <div
                  key={year}
                  className={css`
                    ${tw`flex-1 flex justify-center font-bold capitalize`}
                    min-width: 50px;
                    max-width: 50px;
                    color: #9e9e9e;
                  `}
                >
                  {year}
                </div>
              ))}
            </Row>
          </Col>
        </Row>

        <Row
          className={css`
            ${tw`space-y-2`}
          `}
        >
          {graduateSectionRowHeaders.map((header: MouCategorySubCategoryItem, index: number) => (
            <React.Fragment key={index}>
              <Col
                span={4}
                className={css`
                  ${tw`flex items-center`}
                `}
              >
                <Tooltip title={header.text}>{truncateText(header.text ?? '', sliceCount)}</Tooltip>
              </Col>
              <Col span={20}>
                <Row
                  className={css`
                    ${tw`space-x-6`}
                  `}
                >
                  {yearsList.map((year: number) => (
                    <div
                      key={year}
                      className={css`
                        ${tw`flex-1 flex justify-center items-center`}
                        min-width: 50px;
                        max-width: 50px;
                      `}
                    >
                      {header.title === 'total' ? (
                        <span>{getTotalPositionsTotal(year)}</span>
                      ) : (
                        <Input
                          type="number"
                          className={css`
                            ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                            width: 100%;
                            border: 1px solid #e2e2e2;
                          `}
                          value={periodJobsData[currentMouCategory]?.[year][header.title]}
                          disabled={!isEditable}
                          onChange={handleSetJobsValue({
                            headerTitle: header.title ?? '',
                            year,
                          })}
                        />
                      )}
                    </div>
                  ))}
                </Row>
              </Col>
            </React.Fragment>
          ))}
        </Row>
      </Col>
      <Col
        span={24}
        className={css`
          ${tw`mt-4 flex justify-end`}
        `}
      >
        <Button className="secondary-button" onClick={handleOnSave(periodJobsData)}>
          {saveText}
        </Button>
      </Col>
    </Row>
  );
};
