import { City } from '@components/types/models/CountryCity';
import { Table } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CountriesCitiesTableProps } from './props';

export const CountriesCitiesTable: FC<CountriesCitiesTableProps> = ({
  countriesCitiesList,
  isLoading
}) => {
  const { Column } = Table;
  const { t } = useTranslation();

  return (
    <Table
      scroll={{ y: '100vh' }}
      dataSource={countriesCitiesList}
      loading={isLoading}
      pagination={false}
      rowKey="id"
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Table
              dataSource={record.cities.slice(1)}
              pagination={false}
              showHeader={false}
              style={{ margin: '0' }}
            >
              <Column width="20%" />
              <Column width="20%" />
              <Column width="20%" dataIndex='nameEn'/>
              <Column dataIndex="nameRu" width="20%" />
              <Column dataIndex="regionNameEn" width="20%" render={(region: string) => region ?? '-'}/>
            </Table>
          );
        },
        rowExpandable: (record) => record.cities.length > 1
      }}
    >
      <Column title={t('reports.countryInEng')} dataIndex="nameEn" width="20%" />
      <Column title={t('reports.countryInRus')} dataIndex="nameRu" width="20%" />
      <Column
        title={t('reports.cityInEng')}
        dataIndex="cities"
        render={(cities: City[]) => cities[0]?.nameEn ?? '-'}
        width="20%"
      />
      <Column
        title={t('reports.cityInRus')}
        dataIndex="cities"
        render={(cities: City[]) => cities[0]?.nameRu ?? '-'}
        width="20%"
      />
      <Column
        title={t('reports.region')}
        dataIndex="cities"
        render={(cities: City[]) => cities[0]?.regionNameEn ?? '-'}
        width="20%"
      />
    </Table>
  );
};
