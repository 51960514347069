/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { HomeOutlined } from '@ant-design/icons';
import { actualApi } from '@api/actualApi';
import { companyApi } from '@api/companyApi';
import { ActualAttachments } from '@components/organisms/ActualAttachments';
import { CommentCard } from '@components/organisms/CommentCard';
import { ActualConfigureCitiesModal } from '@components/organisms/JobsTraining/libs/ActualConfigureCitiesModal';
import { NationalizationModal } from '@components/organisms/JobsTraining/libs/NationalizationModal';
import type { NationalizeItem } from '@components/organisms/JobsTraining/libs/NationalizationModal/props';
import type { NationalizeListItem } from '@components/organisms/JobsTraining/types';
import { PERIOD_STATUS } from '@components/types/models/Actuals';
import type { CompanyType } from '@components/types/models/Forecast';
import { SectionType } from '@components/types/models/Forecast';
import { UserContext } from '@contexts/userContext';
import { useActualForecast } from '@hooks/useActualForecast';
import i18n from '@i18n/i18n';
import { css } from '@linaria/core';
import { Button, Col, notification, Row, Spin, Typography } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import moment from 'moment';
import type { FC, ReactNode } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import tw from 'twin.macro';

import { ActualJobsEditForm } from './libs/ActualJobsEditForm';
import type { ActualJobsData, ActualJobsDataForm, ActualJobsFormProps } from './props';

export const ActualJobsForm: FC<ActualJobsFormProps> = ({
  period,
  countries,
  forecastData,
  isEditable,
  refetchPeriodList,
  activeCollapseItem,
  setActiveCollapseItem,
  actualId,
  periodList,
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [isCitiesModalOpen, setCitiesModalOpen] = useState(false);
  const { mouCategories } = useActualForecast({});
  const [isTotalMode, setTotalMode] = useState(false);
  const [currentCityId, setCurrentCityId] = useState<number | null>(null);
  const [isNationalizedModalOpen, setNationalizedModalOpen] = useState<boolean>(false);
  const [nationalizeItem, setNationalizeItem] = useState<NationalizeItem>({});
  const [isPrepopulated, setPrepopulated] = useState(false);
  const [jobsData, setJobsData] = useState<ActualJobsData>();

  const { user } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId;

  const previousApprovedActual = useMemo(() => {
    const currentPeriodIndex = periodList?.findIndex(
      (item) => item.year === period?.year && item.month === period.month
    );

    const beforePeriodList = periodList?.slice(0, currentPeriodIndex).reverse();

    return beforePeriodList?.find(
      (periodItem) => periodItem.approvalStatus === PERIOD_STATUS.APPROVED
    );
  }, [periodList]);

  const previousApprovedActualId = useMemo(
    () => previousApprovedActual?.actuals?.[previousApprovedActual.actuals.length - 1].actualId,
    [previousApprovedActual]
  );

  const fetchActualId = useMemo(
    () => (isPrepopulated ? previousApprovedActualId : actualId),
    [isPrepopulated]
  );

  const handleOpenCitiesModal = useCallback(() => {
    setCitiesModalOpen(true);
  }, [isCitiesModalOpen]);

  const handleCloseCitiesModal = useCallback(() => {
    setCitiesModalOpen(false);
  }, [isCitiesModalOpen]);

  const handleOpenNationalizedModal = useCallback(
    (mouCategory: number, nationalizedList: readonly NationalizeListItem[] = []) =>
      () => {
        const mouCategoryItem = mouCategories.find((category) => category.value === mouCategory);

        setNationalizeItem({
          mouCategory,
          year: period?.year,
          month: period?.month,
          title: mouCategoryItem?.text,
          items: nationalizedList,
        });
        setNationalizedModalOpen(true);
      },
    [nationalizeItem]
  );

  const handleCloseNationalizedModal = useCallback(() => {
    setNationalizeItem({});
    setNationalizedModalOpen(false);
  }, [isNationalizedModalOpen]);

  const handleChangeCity = useCallback(
    (cityId: number) => () => {
      setTotalMode(false);
      setCurrentCityId(cityId);
    },
    [currentCityId]
  );

  const { data: companiesList, refetch: refetchCompaniesList } = useQuery<readonly CompanyType[]>(
    'getContractorRelatedCompaniesList',
    async () => companyApi.getContractorRelatedCompaniesList(contractorId).then((res) => res.data),
    {
      enabled: false,
    }
  );

  const {
    data: initialJobData,
    refetch: refetchInitialJobData,
    isFetching: isInitialJobDataLoading,
  } = useQuery<ActualJobsData | null>(
    'getActualJobsData',
    async () => actualApi.getActualJobs(fetchActualId!).then((res) => res.data),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    setJobsData(initialJobData!);
  }, [initialJobData]);

  const switchSection = (): void => {
    setActiveCollapseItem(activeCollapseItem + 1);
  };

  const handleSetTotal = useCallback(() => {
    setCurrentCityId(null);
    setTotalMode(true);
    void refetchInitialJobData();
  }, [setTotalMode]);

  const addJobsDataMutation = useMutation(
    async (values: ActualJobsDataForm) => actualApi.saveJobsData(actualId, currentCityId, values),
    {
      onSuccess() {
        notification.success({ message: t('saved') });
        void refetchInitialJobData();
        const currentCityIndex = jobsData?.cities.findIndex((city) => city.id === currentCityId);

        if (typeof currentCityIndex === 'number') {
          const nextCity = jobsData?.cities[currentCityIndex + 1];
          if (nextCity) {
            setCurrentCityId(nextCity.id);
          } else {
            setCurrentCityId(null);
            setTotalMode(true);
          }
        }
      },
    }
  );

  const addCitiesJobMutation = useMutation(
    async (cityIds: readonly number[]) =>
      actualId && actualApi.saveActualJobsCities(actualId, cityIds),
    {
      onSuccess() {
        notification.success({ message: t('actuals.citiesUpdated') });

        if (!isPrepopulated) {
          void refetchInitialJobData().then((res) => {
            setCurrentCityId(res.data?.cities[0]?.id ?? null);
          });
        }

        if (!period?.approvalStatus) {
          refetchPeriodList?.();
        }

        setCitiesModalOpen(false);
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      },
    }
  );

  const addNationalizeDataMutation = useMutation(
    async (payload: {
      readonly mouCategory: number;
      readonly positions: readonly NationalizeListItem[];
    }) => {
      const { mouCategory, positions } = payload;

      return axios.put(`api/actual/${actualId}/job/city/${currentCityId}/nationalization`, {
        mouCategory,
        positions,
      });
    },
    {
      onSuccess() {
        notification.success({ message: t('saved') });
        setNationalizedModalOpen(false);
      },
    }
  );

  const renderSelectCities = (): ReactNode => (
    <Col span={24}>
      <Row
        align="middle"
        justify="center"
        className={css`
          ${tw`py-4 space-x-4`}
        `}
      >
        <HomeOutlined
          className={css`
            ${tw`text-xl text-gray-500`}
          `}
        />
        <Text
          className={css`
            ${tw`text-gray-500 -mt-1`}
          `}
        >
          {t('actuals.selectCitiesForJobsReport')}
        </Text>
      </Row>
    </Col>
  );

  useEffect(() => {
    if (actualId) {
      void refetchInitialJobData().then((res) => {
        setCurrentCityId(res.data?.cities[0]?.id ?? null);
      });
    }
  }, [actualId]);

  useEffect(() => {
    if (isEditable) {
      void refetchCompaniesList();
    }
  }, [isEditable]);

  const handlePrepopulateJobData = useCallback(() => {
    setPrepopulated(true);
  }, [isPrepopulated]);

  useEffect(() => {
    if (isPrepopulated) {
      void refetchInitialJobData().then((res) => {
        const newCities = res.data?.cities.map((city) => city.id);
        addCitiesJobMutation.mutate(newCities!);
        setCurrentCityId(res.data?.cities[0]?.id ?? null);
        setPrepopulated(false);
      });
    }
  }, [isPrepopulated]);

  return (
    <Row
      className={css`
        ${tw`space-y-4`}
      `}
    >
      <Col span={24}>
        <Spin spinning={isInitialJobDataLoading}>
          <Col
            span={24}
            className={css`
              ${tw`space-y-2`}
            `}
          >
            {contractorId && isEditable && (
              <Col span={24}>
                <Row
                  align="middle"
                  className={css`
                    ${tw`space-x-2`}
                  `}
                >
                  {contractorId && (
                    <Button
                      disabled={!previousApprovedActual}
                      type="primary"
                      onClick={handlePrepopulateJobData}
                    >
                      {t('actuals.prepopulate')}
                    </Button>
                  )}
                  {previousApprovedActual && (
                    <Text>
                      {t('actuals.prepopulateWithDataFrom')}{' '}
                      {moment()
                        .month(Number(previousApprovedActual.month) - 1)
                        .locale(i18n.language)
                        .format('MMMM')}{' '}
                      {previousApprovedActual.year}
                    </Text>
                  )}
                </Row>
              </Col>
            )}
            <Row
              className={css`
                ${tw`space-x-2`}
              `}
            >
              {isEditable && (
                <Button onClick={handleOpenCitiesModal}>{t('actuals.configureCities')}</Button>
              )}
              {Number(jobsData?.cities.length) > 0 && (
                <>
                  {jobsData?.cities.map((city) => (
                    <Button
                      key={city.id}
                      type={currentCityId === city.id ? 'primary' : 'default'}
                      onClick={handleChangeCity(city.id)}
                    >
                      {i18n.language === 'ru' ? city.nameRu : city.nameEn}
                    </Button>
                  ))}
                  <Button type={isTotalMode ? 'primary' : 'default'} onClick={handleSetTotal}>
                    {t('total')}
                  </Button>
                </>
              )}
            </Row>

            {jobsData?.cities.length ? (
              <ActualJobsEditForm
                period={period!}
                isTotalMode={isTotalMode}
                jobsData={jobsData}
                forecastData={forecastData}
                currentCityId={currentCityId}
                addJobsDataMutation={addJobsDataMutation}
                addNationalizeDataMutation={addNationalizeDataMutation}
                isEditable={isEditable}
                switchSection={switchSection}
                onOpenNationalizedModal={handleOpenNationalizedModal}
              >
                <NationalizationModal
                  isActual
                  isModalOpen={isNationalizedModalOpen}
                  nationalizeItem={nationalizeItem}
                  countries={countries ?? []}
                  companiesList={companiesList}
                  isEditable={isEditable}
                  addNationalizeDataMutation={addNationalizeDataMutation}
                  onClose={handleCloseNationalizedModal}
                />
              </ActualJobsEditForm>
            ) : (
              renderSelectCities()
            )}

            <ActualAttachments
              actualId={actualId}
              isEditable={isEditable}
              sectionType={SectionType.JobAndNationalization}
            />

            <Col span={24}>
              <CommentCard
                isActual
                id={actualId}
                status={period?.approvalStatus}
                sectionType={SectionType.JobAndNationalization}
              />
            </Col>

            <ActualConfigureCitiesModal
              isModalOpen={isCitiesModalOpen}
              countries={countries ?? []}
              addCitiesJobMutation={addCitiesJobMutation}
              initialCities={jobsData?.cities ?? []}
              onClose={handleCloseCitiesModal}
            />
          </Col>
        </Spin>
      </Col>
    </Row>
  );
};
