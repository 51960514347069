export type DatadumpRecord = {
  readonly id: number;
  readonly fileName: string;
  readonly uniqueFileName: string;
  readonly unixTime: number;
  readonly date: number | string;
  readonly status: DatadumpStatuses;
};

/* eslint-disable @typescript-eslint/naming-convention */
export enum DatadumpStatuses {
  inProgress = 1,
  completed = 2,
}
