import { contractorApi } from '@api/contractorApi';
import { CitySelectorTemp } from '@components/atoms/CitySelectorTemp';
import { CountrySelectorTemp } from '@components/atoms/CountrySelectorTemp';
import type { EditContractorFormProps } from '@components/organisms/EditContractorForm/props';
import type { FormValues } from '@components/types/models/Contract';
import type { ContractorEdit } from '@components/types/models/Contractor';
import { ErrorMessage } from '@hookform/error-message';
import { useDefaultQuery } from '@hooks';
import { Button, Col, Form, Input, notification, Row, Space, Typography } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useMutation } from 'react-query';

export const EditContractorForm: FC<EditContractorFormProps> = ({
  countries,
  contractorId,
  setIsEditModalShown,
  onFormSubmitted,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<number>();
  const [t] = useTranslation();
  const { Text } = Typography;

  const { data: contractor } = useDefaultQuery('getContractorData', async () =>
    contractorApi.getContractorData(contractorId).then((res) => res.data)
  );

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    clearErrors,
    setError,
  } = useForm();

  const handleCancel = (): void => {
    setIsEditModalShown(false);
  };

  useEffect(() => {
    if (contractor) {
      setValue('name', contractor.name);
      setValue('address', contractor.address);
      setValue('countryId', contractor.countryId);
      setValue('cityId', contractor.cityId);
      setValue('bin', contractor.bin);
      setValue('phone', contractor.phone);
      setValue('supervisorEmail', contractor.supervisorEmail);
      setSelectedCountry(contractor.countryId);
    } else {
      reset();
    }
  }, [contractor, setValue, reset]);

  const mutation = useMutation(
    async (values: FormValues) => axios.put(`/api/contractor/${contractorId}`, values),
    {
      onSuccess() {
        notification.success({
          message: t('contractors.successUpdate'),
        });
        setIsEditModalShown(false);
        onFormSubmitted();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.message) {
          setError('errorMessage', { message: errData.message });
        }
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error: { readonly name: any; readonly description: string }): void => {
              setError(error.name, { message: error.description });
            }
          );
        }
      },
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: ContractorEdit) => {
      const phone = values.phone?.replace(/\D/gu, '');
      const formValues = {
        ...values,
        phone,
      };
      const data = Object.fromEntries(
        // eslint-disable-next-line eqeqeq, no-eq-null
        Object.entries(formValues).filter(([_, v]) => v != null && v != '')
      );
      mutation.mutate(data);
    })();
  }, [clearErrors, handleSubmit, mutation]);

  const handleCitySelectorChange = useCallback(
    (value: number) => {
      setValue('cityId', value);
    },
    [setValue]
  );

  const handleInputChange = useCallback(
    (name) => (e: React.FormEvent<HTMLInputElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const handleCountrySelectorChange = useCallback(
    (value: number) => {
      setSelectedCountry(value);
      setValue('countryId', value);
      setValue('cityId', global.undefined);
      if (value !== 1) {
        setValue('bin', '');
      }
    },
    [setValue]
  );

  return (
    <Form onFinish={handleFormSubmit}>
      <Text strong style={{ fontSize: '16px' }}>
        {t('contractors.companyInformation')}
      </Text>
      <Row gutter={8}>
        <Col md={24}>
          <ErrorMessage
            errors={errors}
            name="errorMessage"
            render={({ message }) => <Text type="danger">{message}</Text>}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            required
            label={t('contractors.contractorName')}
            labelCol={{ span: 24 }}
            validateStatus={errors.name && 'error'}
            help={errors.name?.message}
          >
            <Input
              placeholder={t('contractors.contractorName')}
              value={watch('name')}
              onChange={handleInputChange('name')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            validateStatus={errors.phone?.message && 'error'}
            help={errors.phone?.message}
            label={t('contractors.workPhoneNumber')}
            labelCol={{ span: 24 }}
          >
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange: handleChange } }) => (
                <InputMask mask="+7(999)999 99 99" value={watch('phone')} onChange={handleChange}>
                  {(inputProps: unknown) => <Input {...inputProps} type="tel" className="input" />}
                </InputMask>
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={8}>
          <Form.Item
            required
            label={t('country')}
            labelCol={{ span: 24 }}
            validateStatus={errors.countryId && 'error'}
            help={errors.countryId?.message}
          >
            <CountrySelectorTemp
              countries={countries}
              selectedCountry={watch('countryId')}
              onChange={handleCountrySelectorChange}
            />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            required
            label={t('city')}
            labelCol={{ span: 24 }}
            validateStatus={errors.cityId && 'error'}
            help={errors.cityId?.message}
          >
            <CitySelectorTemp
              cities={countries.find((c) => c.id === selectedCountry)?.cities}
              selectedCity={watch('cityId')}
              onChange={handleCitySelectorChange}
            />
          </Form.Item>
        </Col>
        {selectedCountry === 1 && (
          <Col md={8}>
            <Form.Item
              required
              label={t('bin')}
              labelCol={{ span: 24 }}
              validateStatus={errors.bin && 'error'}
              help={errors.bin?.message}
            >
              <Input value={watch('bin')} maxLength={12} onChange={handleInputChange('bin')} />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t('contractors.supervisorEmail')}
            labelCol={{ span: 24 }}
            validateStatus={errors.supervisorEmail && 'error'}
            help={errors.supervisorEmail?.message}
          >
            <Input
              value={watch('supervisorEmail')}
              onChange={handleInputChange('supervisorEmail')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <Form.Item
            label={t('address')}
            labelCol={{ span: 24 }}
            validateStatus={errors.address && 'error'}
            help={errors.address?.message}
          >
            <Input value={watch('address')} onChange={handleInputChange('address')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24}>
          <br />
          <Form.Item noStyle>
            <Row justify="end">
              <Col>
                <Space align="end">
                  <Button htmlType="button" onClick={handleCancel}>
                    {t('cancel')}
                  </Button>
                  <Button htmlType="submit" type="primary" loading={mutation.isLoading}>
                    {t('save')}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
