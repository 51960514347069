/* eslint-disable react/jsx-handler-names,*/

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { actualApi } from '@api/actualApi';
import { forecastApi } from '@api/forecastApi';
import { goodWorkServiceApi } from '@api/goodWorksServiceApi';
import { CompanySelectorTemp } from '@components/atoms/CompanySelectorTemp';
import { CONTRACTUAL_RELATIONS_LIST } from '@components/constants/ContractualRelations';
import { CreateGwsForm } from '@components/organisms/CreateGwsForm';
import { NewCompanyForm } from '@components/organisms/NewCompanyForm';
import type { WorksFormValues } from '@components/organisms/WorksForm/libs/types/WorksFormValues';
import type { ActualWorksListItem } from '@components/types/models/Actuals';
import { CONTRACTUAL_RELATIONS } from '@components/types/models/ContractualRelations';
import type {
  ForecastWorkType,
  GoodWorkServiceType,
  RelationshipType,
} from '@components/types/models/Forecast';
import { UserContext } from '@contexts/userContext';
import { useDefaultQuery } from '@hooks';
import { css } from '@linaria/core';
import { isFloat, isPercentage } from '@utils/validate';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import type { SelectValue } from 'antd/es/select';
import type { AxiosError } from 'axios';
import axios from 'axios';
import moment from 'moment';
import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SectionType } from '@components/types/models/Forecast';
import tw from 'twin.macro';

import type { WorksFormProps } from './props';
import numeral from 'numeral';

export const WorksForm: FC<WorksFormProps> = ({
  forecastId,
  currency,
  selectedYear,
  selectedMonth,
  isEditable,
  handleProceed,
  companiesList,
  refetchCompaniesList,
  isActualForm,
  actualId,
  prePopulatedData,
}) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const { Column } = Table;
  const { Option } = Select;
  const { user } = useContext(UserContext);

  const [selectedRow, setSelectedRow] = useState<number>(0);
  const contractorId = user?.userRoles[0]?.entityId;
  const percentSymbol = '%';
  const [data, setData] = useState<readonly WorksFormValues[]>([]);

  const hasFourDecimalNumber = (value: string): boolean => /^(\d*)([,.]\d{0,4})?$/gmu.test(value)
  
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { data: gwsList, refetch: refetchGwsList } = useDefaultQuery<
    readonly GoodWorkServiceType[]
  >(
    'getGwsList',
    async () =>
      isEditable &&
      goodWorkServiceApi
        .getWorksList(Number(contractorId))
        .then((res) => res.data)
  );

  const {
    data: worksList,
    isLoading: isWorksListLoading,
    refetch: refetchWorksList,
  } = useDefaultQuery(
    ['getForecastWorks', selectedMonth, selectedYear, forecastId],
    async () => {
      if (!isActualForm && forecastId) {
        return forecastApi
          .getWorks(forecastId, selectedYear, selectedMonth)
          .then((res) => res.data);
      }

      return actualId && actualApi.getWorks(actualId).then((res) => res.data);
    }
  );

  const emptyRow = useMemo(
    () => ({
      year: selectedYear,
      month: selectedMonth,
      value: null,
      kcShare: null,
      companyId: null,
      relationType: null,
      kcWorkId: null,
      rowIndex: data.length > 1 ? data.length - 1 : 0,
    }),
    [selectedMonth, selectedYear, data]
  );

  useEffect(() => {
    if (worksList?.length > 0 && !isActualForm) {
      setData(
        worksList.map((work: ForecastWorkType, index: number) => ({
          year: work.year,
          month: work.month,
          value: work.forecastValue,
          kcShare: work.forecastKcShare,
          companyId: work.company.contractualRelations?.companyId,
          companyName: work.company.name,
          relationType: work.company.contractualRelations?.relationType,
          kcWorkId: work.kcWork?.id,
          kcWorkTitle: work.kcWork?.title,
          rowIndex: index,
        }))
      );
    } else if (worksList?.length > 0 && isActualForm) {
      setData(
        worksList.map((work: ActualWorksListItem, index: number) => ({
          value: work.actualValue,
          kcShare: work.kcShare,
          companyId: work.companyId,
          companyName: work.companyTitle,
          relationType: work.relationType,
          kcWorkId: work.kcWorkDto.id,
          kcWorkTitle: work.kcWorkDto.title,
          rowIndex: index,
        }))
      );
    } else if (!isEditable && worksList?.length === 0) {
      setData([]);
    } else {
      setData([emptyRow]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksList]);

  useEffect(() => {
    if (prePopulatedData) {
      setData(
        prePopulatedData.map((item, index) => ({
          kcWorkId: item.kcItemModel.id,
          relationType: item.relationType,
          companyId: item.companyDto.id,
          rowIndex: index,
        }))
      );
    }
  }, [prePopulatedData]);

  const handleAddRowClick = (): void => {
    setData([...data, { ...emptyRow, rowIndex: data.length }]);
  };

  const handleValueChange = (
    value: SelectValue | string,
    fieldName: string,
    rowId: number
  ): void => {
    setData((prevData) =>
      prevData.map((item, index) => {
        if (index === rowId) {
          return { ...prevData[index], [fieldName]: value };
        }
        return item;
      })
    );
  };

  const handleRowChange = (value?: number): void => {
    setSelectedRow(value ?? 0);
  };

  const handleDeleteClick = (index?: number): void => {
    setData(data.filter((item) => item.rowIndex !== index));
  };

  const addWorksMutation = useMutation(
    async (values: unknown) =>
      isActualForm
        ? axios.post(`/api/actual/gws/works/${actualId}`, values)
        : axios.post(`api/forecasts/works/${forecastId}`, values),
    {
      onSuccess() {
        void message.success(t('monthDataSaved'));
        handleProceed();
      },
      onError(err: AxiosError) {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError('message', { message: errData.message });
        }
      },
    }
  );

  const handleFormSubmit = (): void => {
    const filteredData = data.map((object) =>
      Object.fromEntries(
        // eslint-disable-next-line no-eq-null
        Object.entries(object).filter(
          ([key, v]) => v !== null && v !== '' && key !== 'rowIndex'
        )
      )
    );
    const forecastFormValues = {
      year: selectedYear,
      month: selectedMonth,
      gwsRecords: filteredData.map((item) => ({
        forecastValue: item.value,
        kcShare: item.kcShare,
        companyId: item.companyId,
        relationType: item.relationType,
        kcWorkId: item.kcWorkId,
      })),
    };

    const actualFormValues = {
      works: filteredData.map((item) => ({
        companyId: item.companyId,
        gwsId: item.kcWorkId,
        relationType: item.relationType,
        actualValue: item.value,
        kcShare: item.kcShare,
      })),
    };
    clearErrors();
    addWorksMutation.mutate(
      isActualForm ? actualFormValues : forecastFormValues
    );
  };

  const isTotalForm = useMemo(
    () => selectedYear <= moment().year() && selectedMonth === 0,
    [selectedYear, selectedMonth]
  );
  const isApprovedMonth = useMemo(
    () => worksList?.[0]?.isApproved,
    [worksList]
  );

  return isEditable && !isTotalForm && !isApprovedMonth ? (
    <Form onFinish={handleFormSubmit}>
      <Table
        bordered
        loading={isWorksListLoading || addWorksMutation.isLoading}
        locale={{ emptyText: t('noData') }}
        dataSource={data}
        pagination={false}
        scroll={{ x: 2000 }}
        onRow={(_, rowIndex) => ({
          onClick: () => {
            handleRowChange(rowIndex);
          },
        })}
      >
        <Column
          key="rowIndex"
          fixed
          dataIndex="rowIndex"
          width={50}
          render={(title) => <Text>{Number(title) + 1}</Text>}
        />
        <Column
          key="description"
          fixed
          title={t('forecasts.worksDescription')}
          width={300}
          render={(item) => (
            <Row gutter={8}>
              <Col span={20}>
                <Form.Item
                  validateStatus={
                    (errors.gwsRecords?.[item?.rowIndex]?.KcWorkId ||
                      errors.works?.[item?.rowIndex]?.GWSId) &&
                    'error'
                  }
                  help={
                    errors.gwsRecords?.[item?.rowIndex]?.KcWorkId?.message ||
                    errors.works?.[item?.rowIndex]?.GWSId?.message
                  }
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    value={item.kcWorkId}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      handleValueChange(value, 'kcWorkId', selectedRow);
                    }}
                  >
                    {gwsList?.map(
                      (work: GoodWorkServiceType, index: number) => (
                        <Option key={index} value={work.id}>
                          {work.title}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <CreateGwsForm
                  sectionType={SectionType.Work}
                  refetch={refetchGwsList}
                  title={t('forecasts.newWork')}
                  api={`/api/contractor/${contractorId}/work`}
                  successTitle={t('forecasts.workAdded')}
                  handleValueChange={(value: number) => {
                    handleValueChange(value, 'kcWorkId', selectedRow);
                  }}
                />
              </Col>
            </Row>
          )}
        />
        <Column
          key="companyName"
          title={t('forecasts.companyName')}
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.CompanyId ||
                  errors.works?.[item?.rowIndex]?.CompanyId) &&
                'error'
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.CompanyId?.message ||
                errors.works?.[item?.rowIndex]?.CompanyId?.message
              }
            >
              <Row gutter={8}>
                <Col span={20}>
                  <CompanySelectorTemp
                    selectedCompany={item.companyId}
                    companiesList={companiesList}
                    className={css`
                      ${tw`w-full`}
                    `}
                    onChange={(value) => {
                      handleValueChange(value, 'companyId', selectedRow);
                      if (companiesList?.[0].id === value) {
                        setData((prevData) =>
                          prevData.map((_, index) => ({
                            ...prevData[index],
                            relationType: CONTRACTUAL_RELATIONS.CONTRACTOR,
                          }))
                        );
                      }
                    }}
                  />
                </Col>
                <Col span={4}>
                  <NewCompanyForm
                    handleValueChange={(value: number) => {
                      handleValueChange(value, 'companyId', selectedRow);
                    }}
                    refetchCountriesList={refetchCompaniesList}
                  />
                </Col>
              </Row>
            </Form.Item>
          )}
        />
        <Column
          key="contractualRelationship"
          title={t('forecasts.contractualRelationship')}
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.RelationType ||
                  errors.works?.[item?.rowIndex]?.RelationType) &&
                'error'
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.RelationType?.message ||
                errors.works?.[item?.rowIndex]?.RelationType?.message
              }
            >
              <Select
                disabled={companiesList?.[0].id === item.companyId}
                value={item.relationType}
                style={{ width: '100%' }}
                onChange={(value) => {
                  handleValueChange(value, 'relationType', selectedRow);
                }}
              >
                {CONTRACTUAL_RELATIONS_LIST.map(
                  (relationship: RelationshipType, index: number) => (
                    <Option key={index} value={relationship.value}>
                      {t(`forecasts.${relationship.label}`)}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          )}
        />
        <Column
          key="value"
          title={
            isActualForm
              ? t('actuals.actualValue')
              : t('forecasts.forecastValue')
          }
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.ForecastValue ||
                  errors.works?.[item?.rowIndex]?.ActualValue) &&
                'error'
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.ForecastValue?.message ||
                errors.works?.[item?.rowIndex]?.ActualValue?.message
              }
            >
              <Row gutter={8} align="middle">
                <Col span={20}>
                  <Input
                    type="number"
                    value={item.value}
                    onChange={(e) => {
                      if (isFloat(e.target.value)) {
                        handleValueChange(e.target.value, 'value', selectedRow);
                      }
                    }}
                  />
                </Col>
                <Col span={4}>
                  <Title level={5}>{currency}</Title>
                </Col>
              </Row>
            </Form.Item>
          )}
        />
        <Column
          key="kcShare"
          title={t('forecasts.staffPercent')}
          render={(item) => (
            <Form.Item
              validateStatus={
                (errors.gwsRecords?.[item?.rowIndex]?.KcShare ||
                  errors.works?.[item?.rowIndex]?.KcShare) &&
                'error'
              }
              help={
                errors.gwsRecords?.[item?.rowIndex]?.KcShare?.message ||
                errors.works?.[item?.rowIndex]?.KcShare?.message
              }
            >
              <Row gutter={8} align="middle">
                <Col span={20}>
                  <Input
                    value={item.kcShare}
                    type="number"
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      if (
                        isPercentage(value) &&
                        hasFourDecimalNumber(value)
                      ) {
                        handleValueChange(value, 'kcShare', selectedRow);
                      }
                    }}
                  />
                </Col>
                <Col span={4}>
                  <Title level={5}>{percentSymbol}</Title>
                </Col>
              </Row>
            </Form.Item>
          )}
        />
        <Column
          title={
            isActualForm
              ? t('actuals.kcActualValue')
              : t('forecasts.kcForecastValue')
          }
          render={(item) => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            const roundedKcForecastValue = item.value * (item.kcShare / 100);
            return (
              <Text>
                {roundedKcForecastValue
                  ? `${numeral(roundedKcForecastValue).format(
                      '0,0.00'
                    )} ${currency}`
                  : '-'}
              </Text>
            );
          }}
        />
        <Column
          key="action"
          width={100}
          render={(item) => (
            <Button
              danger
              title={t('delete')}
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => {
                handleDeleteClick(item.rowIndex);
              }}
            />
          )}
        />
      </Table>
      <Row style={{ marginTop: '24px' }} justify="space-between">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddRowClick}
        >
          {t('forecasts.addWork')}
        </Button>
        <Button
          htmlType="submit"
          className="secondary-button"
          loading={addWorksMutation.isLoading}
          style={{ marginLeft: 'auto' }}
        >
          {isActualForm
            ? t('forecasts.saveAndGoToTheNextSection')
            : t('forecasts.saveAndProceed')}
        </Button>
      </Row>
    </Form>
  ) : (
    <>
      <Table
        bordered
        loading={isWorksListLoading}
        locale={{ emptyText: t('noData') }}
        dataSource={data}
        pagination={false}
        scroll={{ x: 2000 }}
      >
        <Column
          key="rowIndex"
          fixed
          dataIndex="rowIndex"
          width={50}
          render={(title) => <Text>{Number(title) + 1}</Text>}
        />
        <Column
          key="description"
          fixed
          title={t('forecasts.worksDescription')}
          width={300}
          dataIndex="kcWorkTitle"
        />
        <Column
          key="companyName"
          title={t('forecasts.companyName')}
          dataIndex="companyName"
        />
        <Column
          key="contractualRelationship"
          title={t('forecasts.contractualRelationship')}
          dataIndex="relationType"
          render={(relationType) => (
            <Text>
              {t(
                `forecasts.${
                  CONTRACTUAL_RELATIONS_LIST.find(
                    (relationship) => relationship.value === relationType
                  )?.label
                }`
              )}
            </Text>
          )}
        />
        <Column
          key="value"
          dataIndex="value"
          title={
            isActualForm
              ? t('actuals.actualValue')
              : t('forecasts.forecastValue')
          }
          render={(value) => (
            <Text>
              {numeral(value).format('0,0.00')} {currency}
            </Text>
          )}
        />
        <Column
          key="kcShare"
          dataIndex="kcShare"
          title={t('forecasts.staffPercent')}
          render={(kcShare) => (
            <Text>
              {kcShare} {percentSymbol}
            </Text>
          )}
        />
        <Column
          title={
            isActualForm
              ? t('actuals.kcActualValue')
              : t('forecasts.kcForecastValue')
          }
          render={(item) => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            const roundedKcForecastValue = item.value * (item.kcShare / 100);
            return (
              <Text>
                {numeral(roundedKcForecastValue).format('0,0.00')} {currency}
              </Text>
            );
          }}
        />
      </Table>
      <Row style={{ marginTop: '24px' }} justify="space-between">
        <Button
          htmlType="submit"
          className="secondary-button"
          style={{ marginLeft: 'auto' }}
          onClick={handleProceed}
        >
          {t('forecasts.proceed')}
        </Button>
      </Row>
    </>
  );
};
