import {
  AlignLeftOutlined,
  AreaChartOutlined,
  CheckOutlined,
  DollarOutlined,
  FileTextOutlined,
  SnippetsOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { ActualsList } from '@components/pages/contractor/Actual/ActualsList';
import { ActualView } from '@components/pages/contractor/Actual/ActualView';
import { ContractView } from '@components/pages/contractor/Contract';
import { ContractsList } from '@components/pages/contractor/Contract/ContractsList';
import { ContractorForecastsList } from '@components/pages/contractor/Forecast/ContractorForecastsList';
import { ForecastView } from '@components/pages/contractor/Forecast/ForecastView';
import { InvoicesList } from '@components/pages/contractor/Invoice';
import { InvoiceView } from '@components/pages/contractor/Invoice/InvoiceView';
import { ContractorMembersList } from '@components/pages/contractor/Member/ContractorMembersList';
import { ContractorDashboardPage } from '@components/pages/contractor/Reports';
import { ContractorDatadumpListPage } from '@components/pages/contractor/Reports/ContractorDatadumpListPage';
import { ContractorUserGuide } from '@components/pages/contractor/UserGuide';
import { GlossaryPage } from '@components/pages/GlossaryPage';
// Import { ErrorPage } from '@components/pages/ErrorPage';
import { Profile } from '@components/pages/Profile';
import { DefaultLayout } from '@components/templates/DefaultLayout';
import type { RouteConfig, RouteItem } from '@components/types/RouteConfig';
import { UserContext } from '@contexts/userContext';
import React, { useContext, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

const contractorRoutes: RouteConfig = [
  {
    id: 'profile',
    path: '/profile',
    component: Profile,
  },
  {
    id: 'contractor.reports',
    text: 'reports.reports',
    icon: <AreaChartOutlined />,
    items: [
      {
        id: 'contractor.dashboards',
        text: 'reports.dashboards',
        path: '/contractor/reports',
        component: ContractorDashboardPage,
      },
      {
        id: 'contractor.datadumps',
        text: 'reports.datadump',
        path: '/contractor/datadump',
        component: ContractorDatadumpListPage,
      },
    ],
    isNavItem: true,
  },
  {
    id: 'contracts',
    text: 'contractors.contracts',
    icon: <SnippetsOutlined />,
    path: '/contractor/contracts',
    isNavItem: true,
    component: ContractsList,
  },
  {
    id: 'members',
    text: 'members.members',
    icon: <UsergroupAddOutlined />,
    path: '/contractor/members',
    isNavItem: true,
    component: ContractorMembersList,
  },
  {
    id: 'contract',
    path: '/contractor/contract/:id',
    component: ContractView,
  },
  {
    id: 'forecasts',
    text: 'forecasts.forecasts',
    icon: <FileTextOutlined />,
    path: '/contractor/forecasts',
    component: ContractorForecastsList,
    isNavItem: true,
  },
  {
    id: 'forecast',
    path: '/contractor/forecast/:id',
    component: ForecastView,
  },
  {
    id: 'actuals',
    text: 'actuals.actuals',
    icon: <CheckOutlined />,
    path: '/contractor/actuals',
    component: ActualsList,
    isNavItem: true,
  },
  {
    id: 'actual',
    path: '/contractor/actual/details',
    component: ActualView,
  },
  {
    id: 'invoices',
    text: 'invoices.g1cForms',
    icon: <DollarOutlined />,
    path: '/contractor/payment-application/all',
    component: InvoicesList,
    isNavItem: true,
  },
  {
    id: 'invoice',
    path: '/contractor/payment-application/:id',
    component: InvoiceView,
  },
  {
    id: 'glossary',
    text: 'glossary.glossary',
    icon: <AlignLeftOutlined />,
    path: '/glossary',
    component: GlossaryPage,
    isNavItem: true,
  },
  {
    id: 'userGuide',
    path: '/contractor/user-guide',
    component: ContractorUserGuide,
  },
];

const ContractorRoutes: React.FC = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  const filteredAdminRoutes = useMemo(
    () =>
      contractorRoutes.filter((route) =>
        route.permissions ? route.permissions.every((p) => user?.permissions.includes(p)) : true
      ),
    [user]
  );

  return (
    <DefaultLayout
      navItems={filteredAdminRoutes}
      isEmbedContent={location.pathname === '/contractor/reports'}
    >
      <Switch>
        {filteredAdminRoutes.map((route: RouteItem, i) => {
          if (route.component) {
            return <Route key={`${i}.0`} exact path={route.path} component={route.component} />;
          }

          return route.items?.map((subRoute: RouteItem, j) => (
            <Route key={`${i}.${j}`} exact path={subRoute.path} component={subRoute.component} />
          ));
        })}
        <Route exact path="/" render={() => <Redirect to="/profile" />} />
        {/* <Route component={ErrorPage} /> */}
      </Switch>
    </DefaultLayout>
  );
};

export default ContractorRoutes;
