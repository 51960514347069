export const enContractors = {
  country: 'Country',
  contractorName: 'Contractor name',
  workPhoneNumber: 'Work phone number',
  city: 'City',
  supervisorEmail: 'Client supervisor email',
  address: 'Address',
  companyInformation: 'Company information',
  memberInformation: 'Member information',
  email: 'Email',
  phoneNumber: 'Phone number',
  firstName: 'First name',
  lastName: 'Last name',
  jobTitle: 'Job title',
  successRegister: 'Contractor is successfully registered',
  registerContractor: 'Register contractor',
  register: 'Register',
  cancel: 'Cancel',
  contractors: 'Contractors',
  contracts: 'Contracts',
  subcontractors: 'Subcontractors',
  contractNumber: 'Contract number',
  version: 'Version',
  type: 'Type',
  masterAgreement: 'Master agreement',
  status: 'Status',
  name: 'Name',
  bin: 'BIN',
  details: 'Details on scope of work ',
  relationship: 'Contractual relationship',
  addContract: 'Add contract',
  newContract: 'New contract',
  kcCategoryAndArea: 'KC Category and Area',
  contractorsData: "Contractor's data",
  confirmDelete: 'Are you sure you want to delete contractor: {{name}}?',
  contractorDeleted: 'Contractor successfully deleted',
  editContractor: 'Edit contractor',
  successUpdate: 'Contractor successfully saved',
  countryOfResidence: 'Country of Residence',
};
